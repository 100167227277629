import { Component, Inject, OnInit, Optional } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs/Subscription";
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService,ToastService } from "../../../../../shared/services";
import { ApiRoutes } from "app/shared/routes";
import { LocalStorage } from "../../../../../storage";
import { triangleBall, countryCodeHiphen, objToDateObj2 } from 'app/shared/utility';

@Component({
	selector: "app-passengers-edit-modal",
	templateUrl: "./passengers-edit-modal.component.html",
	styleUrls: ["./passengers-edit-modal.component.scss"],
})
export class PassengersEditModalComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;
	subscription: Subscription = new Subscription();
	selectedDoc: any;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

	constructor(private toastr: ToastService,private ls: LocalStorage, private ds: DataService, private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<PassengersEditModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get formControls() {
		return this.form.controls;
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		this.form = new FormGroup({
			user_name: new FormControl(this.data.pessengerDetail.user_name, [Validators.required]),
			user_mobile: new FormControl(this.data.pessengerDetail.user_mobile, [Validators.required]),
			user_email: new FormControl(this.data.pessengerDetail.user_email, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),]),
			image: new FormControl(this.data.pessengerDetail.user_image, [Validators.required]),
			user_id: new FormControl(this.data.pessengerDetail.user_id),
			is_mobile_changed: new FormControl(0),
			is_email_changed: new FormControl(0),
			with_otp: new FormControl(0),
			image_flag: new FormControl(1),
		}, { updateOn: "change" });
		const temp1 = this.form.get("user_mobile").valueChanges.subscribe((data) => {
			if (
				this.data.pessengerDetail.user_mobile ===
				this.form.get("user_mobile").value
			) {
				this.form.get("is_mobile_changed").setValue(0);
			} else {
				this.form.get("is_mobile_changed").setValue(1);
			}
		});
		const temp2 = this.form.get("user_email").valueChanges.subscribe((data) => {
			if (
				this.data.pessengerDetail.user_email ===
				this.form.get("user_email").value
			) {
				this.form.get("is_email_changed").setValue(0);
			} else {
				this.form.get("is_email_changed").setValue(1);
			}
		});
		this.subscription.add(temp1);
		this.subscription.add(temp2);
	}

	/**
	 * Description: file change event
	 */
	onFileChanged(event): void {
		
		if (event.target.files && event.target.files[0]) {
			var ext = event.target.files[0].name.match(/\.(.+)$/)[1];
			if (ext === 'JPG' || ext === 'JPEG' || ext === 'PNG' || ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
			var reader = new FileReader();
			this.selectedDoc = event.target.files[0];
			reader.readAsDataURL(event.target.files[0]); // read file as data url
			reader.onload = (event) => {
				// called once readAsDataURL is completed
				this.form.get("image").setValue(event.target.result);
			};
			}
			else {
				this.toastr.error("Invalid File Format")
			}

		}
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.editUser;
		const accessToken: string = this.ls.getItem("qudos-admin-token");
		var form = new FormData();
		form.append("user_name", this.form.get("user_name").value);
		form.append("user_mobile", countryCodeHiphen(this.form.get("user_mobile").value));
		form.append("user_email", this.form.get("user_email").value);
		form.append("image", this.selectedDoc);
		form.append("user_id", this.form.get("user_id").value);
		form.append("is_mobile_changed", this.form.get("is_mobile_changed").value);
		form.append("is_email_changed", this.form.get("is_email_changed").value);
		form.append("with_otp", this.form.get("with_otp").value);
		form.append("image_flag", this.form.get("image_flag").value);
		form.append("access_token", accessToken);
		this.ds.formDataRequest(url, form).subscribe(res => {
			if (res.flag === 115) {
				this.toastr.success(res.log);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}
}
