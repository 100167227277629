<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">{{ data?.title }}</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row" *ngIf="data?.isDate">
					<label class="col-sm-3 col-form-label">Demo Date</label>
					<div class="col-sm-8">
						<input required class="form-control" type="date" placeholder="dd/mm/yyyy" min="{{data?.minDate}}" formControlName="date">
						<div *ngIf="formControls.date.invalid && isSubmitting" class="help-block form-err text-danger">
							<small [hidden]="!formControls.date.errors?.required">*Date is required.</small>
						</div>
					</div>
				</div>

				<div class="form-group row" *ngIf="data?.isTime">
					<label class="col-sm-3 col-form-label">Demo Time</label>
					<div class="col-sm-8">
						<select class="form-control" formControlName="time">
							<option value="">hh:mm</option>
							<option value="9:00 - 10:00 AM">9:00 - 10:00 AM</option>
							<option value="10:00 - 11:00 AM">10:00 - 11:00 AM</option>
							<option value="11:00 - 12:00 AM">11:00 - 12:00 AM</option>
							<option value="1:00 - 2:00 PM">1:00 - 2:00 PM</option>
							<option value="2:00 - 3:00 PM">2:00 - 3:00 PM</option>
							<option value="3:00 - 4:00 PM">3:00 - 4:00 PM</option>
							<option value="4:00 - 5:00 PM">4:00 - 5:00 PM</option>
						</select>
						<div *ngIf="formControls.time.invalid && isSubmitting" class="help-block form-err text-danger">
							<small [hidden]="!formControls.time.errors?.required">*Time is required.</small>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">

	<mat-dialog-actions align="end" class="dialog-footer justify-content-end">
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">{{ data?.cancelButtonText }}</button>
		<button mat-button class="btn btn-primary button-dialog ml-2" (click)="submitHandler()">{{ data?.confirmButtonText }}</button>
	</mat-dialog-actions>
</section>