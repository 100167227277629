<router-outlet></router-outlet>
<div class="spinner-wrapper">
	<ngx-spinner>
		<p class="mt-5" style="color: white">{{ ds?.spinnerTxt }}</p>
	</ngx-spinner>
</div>

<div class="mobile-back-home-button d-md-none"
	*ngIf="currentUrl != '/dashboard' && currentUrl != '/login' && currentUrl != '/signup' && currentUrl != '/forgot-password'">
	<span class="m-2" (click)="location.back()">
		<img src="assets/img/icons/keyboard_backspace.svg" alt="backArrow" />
	</span>
	|
	<span class="m-2" routerLink="/dashboard">
		<img src="assets/img/icons/drafts.svg" alt="backArrow" />
	</span>
</div>
<!-- https://codesandbox.io/s/swimlane-filter-table-lldho?file=/src/app/filter-table.component.ts -->