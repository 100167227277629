import { Component, Inject, OnInit, Optional, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from '../../../shared/services';
import { ApiRoutes } from '../../../shared/routes';
import { LocalStorage } from '../../../storage';
import { triangleBall } from 'app/shared/utility';
import { ToastService } from '../../../shared/services';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';

@Component({
	selector: 'app-new-account-modal',
	templateUrl: './new-account-modal.component.html',
	styleUrls: ['./new-account-modal.component.scss']
})
export class NewAccountModalComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;
	countryCode: string = '+1';
	countryList: any[] = [];
	stateList: any[] = [];

	constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private toastr: ToastService, private ls: LocalStorage, private ds: DataService, private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<NewAccountModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.getCountries();
		this.initForm();
	}

	get formControls() {
		return this.form.controls;
	}

	private getCountries(): void {
		const url: string = ApiRoutes.getCountryListData;
		this.ds.get(url).subscribe(res => {
			this.countryList = res.countries;
			this.cdr.markForCheck();
		});
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		this.form = new FormGroup({
			first_name: new FormControl({ value: this.data.currentUser.first_name, disabled: true }, [Validators.required]),
			last_name: new FormControl({ value: this.data.currentUser.last_name, disabled: true }, [Validators.required]),
			email: new FormControl({ value: this.data.currentUser.email, disabled: true }, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			mobile: new FormControl({ value: this.data.currentUser.mobile, disabled: true }, [Validators.required]),
			zipcode: new FormControl(this.data.currentUser.zipcode, [Validators.required]),
			vehicles_owned: new FormControl('', [Validators.required]),
			// this.data.currentUser.last_year_revenue
			last_year_revenue: new FormControl('', [Validators.required]),
			business_name: new FormControl("", [Validators.required]),
			ein_number: new FormControl("", [Validators.required]),
			country: new FormControl("", [Validators.required]),
			states: new FormControl("", [Validators.required]),
			regulation_type: new FormControl("", [Validators.required]),
		}, { updateOn: 'change' });
	}

	onCountryChange(e): void {
		this.getStates(e.target.value)
	}

	private getStates(CountryId): void {
		const url: string = ApiRoutes.getStateList;
		const obj = {
			country_id: +CountryId,
			state_id: 0
		}
		this.ds.post(url, obj).subscribe(res => {
			this.stateList = res.states;
			this.cdr.markForCheck();
		});
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			this.toastr.error('Please fill the required fields');
			return;
		}
		this.spinner.show(undefined, triangleBall);
		this.isSubmitting = true;
		const url: string = ApiRoutes.checkBusinessEinAvailability;
		const payload = {
			business_name: this.form.get('business_name').value,
			ein_number: this.form.get('ein_number').value
		};
		this.ds.post(url, payload).subscribe(res => {
			if (res.flag === 8022) {
				this.getOtp();
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	private getOtp(): void {
		// this.spinner.show(undefined, triangleBall);
		let modalParams: any = {
			action: 'Client OTP Verification',
			description: 'Enter OTP sent to your mobile number ' + this.form.get('mobile').value,
			confirmButtonText: 'Submit',
			cancelButtonText: 'Cancel',
			isResendMobileChange: 1,
			data: this.form.getRawValue()
		};
		const data = { modalParams: modalParams };
		const url: string = ApiRoutes.sendOtp;
		const payload = {
			email: this.form.get('email').value,
			mobile: this.form.get('mobile').value,
			new_business_otp: 1
		};
		this.ds.post(url, payload).subscribe(res => {
			if (res.flag === 7) {
				this.toastr.success(res.message);
				const dialogRef = this.dialog.open(OtpModalComponent, { data, width: '500px' });
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.dialogRef.close({ status: true, data: data });
					}
				});
			} else if (res.flag === 0) {
				this.toastr.error(res.message);
			}
			// this.spinner.hide();
		}, err => this.spinner.hide());
	}
}