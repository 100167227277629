import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs/Subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService ,ToastService} from "../../../../../shared/services";
import { ApiRoutes } from "app/shared/routes";
import { LocalStorage } from '../../../../../storage';
import { triangleBall, countryCodeHiphen } from "../../../../../shared/utility";

@Component({
	selector: 'app-affiliates-edit-modal',
	templateUrl: './affiliates-edit-modal.component.html',
	styleUrls: ['./affiliates-edit-modal.component.scss']
})
export class AffiliatesEditModalComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;
	subscription: Subscription = new Subscription();
	selectedDoc: any;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

	constructor(private spinner: NgxSpinnerService, private ls: LocalStorage, private toastr: ToastService,private ds: DataService, private dialogRef: MatDialogRef<AffiliatesEditModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get formControls() {
		return this.form.controls;
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		this.form = new FormGroup({
			first_name: new FormControl(this.data.vendorDetail.first_name, [Validators.required]),
			last_name: new FormControl(this.data.vendorDetail.last_name, [Validators.required]),
			email: new FormControl(this.data.vendorDetail.email, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			mobile: new FormControl(this.data.vendorDetail.mobile, [Validators.required]),
			image: new FormControl(this.data.vendorDetail.image, [Validators.required]),

			date_registered: new FormControl({ value: this.dateString, disabled: true }),
			zipcode: new FormControl(this.data.vendorDetail.zipcode, [Validators.required]),
			passport_number: new FormControl(this.data.vendorDetail.passport_number),
			dmv_num: new FormControl(this.data.vendorDetail.dmv_num),
			vendor_id: new FormControl(this.data.vendorDetail.vendor_id, [Validators.required]),
			image_flag: new FormControl(1),
		}, { updateOn: "change" });
	}

	get dateString(): string {
		let date: any = new Date(this.data.vendorDetail.date_registered);
		date = date.toDateString().replace(/^\S+\s/,'');
		return date;
	}

	/**
	 * Description: file change event
	 */
	onFileChanged(event): void {
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			this.selectedDoc = event.target.files[0];
			reader.readAsDataURL(event.target.files[0]); // read file as data url
			reader.onload = (event) => {
				// called once readAsDataURL is completed
				this.form.get("image").setValue(event.target.result);
			};
		}
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall)
		const url: string = ApiRoutes.editVendor;
		const formData = new FormData();
		formData.append('access_token', this.ls.getItem('qudos-admin-token'));
		formData.append('dmv_num', this.form.get('dmv_num').value);
		formData.append('email', this.form.get('email').value);
		formData.append('first_name', this.form.get('first_name').value);
		formData.append('image', this.selectedDoc);
		formData.append('image_flag', this.selectedDoc ? '1' : '0');
		formData.append('last_name', this.form.get('last_name').value);
		formData.append('mobile', countryCodeHiphen(this.form.get('mobile').value));
		formData.append('passport_number', this.form.get('passport_number').value);
		formData.append('vendor_id', this.form.get('vendor_id').value);
		formData.append('zipcode', this.form.get('zipcode').value);
		this.ds.formDataRequest(url, formData).subscribe(res => {
			if (res.flag === 1115) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}
}
