import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { AuthService } from '../shared/auth';
import { LocalStorage } from '../storage';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService, private ls: LocalStorage) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = (this.auth.isAuthenticated() && this.ls.getItem('qudos-admin-token')) ? this.auth.token : '';
		const currentUser: any = this.ls.getItem('qudos-admin-currentUser');
		const selectedClient: any = this.ls.getItem('qudos-admin-selectedClient');
		const selectedBusiness: any = this.ls.getItem('qudos-admin-selectedBusiness');
		let obj = {};
		if (token && currentUser) {
			if (selectedClient) {
				if (!request?.body?.admin_id) {
					obj = { access_token: token, admin_id: selectedClient.admin_id };
				} else {
					obj = { access_token: token };
				}
			} else if (selectedBusiness) {
				obj = { access_token: token, admin_id: selectedBusiness.admin_id };
			} else {
				if (currentUser.parent_id && (currentUser.admin_type == 2 || currentUser.admin_type == 3)) {
					if (request.body?.useParentIdAsAdminId && request.body.useParentIdAsAdminId === 'false') {
						obj = { access_token: token };
					} else {
						obj = { access_token: token, admin_id: currentUser.parent_id == 1 ? 0 : currentUser.parent_id };
					}
				} else {
					obj = { access_token: token };
				}
			}
		}
		if (request.body?.useParentIdAsAdminId) {
			delete request.body.useParentIdAsAdminId;
		}
		if (request.headers.get('Anonymous') !== 'undefined') {
			request = request.clone({
				setHeaders: {
					Authorization: token
				},
				body: { ...request.body, ...obj }
			});
		}
		return next.handle(request);
	}
}