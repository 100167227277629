<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">Edit Affiliate Info</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Photo</label>
					<div class="col-sm-10">
						<input class="d-none" type="file" accept="image/x-png,image/jpeg,image/jpg" (change)="onFileChanged($event)" #fileInput accept="image/x-png,image/jpeg,image/jpg">
						<div class="user-avatar-sm user-avatar-edit">
							<!-- <img (click)="fileInput.click()" class="edit-icon pointer" src="assets/img/icons/edit-white.png" alt="edit"> -->
							<div (click)="fileInput.click()" class="overlay"></div>
							<img *ngIf="!formControls.image.value" class="profile-pic" src="assets/img/attach2.jpg" alt="user-avatal">
							<img *ngIf="formControls.image.value" class="profile-pic" [src]="formControls.image.value"
								alt="user-avatal">
						</div>
						<div *ngIf="formControls.image.invalid && isSubmitting" class="help-block form-err text-danger">
							<small [hidden]="!formControls.image.errors?.required">*Image is required.</small>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">First Name</label>
							<div class="col-sm-8">
								<input formControlName="first_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.first_name.invalid,
									'is-valid': isSubmitting && !formControls.first_name.invalid
								}">
								<div *ngIf="formControls.first_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.first_name.errors?.required">*First name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Last Name</label>
							<div class="col-sm-8">
								<input formControlName="last_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.last_name.invalid,
									'is-valid': isSubmitting && !formControls.last_name.invalid
								}">
								<div *ngIf="formControls.last_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_name.errors?.required">*Last name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Email</label>
							<div class="col-sm-8">
								<input formControlName="email" type="email" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.email.invalid,
									'is-valid': isSubmitting && !formControls.email.invalid
								}">
								<div *ngIf="formControls.email.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.email.errors?.required">*Email is required.</small>
									<small [hidden]="!formControls.email.errors?.pattern">*Invalid email.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Phone</label>
							<div class="col-sm-8">
								<!-- <input formControlName="mobile" type="tel" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.mobile.invalid,
									'is-valid': isSubmitting && !formControls.mobile.invalid
								}"> -->
								<ngx-intl-tel-input
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15"
                                    [tooltipField]="TooltipLabel.Name"
                                    [phoneValidation]="true"
                                    [separateDialCode]="false"
                                    name="mobile"
                                    [inputId]="'mobile'"
                                    formControlName="mobile"
                                    class="form-control intl position-rel"
                                    [customPlaceholder]="'Enter phone number'">
                                </ngx-intl-tel-input>
								<div *ngIf="formControls.mobile.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.mobile.errors?.required">*Contact is
										required.</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Registration Date</label>
							<div class="col-sm-8">
								<input formControlName="date_registered" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.date_registered.invalid,
									'is-valid': isSubmitting && !formControls.date_registered.invalid
								}">
								<div *ngIf="formControls.date_registered.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.date_registered.errors?.required">*Registration Date
										is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Zipcode</label>
							<div class="col-sm-8">
								<input formControlName="zipcode" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.zipcode.invalid,
									'is-valid': isSubmitting && !formControls.zipcode.invalid
								}">
								<div *ngIf="formControls.zipcode.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.zipcode.errors?.required">*Zipcode is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Passport Number</label>
							<div class="col-sm-8">
								<input formControlName="passport_number" type="text" class="form-control">
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">DMV License</label>
							<div class="col-sm-8">
								<input formControlName="dmv_num" type="text" class="form-control">
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions  class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-4 offset-md-8">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button (click)="submitHandler()" mat-button class="btn btn-primary button-dialog ml-2">Save</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>