<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">Edit Passenger Detail</h2>
	<hr class="cutom-divider m-0">
	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Photo</label>
					<div class="col-sm-10">
						<input class="d-none" type="file" accept="image/x-png,image/jpeg,image/jpg" (change)="onFileChanged($event)" #fileInput accept="image/*">
						<div class="user-avatar-sm user-avatar-edit">
							<!-- <img (click)="fileInput.click()" class="edit-icon pointer" src="assets/img/icons/edit-white.png" alt="edit"> -->
							<div (click)="fileInput.click()" class="overlay"></div>
							<img *ngIf="!formControls.image.value" class="profile-pic" src="assets/img/attach2.jpg" alt="user-avatal">
							<img *ngIf="formControls.image.value" class="profile-pic" [src]="formControls.image.value"
								alt="user-avatal">
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Name</label>
					<div class="col-sm-10">
						<input formControlName="user_name" type="text" class="form-control" [ngClass]="{
							'is-invalid': isSubmitting && formControls.user_name.invalid,
							'is-valid': isSubmitting && !formControls.user_name.invalid
						}">
						<div *ngIf="formControls.user_name.invalid && isSubmitting"
							class="help-block form-err text-danger">
							<small [hidden]="!formControls.user_name.errors?.required">*Name is required.</small>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Contact</label>
					<div class="col-sm-10">
						<!-- <input formControlName="user_mobile" type="tel" class="form-control" [ngClass]="{
							'is-invalid': isSubmitting && formControls.user_mobile.invalid,
							'is-valid': isSubmitting && !formControls.user_mobile.invalid
						}"> -->
						<ngx-intl-tel-input
							[preferredCountries]="preferredCountries"
							[enableAutoCountrySelect]="true"
							[enablePlaceholder]="true"
							[searchCountryFlag]="true"
							[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
							[selectFirstCountry]="false"
							[selectedCountryISO]="CountryISO.UnitedStates"
							[maxLength]="15"
							[tooltipField]="TooltipLabel.Name"
							[phoneValidation]="true"
							[separateDialCode]="false"
							name="user_mobile"
							[inputId]="'user_mobile'"
							formControlName="user_mobile"
							class="form-control intl position-rel"
							[customPlaceholder]="''">
						</ngx-intl-tel-input>
						<div *ngIf="formControls.user_mobile.invalid && isSubmitting"
							class="help-block form-err text-danger">
							<small [hidden]="!formControls.user_mobile.errors?.required">*Contact is required.</small>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Email</label>
					<div class="col-sm-10">
						<input formControlName="user_email" type="email" class="form-control" [ngClass]="{
							'is-invalid': isSubmitting && formControls.user_email.invalid,
							'is-valid': isSubmitting && !formControls.user_email.invalid
						}">
						<div *ngIf="formControls.user_email.invalid && isSubmitting"
							class="help-block form-err text-danger">
							<small [hidden]="!formControls.user_email.errors?.required">*Email is required.</small>
							<small [hidden]="!formControls.user_email.errors?.pattern">*Invalid email.</small>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="center" class="dialog-footer">
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
		<button (click)="submitHandler()" mat-button class="btn btn-primary button-dialog ml-2">Save</button>
	</mat-dialog-actions>
</section>