import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs/Rx";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";

import { ApiRoutes, APP_ROUTES } from "../routes";
import { LocalStorage } from "../../storage";

@Injectable()
export class AuthService {
	private authToken: string;
	private authState: BehaviorSubject<boolean>;
	private initialData: string[] = ["qudos-admin-token"];

	constructor(
		private http: HttpClient,
		private router: Router,
		private ls: LocalStorage
	) {
		this.authState = new BehaviorSubject(false);
		this.initialData.forEach((value) => {
			this[value] = this.ls.getItem(value);
			if (value === "qudos-admin-token") {
				this.authToken = this[value];
			}
		});
	}

	/**
	 * Login action
	 * @param payload is credentials of the user
	 */
	public login(payload) {
		// this.ls.setItem("qudos-admin-token", "jwt-token");
		// this.token = "jwt-token";
		const url: string = ApiRoutes.login;
		return this.http.post(url, payload).pipe(
			map((data: any) => {
				if (data.access_token) {
					this.ls.setItem("qudos-admin-token", data.access_token);
					this.ls.setItem("qudos-admin-currentUser", JSON.stringify(data));
					// this.router.navigate(["/dashboard"]);
					this.token = data.access_token;
				}
				return data;
			})
		);
	}

	//* get decoded token */
	public getToken(): string {
		const helper = new JwtHelperService();
		return helper.decodeToken(this.token);
	}

	/**
	 * Login action
	 * @param token is JWT token
	 */
	public set token(token: string) {
		this.authToken = token;
		this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : "";
	}

	/**
	 * Change auth state of a user
	 * @param newState of the user
	 */
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logiut action */
	public logout(reload: boolean = true): void {
		this.ls.clear();
		this.token = null;
		this.changeAuthState = !!this.token;
		if (reload) {
			window.location.reload();
		} else {
			this.router.navigate([APP_ROUTES.login]);
		}
	}
}
