import { NgModule } from "@angular/core";

import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDialogModule } from "@angular/material/dialog";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatChipsModule } from "@angular/material/chips";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
	exports: [
		MatInputModule,
		MatIconModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatAutocompleteModule,
		MatSliderModule,
		MatRadioModule,
		MatSlideToggleModule,
		MatTableModule,
		MatExpansionModule,
		MatDialogModule,
		MatPaginatorModule,
		MatSortModule,
		MatToolbarModule,
		MatChipsModule,
		MatTabsModule,
		MatMenuModule,
		MatCardModule,
		ClipboardModule
	],
	imports: [
		MatInputModule,
		MatIconModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatAutocompleteModule,
		MatSliderModule,
		MatRadioModule,
		MatSlideToggleModule,
		MatTableModule,
		MatExpansionModule,
		MatDialogModule,
		MatPaginatorModule,
		MatSortModule,
		MatToolbarModule,
		MatChipsModule,
		MatTabsModule,
		MatMenuModule,
		ClipboardModule
	],
	declarations: [],
	providers: [],
})
export class MaterialModule { }