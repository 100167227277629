import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MaterialModule } from '../material/material.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from "agm-direction";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { environment } from 'environments/environment';
//COMPONENTS
import { MapViewComponent, EditTripTimeComponent, PlaceholderLoadingComponent, NavbarComponent, DocViewModalComponent, VerticalMenuComponent, ListHeaderComponent, ConfirmModalComponent, DateModalComponent, PdfViewerComponent, BonusModalComponent, PaymentConfirmationComponent } from "./components";

//DIRECTIVES
import {
    TopMenuAnchorToggleDirective, TopMenuDropdownDirective, TopMenuLinkDirective, TopMenuDirective, NumberDirective, AlphabetOnlyDirective,
    SidebarDirective, SidebarAnchorToggleDirective, SidebarDropdownDirective, SidebarLinkDirective, ToggleFullscreenDirective, InputRestrictionDirective
} from "./directives";

// PIPES
import { IdStringToNameStringPipe, SearchPipe, TimePipe } from './pipes';

import { NgbDateCustomParserFormatter } from './services';
// import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { LogoutConfirmComponent } from './components/navbar/logout-confirm/logout-confirm.component';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { NewAccountModalComponent } from './components/new-account-modal/new-account-modal.component';
import { LinkInviteModalComponent } from './components/link-invite-modal/link-invite-modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { PinchZoomModule } from 'app/pinch/pinch-zoom.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DemoRequestComponent } from './components/demo-request/demo-request.component';
import { ZoomModule } from '../zoom/zoom.module';
import { NoteDetailComponent } from './components/note-detail/note-detail.component';
import { EarningComponent } from './earning/earning.component';

@NgModule({
    exports: [
        CommonModule,
        NavbarComponent,
        VerticalMenuComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NumberDirective,
        InputRestrictionDirective,
        AlphabetOnlyDirective,
        NgbModule,
        ListHeaderComponent,
        ConfirmModalComponent,
        // DocViewModalComponent,
        DateModalComponent,
        // PasswordModalComponent,
        IdStringToNameStringPipe,
        SearchPipe,
        TimePipe,
        ClickOutsideModule,
        NgxExtendedPdfViewerModule,
        PlaceholderLoadingComponent,
        EarningComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        MaterialModule,
        NgxDaterangepickerMd.forRoot(),
        ClickOutsideModule,
        NgxIntlTelInputModule,
        // PinchZoomModule,
        ZoomModule,
        NgxExtendedPdfViewerModule,
        SwiperModule,
        AgmCoreModule.forRoot({
            apiKey: environment.gmapKey,
        }),
        AgmDirectionModule,
        GooglePlaceModule
    ],
    declarations: [
        NavbarComponent,
        VerticalMenuComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        NumberDirective,
        InputRestrictionDirective,
        AlphabetOnlyDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        ListHeaderComponent,
        ConfirmModalComponent,
        DateModalComponent,
        IdStringToNameStringPipe,
        SearchPipe,
        TimePipe,
        // SnackBarComponent,
        LogoutConfirmComponent,
        // PasswordModalComponent,
        OtpModalComponent,
        NewAccountModalComponent,
        LinkInviteModalComponent,
        DocViewModalComponent,
        PdfViewerComponent,
        BonusModalComponent,
        DemoRequestComponent,
        PlaceholderLoadingComponent,
        NoteDetailComponent,
        EarningComponent,
        MapViewComponent,
        EditTripTimeComponent,
        PaymentConfirmationComponent
    ],
    providers: [
        DecimalPipe,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
})
export class SharedModule { }