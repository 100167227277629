import { LocaleConfig } from 'ngx-daterangepicker-material';
import * as moment from 'moment';

export const locale: LocaleConfig = {
    format: "DD/MM/YYYY", 
    displayFormat: "DD/MM/YYYY", 
    cancelLabel: "Cancel", 
    applyLabel: "Okay",
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
}