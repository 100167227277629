<div #zoomContainer class="ngxImageZoomContainer" [style.width.px]="this.thumbWidth"
	[style.height.px]="this.thumbHeight">
	<button class="btn btn-enable-zoom">
		<img src="assets/img/search.png" alt="search">
	</button>
	<img #imageThumbnail class="ngxImageZoomThumbnail" [src]="thumbImage" (load)="onThumbImageLoaded()" />

	<div [ngClass]="{'ngxImageZoomFullContainer': true, 'ngxImageZoomLensEnabled': this.enableLens}"
		[style.display]="this.display" [style.top.px]="this.lensTop" [style.left.px]="this.lensLeft"
		[style.width.px]="this.lensWidth" [style.height.px]="this.lensHeight"
		[style.border-radius.px]="this.lensBorderRadius">
		<img #fullSizeImage class="ngxImageZoomFull" [src]="fullImage" (load)="onFullImageLoaded()"
			[style.display]="this.display" [style.top.px]="this.fullImageTop" [style.left.px]="this.fullImageLeft"
			[style.width.px]="this.magnifiedWidth" [style.height.px]="this.magnifiedHeight" />
	</div>
</div>