<div class="row qudosCustom-row" *ngIf="data.type === 'qudosCustomInfo'">
	<div class="col-sm-12 qudosCustom-header mbp-2">
		<h1>Edit QudosCustom</h1>
	</div>
	<div class="col-sm-12 t-right prp-15">
		<label>Language </label>
		<select multiple [(ngModel)]="selectLang">
			<option *ngFor="let i of this.languages" [ngValue]="i">
				{{ i.language_name }}
			</option>
		</select>
		<br /><br />
		<label for="lname">Pet Friendly </label>
		<select [(ngModel)]="selectPet">
			<option>Yes</option>
			<option>No</option>
		</select>
		<br /><br />
		<label for="email">Prefer Airport</label>
		<select [(ngModel)]="selectAirport">
			<option>Yes</option>
			<option>No</option>
		</select>
		<br /><br />
	</div>
	<div class="col-sm-12 qudosCustom-info-footer">
		<span class="d-flex">
			<button mat-dialog-close class="button-modal-cancel mrp-10">
				cancel
			</button>
			<button class="button-modal-save" (click)="qudosCustom()">save</button>
		</span>
	</div>
</div>

<p *ngIf="data.type === 'driver-doc-edit'">driver-doc-edit</p>
<p *ngIf="data.type === 'driver-license-edit'">driver-license-edit</p>


<section class="custom-mat-dialog-wrapper" id="view-area-modal-modal" *ngIf="data.type === 'carInfo'"
	style="width: 1200px;">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">
		{{ data?.action === 'add' ? 'Add' : 'Edit' }} Car Info
	</h2>

	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label for="fname" class="col-sm-4 col-form-label mr-0">License Plate</label>
							<div class="col-sm-8">
								<input formControlName="car_no" type="text" class="form-control" />
								<div *ngIf="formControl.car_no.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.car_no.errors?.required">*Car Number is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="lname" class="col-sm-4 col-form-label mr-0"># of Passengers</label>
							<div class="col-sm-8">
								<input formControlName="passenger_count" type="text" class="form-control" />
								<div *ngIf="formControl.passenger_count.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.passenger_count.errors?.required">*Passenger Count is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="email" class="col-sm-4 col-form-label mr-0">VIN</label>
							<div class="col-sm-8">
								<input formControlName="vin_number" type="text" class="form-control" />
								<div *ngIf="formControl.vin_number.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.vin_number.errors?.required">*VIN Number is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">TLC Number</label>
							<div class="col-sm-8">
								<input formControlName="tlc_num" type="text" class="form-control" />
							</div>
						</div>
						<div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Car Type</label>
							<div class="col-sm-8">
								<select formControlName="car_type" class="form-control" (change)="onCarTypeChange()">
									<option value="">--Select Car Type--</option>
									<option [value]="item.car_type" *ngFor="let item of carTypes">
										{{ item.car_name }}
									</option>
								</select>
								<div *ngIf="formControl.car_type.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.car_type.errors?.required">*Car Type is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="lname" class="col-sm-4 col-form-label mr-0">Affiliated Base #</label>
							<div class="col-sm-8">
								<input formControlName="affiliated_base_number" type="text" class="form-control"
									(change)="test($event)" />
							</div>
						</div>
						<div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Dispatching Base #</label>
							<div class="col-sm-8">
								<input formControlName="dispatching_base_number" class="form-control" type="text" />
							</div>
						</div>
					</div>

					<div class="col-md-6">
						<div class="row">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Car Make</label>
							<div class="col-sm-8">
								<input formControlName="car_make" class="form-control" type="text" />
								<div *ngIf="formControl.car_make.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.car_make.errors?.required">*Car Make is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="lname" class="col-sm-4 col-form-label mr-0">Car Model</label>
							<div class="col-sm-8">
								<input formControlName="car_model" class="form-control" type="text" />
								<div *ngIf="formControl.car_model.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.car_model.errors?.required">*Car Model is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Year</label>
							<div class="col-sm-8">
								<input formControlName="car_year" class="form-control" type="text" />
								<div *ngIf="formControl.car_year.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControl.car_year.errors?.required">*Year is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label for="lname" class="col-sm-4 col-form-label mr-0">Driver License #</label>
							<div class="col-sm-8">
								<input formControlName="dmv_num" class="form-control" type="text" />
							</div>
						</div>
						<div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Color</label>
							<div class="col-sm-8">
								<input formControlName="car_color" class="form-control" type="text" />
							</div>
						</div>
						<!-- dont remove this code -->
						<!-- <div class="row mt-3">
							<label for="fname" class="col-sm-4 col-form-label mr-0">Essential</label>
							<div class="col-sm-2">
								<div class="essential_checkbox">
									<input formControlName="is_essential" class="form-control" type="checkbox"
										style="width: 16px;">
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">

	<mat-dialog-actions align="end" class="dialog-footer px-3">
		<button mat-dialog-close class="button-modal-cancel mr-2">Cancel</button>
		<button class="button-modal-save" (click)="carInfoSubmit()">Save</button>
	</mat-dialog-actions>
</section>


<!-- Driver Doc Edit And Verify -->
<section class="custom-mat-dialog-wrapper" id="confirm-modal"
	*ngIf="data.type === 'driver-doc-remove' || data.type === 'driver-verify-doc'">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header text-capitalize">

		{{ data.type === 'driver-doc-remove' ? 'Driver Doc Remove' : '' }}
		{{ data.type === 'driver-verify-doc' ? 'Driver Doc Verify' : '' }}
	</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="mat-typography dialog-body">
		<div fxLayout="row" fxLayoutGap="20px">
			<div *ngIf="data.type === 'driver-doc-remove'" class="blue">Are you sure you want to remove <b>document</b>?
			</div>
			<div *ngIf="data.type === 'driver-verify-doc'" class="blue">Are you sure you want to verify <b>document</b>?
			</div>

		</div>
	</mat-dialog-content>

	<hr class="cutom-divider m-0">

	<mat-dialog-actions align="end" class="dialog-footer px-3">
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
		<button mat-button [mat-dialog-close]="true" class="btn btn-primary button-dialog ml-2" cdkFocusInitial
			(click)="onDriverDocSubmit()">
			{{ data.type === 'driver-doc-remove' ? 'Remove' : '' }}
			{{ data.type === 'driver-verify-doc' ? 'Verify' : '' }}
		</button>
	</mat-dialog-actions>
</section>
<!-- Driver Doc Edit And Verify -->


<!-- Edit Driver Details  -->
<section class="custom-mat-dialog-wrapper" id="view-area-modal-modal" *ngIf="data.type === 'driverInfo'">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">
		Edit Driver Info
	</h2>

	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div class="pb-3">
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">First Name</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="first_name" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.first_name.invalid,
                                    'is-valid': isSubmitting && !formControls.first_name.invalid
                                }">
								<div *ngIf="formControls.first_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.first_name.errors?.required">*First Name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label" for="lname">Last Name</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="last_name" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.last_name.invalid,
                                    'is-valid': isSubmitting && !formControls.last_name.invalid
                                }">
								<div *ngIf="formControls.last_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_name.errors?.required">*Last Name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label" for="lname">Gender</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="driver_gender" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.driver_gender.invalid,
                                    'is-valid': isSubmitting && !formControls.driver_gender.invalid
								}">
									<option value="">Select Gender</option>
									<option value="0">Male</option>
									<option value="1">Female</option>
									<option value="2">Non-Binary</option>
								</select>
								<div *ngIf="formControls.driver_gender.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.driver_gender.errors?.required">*Gender is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Email</label>
							<div class="col-sm-8">
								<input type="email" class="form-control" formControlName="email" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.email.invalid,
                                    'is-valid': isSubmitting && !formControls.email.invalid
                                }">
								<div *ngIf="formControls.email.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.email.errors?.required">*Email is required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Birth Date</label>
							<div class="col-sm-8">
								<input type="text" (keypress)="keypress($event)" class="form-control"
									formControlName="date_of_birth" ngxDaterangepickerMd [maxDate]="maxDate"
									placeholder="mm/dd/yyyy" [showDropdowns]="true" [singleDatePicker]="true"
									[autoApply]="true" [locale]="locale" [ngClass]="{
									'is-invalid': isSubmitting && formControls.date_of_birth.invalid,
									'is-valid': isSubmitting && !formControls.date_of_birth.invalid
								}" />
								<!-- <div *ngIf="formControls.date_of_birth.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.date_of_birth.errors?.required">*Date of birth is required.</small>
								</div> -->
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Phone</label>
							<div class="col-sm-8">
								<!-- <input type="text" class="form-control" formControlName="mobile"  [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.mobile.invalid,
                                    'is-valid': isSubmitting && !formControls.mobile.invalid
								}"> -->
								<ngx-intl-tel-input [preferredCountries]="preferredCountries"
									[enableAutoCountrySelect]="true" [enablePlaceholder]="true"
									[searchCountryFlag]="true"
									[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
									[selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
									[maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
									[separateDialCode]="false" name="mobile" [inputId]="'mobile'"
									formControlName="mobile" [customPlaceholder]="'Enter phone number'">
								</ngx-intl-tel-input>
								<div *ngIf="formControls.mobile.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.mobile.errors?.required">*Phone is required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Registration Date</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="date_registered" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.date_registered.invalid,
                                    'is-valid': isSubmitting && !formControls.date_registered.invalid
                                }">
								<div *ngIf="formControls.date_registered.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.date_registered.errors?.required">*Registration Date
										is required.</small>
								</div>
							</div>
						</div>
						<!-- <div class="row">
							<label class="col-sm-4 col-form-label">Interest</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="interest" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.interest.invalid,
                                    'is-valid': isSubmitting && !formControls.interest.invalid
                                }">
								<div *ngIf="formControls.interest.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.interest.errors?.required">*Interest is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Hobby</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="hobby" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.hobby.invalid,
                                    'is-valid': isSubmitting && !formControls.hobby.invalid
                                }">
								<div *ngIf="formControls.hobby.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.hobby.errors?.required">*Hobby is required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Bio</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="bio" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.bio.invalid,
                                    'is-valid': isSubmitting && !formControls.bio.invalid
                                }">
								<div *ngIf="formControls.bio.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.bio.errors?.required">*Bio is required.</small>
								</div>
							</div>
						</div> -->
					</div>

					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Zipcode</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="zipcode" [ngClass]="{
								'is-invalid': isSubmitting && formControls.zipcode.invalid,
								'is-valid': isSubmitting && !formControls.zipcode.invalid
							}">
								<div *ngIf="formControls.zipcode.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.zipcode.errors?.required">*Zipcode is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Country</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="country_id" [ngClass]="{
								'is-invalid': isSubmitting && formControls.country_id.invalid,
								'is-valid': isSubmitting && !formControls.country_id.invalid
							}" (change)="onCountryChange($event)">
									<option value="">Select Country</option>
									<option [value]="item.country_id" *ngFor="let item of countries">{{
										item.country_name }}</option>
								</select>
								<div *ngIf="formControls.country.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.country.errors?.required">*Country is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">State</label>
							<div class="col-sm-8">
								<select class="form-control mt-10" formControlName="state_id">
									<option value="">Select state</option>
									<option [value]="item.state_id" *ngFor="let item of stateList">
										{{ item.state_name }}
									</option>
								</select>
								<div *ngIf="formControls.state_id.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.state_id.errors?.required">*State is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">TLC Number</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="tlc_num" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.tlc_num.invalid,
                                    'is-valid': isSubmitting && !formControls.tlc_num.invalid
                                }">
								<div *ngIf="formControls.tlc_num.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.tlc_num.errors?.required">*TLC Number is
										required.</small>
								</div>
							</div>
						</div>

						<div class="row">
							<label class="col-sm-4 col-form-label">Referral Code</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="referral_code" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.referral_code.invalid,
                                    'is-valid': isSubmitting && !formControls.referral_code.invalid
                                }">
								<div *ngIf="formControls.referral_code.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.referral_code.errors?.required">*Referral Code is
										required.</small>
								</div>
							</div>
						</div>


						<!-- <div class="row">
							<label class="col-sm-4 col-form-label">Referring Affiliate</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="referring_name" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.referring_name.invalid,
                                    'is-valid': isSubmitting && !formControls.referring_name.invalid
                                }">
								<div *ngIf="formControls.referring_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.referring_name.errors?.required">*Reffering Affiliate
										is required.</small>
								</div>
							</div>
						</div>

						<div class="row">
							<label class="col-sm-4 col-form-label">Affiliate 2</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="vendor_2" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.vendor_2.invalid,
                                    'is-valid': isSubmitting && !formControls.vendor_2.invalid
                                }">
								<div *ngIf="formControls.vendor_2.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.vendor_2.errors?.required">*Affiliate 2 is
										required.</small>
								</div>
							</div>
						</div>

						<div class="row">
							<label class="col-sm-4 col-form-label">Affiliate 3</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="vendor_3" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.vendor_3.invalid,
                                    'is-valid': isSubmitting && !formControls.vendor_3.invalid
                                }">
								<div *ngIf="formControls.vendor_3.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.vendor_3.errors?.required">*Affiliate 3 is
										required.</small>
								</div>
							</div>
						</div> -->

						<div class="row">
							<label class="col-sm-4 col-form-label">Language</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="language" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.language.invalid,
                                    'is-valid': isSubmitting && !formControls.language.invalid
                                }">
								<div *ngIf="formControls.language.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.language.errors?.required">*Language is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Nationality</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="nationality" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.nationality.invalid,
                                    'is-valid': isSubmitting && !formControls.nationality.invalid
                                }">
								<div *ngIf="formControls.nationality.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.nationality.errors?.required">*Nationality is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">Driver License</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="dmv_num" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.dmv_num.invalid,
                                    'is-valid': isSubmitting && !formControls.dmv_num.invalid
                                }">
								<div *ngIf="formControls.dmv_num.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.dmv_num.errors?.required">*Driver License is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row">
							<label class="col-sm-4 col-form-label">License Class</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="license_class" [ngClass]="{
                                    'is-invalid': isSubmitting && formControls.license_class.invalid,
                                    'is-valid': isSubmitting && !formControls.license_class.invalid
                                }">
								<div *ngIf="formControls.license_class.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.license_class.errors?.required">*License Class is
										required.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>

	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="center" class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-8 offset-md-4">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button mat-button class="btn btn-primary button-dialog ml-2" (click)="driverInfoSubmit()">Save</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>
<!-- End Edit Driver details -->