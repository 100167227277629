import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";

import { DataService, ToastService } from '../../services';
import { triangleBall } from "../../utility";

@Component({
	selector: 'app-payment-confirmation',
	templateUrl: './payment-confirmation.component.html',
	styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {
	isConfirmed: boolean = false;
	isSubmitted: boolean = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PaymentConfirmationComponent>, private dialog: MatDialog, private ds: DataService, private spinner: NgxSpinnerService, private toastr: ToastService) { }

	ngOnInit(): void {
		console.log(this.data);
	}

	submit(): void {
		if (!this.isConfirmed || (this.data.modalParams.payload.amount > this.data.modalParams.walletDetails.availableBalance)) {
			this.isSubmitted = true;
			return;
		}
		this.isSubmitted = false;
		this.spinner.show(undefined, triangleBall);
		this.ds.post(this.data.modalParams.endpoint, this.data.modalParams.payload).subscribe(res => {
			if (res.flag === 20001) {
				this.toastr.success(res.log, "Success");
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => {
			console.log(err);
			this.spinner.hide();
		});
	}
}