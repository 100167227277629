import { Component, OnInit, Inject, Optional, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { ApiRoutes } from 'app/shared/routes';
import { LocalStorage } from '../../../../../storage';
import { DataService, ToastService } from '../../../../../shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../../../../../shared/services/global.service';
import { triangleBall, countryCodeHiphen, objToDateObj2, dateToObj } from 'app/shared/utility';
import * as moment from "moment";
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
	selector: 'app-driver-info-edit-modal',
	templateUrl: './driver-info-edit-modal.component.html',
	styleUrls: ['./driver-info-edit-modal.component.scss'],
})
export class DriverInfoEditModalComponent implements OnInit {
	maxDate: any = moment();
	form: FormGroup;
	isSubmitting: boolean = false;
	languages: any = [];
	selectAirport: '';
	selectPet: '';
	selectLang: any;
	carTypes: any[] = [];
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
	stateList: any[] = [];
	countries: any[] = [];
	currentUser: any;
	@ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

	constructor(private cd: ChangeDetectorRef, private ls: LocalStorage, private ds: DataService, private global: GlobalService, private spinner: NgxSpinnerService, private toastr: ToastService, private dialogRef: MatDialogRef<DriverInfoEditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
	}

	ngOnInit(): void {
		if (this.data.type === "carInfo") {
			this.getAllCarType();
		} else if (this.data.type === "driverInfo") {
			this.getInitialData();
		}
		this.languages = this.global.languages;
		this.initForm();
	}

	private getInitialData() {
		const url1: string = ApiRoutes.languages;
		const url2: string = ApiRoutes.getCountryList;
		const urls: string[] = [url1, url2];
		this.ds.forkJoin(urls).subscribe(res => {
			if (res[0].languages.length) {
				this.languages = res[0].languages;
			}
			if (res[1].countries.length) {
				this.countries = res[1].countries;
			}
			this.cd.markForCheck();
		});
	}

	private getAllCarType(): void {
		const url: string = ApiRoutes.getAllCarTypes;
		this.ds.post(url, {}).subscribe(res => {
			if (res.flag === 1504) {
				this.carTypes = res.car_types;
				if (!this.data.vehicleData.passenger_count) {
					this.onCarTypeChange();
				}
			}
		});
	}

	//driver docs operation
	onDriverDocSubmit(): void {
		let url;
		let data = {
			car_type: this.data.data.car_type,
			doc_id: this.data.data.doc_id,
			driver_car_id: this.data.data.driver_car_id,
			admin_id: this.currentUser.admin_type === 4 ? this.currentUser.admin_id : this.data.driverData.client_id
		};

		if (this.data.type === 'driver-doc-remove') {
			url = ApiRoutes.validInvalidDoc;
			data['valid_flag'] = 0;
		} else if (this.data.type === 'driver-verify-doc') {
			url = ApiRoutes.verifyDriverDocs;
		}
		this.spinner.show(undefined, triangleBall);
		this.ds.post(url, data).subscribe((res) => {
			if (res.flag === 1307 || res.flag === 1306) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	get formControl() {
		return this.form?.controls;
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		if (this.data.type === 'driverInfo') {
			this.getStateList();
			this.form = new FormGroup({
				country: new FormControl(this.data.data ? this.data.data.country : '', Validators.required),
				country_id: new FormControl(this.data.data ? this.data.data.country_id : '', Validators.required),
				first_name: new FormControl(this.data.data ? this.data.data.first_name || this.data.data.driver_name.split(' ')[0] : '', Validators.required),
				last_name: new FormControl(this.data.data ? this.data.data.last_name || this.data.data.driver_name.split(' ')[1] : '', Validators.required),
				driver_gender: new FormControl(this.data.data ? this.data.data.driver_gender : '', Validators.required),
				email: new FormControl(this.data.data ? this.data.data.email || this.data.data.driver_email : '', Validators.required),
				mobile: new FormControl(this.data.data ? this.data.data.mobile || this.data.data.driver_mobile : '', Validators.required),
				zipcode: new FormControl(this.data.data ? this.data.data.zipcode : '', Validators.required),
				state_id: new FormControl(this.data.data ? this.data.data.state_id : '', Validators.required),
				hobby: new FormControl(this.data.data ? this.data.data.hobby : ''),
				tlc_num: new FormControl(this.data.data ? this.data.data.tlc_num : '', Validators.required),
				vendor_2: new FormControl(this.data.data ? this.data.data.vendor_2 : ''),
				vendor_3: new FormControl(this.data.data ? this.data.data.vendor_3 : ''),
				language: new FormControl(this.data.data ? this.data.data.language : '', Validators.required),
				nationality: new FormControl(this.data.data ? this.data.data.nationality : '', Validators.required),
				dmv_num: new FormControl(this.data.data ? this.data.data.dmv_num : '', Validators.required),
				license_class: new FormControl(this.data.data ? this.data.data.license_class : '', Validators.required),
				bio: new FormControl(this.data.data ? this.data.data.bio : ''),
				interest: new FormControl(this.data.data ? this.data.data.interest : ''),
				date_registered: new FormControl({ value: this.data.data ? new Date(this.data.data.date_registered).toLocaleDateString() : '', disabled: true }, Validators.required),
				referral_code: new FormControl({ value: this.data.data ? this.data.data.referral_code : '', disabled: true }, Validators.required),
				referring_code: new FormControl({ value: this.data.data ? this.data.data.referring_code : '', disabled: true }, Validators.required),
				referring_name: new FormControl({ value: this.data.data ? this.data.data.referring_name : '', disabled: true }),
				date_of_birth: new FormControl({ value: "", disabled: false }),
			});
			setTimeout(() => {
				if (this.data.data && this.data.data.date_of_birth) {
					const formatted = {
						startDate: moment(this.data.data.date_of_birth),
						endDate: moment(this.data.data.date_of_birth)
					}
					this.form.get("date_of_birth").setValue(formatted);
					this.form.get("date_of_birth").updateValueAndValidity();
				}
			}, 200);
		} else if (this.data.type === 'carInfo') {
			this.form = new FormGroup({
				car_no: new FormControl(this.data.vehicleData ? this.data.vehicleData.car_no : '', [Validators.required]),
				passenger_count: new FormControl({ value: this.data.vehicleData ? this.data.vehicleData.passenger_count : '', disabled: true }, [Validators.required]),
				vin_number: new FormControl(this.data.vehicleData ? this.data.vehicleData.vin_number : '', [Validators.required]),
				tlc_num: new FormControl(this.data.vehicleData ? this.data.vehicleData.tlc_num : ''),
				car_type: new FormControl({ value: this.data.vehicleData ? this.data.vehicleData.car_type : "", disabled: false }, [Validators.required]),
				affiliated_base_number: new FormControl({ value: this.data.driverData?.car_base_number, disabled: true }),
				dispatching_base_number: new FormControl({ value: this.data.driverData?.car_base_number, disabled: true }),
				car_make: new FormControl(this.data.vehicleData ? this.data.vehicleData.car_make : '', [Validators.required]),
				car_model: new FormControl(this.data.vehicleData ? this.data.vehicleData.car_model : '', [Validators.required]),
				car_year: new FormControl(this.data.vehicleData ? this.data.vehicleData.car_year : '', [Validators.required]),
				dmv_num: new FormControl(this.data.vehicleData ? this.data.vehicleData.dmv_num : ''),
				license_class: new FormControl(this.data.vehicleData ? this.data.vehicleData.license_class : ''),
				car_color: new FormControl(this.data.vehicleData ? this.data.vehicleData.car_color : ''),
				is_essential: new FormControl(this.data.vehicleData ? this.data.vehicleData.is_essential : true),
			});
		}
	}

	get formControls() {
		return this.form.controls;
	}

	onCarTypeChange(): void {
		const passenger_count = this.carTypes.find(i => i.car_type == this.form.get('car_type').value).passenger_count;
		this.form.get('passenger_count').setValue(passenger_count);
	}

	keypress(e): boolean {
		return false;
	}

	openDatepicker() {
		this.pickerDirective.open();
	}

	get mobileNo(): string {
		const driverData = this.data.data || this.data.driverData;
		if (driverData.mobile) {
			if (driverData.mobile.includes("-")) {
				return driverData.mobile.split('-')[1];
			} else {
				return driverData.mobile;
			}
		} else {
			if (driverData.driver_mobile.includes("-")) {
				return driverData.driver_mobile.split('-')[1];
			} else {
				return driverData.driver_mobile;
			}
		}
	}

	getSessionIdentifierForMAS() {
		return this.ds.post(ApiRoutes.startMasSession, {}).subscribe(res => {
			if (res.session_identifier) {
				return res.session_identifier;
			}
		});
	}

	// driver edit
	driverInfoSubmit(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.spinner.show(undefined, triangleBall);
		this.isSubmitting = false;
		// const url: string = ApiRoutes.editNewDriverInfo;
		const accessToken: string = this.ls.getItem('qudos-admin-token');
		const form = { ...this.form.value };
		form['driver_id'] = this.data.data.driver_id;
		form['lyft_rating'] = this.data.data.lyft_rating;
		form['uber_rating'] = this.data.data.uber_rating;
		form['how_hear_us'] = this.data.data.how_hear_us;
		form['image_flag'] = 0;
		form['driver_gender'] = +this.form.get('driver_gender').value;
		form['state_name'] = this.stateList.find(i => i.state_id == this.form.value['state_id']).state_name;
		form['mobile'] = countryCodeHiphen(this.form.get('mobile').value);
		form['admin_id'] = this.currentUser.admin_type === 4 ? this.currentUser.admin_id : this.data.data.client_id;
		const d = this.form.get('date_of_birth').value;
		form['date_of_birth'] = moment(d.startDate).toISOString();
		// form['image_flag'] = this.data.data.driver_image ? 1 : 0;
		form['access_token'] = accessToken;
		//  || this.data.data.is_mas_approved == "1"
		if (this.data.data.mas_status == "1") {
			this.ds.post(ApiRoutes.startMasSession, {}).subscribe(res => {
				if (res.session_identifier) {
					form['mobile_without_country_code'] = this.mobileNo;
					form['session_identifier'] = res.session_identifier;
					form['dmv_state_code'] = this.data.data.state_code;
					form['dmv_expiration'] = moment(this.data.data.dmv_expiration).utc().format("MM/DD/YYYY");
					this.editDriverCall(ApiRoutes.editNewDriverInfo, form);
				}
			});
		} else {
			this.editDriverCall(ApiRoutes.editNewDriverInfo, form);
		}
	}

	private editDriverCall(url, form): void {
		this.ds.post(url, form).subscribe((res) => {
			if (res.flag === 1610 || res.log === 'Driver details successfully updated.') {
				this.toastr.success(res.log);
				if (this.data.data.mas_status == "0" && this.data.isMasSubmitFlow) {
					this.submitMasCheck();
				} else {
					this.dialogRef.close(true);
				}
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	private submitMasCheck(): void {
		this.spinner.show(undefined, triangleBall);
		this.ds.post(ApiRoutes.startMasSession, {}).subscribe(res => {
			if (res.session_identifier) {
				const payload: any = {
					session_identifier: res.session_identifier,
					dmv_expiration: moment(this.data.data.dmv_expiration).utc().format("MM/DD/YYYY"),
					tlc_expiration: this.data.data.tlc_expiration ? moment(this.data.data.tlc_expiration).utc().format("MM/DD/YYYY") : '',
					first_name: this.data.data.first_name ? this.data.data.first_name : this.data.data.driver_name.split(" ")[0],
					last_name: this.data.data.last_name ? this.data.data.last_name : this.data.data.driver_name.split(" ")[1],
					dmv_state_code: this.data.data.state_code,
					dmv_num: this.data.data.dmv_num,
					license_class: this.data.data.license_class,
					mobile: this.mobileNo,
					email: this.data.data.email ? this.data.data.email : this.data.data.driver_email,
					driver_id: this.data.data.driver_id,
					driver_car_id: this.data.data.driver_car_id,
					car_make: this.data.data.car_make,
					car_name: this.data.data.car_name,
					car_no: this.data.data.car_no,
					admin_id: this.currentUser.admin_type === 4 ? this.currentUser.admin_id : this.data.data.client_id,
				};
				this.ds.post(ApiRoutes.addMassDriver, payload).subscribe(res => {
					console.log(res);
					if (res.message == 'Success' || res.message == 'Success.') {
						this.toastr.success('Driver successfully added to MAS drivers', 'Success');
						this.dialogRef.close(true);
					}
					this.spinner.hide();
				}, err => {
					this.spinner.hide();
				});
			}
		}, err => {
			this.spinner.hide();
		});
	}

	carInfoSubmit(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall);
		let url: string;
		if (this.data.action === 'add') {
			url = ApiRoutes.addVehicle;
		} else {
			url = ApiRoutes.editDriverCarInfo;
		}
		const data: any = {
			driver_id: this.data.driverData.driver_id,
			car_no: this.form.get('car_no').value,
			is_pre_reg_driver: 1,
			is_essential: this.form.get('is_essential').value ? 1 : 0,
			passenger_count: this.form.get('passenger_count').value,
			vin_number: this.form.get('vin_number').value,
			car_type: +this.form.get('car_type').value,
			car_make: this.form.get('car_make').value,
			car_model: this.form.get('car_model').value,
			car_year: this.form.get('car_year').value,
			car_color: this.form.get('car_color').value,
			car_name: this.carTypes.find(i => i.car_type === +this.form.get('car_type').value).car_name,
			dispatching_base_number: this.form.get('dispatching_base_number').value,
			affiliated_base_number: this.form.get('affiliated_base_number').value,
			driver_car_id: this.data.vehicleData.driver_car_id,
			tlc_num: this.form.get('tlc_num').value,
			dmv_num: this.form.get('dmv_num').value,
			license_class: this.form.get('license_class').value,
			tlc_expiration: this.data.driverData.tlc_expiration ? moment(this.data.driverData.tlc_expiration).utc().format("MM/DD/YYYY") : '',
			dmv_state_code: this.data.driverData.state_code,
			dmv_expiration: this.data.driverData.dmv_expiration ? moment(this.data.driverData.dmv_expiration).utc().format("MM/DD/YYYY") : '',
			mobile_without_country_code: this.mobileNo,
			first_name: this.data.driverData.first_name || this.data.driverData.driver_name.split(' ')[0],
			last_name: this.data.driverData.last_name || this.data.driverData.driver_name.split(' ')[1],
			admin_id: this.currentUser.admin_type === 4 ? this.currentUser.admin_id : this.data.driverData.client_id,
			useParentIdAsAdminId: "false"
		};

		// this.data.driverData.is_mas_approved == "1"
		if (this.data.driverData.mas_status == 1) {
			this.ds.post(ApiRoutes.startMasSession, {}).subscribe(res => {
				if (res.session_identifier) {
					data['session_identifier'] = res.session_identifier;
					this.carApiCall(url, data);
				}
			});
		} else {
			this.carApiCall(url, data);
		}
	}

	carApiCall(url: string, data: any) {
		this.ds.post(url, data).subscribe((res) => {
			if (res.flag === 8) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	qudosCustom() {
		let url: string = ApiRoutes.setUserPrefrences;
		let access_token: string = this.ls.getItem('qudos-admin-token');
		let data = {
			preference: {
				language_id: this.selectLang,
				pet_friendly_car: this.selectPet,
				prefer_airport: this.selectAirport,
			},
			qudos_user_id: this.data.data.driver_id,
			qudos_user_type: 1,
			access_token,
		};

		// this.ds.post(url, data).subscribe(
		//   (res) => {
		//     this.spinner.hide();
		//     this.dialogRef.close(true);
		//   },
		//   (err) => this.spinner.hide()
		// );
	}

	onCountryChange(e): void {
		const country: any = this.countries.find(i => i.country_id == this.form.get('country_id').value);
		this.form.get('country').setValue(country.country_name);
		this.getStateList(e.target.value);
	}

	getStateList(countryID?: any) {
		const url: string = ApiRoutes.getStateList1;
		const obj = {
			country_id: countryID === undefined ? this.data.data.country_id : countryID,
			state_id: 0
		}
		this.ds.post(url, obj).subscribe(res => {
			this.stateList = res.states;
			this.cd.markForCheck();
		});
	}
}