import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';

@Component({
	selector: 'app-bonus-modal',
	templateUrl: './bonus-modal.component.html',
	styleUrls: ['./bonus-modal.component.scss']
})
export class BonusModalComponent implements OnInit {
	isChecked: boolean = false;
	proceedWithBonus: number;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<BonusModalComponent>, private dialog: MatDialog) { }

	ngOnInit(): void { }

	proceed(flag: number) {
		this.proceedWithBonus = flag;
		if (!this.isChecked && this.proceedWithBonus) {
			return;
		}
		this.dialogRef.close({ result: true, proceedWithBonus: this.proceedWithBonus });
	}

	openPdfViewer(): void {
		const data: any = {
			action: 'New Car Base Signup Bonus Terms and Conditions',
			url: 'assets/Qudos_Client_Car_Base_Agreement_(Terms_and_Conditions).pdf',
			cancelButtonText: 'Close'
		}
		const dialogRef = this.dialog.open(PdfViewerComponent, { data, width: '1024px' });
		dialogRef.afterClosed().subscribe(result => {
			if (result) { }
		});
	}
}