function nullable() {
	return { transform: (value) => value ? value : 'NA' };
}

export function rideColumn(key: string, cellTemplate: any = {}) {
	const currentUser: any = JSON.parse(localStorage.getItem("qudos-admin-currentUser"));
	if (currentUser.regulation_type != 2) {
		if (key === 'Completed Rides') {
			const completedRidesColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'driver_image ',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.pic,
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.rideessential,
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.isessential,
				},
				{
					prop: 'promo_code',
					name: 'Promo Code Used',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Promo Code Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'drop_time',
					name: 'Pickup Date/Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Fare Structure 1',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.farestructure,
				},
				{
					prop: '',
					name: 'Fare Structure 2',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.farestructure,
				},
				{
					prop: 'total_payment',
					name: 'Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: '',
					name: 'Driver Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.driver_affiliate_fee,
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Rider Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.rider_affiliate_fee,
					pipe: nullable()
				},
				{
					prop: 'driver_affiliate_name',
					name: 'Driver Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_name',
					name: 'Rider Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'driver_rating',
					name: 'Driver Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.driverRating
	
				},
				{
					prop: 'user_rating',
					name: 'Rider Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.rideRating
				},
			];
			return completedRidesColumn;
		} else if (key === 'Missed Rides') {
			const missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.email,
				},
				{
					prop: 'ride_status ',
					name: 'Ride Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.ridestatus,
				},
				{
					prop: 'driver_image ',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.pic,
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.isessential,
				},
				{
					prop: 'affiliate_name',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'sent_to',
					name: 'Request Sent to Driver ID(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'drivers',
					name: 'Request Sent to Driver(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 275,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 275,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'total_with_tax',
					name: 'Missed Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'pay_to_driver',
					name: 'Missed Total Payout',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
			];
			return missedRides;
		} else if (key === 'Cancelled Rides' || key == 'On Processed Rides') {
			const cancelledRidesColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.email,
				},
				{
					prop: 'ride_status',
					name: 'Ride Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.ridestatus,
				},
				{
					prop: 'driver_image',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.pic,
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.isessential,
				},
				{
					prop: 'affiliate_name',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'sent_to',
					name: 'Request Sent to Driver ID(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'drivers',
					name: 'Request Sent to Driver(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'total_with_tax',
					name: 'Cancelled Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'pay_to_driver',
					name: 'Cancelled Total Payout',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				},
			];
			return cancelledRidesColumn;
		}
	} else {
		if (key === 'Completed Rides') {
			const completedRidesColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.rideessential,
				},
				{
					prop: 'drop_time',
					name: 'Pickup Date/Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
			];
			return completedRidesColumn;
		} else if (key === 'Missed Rides') {
			const missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 275,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 275,
					cellClass: 'd-flex align-items-center cell-class',
				}
			];
			return missedRides;
		} else if (key === 'Cancelled Rides' || key == 'On Processed Rides') {
			const cancelledRidesColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'car_name',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
					cellTemplate: cellTemplate.droptime,
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class',
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class',
				}
			];
			return cancelledRidesColumn;
		}
	}
}