import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-placeholder-loading',
	templateUrl: './placeholder-loading.component.html',
	styleUrls: ['./placeholder-loading.component.scss']
})
export class PlaceholderLoadingComponent implements OnInit {

	@Input() placeholderWidth: string;
	@Input() placeholderBg: string;
	@Input() animatedBgHeight: string;
	@Input() placeholdermargin?: string;

	constructor() { }

	ngOnInit(): void { }
}