<section class="list-header-wrapper">
	<div class="row">
		<div class="{{ config?.rowLayout?.col1 ? config?.rowLayout?.col1 : 'col-md-4 col-sm-12' }}">
			<div class="item item-1" *ngIf="config.title.isTitle">
				<div class="d-flex align-items-baseline">
					<!-- For Desktop view Design -->
					<ng-container *ngIf="!config.title.islongTitle && !isMobileScreen">
						<div class="title m-0">
							{{ config.title.name }}
							<span *ngIf="config.title.count">({{ config.title.count }})</span>
							<span class="ml-3" *ngIf="config.title.amount">$ {{ config.title.amount }}</span>
						</div>
					</ng-container>
					<!-- For Mobile screen Total Count Dropdown -->
					<ng-container *ngIf="config.title.islongTitle && isMobileScreen">
						<div class="id-text profileId mt-1" ngbDropdown>
							<div id="totalCountDropdown" ngbDropdownToggle>
								Totals
							</div>
							<div ngbDropdownMenu aria-labelledby="totalCountDropdown">
								<div class="mobile-menu">
									<div class="export-btn-group mt-2 p-0" ngbDropdownItem>
										<span class="export-btn-labels font-weight-bold">
											{{ getFormattedValue()[0] }}<br>
											{{ getFormattedValue()[1] }}<br>
											{{ getFormattedValue()[2] }}<br>

										</span>
										<span *ngIf="config.title.count" class="export-btn-labels">
											({{ config.title.count }})
										</span>
										<span *ngIf="config.title.amount" class="export-btn-labels">
											$ {{ config.title.amount }}
										</span>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="mobile-menu-footer">
									<span ngbDropdownItem>
										Dismiss
									</span>
								</div>
							</div>
						</div>
					</ng-container>
					<div *ngIf="config.back?.isBack" class="ml-3 max-765">
						<a href="javascript:;" class="back" (click)="back(config.back?.route)">{{ config.back?.name
							}}</a>
					</div>
				</div>
			</div>
		</div>
		<!-- Desktop View Design -->
		<div class="{{ config?.rowLayout?.col2 ? config?.rowLayout?.col2 : 'col-md-8 col-sm-12' }} max-765"
			[ngClass]="{'second-section': config.type == 'assigned&queued'}">
			<div class="car-type-filter d-flex justify-content-end ml-2">

				<div class="dropdown filter-dd" *ngIf="config?.masFilter?.isMasFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuMasFilter" data-toggle="dropdown"
						aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.masFilter.label }}: </label>
						<span class="nav-filter-value mr-1"> {{ selectedMasFilter }}</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuMasFilter">
						<a class="dropdown-item" href="javascript:;" (click)="onMasFilterChange(item)"
							*ngFor="let item of masFilters">
							{{ item }}
						</a>
					</div>
				</div>
				<div class="dropdown filter-dd ml-2" *ngIf="config?.languageFilter?.islanguageFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkLanguage" data-toggle="dropdown"
						aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.languageFilter.label }}: </label>
						<span class="nav-filter-value mr-1"> {{ selectedLanguage ? selectedLanguage?.language_name :
							'All'}}</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuLinkLanguage">
						<a class="dropdown-item" href="javascript:;" (click)="onlanguageFilterChange(item)"
							*ngFor="let item of languages">
							{{ item.language_name }}
						</a>
					</div>
				</div>
				<div class="dropdown filter-dd ml-2" *ngIf="config?.carTypeFilter?.isCarTypeFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkCarType" data-toggle="dropdown"
						aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.carTypeFilter.label }}: </label>
						<span class="nav-filter-value mr-1"> {{ selectedCarType ? selectedCarType?.car_name :
							'All'}}</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuLinkCarType">
						<a class="dropdown-item" href="javascript:;" (click)="onCarTypeFilterChange(item)"
							*ngFor="let item of carTypes">
							{{ item.car_name }}
						</a>
					</div>
				</div>
				<div class="dropdown filter-dd ml-2" *ngIf="config?.onlineFilter?.isOnlineFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkOnlineFilter"
						data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.onlineFilter.label }}: </label>
						<span class="nav-filter-value mr-1"> {{ selectedOnlineStatus ? selectedOnlineStatus :
							'All'}}</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuLinkOnlineFilter">
						<a class="dropdown-item" href="javascript:;" (click)="onOnlineStatusSelect(item)"
							*ngFor="let item of onlineStatus">
							{{ item }}
						</a>
					</div>
				</div>
				<!-- prepaid info -->
				<div class="dt-header-export-btn dt-header-date-filter d-flex align-items-center prepaid-total"
					*ngIf="config.prepaidTotal?.isTotal">
					<a>
						{{config.prepaidTotal?.totalLabel}} <span class="prepaid-trips">
							$ {{config.prepaidTotal?.total | number : '1.2-2' }} |
							{{config.prepaidTotal?.tripsLabel}} {{config.prepaidTotal?.trips }}
						</span>

						<span *ngIf="currentUser?.admin_type == 1 || currentUser?.admin_type == 2">
							<span>
								{{config.prepaidTotal?.networkFeeLabel}}
							</span>
							<span class="prepaid-trips">
								$ {{config.prepaidTotal?.networkFeeTotal | number : '1.2-2'}}
							</span>
						</span>
					</a>
				</div>
				<div class="dropdown filter-dd ml-2" *ngIf="config?.rideStatusFilter?.isrideStatusFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkRideStatusFilter"
						data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.rideStatusFilter.label }}: </label>
						<span class="nav-filter-value mr-1">
							{{ selectedRideStatus ? selectedRideStatus.label : 'All Trips'}}
						</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuLinkRideStatusFilter">
						<a class="dropdown-item" href="javascript:;" (click)="onRideStatusStatusSelect(item)"
							*ngFor="let item of config?.rideStatusFilter?.filter">
							{{ item?.label }}
						</a>
					</div>
				</div>
				<div class="dropdown filter-dd ml-2" *ngIf="config?.queueStatusFilter?.isQueuedStatusFilter">
					<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkQueuedStatus"
						data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<label class="nav-filter-label">{{ config?.queueStatusFilter.label }}: </label>
						<span class="nav-filter-value mr-1">
							{{ selectedQueuedStatus ? selectedQueuedStatus.label : 'All'}}
						</span>
						<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
					</a>
					<div class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuLinkQueuedStatus">
						<a class="dropdown-item" href="javascript:;" (click)="onQueuedStatusChange(item)"
							*ngFor="let item of config?.queueStatusFilter?.filter">
							{{ item?.label }}
						</a>
					</div>
				</div>

				<div class="dt-header-export-btn dt-header-date-filter d-flex align-items-center ml-2"
					*ngIf="config.dateFilter?.isDate &&  (config.type == 'v2Trips' || config.type == 'revenueTable') && !isMobileScreen">
					<ng-container *ngIf="!config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="selectedStartDateTrip" name="selectedStartDateTrip"
							[showDropdowns]="true" [lockStartDate]="false"
							[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null" [customRangeDirection]="false"
							(change)="onDateRangeTripV2($event)" [showClearButton]="config.dateFilter.showClearButton"
							[singleDatePicker]="false" />
					</ng-container>
					<ng-container *ngIf="config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="selectedEndDateTrip" name="selectedEndDateTrip"
							[showDropdowns]="true" [lockStartDate]="false"
							[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null" [customRangeDirection]="false"
							(change)="onDateRangeTripV2($event)" [showClearButton]="config.dateFilter.showClearButton"
							[ranges]="config.dateFilter?.ranges" [alwaysShowCalendars]="true"
							[singleDatePicker]="false" />
					</ng-container>

					<div class="dt-header-date-filter-btn btn btn-outline-secondary" (click)="openDatepicker()">
						<span class="ngx-daterangepicker-action pointer dateFilter-label">
							Filter:
						</span>
						<span class="ngx-daterangepicker-action pointer dateFilter-value">
							<span *ngIf="selectedStartDateTrip && selectedEndDateTrip">{{ selectedStartDateTrip }} - {{
								selectedEndDateTrip }}</span>
							<span *ngIf="!selectedStartDateTrip || !selectedEndDateTrip">All</span>
						</span>
					</div>
				</div>

				<div class="dt-header-export-btn dt-header-date-filter d-flex align-items-center ml-2"
					*ngIf="config.dateFilter?.isDate && config.type == 'assigned&queued' && !isMobileScreen">
					<ng-container *ngIf="!config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="selectedStartDate" name="selectedStartDate"
							[showDropdowns]="true" [lockStartDate]="false"
							[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null" [customRangeDirection]="false"
							(change)="onDateRangeAssignedQueued($event)"
							[showClearButton]="config.dateFilter.showClearButton" [singleDatePicker]="false" />
					</ng-container>
					<ng-container *ngIf="config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="selectedEndDate" name="selectedEndDate" [showDropdowns]="true"
							[lockStartDate]="false" [maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null"
							[customRangeDirection]="false" (change)="onDateRangeAssignedQueued($event)"
							[showClearButton]="config.dateFilter.showClearButton" [ranges]="config.dateFilter?.ranges"
							[alwaysShowCalendars]="true" [singleDatePicker]="false" />
					</ng-container>

					<div class="dt-header-date-filter-btn btn btn-outline-secondary" (click)="openDatepicker()">
						<span class="ngx-daterangepicker-action pointer dateFilter-label">
							Filter:
						</span>
						<span class="ngx-daterangepicker-action pointer dateFilter-value">
							{{ selectedStartDate }} - {{selectedEndDate }}
						</span>
					</div>
				</div>
				<div class="dt-header-export-btn dt-header-date-filter d-flex align-items-center ml-2"
					*ngIf="config.dateFilter?.isDate && config.type != 'assigned&queued' && config.type != 'v2Trips' && config.type != 'revenueTable' && !isMobileScreen">
					<ng-container *ngIf="!config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="dateRange" [showDropdowns]="true" [lockStartDate]="false"
							[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null" [customRangeDirection]="false"
							(change)="onDateTangeChange($event)" [showClearButton]="config.dateFilter.showClearButton"
							[singleDatePicker]="config.dateFilter?.singleDatePicker ? true : false" />
					</ng-container>
					<ng-container *ngIf="config.dateFilter?.isCustomeRange">
						<input class="form-control d-none" ngxDaterangepickerMd
							[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
							placeholder="" [(ngModel)]="dateRange" [showDropdowns]="true" [lockStartDate]="false"
							[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null" [customRangeDirection]="false"
							(change)="onDateTangeChange($event)" [showClearButton]="config.dateFilter.showClearButton"
							[ranges]="config.dateFilter?.ranges" [alwaysShowCalendars]="true"
							[singleDatePicker]="config.dateFilter?.singleDatePicker ? true : false" />
					</ng-container>

					<div class="dt-header-date-filter-btn btn btn-outline-secondary">
						<span class="ngx-daterangepicker-action pointer dateFilter-label" (click)="openDatepicker()">
							Filter:
						</span>
						<span class="ngx-daterangepicker-action pointer dateFilter-value" (click)="openDatepicker()">
							<ng-container *ngIf="config.dateFilter?.singleDatePicker">
								{{ selectedReadableDate ? selectedReadableDate : 'Date' }}
							</ng-container>
							<ng-container *ngIf="!config.dateFilter?.singleDatePicker">Date</ng-container>
						</span>
					</div>
				</div>
				<div class="dt-header-export-btn ml-2" *ngIf="config.dd?.idDd">
					<select class="form-control" [(ngModel)]="config.dd.selected" (change)="ddOnChange($event)">
						<option value="">{{ config.dd?.text }}</option>
						<option *ngFor="let item of config.dd?.arr" [value]="item[config.dd?.id]">
							{{ item[config.dd?.label] }}
						</option>
					</select>
				</div>
				<div class="dt-header-export-btn ml-2" *ngIf="config.export.isExport">
					<button type="button" class="btn btn-outline-secondary" *ngIf="!config.export.type">{{
						config.export.text }}</button>
					<button type="button" class="btn btn-outline-secondary" *ngIf="config.export.type"
						[matMenuTriggerFor]="exportDdBtn">
						{{ config.export.text }}
					</button>
					<mat-menu #exportDdBtn="matMenu" class="action-dd">
						<button mat-menu-item *ngFor="let item of config.export.subBtns" class="export-btn-group"
							(click)="btnClick(item)">
							<span class="export-btn-labels">{{ item }}</span>
						</button>
					</mat-menu>
				</div>

				<div class="dt-header-export-btn ml-2" *ngIf="config.payment?.isPayment"
					(click)="openPaymentModal(config.payment?.btnText)">
					<button type="button" class="btn btn-outline-secondary">{{ config.payment?.btnText }}</button>
				</div>

				<div class="dt-header-export-btn ml-2 " *ngIf="config.add?.isAdd">
					<button (click)="btnClick('add')" type="button" class="btn btn-outline-secondary">
						{{ config.add?.btnText }}
					</button>
				</div>

				<div class="dt-header-export-btn ml-2" *ngIf="config.create?.isCreate">
					<button (click)="btnClick('create')" type="button" class="btn btn-outline-secondary">
						<img src="assets/img/icons/edit.png" alt="edit">
						{{ config.create?.btnText }}
					</button>
				</div>

				<div class="dt-header-search ml-2" *ngIf="config.search.isSearch">
					<div class="input-group mb-0">
						<img src="assets/img/icons/navbar/search.png" width="15" height="15" alt="search">
						<input #input type="text" class="form-control" (input)="onSearchInput($event)"
							[(ngModel)]="inputSearch" placeholder={{config.search.searchText}}>
						<img *ngIf="inputSearch" (click)="resetSearch('input')" class="remove-search-icon"
							src="assets/img/icons/searchCloseTheme.svg" width="15" height="15" alt="search">
					</div>
				</div>
			</div>
		</div>

		<!-- Mobile View Design -->
		<div class="min-765">
			<div class="mobile-screen-more-info">
				<img *ngIf="config.add?.isAdd" src="assets/img/icons/add_circle.svg" alt="edit"
					(click)="btnClick('add')"
					class="cursor-pointer hover:opacity-75 transition-opacity d-md-none mr-2" />

				<!-- For the Payment Add modal -->
				<img *ngIf="config.payment?.isPayment" src="assets/img/icons/add_circle.svg" alt="edit"
					(click)="openPaymentModal(config.payment?.btnText)"
					class="cursor-pointer hover:opacity-75 transition-opacity d-md-none mr-2" />

				<div class="position-relative search-icons mr-2 mt-1"
					[ngClass]="{'mr-0' : !config.export.isExport, 'mr-2' : config.export.isExport}"
					*ngIf="config.search.isSearch" ngbDropdown [autoClose]="false" #mySearchDrop="ngbDropdown">
					<img src="/assets/img/icons/whiteCircle.svg" alt="whiteCircle" class="w-full h-full" />
					<div class="position-absolute search inset-0 d-flex" id="mobileDropdown" ngbDropdownToggle>
						<img *ngIf="!searchDropdownOpen" (click)="searchDropdownOpen = true"
							src="/assets/img/icons/search.svg" alt="search" width="18" height="18"
							class="cursor-pointer hover:opacity-75 transition-opacity" />
						<img *ngIf="searchDropdownOpen" (click)="resetSearch($event);"
							src="assets/img/icons/searchCloseTheme.svg" alt="search" width="18" height="18"
							class="cursor-pointer hover:opacity-75 transition-opacity" />
					</div>
					<div ngbDropdownMenu aria-labelledby="mobileDropdown">
						<div class="mobile-menu">
							<div class="dt-header-search">
								<div class="input-group mb-0">
									<img src="assets/img/icons/navbar/search.png" width="15" height="15" alt="search">
									<input type="text" class="form-control" (input)="onSearchInput($event)"
										[(ngModel)]="inputSearch" placeholder={{config.search.searchText}}>
									<img *ngIf="inputSearch" (click)="resetInputSearch()" class="remove-search-icon"
										src="assets/img/icons/searchCloseTheme.svg" width="15" height="15" alt="search">
								</div>
							</div>
						</div>
						<div class="dropdown-divider"></div>
						<div class="mobile-menu-footer">
							<span ngbDropdownItem
								(click)="mySearchDrop.close();inputSearch ? searchDropdownOpen = true : searchDropdownOpen = false">
								Dismiss
							</span>
						</div>
					</div>
				</div>
				<div ngbDropdown *ngIf="config.export.isExport">
					<img src="/assets/img/icons/more_horiz_rounded.svg" alt="addAccount" width="35" height="35"
						class="cursor-pointer" id="mobileDropdownMoreOption" ngbDropdownToggle />
					<div ngbDropdownMenu aria-labelledby="mobileDropdownMoreOption">
						<div class="mobile-menu">
							<h5 class="option mb-3">
								More Options
							</h5>
							<div *ngFor="let item of config.export.subBtns" class="export-btn-group mt-2 p-0"
								(click)="btnClick(item)" ngbDropdownItem>
								<span class=" export-btn-labels">
									{{ item }}
								</span>
							</div>
						</div>
						<ng-container *ngIf="config.prepaidTotal?.isTotal">
							<div class="dropdown-divider mb-2"></div>
							<div class="mobile-menu">
								<h5 class="export-btn-labels mb-2">Prepaid Info</h5>
								<div class="export-btn-group mt-2 p-0">
									<span class="export-btn-labels">{{ config.prepaidTotal?.totalLabel }}</span>
									<span class="export-btn-labels ml-1">$ {{config.prepaidTotal?.total | number :
										'1.2-2' }}</span>
								</div>
								<div class="export-btn-group mt-2 p-0">
									<span class="export-btn-labels">{{ config.prepaidTotal?.tripsLabel }}</span>
									<span class="export-btn-labels ml-1">{{ config.prepaidTotal?.trips }}</span>
								</div>
								<div *ngIf="currentUser?.admin_type == 1 || currentUser?.admin_type == 2"
									class="export-btn-group mt-2 p-0">
									<span class=" export-btn-labels">
										{{ config.prepaidTotal?.networkFeeLabel.includes("| ") ?
										config.prepaidTotal?.networkFeeLabel.replace("| ", "") :
										config.prepaidTotal?.networkFeeLabel }}
									</span>
									<span class=" export-btn-labels ml-1">$ {{ config.prepaidTotal?.networkFeeTotal |
										number : '1.2-2' }}</span>
								</div>
							</div>
						</ng-container>
						<div class="dropdown-divider"></div>
						<div class="mobile-menu-footer">
							<span ngbDropdownItem>
								Dismiss
							</span>
						</div>
					</div>
				</div>
				<div class="filter-img" [ngClass]="{'isTabMultiple' : !isTabMultiple}" data-toggle="modal"
					data-target="#exampleModal" (click)="openModal()">
					<img src="/assets/img/icons/filter_list.svg" alt="addAccount" width="25" height="25"
						class="cursor-pointer" />
				</div>
			</div>
		</div>
	</div>
</section>


<!-- Mobile Screen Filter Modal -->
<div class="modal fade modal-fullscreen" id="exampleModal" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title mr-1" id="exampleModalLabel">
					Filter:
				</h5>
				<span class="reset-label" (click)="resetAllFilter()">
					Reset
				</span>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
					<span aria-hidden="true">
						<img src="/assets/img/icons/searchCloseTheme.svg" alt="addAccount" />
					</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="d-flex align-items-center justify-content-center p-4"
					*ngIf="config?.languageFilter?.islanguageFilter">
					<div class="dropdown filter-dd ml-2">
						<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkLanguage"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<label class="nav-filter-label">{{ config?.languageFilter.label }}: </label>
							<span class="nav-filter-value mr-1"> {{ selectedLanguage ? selectedLanguage?.language_name :
								'All'}}</span>
						</a>
						<div class="dropdown-menu dropdown-menu-mobile" aria-labelledby="dropdownMenuLinkLanguage">
							<div class="dropdown-header p-3">
								Select Language
							</div>
							<div class="dropdown-item-section">
								<a class="dropdown-item text-center" href="javascript:;"
									(click)="onlanguageFilterChange(item)" *ngFor="let item of languages">
									{{ item.language_name }}
								</a>
							</div>
						</div>
					</div>
				</div>
				<ng-container *ngIf="config?.carTypeFilter?.isCarTypeFilter">
					<div class="d-flex align-items-center justify-content-center p-4">
						<div class="dropdown filter-dd ml-2">
							<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkCarType"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<label class="nav-filter-label">{{ config?.carTypeFilter.label }}: </label>
								<span class="nav-filter-value mr-1"> {{ selectedCarType ? selectedCarType?.car_name :
									'All'}}</span>
							</a>
							<div class="dropdown-menu dropdown-menu-mobile" aria-labelledby="dropdownMenuLinkCarType">
								<div class="dropdown-header p-3">
									Select Car Type
								</div>
								<div class="dropdown-item-section">
									<a class="dropdown-item text-center" href="javascript:;"
										(click)="onCarTypeFilterChange(item)" *ngFor="let item of carTypes">
										{{ item.car_name }}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="dropdown-divider"></div>
				</ng-container>
				<ng-container *ngIf="config?.rideStatusFilter?.isrideStatusFilter">
					<div class="d-flex align-items-center justify-content-center p-4">
						<div class="dropdown filter-dd ml-2">
							<a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLinkRideStatusFilter"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<label class="nav-filter-label">{{ config?.rideStatusFilter.label }}: </label>
								<span class="nav-filter-value mr-1">
									{{ selectedRideStatus ? selectedRideStatus.label : 'All Trips'}}
								</span>
							</a>
							<div class="dropdown-menu scrollable-menu"
								aria-labelledby="dropdownMenuLinkRideStatusFilter">
								<a class="dropdown-item" href="javascript:;" (click)="onRideStatusStatusSelect(item)"
									*ngFor="let item of config?.rideStatusFilter?.filter">
									{{ item?.label }}
								</a>
							</div>
						</div>
					</div>
					<div class="dropdown-divider"></div>
				</ng-container>
				<ng-container *ngIf="config.dateFilter?.isDate && config.type != 'assigned&queued' && isMobileScreen">
					<div class="d-flex align-items-center justify-content-center p-4 date-time-filter">
						<ng-container *ngIf="!config.dateFilter?.isCustomeRange">
							<input class="form-control d-none" ngxDaterangepickerMd
								[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
								placeholder="" [(ngModel)]="dateRange" [showDropdowns]="true" [lockStartDate]="false"
								[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null"
								[customRangeDirection]="false" (change)="onDateTangeChange($event)"
								[showClearButton]="config.dateFilter.showClearButton"
								[singleDatePicker]="config.dateFilter?.singleDatePicker ? true : false" />
						</ng-container>
						<ng-container *ngIf="config.dateFilter?.isCustomeRange">
							<input class="form-control d-none" ngxDaterangepickerMd
								[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
								placeholder="" [(ngModel)]="dateRange" [showDropdowns]="true" [lockStartDate]="false"
								[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null"
								[customRangeDirection]="false" (change)="onDateTangeChange($event)"
								[showClearButton]="config.dateFilter.showClearButton"
								[ranges]="config.dateFilter?.ranges" [alwaysShowCalendars]="true"
								[singleDatePicker]="config.dateFilter?.singleDatePicker ? true : false" />
						</ng-container>

						<div class="dt-header-date-filter-btn btn btn-outline-secondary">
							<span class="ngx-daterangepicker-action pointer dateFilter-label"
								(click)="openDatepicker()">
								Filter:
							</span>
							<span class="ngx-daterangepicker-action pointer dateFilter-value"
								(click)="openDatepicker()">
								<ng-container *ngIf="config.dateFilter?.singleDatePicker">
									{{ selectedReadableDate ? selectedReadableDate : 'Date' }}
								</ng-container>
								<ng-container *ngIf="!config.dateFilter?.singleDatePicker">Date</ng-container>
							</span>
						</div>
					</div>
					<div class="dropdown-divider"></div>
				</ng-container>
				<ng-container *ngIf="config.dateFilter?.isDate && config.type == 'assigned&queued' && isMobileScreen">
					<div class="d-flex align-items-center justify-content-center p-4 date-time-filter">
						<ng-container *ngIf="!config.dateFilter?.isCustomeRange">
							<input class="form-control d-none" ngxDaterangepickerMd
								[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
								placeholder="" [(ngModel)]="selectedStartDate" name="selectedStartDate"
								[showDropdowns]="true" [lockStartDate]="false"
								[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null"
								[customRangeDirection]="false" (change)="onDateRangeAssignedQueued($event)"
								[showClearButton]="config.dateFilter.showClearButton" [singleDatePicker]="false" />
						</ng-container>
						<ng-container *ngIf="config.dateFilter?.isCustomeRange">
							<input class="form-control d-none" ngxDaterangepickerMd
								[autoApply]="config.dateFilter.autoApply" [showCancel]="config.dateFilter.showCancel"
								placeholder="" [(ngModel)]="selectedEndDate" name="selectedEndDate"
								[showDropdowns]="true" [lockStartDate]="false"
								[maxDate]="!config.dateFilter?.isFutureDate ? maxDate : null"
								[customRangeDirection]="false" (change)="onDateRangeAssignedQueued($event)"
								[showClearButton]="config.dateFilter.showClearButton"
								[ranges]="config.dateFilter?.ranges" [alwaysShowCalendars]="true"
								[singleDatePicker]="false" />
						</ng-container>

						<div class="dt-header-date-filter-btn btn btn-outline-secondary">
							<span class="ngx-daterangepicker-action pointer dateFilter-label"
								(click)="openDatepicker()">
								Filter:
							</span>
							<span class="ngx-daterangepicker-action pointer dateFilter-value"
								(click)="openDatepicker()">
								{{ selectedStartDate}} - {{selectedEndDate}}
							</span>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="custom-modal-footer">
				<div class="buttons">
					<button class="dismiss" data-dismiss="modal" (click)="closeModal()">
						Dismiss
					</button>
					<button class="apply" data-dismiss="modal" (click)="closeModal()">
						Apply
					</button>
				</div>
			</div>
		</div>
	</div>
</div>