import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { triangleBall } from 'app/shared/utility';
import { ApiRoutes } from '../../routes';
import { DataService } from '../../services';
import { ToastService } from '../../services';

@Component({
	selector: 'app-link-invite-modal',
	templateUrl: './link-invite-modal.component.html',
	styleUrls: ['./link-invite-modal.component.scss']
})
export class LinkInviteModalComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

	constructor(private toastr: ToastService, private ds: DataService, private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<LinkInviteModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.initForm();
	}

	get formControls() {
		return this.form.controls;
	}

	private initForm(): void {
		this.form = new FormGroup({
			send_via: new FormControl('1', [Validators.required]),
			message: new FormControl({ value: this.message, disabled: true }, [Validators.required]),
			send_on: new FormControl('', [Validators.required])
		}, { updateOn: "change" });
	}

	onTypeChange(): void {
		const type: string = this.formControls.send_via.value;
		if (type === '1') {
			this.form.get('send_on').setValue('');
			this.form.get('send_on').setValidators([Validators.required]);
		} else {
			this.form.get('send_on').setValue('');
			this.form.get('send_on').setValidators([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]);
		}
		this.form.updateValueAndValidity();
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.inviteLink;
		this.ds.post(url, this.payload).subscribe(res => {
			if (res.flag === 3889) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	private get payload() {
		const obj: any = {
			user_type: this.data.actionObj.typeId,
			send_via: this.form.get('send_via').value,
			client_id: this.data.currentUser.admin_id,
			useParentIdAsAdminId: 'false'
		};
		if (this.formControls.send_via.value == '0') {
			obj.send_on = this.form.get('send_on').value;
		} else {
			obj.send_on = this.form.get('send_on').value['e164Number'];
		}
		return obj;
	}

	private get message(): string {
		switch (this.data.actionObj.typeId) {
			case '0':
				return 'Welcome to a better riding experience. To continue enjoying the service of ' + this.data.currentUser.business_name + ', please register and install the Qudos Rider App in this link: https://rd.ridequdos.com/r?rc=' + this.data.currentUser.referral_code;
			case '1':
				return 'Please install the Qudos Driver app and complete the registration process under ' + this.data.currentUser.business_name + ' to start driving and earning soon. Click link: https://dd.ridequdos.com/d?rc=' + this.data.currentUser.referral_code;
			case '2':
				return 'Welcome to a better experience. ' + this.data.currentUser.business_name + ' is now available by installing the Qudos Affiliate in this link:';
			case '3':
				return "To manage your clients and guest's car service needs from  " + this.data.currentUser.business_name + ", please register and install the Qudos Concierge App in this link: https://cd.ridequdos.com/c?rc=" + this.data.currentUser.referral_code;
		}
	}
}