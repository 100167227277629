<nav class="navbar navbar-expand-lg navbar-light header-navbar bg-w {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
	<div class="container-fluid navbar-wrapper">
		<div class="nav-overlay"
			*ngIf="(!userStatus?.docs || !userStatus?.email_verified || !userStatus?.is_approved || !userStatus?.docs_verified) && currentUser.admin_type === 4">
			Please complete activation to get access on these features
		</div>
		<div class="navbar-header d-flex navbar-header-custom">
			<div class="mt-1 d-xl-none d-block" data-toggle="collapse" (click)="toggleSidebar()">
				<em class="ft-menu font-medium-3"></em>
			</div>
			<ul class="navbar-nav">
				<li class="nav-item mr-2 d-none d-lg-block">
					<a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;"
						appToggleFullscreen (click)="ToggleClass()"><em class=" {{toggleClass}} screen-zoom"></em></a>
				</li>

				<li class="nav-item nav-search">
					<a class="nav-link nav-link-search" href="javascript:">
						<img *ngIf="!searchText" src="assets/img/icons/navbar/search.png" alt="search" width="15">
						<img *ngIf="searchText" src="assets/img/icons/searchClose.svg" alt="search" width="15"
							(click)="clearSearch()">
						<input #search class="input" type="text" placeholder="Search" tabindex="0"
							data-search="template-search" (focus)="onSearchFocus($event)" (blur)="onSearchBlur($event)"
							[(ngModel)]="searchText" />
					</a>
					<ul tabindex="0" *ngIf="isGlobalSearchSuggestionVisible" class="suggested-search card m-0 pl-0">
						<ng-container *ngIf="!globalSearcghResult?.length">
							<li class="py-1">TRY SEARCHING...</li>
							<li class="py-1">First name</li>
							<li class="py-1">Last name</li>
							<li class="py-1">Business name</li>
							<li class="py-1" *ngIf="currentUser?.admin_type === 4">Driver/Rider/Affiliate/Corporate ID
							</li>
							<li class="py-1" *ngIf="currentUser?.admin_type !== 4">
								Client/Driver/Rider/Affiliate/Corporate ID</li>
							<li class="py-1">Mobile number</li>
							<li class="py-1">Email address</li>
							<li class="py-1">Vehicle Plate #</li>
							<li class="py-1">Driver License #</li>
						</ng-container>
						<ng-container *ngIf="globalSearcghResult?.length">
							<li class="py-1 pointer search-hover" *ngFor="let item of globalSearcghResult"
								(mousedown)="onSearchSelect(item)">
								{{ item }}
							</li>
						</ng-container>
					</ul>
				</li>


			</ul>
			<!-- <div class="navbar-brand-center">
				<div class="navbar-header">
					<ul class="navbar-nav">
						<li class="nav-item">
							<div class="logo">
								<a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
									<div class="logo-img"><img class="logo-img" alt="logo" [src]="logoUrl"></div>
									<span class="text">Qudos</span>
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div> -->
		</div>
		<div class="navbar-container">

			<!-- Mobile Screen Responsive Design start -->
			<div ngbDropdown class="d-lg-none">
				<img class="avatar" id="mobileDropdown" ngbDropdownToggle
					[src]="currentUser.image ? currentUser.image : 'assets/img/user.png'" alt="avatar" height="36"
					width="36" />
				<div ngbDropdownMenu aria-labelledby="mobileDropdown">
					<div class="mobile-menu">
						<div class="profile-upper">
							<div class="text-clip">{{ currentUser?.admin_name || currentUser?.client_name }}</div>
							<p *ngIf="currentUser?.admin_type === 1">Super Admin</p>
							<p *ngIf="currentUser?.admin_type === 2">Admin</p>
							<p *ngIf="currentUser?.admin_type === 3">Operator</p>
							<p *ngIf="currentUser?.admin_type === 4">Client</p>
						</div>
						<div class="mobile-content">
							<div class="filter">
								<ng-container *ngIf="isCountryStateSearch">
									<li class="nav-item dropdown filter-dd">
										<a class="dropdown-toggle" href="#" id="navbarDropdownMenuLinkCountry"
											data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<label class="nav-filter-label">Country: </label>
											<span class="nav-filter-value mr-1"> {{ selectedCountry?.country_name
												}}</span>

										</a>
										<div class="dropdown-menu scrollable-menu"
											aria-labelledby="navbarDropdownMenuLinkCountry">
											<a class="dropdown-item" href="javascript:;"
												*ngFor="let item of countryList"
												(click)="selectedCountry = item; getStates(); onCountrySelect()">
												{{ item.country_name }}
											</a>
										</div>
									</li>

									<li class="nav-item dropdown filter-dd mt-2">
										<a class="dropdown-toggle" href="#" id="navbarDropdownMenuLinkState"
											data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<label class="nav-filter-label">State: </label>
											<span class="nav-filter-value mr-1">
												{{ selectesState?.state_name ? selectesState.state_name : 'All'
												}}</span>
										</a>
										<div class="dropdown-menu scrollable-menu left-align"
											aria-labelledby="navbarDropdownMenuLinkState">
											<a class="dropdown-item" href="javascript:;" *ngFor="let item of stateList"
												(click)="selectesState = item; onStateSelect()">
												{{ item.state_name }}
											</a>
										</div>
									</li>
								</ng-container>
							</div>
							<div class="dropdown-divider"></div>
							<div class="profile-item-container">
								<ng-container *ngFor="let item of profileDd">
									<a *ngIf="!item.clickable" (click)="goto(item.path)">
										<div class="d-flex align-items-center">
											<em *ngIf="item.icon" class="{{item.icon}}"></em>
											<span>{{ item.title }}</span>
										</div>
									</a>
									<a *ngIf="item.clickable" href="javascript:;" (click)="logout()">
										<div class="d-flex align-items-center mt-2">
											<em *ngIf="item.icon" class="{{item.icon}}"></em>
											<span>{{ item.title }}</span>
										</div>
									</a>
								</ng-container>
							</div>
							<div class="dropdown-divider"></div>
							<div class="mobile-menu-footer">
								<span ngbDropdownItem>
									Dismiss
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Mobile Screen Responsive Design End -->

			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav">
					<li class="nav-item mr-2 nav-items-divider dropdown filter-dd">
						<a href="javascript:;" (click)="externalNavigation(corpUrl, 0)"
							class="nav-link notification-sidebar-toggle nav-filter-value">
							Concierge
						</a>
					</li>

					<li class="nav-item dropdown filter-dd pr-2">
						<a href="javascript:;" (click)="externalNavigation(livemapUrl, 1)"
							class="nav-link notification-sidebar-toggle nav-filter-value">
							Live Tracking
						</a>
					</li>
					<ng-container *ngIf="isCountryStateSearch">
						<li class="nav-item mr-2 nav-items-divider dropdown filter-dd">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLinkCountry"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<label class="nav-filter-label">Country: </label>
								<span class="nav-filter-value mr-1"> {{ selectedCountry?.country_name }}</span>
								<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
							</a>
							<div class="dropdown-menu scrollable-menu" aria-labelledby="navbarDropdownMenuLinkCountry">
								<a class="dropdown-item" href="javascript:;" *ngFor="let item of countryList"
									(click)="selectedCountry = item; getStates(); onCountrySelect()">
									{{ item.country_name }}
								</a>
							</div>
						</li>

						<li class="nav-item dropdown filter-dd">
							<a class="nav-link dropdown-toggle pr-2" href="#" id="navbarDropdownMenuLinkState"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<label class="nav-filter-label">State: </label>
								<span class="nav-filter-value mr-1">
									{{ selectesState?.state_name ? selectesState.state_name : 'All' }}</span>
								<img class="nav-filter-caret" src="assets/img/icons/caret-down.png" alt="caret-down">
							</a>
							<div class="dropdown-menu scrollable-menu left-align"
								aria-labelledby="navbarDropdownMenuLinkState">
								<a class="dropdown-item" href="javascript:;" *ngFor="let item of stateList"
									(click)="selectesState = item; onStateSelect()">
									{{ item.state_name }}
								</a>
							</div>
						</li>
					</ng-container>


					<li class="nav-item d-none d-lg-block mr-1" [ngClass]="{'nav-items-divider': isCountryStateSearch}">
						<a class="nav-link notification-sidebar-toggle">
							<img src="assets/img/icons/navbar/notification.png" alt="support">
						</a>
					</li>
					<li class="nav-item d-none d-lg-block mr-1">
						<a class="nav-link notification-sidebar-toggle">
							<img src="assets/img/icons/navbar/support.png" alt="support">
						</a>
					</li>
					<li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
						<!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button> -->
						<!-- <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic1"
							href="javascript:;" ngbDropdownToggle>
							<img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="36"
								width="36" />
						</a>
						<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
							<button ngbDropdownItem>Action - 1</button>
							<button ngbDropdownItem>Another Action</button>
							<button ngbDropdownItem>Something else is here</button>
						</div> -->
						<a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
							href="javascript:;" ngbDropdownToggle>
							<img class="avatar" [src]="currentUser.image ? currentUser.image : 'assets/img/user.png'"
								alt="avatar" height="36" width="36" />
						</a>
						<div class="dropdown-menu profile text-left dropdown-menu-right m-0 pb-0"
							aria-labelledby="dropdownBasic2" ngbDropdownMenu>
							<div class="profile-upper">
								<div class="text-clip">{{ currentUser?.admin_name || currentUser?.client_name }}</div>
								<p *ngIf="currentUser?.admin_type === 1">Super Admin</p>
								<p *ngIf="currentUser?.admin_type === 2">Admin</p>
								<p *ngIf="currentUser?.admin_type === 3">Operator</p>
								<p *ngIf="currentUser?.admin_type === 4">Client</p>
							</div>
							<div class="dropdown-divider"></div>
							<div class="profile-item-container">
								<ng-container *ngFor="let item of profileDd">
									<a *ngIf="!item.clickable" class="dropdown-item" (click)="goto(item.path)">
										<div class="d-flex align-items-center">
											<em *ngIf="item.icon" class="{{item.icon}} mr-2"></em>
											<span>{{ item.title }}</span>
										</div>
									</a>
									<a *ngIf="item.clickable" class="dropdown-item" href="javascript:;"
										(click)="logout()">
										<div class="d-flex align-items-center">
											<em *ngIf="item.icon" class="{{item.icon}} mr-2"></em>
											<span>{{ item.title }}</span>
										</div>
									</a>
								</ng-container>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>