import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ConfirmModalComponent } from '../../../shared/components';

@Component({
	selector: 'app-date-modal',
	templateUrl: './date-modal.component.html',
	styleUrls: ['./date-modal.component.scss']
})
export class DateModalComponent implements OnInit {
	selectedDate: any;
	isSubmitting: boolean = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DateModalComponent>, private dialog: MatDialog) { }

	ngOnInit(): void { }

	pay(): void {
		if (!this.selectedDate && !this.isSubmitting) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		const obj = {
			result: true,
			date: this.selectedDate
		};
		let modalParams: any = {
			action: 'proceed with',
			for: 'transaction',
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		};
		const data = { modalParams: modalParams };
		const dialogRef = this.dialog.open(ConfirmModalComponent, { data, width: '400px' });
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.dialogRef.close(obj);
			}
		});
	}
}