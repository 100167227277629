import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef, HostListener, TemplateRef } from '@angular/core';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';

import { IListHeaderConfig } from '../../../interfaces';
import { ConfigService, DataService, GlobalService } from '../../services';
import { Router } from '@angular/router';
import { ApiRoutes } from '../../../shared/routes';
import { LocalStorage } from 'app/storage';
import { map, switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

@Component({
	selector: 'app-list-header',
	templateUrl: './list-header.component.html',
	styleUrls: ['./list-header.component.scss'],
})
export class ListHeaderComponent implements OnInit {
	@Input() config: IListHeaderConfig;
	@Input() isTabMultiple: boolean;
	@Output() listener: EventEmitter<any> = new EventEmitter();
	@ViewChild('input', { static: false }) input: ElementRef;
	inputSearch: string = '';
	searchDropdownOpen: boolean = false;
	private searchInputSubject = new Subject<string>();
	subscriptions: Subscription = new Subscription();
	dateRange: any;
	@ViewChild(DaterangepickerDirective, { static: false })
	pickerDirective: DaterangepickerDirective;
	selectedCarType: any;
	selectedOnlineStatus: any;
	selectedRideStatus: any;
	onlineStatus: string[] = ['All', 'Online', 'Offline'];
	carTypes: any[] = [
		{ car_name: "All", car_type: 'All', passenger_count: 'All' }
	];
	maxDate: any = moment();
	languages: any[] = [
		{ language_id: null, language_name: 'All' }
	];
	selectedLanguage: any;
	selectedMasFilter: string = 'All';
	masFilters: string[] = ['All', 'Non-MAS', 'MAS Only'];
	selectedQueuedStatus: any;
	selectedReadableDate: string;
	selectedStartDate: string = moment().format("MMMM DD");
	selectedEndDate: string = moment().format("MMMM DD");
	selectedStartDateTrip: string = '';
	selectedEndDateTrip: string = '';
	currentUser: any;
	isMobileScreen: boolean = false;

	constructor(private ds: DataService, private router: Router, private cd: ChangeDetectorRef, private ls: LocalStorage, private configService: ConfigService) {
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
	}

	ngOnInit(): void {
		if (this.config.search.isSearch) {
			const temp = this.ds.tabChangeEvent.subscribe((data) => {
				this.input.nativeElement.value = '';
				this.dateRange = null;
			});
			this.subscriptions.add(temp);

			/** Input Search Subject */
			this.searchInputSubject.pipe(
				debounceTime(500),
				switchMap((value: string) => {
					const obj = {
						action: 'search',
						value: value,
					};
					this.cd.markForCheck();
					this.listener.emit(obj);
					return of(null); // Replace with actual observable from your API call
				})
			).subscribe();
		}
		if (this.config?.carTypeFilter?.isCarTypeFilter) {
			this.getCarTypeList();
		}
		if (this.config?.languageFilter?.islanguageFilter) {
			this.getLanguages();
		}

		if (window.innerWidth < 765) {
			this.config.title.islongTitle ? this.isMobileScreen = true : this.isMobileScreen = false;
		} else {
			this.isMobileScreen = false;
			this.config.title.islongTitle = false;
		}
	}

	getWeekLabel() {
		return "week 1"
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit(): void {
		// if (this.config.search.isSearch) {
		// 	setTimeout(() => {
		// 		const searchEvt = fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(500), distinctUntilChanged()).subscribe((data: any) => {
		// 			const obj = {
		// 				action: 'search',
		// 				value: this.input.nativeElement.value,
		// 			};
		// 			console.log(obj)
		// 			this.cd.markForCheck();
		// 			this.listener.emit(obj);
		// 		});
		// 		this.subscriptions.add(searchEvt);
		// 	});
		// }
	}

	/** On Input search  */
	onSearchInput(event: any): void {
		if (this.config.search.isSearch) {
			const searchInput = event.target;
			this.searchInputSubject.next(searchInput.value);
		}
	}

	/** Reset Search */
	resetSearch(event) {
		if (this.inputSearch && this.searchDropdownOpen) {
			this.inputSearch = '';
			const obj = {
				action: 'search',
				value: '',
			};
			this.listener.emit(obj);
			event.stopPropagation();
			this.searchDropdownOpen = false;
			this.cd.markForCheck();
		} else {
			this.searchDropdownOpen = false;
			if (this.inputSearch) {
				this.inputSearch = '';
				const obj = {
					action: 'search',
					value: '',
				};
				this.listener.emit(obj);
				this.cd.markForCheck();
			}
		}
	}

	/** Reset Input Search */
	resetInputSearch() {
		if (this.inputSearch) {
			this.inputSearch = '';
			const obj = {
				action: 'search',
				value: '',
			};
			this.listener.emit(obj);
			this.cd.markForCheck();
		}
	}


	private getLanguages(): void {
		this.ds.get(ApiRoutes.languages).subscribe(res => {
			if (res.languages.length) {
				this.languages = [...this.languages, ...res.languages];
			}
		})
	}

	onlanguageFilterChange(item): void {
		this.selectedLanguage = item;
		const obj = {
			action: 'languageFilter',
			value: item,
		};
		this.listener.emit(obj);
	}

	getCarTypeList(): void {
		this.ds.post(ApiRoutes.getAllCarTypes, {}).subscribe(res => {
			if (res.flag === 1504) {
				this.carTypes = [...this.carTypes, ...res.car_types];
			}
		});
	}

	onCarTypeFilterChange(item: any): void {
		this.selectedCarType = item;
		const obj = {
			action: 'carTypeFilter',
			value: item,
		};
		this.listener.emit(obj);
	}

	onMasFilterChange(item: string): void {
		this.selectedMasFilter = item;
		const obj = {
			action: 'masFilter',
			value: item,
		};
		this.listener.emit(obj);
	}

	onOnlineStatusSelect(value: string): void {
		this.selectedOnlineStatus = value;
		const obj = {
			action: 'onlineStatus',
			value: value,
		};
		this.listener.emit(obj);
	}

	onRideStatusStatusSelect(selection: any): void {
		this.selectedRideStatus = selection;
		const obj = {
			action: 'assignedTripStatus',
			value: selection,
		};
		this.listener.emit(obj);
	}

	onQueuedStatusChange(selection: any): void {
		this.selectedQueuedStatus = selection;
		const obj = {
			action: 'queuedStatus',
			value: selection,
		};
		this.listener.emit(obj);
	}

	btnClick(actionType: string): void {
		// if (actionType === 'add' || actionType === 'create') {
		const obj = {
			action: actionType,
			value: '',
		};
		this.listener.emit(obj);
		// }
	}

	ddOnChange(e): void {
		const obj = {
			action: 'dd-filter',
			value: e.target.value,
		};
		this.listener.emit(obj);
	}

	openDatepicker(): void {
		this.pickerDirective.open();
	}

	onDateTangeChange(e): void {
		if (e.endDate === null && e.startDate === null) {
			this.selectedReadableDate = moment().format("MMMM DD");
			const obj = {
				action: 'datefilter',
				startDate: e.startDate,
				endDate: e.endDate,
			};
			this.listener.emit(obj);
		}
		if (e.endDate || e.startDate) {
			this.selectedReadableDate = moment(this.dateRange.startDate).format("MMMM DD");
			const obj = {
				action: 'datefilter',
				startDate: e.startDate._d,
				endDate: e.endDate._d,
			};
			this.listener.emit(obj);
		}
	}

	onDateRangeAssignedQueued(e): void {
		const startDate = e.startDate ? e.startDate : moment().format("MMMM DD");
		this.selectedStartDate = moment(startDate).format("MMMM DD");
		const endDate = e.endDate ? e.endDate : moment().format("MMMM DD");
		this.selectedEndDate = moment(endDate).format("MMMM DD");
		const obj = {
			action: 'datefilter',
			startDate: e.startDate ? e.startDate : moment().startOf('day'),
			endDate: e.endDate ? e.endDate : moment().endOf('day')
		};
		this.listener.emit(obj);
	}

	onDateRangeTripV2(e): void {
		this.selectedStartDateTrip = e.startDate ? moment(e.startDate).format("MMMM DD") : '';
		this.selectedEndDateTrip = e.endDate ? moment(e.endDate).format("MMMM DD") : '';
		const obj = {
			action: 'datefilter',
			startDate: e.startDate ? e.startDate : '',
			endDate: e.endDate ? e.endDate : ''
		};
		this.listener.emit(obj);
	}

	openPaymentModal(flag: string): void {
		const obj = {
			action: flag,
			value: '',
		};
		this.listener.emit(obj);
	}

	redirect() {
		this.router.navigate(['reports']);
	}

	back(url: string): void {
		this.router.navigate([url]);
	}

	resetAllFilter() {
		this.onlanguageFilterChange(this.languages);
		this.onCarTypeFilterChange(this.carTypes);
	}

	/** Show the total value today this week this month  */
	getFormattedValue(): string[] {
		return this.config.title.name.split('|').map(value => value.trim());
	}

	/** Window Reaize event */
	@HostListener('window:resize', ['$event'])
	windowResizecall(event: any) {
		// this.checkStatuForResize(false);
	}

	/** Check Status For Resize */
	// checkStatuForResize(firstTime: any) {
	// 	if (window.innerWidth < 765) {
	// 		this.isMobileScreen = true;
	// 	} else {
	// 		this.isMobileScreen = false;
	// 	}
	// }


	openModal(): void {
		this.configService.setModalState(true);
	}

	closeModal(): void {
		this.configService.setModalState(false);
	}
}