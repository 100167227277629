import { Component, Inject, OnInit, Optional, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs/Subscription";
import { NgxSpinnerService } from "ngx-spinner";

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "../../../../../shared/services";
import { ApiRoutes } from "app/shared/routes";
import { ToastService } from '../../../../../shared/services';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { countryCodeHiphen, triangleBall } from "app/shared/utility";
import { LocalStorage } from '../../../../../storage';

@Component({
	selector: 'app-clients-edit-modal',
	templateUrl: './clients-edit-modal.component.html',
	styleUrls: ['./clients-edit-modal.component.scss']
})
export class ClientsEditModalComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;
	subscription: Subscription = new Subscription();
	countries: any[] = [];
	languages: any[] = [];

	states: any[] = [];
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
	selectedDoc: any;

	constructor(private spinner: NgxSpinnerService, private ls: LocalStorage, private cd: ChangeDetectorRef, private toastr: ToastService, private ds: DataService, private dialogRef: MatDialogRef<ClientsEditModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.getInitialData();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private getInitialData() {
		const url1: string = ApiRoutes.languages;
		const url2: string = ApiRoutes.getCountryList;
		const urls: string[] = [url1, url2];
		this.ds.forkJoin(urls).subscribe(res => {
			if (res[0].languages.length) {
				this.languages = res[0].languages;
			}
			if (res[1].countries.length) {
				this.countries = res[1].countries;
			}
			this.cd.markForCheck();
		});
		this.initForm();
		if (this.data.action === 'edit') {
			this.getStates(this.data.vendorDetail.country_id)
		}
	}

	onCountryChange(e): void {
		this.getStates(e.target.value);
	}

	getStates(countryId: string): void {
		const url: string = ApiRoutes.getStateList;
		const obj = {
			country_id: +countryId,
			state_id: 0
		}
		this.ds.post(url, obj).subscribe(res => {
			this.states = res.states;
			this.cd.markForCheck();
		});
	}

	get formControls() {
		return this.form.controls;
	}

	/**
	 * Description: file change event
	 */
	onFileChanged(event): void {
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			this.selectedDoc = event.target.files[0];
			reader.readAsDataURL(event.target.files[0]); // read file as data url
			reader.onload = (event) => {
				// called once readAsDataURL is completed
				this.form.get("image").setValue(event.target.result);
			};
		}
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		this.form = new FormGroup({
			first_name: new FormControl(this.data.vendorDetail.first_name, [Validators.required]),
			last_name: new FormControl(this.data.vendorDetail.last_name, [Validators.required]),
			email: new FormControl(this.data.vendorDetail.email, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			mobile: new FormControl(this.data.vendorDetail.mobile, [Validators.required]),
			support_number: new FormControl(this.data.vendorDetail.support_number),
			support_email: new FormControl(this.data.vendorDetail.support_email, [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			zipcode: new FormControl(this.data.vendorDetail.zipcode, [Validators.required]),
			admin_id: new FormControl(this.data.vendorDetail.admin_id, [Validators.required]),
			angle: new FormControl(null),
			image: new FormControl(this.data.vendorDetail.image, [Validators.required]),
			business_name: new FormControl(this.data.vendorDetail.business_name, [Validators.required]),
			car_base_number: new FormControl(this.data.vendorDetail.car_base_number, [Validators.required]),
			date_registered: new FormControl({ value: this.dateString, disabled: true }),
			passport_number: new FormControl(this.data.vendorDetail.passport_number, Validators.required),
			dmv_num: new FormControl((this.data.vendorDetail.dmv_num && this.data.vendorDetail.dmv_num != 'null') ? this.data.vendorDetail.dmv_num : ''),
			country_id: new FormControl(this.data.vendorDetail.country_id ? this.data.vendorDetail.country_id : ''),
			state_id: new FormControl(this.data.vendorDetail.state_id ? this.data.vendorDetail.state_id : ''),
			language: new FormControl(this.data.vendorDetail.language ? this.data.vendorDetail.language : ''),
			address: new FormControl(this.data.vendorDetail.address ? this.data.vendorDetail.address : ''),
			regulation_type: new FormControl((this.data.vendorDetail.regulation_type || this.data.vendorDetail.regulation_type == 0) ? this.data.vendorDetail.regulation_type : '')
		}, { updateOn: "change" });
	}

	get dateString(): string {
		let date: any = new Date(this.data.vendorDetail.date_created);
		date = date.toDateString().replace(/^\S+\s/, '');
		return date;
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.editClient;
		// this.form.value['mobile'] = countryCodeHiphen(this.form.value['mobile']);
		const payload = new FormData();
		payload.append('access_token', this.ls.getItem('qudos-admin-token'));
		payload.append('address', this.form.get('address').value);
		payload.append('admin_id', this.form.get('admin_id').value);
		payload.append('angle', this.form.get('angle').value);
		payload.append('business_name', this.form.get('business_name').value);
		payload.append('car_base_number', this.form.get('car_base_number').value);
		payload.append('country_id', this.form.get('country_id').value);
		payload.append('dmv_num', this.form.get('dmv_num').value);
		payload.append('email', this.form.get('email').value);
		payload.append('first_name', this.form.get('first_name').value);
		payload.append('last_name', this.form.get('last_name').value);
		payload.append('language', this.form.get('language').value);
		payload.append('mobile', countryCodeHiphen(this.form.value['mobile']));
		payload.append('passport_number', this.form.get('passport_number').value);
		payload.append('state_id', this.form.get('state_id').value);
		payload.append('zipcode', this.form.get('zipcode').value);
		payload.append('image', this.selectedDoc);
		payload.append('image_flag', this.selectedDoc ? '1' : '0');
		payload.append('regulation_type', this.form.get('regulation_type').value);
		payload.append('support_number', countryCodeHiphen(this.form.value['support_number']));
		payload.append('support_email', this.form.get('support_email').value);
		this.ds.formDataRequest(url, payload).subscribe(res => {
			if (res.flag === 8013) {
				this.dialogRef.close(true);
				this.toastr.success('Client data Successfully updated');
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}
}