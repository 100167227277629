<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">
		{{ data?.actionObj.btnText + ' ' + data?.actionObj.title }}
	</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-12">
						<div class="row">
							<label class="col-sm-2 col-form-label">Type</label>
							<div class="col-sm-8">
								<select formControlName="send_via" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.send_via.invalid,
									'is-valid': isSubmitting && !formControls.send_via.invalid}" (change)="onTypeChange()">
									<option value="0">Email</option>
									<option value="1">SMS</option>
								</select>
								<div *ngIf="formControls.send_via.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.send_via.errors?.required">*Type is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-2 col-form-label align-items-start">Send To</label>
							<div class="col-sm-8">
								<ng-container *ngIf="formControls.send_via.value === '0'">
									<input type="email" formControlName="send_on" class="form-control" [ngClass]="{
										'is-invalid': isSubmitting && formControls.send_on.invalid,
										'is-valid': isSubmitting && !formControls.send_on.invalid}" placeholder="Enter email here">
									<div *ngIf="formControls.send_on.invalid && isSubmitting"
										class="help-block form-err text-danger">
										<small [hidden]="!formControls.send_on.errors?.required">*Email is
											required.</small>
										<small [hidden]="!formControls.send_on.errors?.pattern">*Invalid email.</small>
									</div>
								</ng-container>
								<ng-container *ngIf="formControls.send_via.value === '1'">
									<ngx-intl-tel-input [preferredCountries]="preferredCountries"
										[enableAutoCountrySelect]="false" [enablePlaceholder]="true"
										[searchCountryFlag]="true" [customPlaceholder]="'Enter mobile number here'"
										[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
										[selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
										[maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
										[inputId]="my-input-id" name="phone" formControlName="send_on"
										class="form-control intl position-rel">
									</ngx-intl-tel-input>
									<div *ngIf="formControls.send_on.invalid && isSubmitting"
										class="help-block form-err text-danger">
										<small [hidden]="!formControls.send_on.errors?.required">*Mobile number is
											required.</small>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-2 col-form-label align-items-start">Message</label>
							<div class="col-sm-8">
								<textarea formControlName="message" class="form-control" cols="30" rows="3" [ngClass]="{
									'is-invalid': isSubmitting && formControls.message.invalid,
									'is-valid': isSubmitting && !formControls.message.invalid}">
								</textarea>
								<div *ngIf="formControls.message.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.message.errors?.required">*Message is
										required.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

		</div>
	</mat-dialog-content>

	<hr class="cutom-divider m-0">
	<mat-dialog-actions class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-5 offset-md-7">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button (click)="submitHandler()" mat-button class="btn btn-primary button-dialog ml-2">Send</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>