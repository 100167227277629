<section id="user-home">
	<div class="main-heading">Users</div>

	<div class="dt-wrapper" *ngIf="data?.value.clients.length && currentUser?.admin_type !== 4">
		<div class="dt-header mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-baseline">
					<div class="main-heading">New Clients</div>
					<div class="more-results ml-2 pointer" (click)="goTo(route.clients)">View {{
						data?.value.clients_count }} results</div>
				</div>
				<div class="dt-header-export-btn">
					<button type="button" class="btn btn-outline-secondary" (click)="goTo(route.clients)">View
						List</button>
				</div>
			</div>
		</div>

		<hr class="cutom-divider">

		<div class="dt-content">
			<div [ngClass]="{'dt-head-scroll': !clientRows?.length}">
				<ngx-datatable class="material" #clientTable id="client-home" [rows]="clientRows"
					[columns]="clientColumns" [columnMode]="'force'" [headerHeight]="headerHeight"
					[rowHeight]="rowHeight" [footerHeight]="footerHeight" [rowClass]="getRowClass" [scrollbarH]="true"
					[rowIdentity]="getId" [externalPaging]="false" [externalSorting]="false" [limit]="10"
					[count]="clientRows?.length">
				</ngx-datatable>
				<ng-template #actionClientT let-row="row" let-value="value" let-i="index">
					<img mat-icon-button [matMenuTriggerFor]="menu1" src="assets/img/icons/3dot.png" alt="action"
						class="cursor-pointer">
					<mat-menu #menu1="matMenu">
						<button mat-menu-item (click)="clientAction(row, 'view')">
							<span>View</span>
						</button>
						<!-- <button mat-menu-item (click)="clientAction(row, 'edit')">
							<span>Edit</span>
						</button> -->
					</mat-menu>
				</ng-template>
				<ng-template #dateClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')">{{ value ? (value | date:'MMM dd yyyy, hh:mm a') : 'NA'
						}}</span>
				</ng-template>
				<ng-template #nameClientT let-row="row" let-value="value" let-i="index">
					<img (click)="clientAction(row, 'view')" *ngIf="row.image" class="dt-user-avatar pointer"
						[src]="row.image" width="43" alt="user-avatar">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ row.first_name + ' ' + row.last_name
						}}</span>
				</ng-template>
				<ng-template #idClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ value }}</span>
				</ng-template>
				<ng-template #emailClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #mobileClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #stateClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #blockClientT let-row="row" let-value="value" let-i="index">
					<span (click)="clientAction(row, 'view')" class="pointer">{{ value ? 'Yes' : 'No' }}</span>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="dt-wrapper mt-n2" *ngIf="data?.value.users.length">
		<div class="dt-header mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-baseline">
					<div class="main-heading">New Passengers</div>
					<div class="more-results ml-2 pointer" (click)="goTo(route.passengers)">View {{
						data?.value.users_count }} results</div>
				</div>
				<div class="dt-header-export-btn">
					<button type="button" class="btn btn-outline-secondary" (click)="goTo(route.passengers)">View
						List</button>
				</div>
			</div>
		</div>

		<hr class="cutom-divider">

		<div class="dt-content">
			<div [ngClass]="{'dt-head-scroll': !usersRows?.length}">
				<ngx-datatable class="material" #userTable id="user-home" [rows]="usersRows" [columns]="userColumns"
					[columnMode]="'force'" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
					[footerHeight]="footerHeight" [rowClass]="getRowClass" [scrollbarH]="true" [rowIdentity]="getId"
					[externalPaging]="false" [externalSorting]="false" [limit]="10" [count]="usersRows?.length">
				</ngx-datatable>
				<ng-template #actionUserT let-row="row" let-value="value" let-i="index">
					<img mat-icon-button [matMenuTriggerFor]="menu2" src="assets/img/icons/3dot.png" alt="action"
						class="cursor-pointer">
					<mat-menu #menu2="matMenu">
						<button mat-menu-item (click)="passengerAction(row, 'view')">
							<span>View</span>
						</button>
						<!-- <button mat-menu-item (click)="passengerAction(row, 'edit')">
							<span>Edit</span>
						</button> -->
					</mat-menu>
				</ng-template>
				<ng-template #nameUserT let-row="row" let-value="value" let-i="index">
					<img (click)="passengerAction(row, 'view')" *ngIf="row.user_image" class="dt-user-avatar pointer"
						[src]="row.user_image" width="43" alt="user-avatar">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ row.user_name }}</span>
				</ng-template>
				<ng-template #dateUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value ? (value | date:'MMM dd yyyy,
						hh:mm a') : 'NA' }}</span>
				</ng-template>
				<ng-template #idUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value }}</span>
				</ng-template>
				<ng-template #emailUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #mobileUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #deviceUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #blockUserT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="passengerAction(row, 'view')">{{ value ? 'Yes' : 'No' }}</span>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="dt-wrapper mt-n2" *ngIf="data?.value.affiliates.length">
		<div class="dt-header mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-baseline">
					<div class="main-heading">New Affiliates</div>
					<div class="more-results ml-2 pointer" (click)="goTo(route.affiliates)">View {{
						data?.value.affiliates_count }} results</div>
				</div>
				<div class="dt-header-export-btn">
					<button type="button" class="btn btn-outline-secondary" (click)="goTo(route.affiliates)">View
						List</button>
				</div>
			</div>
		</div>

		<hr class="cutom-divider">

		<div class="dt-content">
			<div [ngClass]="{'dt-head-scroll': !affiliatesRows?.length}">
				<ngx-datatable class="material" #affiliateTable id="affiliate-home" [rows]="affiliatesRows"
					[columns]="affiliateColumns" [columnMode]="'force'" [headerHeight]="headerHeight"
					[rowHeight]="rowHeight" [footerHeight]="footerHeight" [rowClass]="getRowClass" [scrollbarH]="true"
					[rowIdentity]="getId" [externalPaging]="false" [externalSorting]="false" [limit]="10"
					[count]="affiliatesRows?.length">
				</ngx-datatable>
				<ng-template #actionaffiliateT let-row="row" let-value="value" let-i="index">
					<img mat-icon-button [matMenuTriggerFor]="menu3" src="assets/img/icons/3dot.png" alt="action"
						class="cursor-pointer">
					<mat-menu #menu3="matMenu">
						<button mat-menu-item (click)="affiliateAction(row, 'view')">
							<span>View</span>
						</button>
						<!-- <button mat-menu-item (click)="affiliateAction(row, 'edit')">
							<span>Edit</span>
						</button> -->
					</mat-menu>
				</ng-template>
				<ng-template #dateaffiliateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="affiliateAction(row, 'view')">{{ value ? (value | date:'MMM dd yyyy,
						hh:mm a') : 'NA' }}</span>
				</ng-template>
				<ng-template #nameAffiliateT let-row="row" let-value="value" let-i="index">
					<img *ngIf="row.image" class="dt-user-avatar pointer" [src]="row.image" width="43"
						alt="user-avatar">
					<span class="pointer" (click)="affiliateAction(row, 'view')">{{ row.first_name + ' ' + row.last_name
						}}</span>
				</ng-template>
				<ng-template #idAffiliateT let-row="row" let-value="value" let-i="index">
					<span (click)="affiliateAction(row, 'view')" class="pointer">{{ value }}</span>
				</ng-template>
				<ng-template #emailAffiliateT let-row="row" let-value="value" let-i="index">
					<span (click)="affiliateAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #mobileAffiliateT let-row="row" let-value="value" let-i="index">
					<span (click)="affiliateAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #stateAffiliateT let-row="row" let-value="value" let-i="index">
					<span (click)="affiliateAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #blockAffiliateT let-row="row" let-value="value" let-i="index">
					<span (click)="affiliateAction(row, 'view')" class="pointer">{{ value ? 'Yes' : 'No' }}</span>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="dt-wrapper mt-n2" *ngIf="data?.value.drivers.length">
		<div class="dt-header mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-baseline">
					<div class="main-heading">New Drivers</div>
					<div class="more-results ml-2 pointer" (click)="goTo(route.drivers)">View {{
						data?.value.drivers_count }} results</div>
				</div>
				<div class="dt-header-export-btn">
					<button type="button" class="btn btn-outline-secondary" (click)="goTo(route.drivers)">View
						List</button>
				</div>
			</div>
		</div>

		<hr class="cutom-divider">

		<div class="dt-content">
			<div [ngClass]="{'dt-head-scroll': !driversRows?.length}">
				<ngx-datatable class="material" #driverTable id="driver-home" [rows]="driversRows"
					[columns]="driverColumns" [columnMode]="'force'" [headerHeight]="headerHeight"
					[rowHeight]="rowHeight" [footerHeight]="footerHeight" [rowClass]="getRowClass" [scrollbarH]="true"
					[rowIdentity]="getId" [externalPaging]="false" [externalSorting]="false" [limit]="10"
					[count]="driversRows?.length">
				</ngx-datatable>
				<ng-template #actionDriverT let-row="row" let-value="value" let-i="index">
					<img mat-icon-button [matMenuTriggerFor]="menu4" src="assets/img/icons/3dot.png" alt="action"
						class="cursor-pointer">
					<mat-menu #menu4="matMenu">
						<button mat-menu-item (click)="driverAction(row, 'view')">
							<span>View</span>
						</button>
						<!-- <button mat-menu-item (click)="driverAction(row, 'edit')">
							<span>Edit</span>
						</button> -->
					</mat-menu>
				</ng-template>
				<ng-template #dateDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value ? (value | date:'MMM dd yyyy,
						hh:mm a') : 'NA' }}</span>
				</ng-template>
				<ng-template #nameDriverT let-row="row" let-value="value" let-i="index">
					<img (click)="driverAction(row, 'view')" *ngIf="row.driver_image" class="dt-user-avatar pointer"
						[src]="row.driver_image" width="43" alt="user-avatar">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ row.first_name + ' ' + row.last_name
						}}</span>
				</ng-template>
				<ng-template #idDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value }}</span>
				</ng-template>
				<ng-template #emailDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #mobileDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #stateDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #deviceDriverT let-row="row" let-value="value" let-i="index">
					<span (click)="driverAction(row, 'view')" class="pointer">{{ value ? 'Yes' : 'No' }}</span>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="dt-wrapper mt-n2" *ngIf="data?.value.corporates.length">
		<div class="dt-header mt-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-baseline">
					<div class="main-heading">New Corporates</div>
					<div class="more-results ml-2 pointer" (click)="goTo(route.corporates)">View {{
						data?.value.corporates_count }} results</div>
				</div>
				<div class="dt-header-export-btn">
					<button type="button" class="btn btn-outline-secondary" (click)="goTo(route.corporates)">View
						List</button>
				</div>
			</div>
		</div>

		<hr class="cutom-divider">

		<div class="dt-content">
			<div [ngClass]="{'dt-head-scroll': !corporatesRows?.length}">
				<ngx-datatable class="material" #corporatesTable id="corporate-home" [rows]="corporatesRows"
					[columns]="corporateColumns" [columnMode]="'force'" [headerHeight]="headerHeight"
					[rowHeight]="rowHeight" [footerHeight]="footerHeight" [rowClass]="getRowClass" [scrollbarH]="true"
					[rowIdentity]="getId" [externalPaging]="false" [externalSorting]="false" [limit]="10"
					[count]="corporatesRows?.length">
				</ngx-datatable>
				<ng-template #actionCorporateT let-row="row" let-value="value" let-i="index">
					<img mat-icon-button [matMenuTriggerFor]="menu5" src="assets/img/icons/3dot.png" alt="action"
						class="cursor-pointer">
					<mat-menu #menu5="matMenu">
						<button mat-menu-item (click)="corporateAction(row, 'view')">
							<span>View</span>
						</button>
						<!-- <button mat-menu-item (click)="corporateAction(row, 'edit')">
							<span>Edit</span>
						</button> -->
					</mat-menu>
				</ng-template>
				<ng-template #dateCorporateT let-row="row" let-value="value" let-i="index">
					<span (click)="corporateAction(row, 'view')" class="pointer">{{ value ? (value | date:'MMM dd yyyy,
						hh:mm a') : 'NA' }}</span>
				</ng-template>
				<ng-template #userNameCorporateT let-row="row" let-value="value" let-i="index">
					<img (click)="corporateAction(row, 'view')" *ngIf="row.image" class="dt-user-avatar pointer"
						[src]="row.image" width="43" alt="user-avatar">
					<span (click)="corporateAction(row, 'view')" class="pointer">{{ row.first_name + ' ' + row.last_name
						}}</span>
				</ng-template>
				<ng-template #nameCorporateT let-row="row" let-value="value" let-i="index">
					<span (click)="corporateAction(row, 'view')" class="pointer">{{ row.corporate_name ?
						row.corporate_name : 'NA' }}</span>
				</ng-template>
				<ng-template #idCorporateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="corporateAction(row, 'view')">{{ value }}</span>
				</ng-template>
				<ng-template #emailCorporateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="corporateAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #mobileCorporateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="corporateAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #stateCorporateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="corporateAction(row, 'view')">{{ value ? value : 'NA' }}</span>
				</ng-template>
				<ng-template #blockCorporateT let-row="row" let-value="value" let-i="index">
					<span class="pointer" (click)="corporateAction(row, 'view')">{{ value ? 'Yes' : 'No' }}</span>
				</ng-template>
			</div>
		</div>
	</div>
</section>