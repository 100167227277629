<section class="p-2">
	<h2 mat-dialog-title>Select Date</h2>
	<mat-dialog-content class="mat-typography">
		<div>
			<form novalidate>
				<input required class="form-control" type="date" placeholder="dd/mm/yyyy"
					min="{{data.modalParams?.minDate}}" [(ngModel)]="selectedDate"
					[ngModelOptions]="{standalone: true}">
				<div *ngIf="!selectedDate && isSubmitting" class="help-block form-err text-danger">
					<small>*Date is required.</small>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
		<button (click)="pay()" mat-button class="btn btn-primary button-dialog ml-2">Save</button>
	</mat-dialog-actions>
</section>