import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/Subscription';
import { timer } from 'rxjs/internal/observable/timer';

import { triangleBall } from 'app/shared/utility';
import { ApiRoutes } from '../../routes';
import { DataService, ToastService } from '../../services';
import { LocalStorage } from '../../../storage';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
	selector: 'app-otp-modal',
	templateUrl: './otp-modal.component.html',
	styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements OnInit {
	form: FormGroup;
	isSubmitting: boolean = false;
	countDown: Subscription;
	counter: number;
	tick = 1000;

	constructor(private toastr: ToastService, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private ds: DataService, private spinner: NgxSpinnerService, private ls: LocalStorage, private dialogRef: MatDialogRef<OtpModalComponent>) { }

	ngOnInit(): void {
		if (this.data.modalParams.action === 'Email Verification') {
			this.getOtpOnEmail();
		} else {
			// this.getOtpOnPhn();
			// /otp_send , please check the apidoc for this api
			// /otp_verify
			// /add_new_business_client
		}
		if (this.data.modalParams?.resendEmail || this.data.modalParams?.isResendMobileChange) {
			this.startTimer();
		}
		this.initForm();
	}

	private startTimer(): void {
		this.counter = 60;
		this.countDown = timer(0, this.tick).subscribe(() => {
			if (this.counter > 0) {
				--this.counter;
			}
			if (this.counter === 0) {
				this.countDown.unsubscribe();	
			}
		});
	}

	get formControls() {
		return this.form.controls;
	}

	initForm(): void {
		this.form = new FormGroup({
			otp: new FormControl('', [Validators.required]),
		}, { updateOn: "change" });
	}

	ngOnDestroy() {
		this.countDown.unsubscribe();
	}

	getOtpOnEmail(): void {
		const url: string = ApiRoutes.getEmailVerifyOtp;
		this.ds.post(url, {}).subscribe(res => {
			if (res.flag === 7) { }
		});
	}

	submitOtp(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		if (this.data.modalParams.action === 'Email Verification') {
			this.spinner.show(undefined, triangleBall);
			const url: string = ApiRoutes.verifyEmail;
			this.ds.post(url, this.form.value).subscribe(res => {
				if (res.flag === 137) {
					this.dialogRef.close(true);
				}
				this.spinner.hide();
			}, err => this.spinner.hide());
		} else {
			this.spinner.show(undefined, triangleBall);
			const url1: string = ApiRoutes.verifyOtp;
			const payload1: any = {
				otp: this.form.get('otp').value,
				mobile: this.data.modalParams.data.mobile
			}
			this.ds.post(url1, payload1).subscribe(res => {
				if (res.flag === 137) {
					const url2: string = ApiRoutes.addNewBusiness;
					const payload2: any = {
						business_name: this.data.modalParams.data.business_name,
						states: this.data.modalParams.data.states,
						country: this.data.modalParams.data.country,
						ein_number: this.data.modalParams.data.ein_number,
						first_name: this.data.modalParams.data.first_name,
						last_name: this.data.modalParams.data.last_name,
						regulation_type: this.data.modalParams.data.regulation_type
					};
					this.ds.post(url2, payload2).subscribe(res => {
						if (res.flag === 7) {
							const modalParams: any = {
								action: 'New Client Application Added',
								for: '',
								confirmButtonText: 'Ok',
								cancelButtonText: '',
								text: 'Thanks for your interest. Please login to your account to complete the activation process'
							};
							const data = { modalParams: modalParams };
							const dialogRef = this.dialog.open(ConfirmModalComponent, { data, width: '400px' });
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									this.dialogRef.close(true);
								}
							});
						}
						this.spinner.hide();
					}, err => this.spinner.hide());
				}
				this.spinner.hide();
			}, err => this.spinner.hide());
		}
	}

	// getOtpOnPhn(): void {
	// 	const url: string = ApiRoutes.sendOtp;
	// 	const payload = {
	// 		email: this.data.modalParams.data.email,
	// 		mobile: this.data.modalParams.data.mobile,
	// 		new_business: 1
	// 	};
	// 	this.ds.post(url, payload).subscribe(res => {
	// 		if (res.flag === 7) {
	// 			this.toastr.success(res.message);
	// 		}
	// 	});
	// }

	resendOtp(): void {
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.resendOtp;
		const payload: any = {
			mobile: this.data.modalParams.data.mobile
		};
		this.ds.post(url, payload).subscribe(res => {
			if (res.flag === 7) {
				this.toastr.success(res.message);
				this.startTimer();
			} else if (res.flag === 0) {
				this.toastr.error(res.message);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}

	resendOtpEmail(): void {
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.resendOtpEmail;
		const payload: any = {
			access_token: this.ls.getItem("qudos-admin-token")
		};
		this.ds.post(url, payload).subscribe(res => {
			if (res.flag === 7) {
				this.toastr.success(res.message);
				this.startTimer();
			} else if (res.flag === 0) {
				this.toastr.error(res.message);
			}
			this.spinner.hide();
		}, err => this.spinner.hide());
	}
}