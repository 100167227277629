<div class="modal-body">
    <a href="javascript:;" class="cross" (click)="activeModal.dismiss()">
        <img src="assets/img/tour/cross-b.png" alt="close" width="14" height="14">
    </a>
    <div class="row">
        <div class="col-md-5">
            <div>
                <h5 class="dialog-header-text">Ride Information</h5>

                <p class="custom-label mb-0">Rider Detail</p>
                <p class="custom-label-value">{{ data.rows[data.currentIndex]?.user_name }} {{
                    data.rows[data.currentIndex]?.user_mobile }}</p>

                <ng-container *ngIf="data.rows[data.currentIndex]?.driver_name">
                    <p class="custom-label mb-0">Driver Details</p>
                    <p class="custom-label-value">{{ data.rows[data.currentIndex]?.driver_name }} {{
                        data.rows[data.currentIndex]?.driver_mobile }}</p>
                </ng-container>

                <ng-container *ngIf="data?.isQueueTrip">
                    <p class="custom-label">
                        Driver Details
                        <span class="view"
                            (click)="openAssignDriverContextMenu($event, data.rows[data.currentIndex])">Share</span>
                    </p>
                </ng-container>

                <p class="custom-label mb-0">Car Base Details</p>
                <p class="custom-label-value">
                    <ng-container *ngIf="data.rows[data.currentIndex]?.car_base_name">
                        {{ data.rows[data.currentIndex]?.car_base_name }}
                    </ng-container>
                    <ng-container *ngIf="data.rows[data.currentIndex]?.business_name">
                        {{ data.rows[data.currentIndex]?.business_name }}
                    </ng-container>
                </p>
            </div>
            <hr class="cutom-divider mx-0">
            <div>
                <h5 class="dialog-header-text">Assigned Trip Information</h5>

                <p class="custom-label mb-0">Pickup Date & Time</p>
                <p class="custom-label-value mb-0">
                    {{ data.rows[data.currentIndex]?.pickup_date }} | <b>{{ data.rows[data.currentIndex]?.pickup_time
                        }}</b>
                </p>
                <p class="custom-label-value">
                    Trip Duration: <b>{{ (data.rows[data.currentIndex]?.ride_estimate_time/60) | number : '1.0-0' }}
                        minutes</b>
                </p>

                <p class="custom-label mb-0">Pickup Location</p>
                <p class="custom-label-value">{{ data.rows[data.currentIndex]?.pickup_location }}</p>

                <p class="custom-label mb-0">Drop Off Location</p>
                <p class="custom-label-value">{{ data.rows[data.currentIndex]?.dropoff_location }}</p>
            </div>
            <hr class="cutom-divider mx-0">
            <div class="next-prev">
                <div class="prev" *ngIf="data?.currentIndex > 0">
                    <h5 class="dialog-header-text">
                        Previous <span class="view" (click)="prev()">View</span>
                    </h5>
                    <p class="custom-label mb-0">Passenger Name</p>
                    <p class="custom-label-value">{{ data.rows[data.prevIndex]?.user_name }}</p>

                    <p class="custom-label mb-0">Pickup Date & Time</p>
                    <p class="custom-label-value mb-0">
                        {{ data.rows[data.prevIndex].pickup_date }} | <b>{{ data.rows[data.prevIndex].pickup_time }}</b>
                    </p>
                </div>

                <hr class="cutom-divider"
                    *ngIf="(data?.nextIndex && (data?.nextIndex <= data?.rows.length - 1)) && data?.currentIndex > 0">

                <div class="next" *ngIf="data?.nextIndex && (data?.nextIndex <= data.rows.length - 1)">
                    <h5 class="dialog-header-text">
                        Next Trip
                        <span class="view" (click)="next()">View</span>
                    </h5>
                    <p class="custom-label mb-0">Passenger Name</p>
                    <p class="custom-label-value">{{ data.rows[data.nextIndex].user_name }}</p>

                    <p class="custom-label mb-0">Pickup Date & Time</p>
                    <p class="custom-label-value mb-0">
                        {{ data.rows[data.nextIndex].pickup_date }} | <b>{{ data.rows[data.nextIndex].pickup_time }}</b>
                    </p>
                </div>
            </div>
            <ng-container *ngIf="data?.isQueueTrip">
                <hr class="cutom-divider mx-0">
                <div class="queue-btns">
                    <button class="btn unqueue">Unqueue</button>
                    <button class="btn redispatch">Re-Dispatch</button>
                </div>
            </ng-container>
        </div>
        <div class="col-md-7">
            <hr class="cutom-divider mx-0 d-md-none">
            <div class="map">
                <agm-map [latitude]="lat" [longitude]="lng" (mapReady)="onMapReady($event)">
                    <agm-direction [origin]="data.rows[data.currentIndex].pickup_location"
                        [destination]="data.rows[data.currentIndex].dropoff_location">
                    </agm-direction>
                </agm-map>
            </div>
        </div>
    </div>
</div>

<ng-template #dialogTemplate>
    <div class="context-menu" id="context-menu">
        <div class="context-menu-body">
            <div class="search">
                <input type="text" class="form-control" placeholder="Search Drivers"
                    [(ngModel)]="contextMenuSearchString">
            </div>
            <div *ngIf="!data.rows[data.currentIndex]?.drivers?.length && isDriverLoaded"
                class="text-center mt-1 no-data">No Driver Associated</div>
            <ng-container *ngIf="!data.rows[data.currentIndex]?.drivers && !isDriverLoaded">
                <ng-container *ngTemplateOutlet="contentPlaceholder"></ng-container>
            </ng-container>
            <div class="data-list"
                *ngFor="let item of data.rows[data.currentIndex]?.drivers | search : contextMenuSearchString"
                [ngClass]="{'highlighted': selectedDriver?.driver_id == item.driver_id}">
                <span class="dark-hover"></span>
                <span class="d-block w-100 span-name" (click)="selectedDriver = item">{{ item.driver_name }}</span>
                <span class="icon" (click)="sendAcceptanceAlertToDriver(item, data.rows[data.currentIndex])">
                    <img src="assets/img/icons/send_arrow.svg" alt="send">
                </span>
            </div>
        </div>
        <hr class="cutom-divider m-0">
    </div>
    <div class="context-menu-footer">
        <div class="footer-btns">
            <button class="btn" (click)="dismissContextMenu()">
                Dismiss
            </button>
            <button class="btn" (click)="assignDriverInitiate(selectedDriver, data.rows[data.currentIndex])"
                [disabled]="!selectedDriver">
                Assign
            </button>
        </div>
    </div>
</ng-template>

<ng-template #contentPlaceholder>
    <div class="pt-2 pb-1">
        <app-placeholder-loading [placeholderWidth]="'220px'" [animatedBgHeight]="'14px'" [placeholderBg]="'#e1f3fd'"
            [placeholdermargin]="'0 auto'">
        </app-placeholder-loading>
    </div>
    <div class="py-1">
        <app-placeholder-loading [placeholderWidth]="'220px'" [animatedBgHeight]="'14px'" [placeholderBg]="'#e1f3fd'"
            [placeholdermargin]="'0 auto'">
        </app-placeholder-loading>
    </div>
    <div class="py-1">
        <app-placeholder-loading [placeholderWidth]="'220px'" [animatedBgHeight]="'14px'" [placeholderBg]="'#e1f3fd'"
            [placeholdermargin]="'0 auto'">
        </app-placeholder-loading>
    </div>
</ng-template>