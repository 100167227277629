<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">
		Create New Client Account
	</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">First Name</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="first_name" [ngClass]="{
									'is-invalid': isSubmitting && formControls.first_name.invalid,
									'is-valid': isSubmitting && !formControls.first_name.invalid
                                }">
								<div *ngIf="formControls.first_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.first_name.errors?.required">*First name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Email</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="email" [ngClass]="{
									'is-invalid': isSubmitting && formControls.email.invalid,
									'is-valid': isSubmitting && !formControls.email.invalid
                                }">
								<div *ngIf="formControls.email.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.email.errors?.required">*Email is required.</small>
									<small [hidden]="!formControls.email.errors?.pattern">*Invalid email.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Country</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="country" [ngClass]="{
									'is-invalid': isSubmitting && formControls.country.invalid,
									'is-valid': isSubmitting && !formControls.country.invalid
                                }" (change)="onCountryChange($event)">
									<option value="">Select Country</option>
									<option [value]="item?.country_id" *ngFor="let item of countryList">{{
										item?.country_name }}</option>
								</select>
								<div *ngIf="formControls.country.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.country.errors?.required">*Country is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Business Name</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="business_name" [ngClass]="{
									'is-invalid': isSubmitting && formControls.business_name.invalid,
									'is-valid': isSubmitting && !formControls.business_name.invalid
								}" placeholder="Enter business name">
								<div *ngIf="formControls.business_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.business_name.errors?.required">*Business Name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">EIN</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="ein_number" numbersOnly
									[ngClass]="{
									'is-invalid': isSubmitting && formControls.ein_number.invalid,
									'is-valid': isSubmitting && !formControls.ein_number.invalid
								}" placeholder="Enter EIN number">
								<div *ngIf="formControls.ein_number.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.ein_number.errors?.required">*EIN number is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label"># of Vehicles</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="vehicles_owned" [ngClass]="{
									'is-invalid': isSubmitting && formControls.vehicles_owned.invalid,
									'is-valid': isSubmitting && !formControls.vehicles_owned.invalid
								}">
									<option value="">Select number of vehicles owned</option>
									<option value="100 and below">100 and below</option>
									<option value="100 to 1,000">100 to 1,000</option>
									<option value="1,000 and above">1,000 and above</option>
								</select>
								<div *ngIf="formControls.vehicles_owned.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.vehicles_owned.errors?.required">*No. of vehicles
										owned is required.</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Last Name</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="last_name" [ngClass]="{
									'is-invalid': isSubmitting && formControls.last_name.invalid,
									'is-valid': isSubmitting && !formControls.last_name.invalid
                                }">
								<div *ngIf="formControls.last_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_name.errors?.required">*Last name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Mobile</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" formControlName="mobile" [ngClass]="{
									'is-invalid': isSubmitting && formControls.mobile.invalid,
									'is-valid': isSubmitting && !formControls.mobile.invalid
                                }">
								<div *ngIf="formControls.mobile.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.mobile.errors?.required">*Mobile is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">State</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="states" [ngClass]="{
									'is-invalid': isSubmitting && formControls.states.invalid,
									'is-valid': isSubmitting && !formControls.states.invalid
                                }">
									<option value="">Select State</option>
									<option [value]="item.state_id" *ngFor="let item of stateList">{{ item.state_name }}
									</option>
								</select>
								<div *ngIf="formControls.states.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.states.errors?.required">*State is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Zip Code</label>
							<div class="col-sm-8">
								<input type="text" formControlName="zipcode" class="form-control" maxlength="6"
									numbersOnly [ngClass]="{
									'is-invalid': isSubmitting && formControls.zipcode.invalid,
									'is-valid': isSubmitting && !formControls.zipcode.invalid
								}">
								<div *ngIf="formControls.zipcode.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.zipcode.errors?.required">*Zip code is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Regulation Type</label>
							<div class="col-sm-8">
								<select formControlName="regulation_type" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.regulation_type.invalid,
									'is-valid': isSubmitting && !formControls.regulation_type.invalid }">
									<option value="">Select Regulation Type</option>
									<option value="0">TNC</option>
									<option value="1">TLC</option>
									<option value="2">Others</option>
								</select>
								<div *ngIf="formControls.regulation_type.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.regulation_type.errors?.required">*Regulation type is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-2">
							<label class="col-sm-4 col-form-label">Last Year Revenue</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="last_year_revenue" [ngClass]="{
									'is-invalid': isSubmitting && formControls.last_year_revenue.invalid,
									'is-valid': isSubmitting && !formControls.last_year_revenue.invalid
								}">
									<option value="">Select last year revenue</option>
									<option value="$ 100,000 to $1,000,000">$ 100,000 to $1,000,000</option>
									<option value="$ 1,000,001 to $5,000,000">$ 1,000,001 to $5,000,000</option>
									<option value="$ 5,000,000 and above">$ 5,000,000 and above</option>
								</select>
								<div *ngIf="formControls.last_year_revenue.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_year_revenue.errors?.required">*Last year
										revenue is required.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>

	<hr class="cutom-divider m-0">
	<mat-dialog-actions class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-4 offset-md-8">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button (click)="submitHandler()" mat-button class="btn btn-primary button-dialog ml-2">Submit</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>