<section class="custom-mat-dialog-wrapper">

	<h2 class="mb-0 dialog-header-text dialog-header">Sign Out</h2>

	<hr class="cutom-divider m-0">
	<mat-dialog-content class="dialog-body">
		<div>
			<p>You are signing out, do you want to proceed?</p>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="end" class="dialog-footer">
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
		<button mat-button class="btn btn-primary button-dialog ml-2" (click)="logout()">Ok</button>
	</mat-dialog-actions>
</section>