<!-- *NAVBAR* starts -->
<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>
<!-- *NAVBAR* ends -->

<div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty,'filter-modal-open': isModalOpen}"
	(click)="onWrapperClick();" (window:resize)="onResize($event)">

	<!-- *SIDE* Menu starts -->
	<div appSidebar *ngIf="menuPosition === 'Side' || displayOverlayMenu"
		[ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': !perfectScrollbarEnable }"
		class="app-sidebar" (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)"
		data-active-color="white"
		[attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
		[attr.data-image]="bgImage">
		<!-- // #1e1532 -->
		<app-sidebar></app-sidebar>
		<div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
			*ngIf="config?.layout.sidebar.backgroundImage"></div>
	</div>
	<!-- *SIDE* Menu ends -->

	<div class="main-panel">
		<div class="main-content">
			<div class="content-overlay"></div>
			<div class="content-wrapper py-3 px-4">
				<router-outlet *ngIf="!isUserHomeView"></router-outlet>
				<app-search-result *ngIf="isUserHomeView" (toogleView)="toogleView($event)" [data]=data>
				</app-search-result>
			</div>
		</div>
		<button class="btn btn-info scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()">
			<em class="ft-arrow-up"></em>
		</button>
	</div>

	<div class="sidenav-overlay" (click)="onOutsideClick($event);"
		[ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}">
	</div>
	<div class="drag-target"></div>
</div>