import { DatePipe } from "@angular/common";

function nullable() {
	return { transform: (value) => value ? value : 'NA' };
}

function nullable2() {
	return { transform: (value) => (value || value == 0) ? value : 'NA' };
}

function regulationType() {
	// 	0 -- TNC, 1 -- TLC
	return { transform: (value) => value == 0 ? 'TNC' : value == 1 ? 'TLC' : 'Others' };
}

function zero() {
	return { transform: (value) => value ? value : 0 };
}

function dollar() {
	return { transform: (value) => (value || value == 0) ? '$ ' + value : 'NA' };
}

// function dollar2() {
// 	return { transform: (value) => (value || value == 0) ? '$ ' + value : 'NA' };
// }

function yesNo() {
	return { transform: (value) => value ? 'Yes' : 'No' };
}

function priority() {
	// 	1=high, 2=medium, 3=low
	return {
		transform: (value) => {
			switch (value) {
				case 1:
					return value = 'High'
				case 2:
					return value = 'Medium'
				case 3:
					return value = 'Low'
			}
		}
	};
}

function corporateCut() {
	return { transform: (value) => value ? '@ ' + value + '%' : 'NA' };
}

function formatDate() {
	return { transform: (value: any) => (value && value != '0000-00-00 00:00:00') ? new DatePipe('en-US').transform(value, 'MMM dd yyyy, hh:mm a') : 'NA' };
}

function formatDateWithoutTime() {
	return { transform: (value: any) => (value && value != '0000-00-00 00:00:00') ? new DatePipe('en-US').transform(value, 'MMM dd yyyy') : 'NA' };
}

const nameSorting = (x, y) => {
	if (x < y) {
		return -1;
	}
	if (x > y) {
		return 1;
	}
	return 0;
}

// # Super admin
// admin_type: 1
// parent_id: 0
// parent_type: 0

// # Client
// admin_type: 4
// parent_id: 0
// parent_type: 0

// # Client admin staff
// admin_type: 2
// parent_id: id of the parent client
// parent_type: 4

// # Client operator staff
// admin_type: 3
// parent_id: id of the parent client
// parent_type: 4

export function columns(key: string, cellTemplate: any = {}, adminType: any = null) {
	if (key === 'passengers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let passengersColumn = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			passengersColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					cellTemplate: cellTemplate.action,
					width: 120,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'user_id',
					name: 'Passenger ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'user_name',
					name: 'Passenger Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'user_email',
					name: 'Passenger Email',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.email,
					pipe: nullable()
				},
				// {
				// 	prop: 'business_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.client,
				// 	// pipe: nullable()
				// },
				{
					prop: 'user_mobile',
					name: 'Mobile',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'country',
					name: 'Country',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'affiliateName',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'date_registered',
					name: 'Registration Date',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				}
			];
		} else {
			passengersColumn = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					cellTemplate: cellTemplate.action,
					width: 120,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'user_id',
					name: 'Passenger ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'user_name',
					name: 'Passenger Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'user_email',
					name: 'Passenger Email',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.email,
					pipe: nullable()
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.client,
					// pipe: nullable()
				},
				{
					prop: 'user_mobile',
					name: 'Mobile',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'country',
					name: 'Country',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'affiliateName',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'date_registered',
					name: 'Registration Date',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				}
			];
		}
		return passengersColumn;
	} else if (key === 'ride&ref') {
		const ridesAndPreferences = [
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'total_with_tax',
				name: 'Total Payment',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'car_name',
				name: 'Car Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.carDetail,
			},
			{
				prop: 'ride_essential',
				name: 'Is Essentials',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellTemplate: cellTemplate.rideEssential,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'accept_time',
				name: 'Accept Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellTemplate: cellTemplate.acceptTime,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'start_time',
				name: 'Pickup Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickUpTime,
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_id',
				name: 'Fare Structure',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.fareStructure,
			},
			{
				prop: 'user_id',
				name: 'Payment Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.paymentStatus,
			},
			{
				prop: 'user_id',
				name: 'Ride Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				pipe: nullable(),
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.rideStatus,
			},
		];
		return ridesAndPreferences;
	} else if (key === 'Driver Pool') {
		const driver_pool = [
			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: '',
				name: 'Car Detail',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.carDetail,
			},
			{
				prop: 'car_name',
				name: 'Car Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'login_device',
				name: 'Device',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.action,
			},
		];
		return driver_pool;
	} else if (key === 'Driver New Entries') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_new_entries: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_new_entries = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.email,
				},
				{
					prop: 'date_registered',
					name: 'Registration',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate
				},
				{
					prop: 'country',
					name: 'Country',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				// {
				// 	prop: 'login_device',
				// 	name: 'Device',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// },
			];
		} else {
			driver_new_entries = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.email,
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'date_registered',
					name: 'Registration',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate
				},
				{
					prop: 'country',
					name: 'Country',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},

				// {
				// 	prop: 'login_device',
				// 	name: 'Device',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// },
			];
		}
		return driver_new_entries;
	} else if (key === 'Driver Submerchant List') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_submerchant_list: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_submerchant_list = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Payment Options',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
			];
		} else {
			driver_submerchant_list = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Payment Options',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
			];
		}
		return driver_submerchant_list;
	} else if (key === 'Driver Verified Drivers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_verified_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_verified_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_registered',
					name: 'Date Registered',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: '',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.status,
				},
				{
					prop: 'submerchant_id',
					name: 'Payout Method',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOptionVD,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
			];
		} else {
			driver_verified_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_registered',
					name: 'Date Registered',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					// pipe: nullable(),
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: '',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.status,
				},
				{
					prop: 'submerchant_id',
					name: 'Payout Method',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOptionVD,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
			];
		}
		return driver_verified_drivers;
	} else if (key === 'Driver Approved Drivers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_approved_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_approved_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'zipcode',
					name: 'Zipcode',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_approved_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					// pipe: nullable(),
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'zipcode',
					name: 'Zipcode',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_approved_drivers;
	} else if (key === 'Driver Approved Wav') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_approved_wav: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_approved_wav = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_approved_wav = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_approved_wav;
	} else if (key === 'Driver MAS Approved Drivers') {
		const madApprovedDriver = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.driverid,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'driver_email',
				name: 'Driver Email',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_mobile',
				name: 'Mobile',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'mas_status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.status
			},
		];
		return madApprovedDriver
	} else if (key === 'Driver MAS Deactivated') {
		const madDeactivatedDriver = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.driverid,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'driver_email',
				name: 'Driver Email',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_mobile',
				name: 'Mobile',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'mas_status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		];
		return madDeactivatedDriver
	} else if (key === 'Driver Approved Elite') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_approved_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_approved_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_approved_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_approved_drivers;
	} else if (key === 'Drivers In-house') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_inHouse: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_inHouse = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_inHouse = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_inHouse;
	} else if (key === 'Drivers Approved Network') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_approved_network: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_approved_network = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_approved_network = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'date_approved',
					name: 'Date Of Approval',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 'auto',
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_approved_network;
	} else if (key === 'Driver Blocked Drivers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_blocked_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_blocked_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				// {
				// 	prop: 'is_blocked',
				// 	name: 'Is Blocked',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.isblock,
				// },
			];
		} else {
			driver_blocked_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				// {
				// 	prop: 'is_blocked',
				// 	name: 'Is Blocked',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.isblock,
				// },
			];
		}
		return driver_blocked_drivers;
	} else if (key === 'Driver Deleted Drivers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_deleted_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_deleted_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			driver_deleted_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
					comparator: nameSorting
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return driver_deleted_drivers;
	} else if (key === 'Driver block-admin Drivers') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let driver_blocked_drivers: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			driver_blocked_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				// {
				// 	prop: 'is_blocked',
				// 	name: 'Is Blocked',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.isblock,
				// },
			];
		} else {
			driver_blocked_drivers = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverid,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.name,
				},
				{
					prop: 'driver_email',
					name: 'Driver Email',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.under_client,
				},
				{
					prop: 'driver_mobile',
					name: 'Mobile',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'submerchant_id',
					name: 'Submerchant #',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.paymentOption,
				},
				{
					prop: 'submerchant_date',
					name: 'Submerchant # Added On',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.regDate,
				},
				{
					prop: 'login_device',
					name: 'Device',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				// {
				// 	prop: 'is_blocked',
				// 	name: 'Is Blocked',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: true,
				// 	width: 180,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.isblock,
				// },
			];
		}
		return driver_blocked_drivers;
	}
	else if (key === 'client new entries') {
		const clientNewEntriesColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				minWidth: 100,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'admin_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 100,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.businessName,
			},
			{
				prop: 'regulation_type',
				name: 'Regulation Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.regulationType,
			},
			{
				prop: 'email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			}
		];
		return clientNewEntriesColumn;
	} else if (key === 'client verified entries') {
		const clientVerifiedEntriesColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'admin_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.businessName,
			},
			{
				prop: 'regulation_type',
				name: 'Regulation Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.regulationType,
			},
			{
				prop: 'email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			// {
			//   prop: 'state_name',
			//   name: 'State',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 180,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'submerchant_id',
				name: 'Payout Method',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.payout,
			},
			{
				prop: '',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.approveStatus,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			// {
			//   prop: 'submerchant_id',
			//   name: 'Payout Method',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 250,
			//   cellTemplate: cellTemplate.paymentOption,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return clientVerifiedEntriesColumn;
	} else if (key === 'client approved entries') {
		const clientsapprovedColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_view',
				name: 'Driver View',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.driverView,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'rider_view',
				name: 'Rider View',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.riderView,
				width: 80,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'admin_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 80,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.businessName,
			},
			{
				prop: 'regulation_type',
				name: 'Regulation Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.regulationType,
			},
			{
				prop: 'email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			// {
			//   prop: 'state_name',
			//   name: 'State',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 120,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'referral_code',
				name: 'Referral Code #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			// {
			//   prop: 'submerchant_id',
			//   name: 'Payment Option',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 250,
			//   cellTemplate: cellTemplate.paymentOption,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			// {
			//   prop: 'is_blocked',
			//   name: 'Is Blocked',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 100,
			//   cellTemplate: cellTemplate.block,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return clientsapprovedColumn;
	} else if (
		key === 'client blocked entries' ||
		key === 'client deleted entries' ||
		key === 'client block-admin entries'
	) {
		const clientapprovedColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'admin_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.businessName,
			},
			{
				prop: 'regulation_type',
				name: 'Regulation Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.regulationType,
			},
			{
				prop: 'email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'referral_code',
				name: 'Referral Code #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			// {
			//   prop: 'submerchant_id',
			//   name: 'Payment Option',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 200,
			//   cellTemplate: cellTemplate.paymentOption,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			// {
			//   prop: 'online',
			//   name: 'Online Status',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 120,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Is Blocked',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 100,
			// 	cellTemplate: cellTemplate.block,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return clientapprovedColumn;
	}
	else if (key === 'new entries') {
		const affiliatesNewEntriesColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'vendor_id',
				name: 'Affiliate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			//{
			//	prop: 'image',
			//	name: 'Profile Pic',
			//	sortable: true,
			//	draggable: false,
			//	canAutoResize: false,
			//	width: 150,
			//	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			//	cellTemplate: cellTemplate.profilePic,
			//},
			{
				prop: 'first_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Affiliate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 150,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 180,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'submerchant_id',
				name: 'Payment Options',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.paymentOption,
			}
		];
		return affiliatesNewEntriesColumn;
	} else if (key === 'verified entries') {
		const affiliatesVerifiedEntriesColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 180,
				maxWidth: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'vendor_id',
				name: 'Affiliate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Affiliate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			// {
			//   prop: 'state_name',
			//   name: 'State',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 180,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: '',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.approveStatus,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'submerchant_id',
				name: 'Payout Method',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellTemplate: cellTemplate.paymentOption,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			}
		];
		return affiliatesVerifiedEntriesColumn;
	} else if (key === 'approved entries') {
		const affiliatesapprovedColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 180,
				maxWidth: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'vendor_id',
				name: 'Affiliate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Affiliate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'mobile',
				name: 'Mobile No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'referral_code',
				name: 'Referral Code #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'submerchant_id',
				name: 'Payment Option',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellTemplate: cellTemplate.paymentOption,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			//{
			//prop: 'web_online',
			//name: 'Online Status',
			//sortable: true,
			//draggable: false,
			//canAutoResize: false,
			//width: 120,
			//cellClass: 'd-flex align-items-center cell-class',
			//},
			//{
			//prop: 'is_blocked',
			//name: 'Is Blocked',
			//sortable: true,
			//draggable: false,
			//canAutoResize: false,
			//width: 100,
			//cellTemplate: cellTemplate.block,
			//cellClass: 'd-flex align-items-center cell-class',
			//}
		];
		return affiliatesapprovedColumn;
	} else if (
		key === 'blocked entries' ||
		key === 'deleted entries' || key === 'block-admin entries'
	) {
		const affiliatesapprovedColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 180,
				maxWidth: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'vendor_id',
				name: 'Affiliate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Affiliate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'referral_code',
				name: 'Referral Code #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'submerchant_id',
				name: 'Payment Option',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellTemplate: cellTemplate.paymentOption,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			//{
			//	prop: 'online',
			//	name: 'Online Status',
			//	sortable: true,
			//	draggable: false,
			//	canAutoResize: false,
			//	width: 150,
			//	cellClass: 'd-flex align-items-center cell-class',
			//},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Is Blocked',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 100,
			// 	cellTemplate: cellTemplate.block,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return affiliatesapprovedColumn;
	}
	else if (key === 'Corporates New Corporates') {
		const new_corporats = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 250,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'corporate_id',
				name: 'Corporate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Full Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Corporate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.client,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
		];
		return new_corporats;
	} else if (key === 'Corporates Verified Corporates') {
		const driver_verified_drivers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'first_name',
				name: 'Full Name',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'corporate_id',
				name: 'Corporate ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'email',
				name: 'Corporate Email',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.client,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state,
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.zip,
			},
			{
				prop: 'submerchant_id',
				name: 'Payment Options',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.paymentOption,
			},
			{
				prop: '',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.status,
			},
		];
		return driver_verified_drivers;
	} else if (key === 'Corporates Approved Corporates') {
		const approveCorporate = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'corporate_id',
				name: 'Corporate ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Full Name',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Corporate Email',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.client,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.zip
			},
			{
				prop: 'referral_code',
				name: 'Referral Code',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.referral
			},
			{
				prop: 'submerchant_id',
				name: 'Submerchant #',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.paymentOption,
			},
		];
		return approveCorporate;
	}
	else if (key === 'Corporates Blocked (Admin) Corporates' || key === 'Corporates Blocked (Client) Corporates' || key === 'Corporates Deleted Corporates') {
		const driver_approved_drivers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'corporate_id',
				name: 'Corporate ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Full Name',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name,
			},
			{
				prop: 'email',
				name: 'Corporate Email',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.client,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.zip
			},
			{
				prop: 'referral_code',
				name: 'Referral Code',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.referral
			},
			{
				prop: 'submerchant_id',
				name: 'Submerchant #',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.paymentOption,
			},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Is Blocked',
			// 	sortable: false,
			// 	draggable: false,
			// 	canAutoResize: true,
			// 	width: 150,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.status,
			// },
		];
		return driver_approved_drivers;
	} else if (key === 'affiliatenotes') {
		const affiliateNotes = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'notes_id',
				name: 'Notes ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'notes_title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'priority',
				name: 'Priority',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.priority,
				pipe: priority()
			},
			{
				prop: 'notes',
				name: 'Description',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},

		];
		return affiliateNotes;
	} else if (key === 'note') {
		const note = [
			{
				prop: 'notes_id',
				name: 'Notes ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'notes_title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.title,
			},
			{
				prop: 'priority',
				name: 'Priority',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.priority,
				pipe: priority()
			},
			{
				prop: 'notes',
				name: 'Description',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.desc,
			},
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
		];
		return note;
	} else if (key === 'affiliate rider trip') {
		const trip = [
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_id',
				name: 'Driver Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Rider Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.carDetail,
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.isEssential,
			},
			{
				prop: 'affiliate_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'promo_code',
				name: 'Promo Code Used',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'discount',
				name: 'Promo Code Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickupDateTime,
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Fare Structure1',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.fareStructure1,
			},
			{
				prop: '',
				name: 'Fare Structure 2',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.fareStructure2,
			},
			{
				prop: 'is_payment_successful',
				name: 'Payment Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.paymentStatus,
			},
			// {
			// 	prop: 'cancellation_fee',
			// 	name: 'Cancellation Charges',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			{
				prop: 'toll',
				name: 'Toll Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'scheduled_ride_surcharge_fee',
				name: 'Scheduled Surcharge Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Driver Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverAffiliateFee,
			},
			{
				prop: 'affiliate_fee',
				name: 'Rider Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return trip;
	} else if (key === 'affiliate driver trip') {
		const trip = [
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_id',
				name: 'Driver Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Rider Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.carDetail,
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.isEssential,
			},
			{
				prop: 'affiliate_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'promo_code',
				name: 'Promo Code Used',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'discount',
				name: 'Promo Code Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickupDateTime,
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Fare Structure1',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.fareStructure1,
			},
			{
				prop: '',
				name: 'Fare Structure 2',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.fareStructure2,
			},
			{
				prop: 'is_payment_successful',
				name: 'Payment Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.paymentStatus,
			},
			// {
			// 	prop: 'cancellation_fee',
			// 	name: 'Cancellation Charges',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			{
				prop: 'toll',
				name: 'Toll Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'scheduled_ride_surcharge_fee',
				name: 'Scheduled Surcharge Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'affiliate_fee',
				name: 'Driver Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'Rider Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.riderAffiliateFee,
			},
		];
		return trip;
	} else if (key === 'affiliate corporate trip') {
		const trip = [
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_id',
				name: 'Driver Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				minWidth: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Rider Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carDetail,
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.isEssential,
			},
			{
				prop: 'affiliate_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'promo_code',
				name: 'Promo Code Used',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'discount',
				name: 'Promo Code Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.pickupDateTime,
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: '',
				name: 'Fare Structure1',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.fareStructure1,
			},
			{
				prop: '',
				name: 'Fare Structure 2',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.fareStructure2,
			},
			{
				prop: 'is_payment_successful',
				name: 'Payment Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.paymentStatus,
			},
			// {
			// 	prop: 'cancellation_fee',
			// 	name: 'Cancellation Charges',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'toll',
				name: 'Toll Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'scheduled_ride_surcharge_fee',
				name: 'Scheduled Surcharge Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'affiliate_fee',
				name: 'Driver Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: '',
				name: 'Rider Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.riderAffiliateFee,
			},
		];
		return trip;
	} else if (key === 'rider referral') {
		const riderReferral = [
			{
				prop: 'user_id',
				name: 'Rider Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_name',
				name: 'Rider Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'user_email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'user_mobile',
				name: 'Mobile Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'date_registered',
				name: 'Registration Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: formatDate()
			},
		];
		return riderReferral;
	} else if (key === 'driver referral') {
		const driverReferral = [
			{
				prop: 'driver_id',
				name: 'Driver Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'driver_mobile',
				name: 'Mobile Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'zipcode',
				name: 'Zipcode',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'date_registered',
				name: 'Registration Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				// cellTemplate: cellTemplate.registrationDate,
				pipe: formatDate()
			},
		];
		return driverReferral;
	} else if (key === 'corporate referral') {
		const driverReferral = [
			{
				prop: 'corporate_id',
				name: 'Corporate Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'mobile',
				name: 'Mobile Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			// {
			// 	prop: 'zipcode',
			// 	name: 'Zipcode',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 300,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'date_registered',
				name: 'Registration Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.registrationDate,
				pipe: formatDate()
			},
		];
		return driverReferral;
	} else if (key === 'custom payout') {
		const customPayout = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'id',
				name: 'Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id
			},
			{
				prop: 'transfer_id',
				name: 'Transfer Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.transferId
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.customerName
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'releasor',
				name: 'Releasor',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.releasor
			},
			{
				prop: 'created_at',
				name: 'Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTime
			},
			{
				prop: 'charge_type',
				name: 'Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.chargeType
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDateTime
			},
			{
				prop: 'schedule_payout_date',
				name: 'Available On',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.availableOn
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
				cellTemplate: cellTemplate.description
			},
		];
		return customPayout;
	} else if (key === 'custom charge') {
		const customCharge = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'id',
				name: 'Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id
			},
			{
				prop: 'transaction_id',
				name: 'Transfer Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.transferId
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.customerName
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.corporateName
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'releasor',
				name: 'Releasor',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.releasor
			},
			{
				prop: 'created_at',
				name: 'Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTime,
			},
			{
				prop: 'charge_type',
				name: 'Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.chargeType
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDateTime,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description
			},
		];
		return customCharge;
	} else if (key === 'disputes') {
		const disputes = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'id',
				name: 'Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id
			},
			{
				prop: 'reversal_transfer_id',
				name: 'Transfer Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.transferId
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.customerName
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'created_at',
				name: 'Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTime,
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDateTime,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description
			},
		];
		return disputes;
	} else if (key === 'revenue regular rides all') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let revenueRR: any = [];
		if (currentUser.regulation_type != 2) {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'charge_id',
					name: 'Charge Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_payment',
					name: 'Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: dollar()
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.customerDetail
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'driver_affiliate_name',
					name: 'Driver Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_name',
					name: 'Rider Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_with_tax',
					name: 'Total Rider Paid',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_paid',
					name: 'Rider Paid Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Qudos Promotions',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'qudos_incentive',
					name: 'Qudos Incentive',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_fare',
					name: 'Ride Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_tip',
					name: 'Tip',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'fare_calculated',
					name: 'Net Ride Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'master_merchant_pay',
					name: 'Master Merchant pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: zero()
				},
				{
					prop: 'sub_merchant_pay',
					name: 'Sub Merchant Pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_share',
					name: 'Driver Share',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_base_share',
					name: 'Base Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_miles_share',
					name: 'Distance Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'distance_fare_total',
					name: 'Distance Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_min_share',
					name: 'Time Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'time_fare_total',
					name: 'Time Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'qudos_fee',
					name: 'Qudos Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'qudos_total_take',
					name: 'Qudos Total Take',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'credit_card_processing',
					name: 'CC Processing fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'credit_card_processing_tip_fee',
					name: 'CC Processing fee (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'payment_gateway_fee',
					name: 'Stripe Transaction Fees',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'payment_gateway_tip_fee',
					name: 'Stripe Transaction Fees (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'sales_tax',
					name: 'Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'congestion_tax',
					name: 'Congestion Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'black_car_fund',
					name: 'Black Car Fund',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'base_fare',
					name: 'Base Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'distance_fare',
					name: 'Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'real_distance_fare',
					name: 'Real Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'real_distance',
					name: 'Real Distance (Mile)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_fare',
					name: 'Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'time_estimate',
					name: 'Time estimate (Minutes)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'real_time_fare',
					name: 'Real Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'real_time',
					name: 'Real Time (Minutes)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'toll',
					name: 'Toll',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'affiliate_fee',
					name: 'Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverAffiliateFee
				},
				{
					prop: 'rider_affiliate_fee',
					name: 'Rider Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'promo_code',
					name: 'Promo Code Used',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Promo Code Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
			];
		} else {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Network Bonus',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverPoolFee
				},
				{
					prop: '',
					name: 'Driver Revenue',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRevenue
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'time_estimate',
					name: 'Minutes (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_estimate',
					name: 'Mileage (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return revenueRR;
	} else if (key === 'revenue regular rides') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let revenueRR: any = [];
		if (currentUser.regulation_type != 2) {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'charge_id',
					name: 'Charge Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_payment',
					name: 'Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: dollar()
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.customerDetail
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'driver_affiliate_name',
					name: 'Driver Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_name',
					name: 'Rider Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_with_tax',
					name: 'Total Rider Paid',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_paid',
					name: 'Rider Paid Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Qudos Promotions',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'qudos_incentive',
					name: 'Qudos Incentive',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_fare',
					name: 'Ride Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_tip',
					name: 'Tip',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'fare_calculated',
					name: 'Net Ride Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'master_merchant_pay',
					name: 'Master Merchant pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: zero()
				},
				{
					prop: 'sub_merchant_pay',
					name: 'Sub Merchant Pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_share',
					name: 'Driver Share',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_base_share',
					name: 'Base Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_miles_share',
					name: 'Distance Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_fare_total',
					name: 'Distance Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_min_share',
					name: 'Time Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_fare_total',
					name: 'Time Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'qudos_fee',
					name: 'Qudos Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'qudos_total_take',
					name: 'Qudos Total Take',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'credit_card_processing',
					name: 'CC Processing fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'credit_card_processing_tip_fee',
					name: 'CC Processing fee (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'payment_gateway_fee',
					name: 'Stripe Transaction Fees',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'payment_gateway_tip_fee',
					name: 'Stripe Transaction Fees (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'sales_tax',
					name: 'Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'congestion_tax',
					name: 'Congestion Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'black_car_fund',
					name: 'Black Car Fund',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'base_fare',
					name: 'Base Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_fare',
					name: 'Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_distance_fare',
					name: 'Real Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_distance',
					name: 'Real Distance (Mile)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_fare',
					name: 'Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_estimate',
					name: 'Time estimate (Minutes)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_time_fare',
					name: 'Real Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_time',
					name: 'Real Time (Minutes)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'toll',
					name: 'Toll',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'affiliate_fee',
					name: 'Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverAffiliateFee
				},
				{
					prop: 'rider_affiliate_fee',
					name: 'Rider Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'promo_code',
					name: 'Promo Code Used',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Promo Code Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					// pipe: nullable()
				},
			];
		} else {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Network Bonus',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverPoolFee
				},
				{
					prop: '',
					name: 'Driver Revenue',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRevenue
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'time_estimate',
					name: 'Minutes (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_estimate',
					name: 'Mileage (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return revenueRR;
	} else if (key === 'revenue schedule rides') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let revenueRR: any = [];
		if (currentUser.regulation_type != 2) {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'charge_id',
					name: 'Charge Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_payment',
					name: 'Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: dollar()
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.customerDetail
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'corporate_name',
					name: 'Corporate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_affiliate_name',
					name: 'Driver Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_name',
					name: 'Rider Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_with_tax',
					name: 'Total Rider Paid',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_paid',
					name: 'Rider Paid Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Qudos Promotions',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'qudos_incentive',
					name: 'Qudos Incentive',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_fare',
					name: 'Ride Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_tip',
					name: 'Tip',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'fare_calculated',
					name: 'Net Ride Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'master_merchant_pay',
					name: 'Master Merchant pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'sub_merchant_pay',
					name: 'Sub Merchant Pay',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_share',
					name: 'Driver Share',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_base_share',
					name: 'Base Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_miles_share',
					name: 'Distance Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_fare_total',
					name: 'Distance Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_min_share',
					name: 'Time Fare (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_fare_total',
					name: 'Time Fare Total (Driver Share)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'qudos_fee',
					name: 'Qudos Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'qudos_total_take',
					name: 'Qudos Total Take',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'corporate_cut',
					name: 'Corporate Cut',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: corporateCut()
				},
				{
					prop: 'credit_card_processing',
					name: 'CC Processing fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'credit_card_processing_tip_fee',
					name: 'CC Processing fee (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'payment_gateway_fee',
					name: 'Stripe Transaction Fees',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'payment_gateway_tip_fee',
					name: 'Stripe Transaction Fees (Tip)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'sales_tax',
					name: 'Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'congestion_tax',
					name: 'Congestion Tax',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'black_car_fund',
					name: 'Black Car Fund',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'base_fare',
					name: 'Base Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_fare',
					name: 'Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_estimate',
					name: 'Distance Estimate (Mile)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_distance_fare',
					name: 'Real Distance Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_distance',
					name: 'Distance Estimate (Mile)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_fare',
					name: 'Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'time_estimate',
					name: 'Time estimate',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_time_fare',
					name: 'Real Time Fare',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'real_time',
					name: 'Real Time (Minutes)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'toll',
					name: 'Toll',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'scheduled_ride_surcharge_fee',
					name: 'Scheduled Surcharge Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'affiliate_fee',
					name: 'Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverAffiliateFee
				},
				{
					prop: '',
					name: 'Driver Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverAffiliateFee
				},
				{
					prop: 'rider_affiliate_fee',
					name: 'Rider Affiliate fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'promo_code',
					name: 'Promo Code Used',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'discount',
					name: 'Promo Code Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
			];
		} else {
			revenueRR = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session Id',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Driver Network Bonus',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverPoolFee
				},
				{
					prop: '',
					name: 'Driver Revenue',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRevenue
				},
				{
					prop: 'user_name',
					name: 'Customer Details',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail
				},
				{
					prop: '',
					name: 'Date And Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop Off Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'time_estimate',
					name: 'Minutes (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'distance_estimate',
					name: 'Mileage (Estimate only)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}
		return revenueRR;
	} else if (key === 'vo') {
		let vo: any = [];
		if (!adminType) {
			vo = [
				{
					prop: 'is_active',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.voStatus
				},
				// {
				// 	prop: '',
				// 	name: 'Blocked',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 120,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.voBlocked
				// },
				{
					prop: 'is_mandatory',
					name: 'Mandatory',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.voMandatory
				},
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'title',
					name: 'Title',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.title
				},
				{
					prop: 'description',
					name: 'Description',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 280,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.description
				},
				{
					prop: 'virtual_orientation_for',
					name: 'User Type',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.userType
				},
				{
					prop: 'admin_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.clientName
				},
				{
					prop: 'cover_image',
					name: 'Cover Image',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.coverImage
				},
				{
					prop: 'content_url',
					name: 'Video',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			vo = [
				{
					prop: 'is_active',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.voStatus
				},
				{
					prop: '',
					name: 'Blocked',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.voBlocked
				},
				{
					prop: 'is_mandatory',
					name: 'Mandatory',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.voMandatory
				},
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'title',
					name: 'Title',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.title
				},
				{
					prop: 'description',
					name: 'Description',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 280,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.description

				},
				{
					prop: 'virtual_orientation_for',
					name: 'User Type',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.userType
				},
				{
					prop: 'admin_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.clientName
				},
				{
					prop: 'cover_image',
					name: 'Cover Image',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.coverImage
				},
				{
					prop: 'content_url',
					name: 'Video',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		}

		return vo;
	} else if (key === 'voClient') {
		const voClient = [
			{
				prop: 'is_active',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.voStatus
			},
			{
				prop: '',
				name: 'Blocked',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.voBlocked
			},
			{
				prop: 'is_mandatory',
				name: 'Mandatory',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.voMandatory
			},
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'admin_type',
				name: 'Video Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.voVideoType
			},
			{
				prop: 'title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.title
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description

			},
			{
				prop: 'virtual_orientation_for',
				name: 'User Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userType
			},
			{
				prop: 'admin_name',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.clientName
			},
			{
				prop: 'cover_image',
				name: 'Cover Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.coverImage
			},
			{
				prop: 'content_url',
				name: 'Video',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		];
		return voClient;
	} else if (key === 'client-setting-vo') {
		const clientSettingVo = [
			{
				prop: 'is_active',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 80,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'is_blocked',
				name: 'Blocked',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 80,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.blocked
			},
			{
				prop: '',
				name: 'Mandatory',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.mandatory
			},
			{
				prop: 'admin_type',
				name: 'SuperAdmin/Client',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.adminType
			},
			{
				prop: 'title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			// {
			// 	prop: 'virtual_orientation_for',
			// 	name: 'User Type',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 120,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.userType
			// },
			{
				prop: 'cover_image',
				name: 'Cover Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.img
			},
			{
				prop: 'content_url',
				name: 'Video',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return clientSettingVo;
	} else if (key === 'car types') {
		const carTypes = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'car_type',
				name: 'Car Type ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'car_name',
				name: 'Car Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'car_type_image',
				name: 'Car Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer car_img',
				cellTemplate: cellTemplate.carImg
			},
			{
				prop: 'added_at',
				name: 'Date Added',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
		];
		return carTypes;
	} else if (key === 'affiliate types') {
		const affiliateTypes = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 375,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'subtype_id',
				name: 'Sub-type ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 375,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'subtype_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 375,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'date_added',
				name: 'Date Added',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 375,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
		];
		return affiliateTypes;
	} else if (key === 'document types') {
		let documentType: any[] = [];
		if (adminType == 4) {
			documentType = [
				{
					prop: 'status',
					name: 'Enabled/Disabled',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.enabled
				},
				{
					prop: 'is_mandatory',
					name: 'Mandatory',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.mandatory
				},
				// {
				// 	prop: '',
				// 	name: 'Actions',
				// 	sortable: false,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 300,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.action
				// },
				{
					prop: 'document_type_id',
					name: 'Document Type ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 450,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'document_name',
					name: 'Document Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 450,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'date_added',
					name: 'Date and Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 450,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.date
				},

			];
		} else {
			documentType = [
				{
					prop: 'status',
					name: 'Enabled/Disabled',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.enabled
				},
				{
					prop: 'is_mandatory',
					name: 'Mandatory',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.mandatory
				},
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'document_type_id',
					name: 'Document Type ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'document_name',
					name: 'Document Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'date_added',
					name: 'Date and Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.date
				},

			];
		}
		return documentType;
	} else if (key === 'dashboard users') {
		const dashboardUsers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'dashboard_user_id',
				name: 'Login Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'dashboard_user_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'dashboard_user_email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
		];
		return dashboardUsers;
	} else if (key === 'live map users') {
		const liveMapUsers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'live_user_id',
				name: 'User ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'live_user_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'staff',
				name: 'Staff',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.staff
			},
			{
				prop: 'country',
				name: 'Country',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.country
			},
		];
		return liveMapUsers;
	} else if (key === 'admin users') {
		const adminUsers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'admin_id',
				name: 'User ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'admin_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'staff',
				name: 'Staff',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.staff
			},
			{
				prop: 'country_names',
				name: 'Country',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.country
			},
			{
				prop: 'state_names',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			}
		];
		return adminUsers;
	} else if (key === 'payment setting') {
		const paymentSettings = [
			{
				prop: 'id',
				name: 'ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'charge_id',
				name: 'Transaction ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'last4_digits',
				name: 'Card Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 259,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'created_at',
				name: 'Charge Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'amount',
				name: 'Amount($)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
		];
		return paymentSettings;
	} else if (key === 'view area') {
		const area = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'region_id',
				name: 'Region ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'region_name',
				name: 'Region Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'regulation',
				name: 'Regulation',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'region_image',
				name: 'Region Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.img
			},
			{
				prop: 'added_at',
				name: 'Date Added',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
		];
		return area;
	} else if (key === 'region detail') {
		const regionDetail = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 80,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.statusOption
			},
			{
				prop: 'car_name',
				name: 'Car Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carType
			},
			{
				prop: 'fare_fixed',
				name: 'Base Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.fixedFare
			},
			{
				prop: 'minimum_fare',
				name: 'Minimum Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.minFare
			},
			{
				prop: 'fare_per_km',
				name: 'Fare Per Mile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.farePerMile
			},
			{
				prop: 'fare_per_min',
				name: 'Fare Per Min',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.farePerMin
			},
			{
				prop: 'black_car_fund',
				name: 'Black Car Fund',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.blackCarFund
			},
			{
				prop: 'sales_tax',
				name: 'Sales Tax',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.salesTax
			},
			{
				prop: 'cancellation_time',
				name: 'Cancellation Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.cancelTime
			},
			{
				prop: 'cancellation_fee',
				name: 'Cancellation Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.cancelFare
			},
			{
				prop: 'car_type_image',
				name: 'Car Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer p-2',
				cellTemplate: cellTemplate.img
			},
		];
		return regionDetail;
	} else if (key === 'audit') {
		const audit = [
			{
				prop: 'audit_id',
				name: 'Audit ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'admin_name',
				name: 'Audit Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'message',
				name: 'Message',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 500,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'created_at',
				name: 'Created At',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
		];
		return audit;
	} else if (key === 'corporate rides completed') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let completedRides = [];
		if (currentUser?.regulation_type != 2) {
			completedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: '',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'promo_code',
					name: 'Promo Code Used',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				// {
				// 	prop: 'discount',
				// 	name: 'Promo Code Used',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'discount',
					name: 'Promo Code Cost',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'pickup_time',
					name: 'Pickup Date/Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.startTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Fare Structure',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.fareStructure
				},
				{
					prop: 'total_payment',
					name: 'Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'driver_affiliate_fee',
					name: 'Driver Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_fee',
					name: 'Rider Affiliate Fee',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_affiliate_name',
					name: 'Driver Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'rider_affiliate_name',
					name: 'Rider Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_rating',
					name: 'Driver Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRating
				},
				{
					prop: 'user_rating',
					name: 'Rider Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderRating

				},
			];
		} else {
			completedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'pickup_time',
					name: 'Pickup Date/Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 180,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.startTime
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
			];
		}
		return completedRides;
	} else if (key === 'corporate rides cancelled') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let cancelledRides = [];
		if (currentUser?.regulation_type != 2) {
			cancelledRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTimeRideRequested,
					pipe: formatDate()
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_status',
					name: 'Ride Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.rideStatusCancelled
				},
				{
					prop: '',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'affiliate_name',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'sent_to',
					name: 'Request Sent to Driver ID(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'drivers',
					name: 'Request Sent to Driver(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				// {
				// 	prop: 'pickup_time',
				// 	name: 'Pickup Date/Time',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 350,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	cellTemplate: cellTemplate.dateTimePickupRequested

				// },
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_with_tax',
					name: 'Cancelled Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'pay_to_driver',
					name: 'Cancelled Total Payout',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			cancelledRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTimeRideRequested,
					pipe: formatDate()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				}
			];
		}
		return cancelledRides;
	} else if (key === 'corporate rides missed') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let missedRides = [];
		if (currentUser?.regulation_type != 2) {
			missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTimeRideRequested,
					pipe: formatDate()
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_status',
					name: 'Ride Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.rideStatusCancelled
				},
				{
					prop: '',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'affiliate_name',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'state_name',
					name: 'State',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'sent_to',
					name: 'Request Sent to Driver ID(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'drivers',
					name: 'Request Sent to Driver(s)',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'total_with_tax',
					name: 'Missed Total Payment',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
				{
					prop: 'pay_to_driver',
					name: 'Missed Total Payout',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 300,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				},
			];
		} else {
			missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				// {
				// 	prop: 'carBase_name',
				// 	name: 'Under Client',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 150,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				// {
				// 	prop: 'corporate_name',
				// 	name: 'Corporate Name',
				// 	sortable: true,
				// 	draggable: false,
				// 	canAutoResize: false,
				// 	width: 250,
				// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// 	pipe: nullable()
				// },
				{
					prop: 'payment_date',
					name: 'Date/Time Ride Requested',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 220,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.dateTimeRideRequested,
					pipe: formatDate()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Requested Dropoff Location',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 350,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				}
			];
		}
		return missedRides;
	} else if (key === 'corporate rides process') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let missedRides = [];
		if (currentUser?.regulation_type != 2) {
			missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'corporate_name',
					name: 'Corporate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'ride_status',
					name: 'Ride Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.rideStatusCancelled
				},
				{
					prop: '',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				{
					prop: 'ride_essential',
					name: 'Is Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: yesNo()
				},
				{
					prop: 'affiliate_name',
					name: 'Affiliate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				// {
				//   prop: 'state_name',
				//   name: 'State',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 150,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'test',
				//   name: 'Request Sent to Driver ID(s)',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 200,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'test',
				//   name: 'Request Sent to Driver(s)',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 200,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'payment_date',
				//   name: 'Date/Time Ride Requested',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 220,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   cellTemplate: cellTemplate.dateTimeRideRequested
				// },
				// {
				//   prop: 'pickup_location_address',
				//   name: 'Pickup Location',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 350,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'manual_destination_address',
				//   name: 'Requested Dropoff Location',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 350,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'total_with_tax',
				//   name: 'Cancelled Total Payment',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 150,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// },
				// {
				//   prop: 'pay_to_driver',
				//   name: 'Cancelled Total Payout',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 150,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// },
			];
		} else {
			missedRides = [
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					maxWidth: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer'
				},
				{
					prop: 'carBase_name',
					name: 'Under Client',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'corporate_name',
					name: 'Corporate Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'driver_name',
					name: 'Driver Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: 'user_name',
					name: 'Rider Full Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					pipe: nullable()
				},
				{
					prop: '',
					name: 'Car Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetails
				},
				// {
				//   prop: 'payment_date',
				//   name: 'Date/Time Ride Requested',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 220,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   cellTemplate: cellTemplate.dateTimeRideRequested
				// },
				// {
				//   prop: 'pickup_location_address',
				//   name: 'Pickup Location',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 350,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },
				// {
				//   prop: 'manual_destination_address',
				//   name: 'Requested Dropoff Location',
				//   sortable: true,
				//   draggable: false,
				//   canAutoResize: false,
				//   width: 350,
				//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				//   pipe: nullable()
				// },				
			];
		}
		return missedRides;
	} else if (key === 'camppaign') {
		const camppaign = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: '',
				name: 'Campaign ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: '',
				name: 'Campaign Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: '',
				name: 'Campaign Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: '',
				name: 'Date/Time Created',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'campaign',
				name: 'Sent To',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'

			},
			{
				prop: '',
				name: 'Number of Users Sent To',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
		];
		return camppaign;
	} else if (key === 'contact_groups') {
		const contactsGroups = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'title',
				name: 'Group Contacts Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'description',
				name: 'Contacts Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 450,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			// {
			// 	prop: 'date_created',
			// 	name: 'Date/Time Created',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 300,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			// },
			{
				prop: 'user_count',
				name: '# of Contacts',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			}
		];
		return contactsGroups;
	} else if (key === 'contact_groups_detail') {
		const contactsGroupsDetail = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				maxWidth: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'id',
				name: 'Contact ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				maxWidth: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.contactID
			},
			{
				prop: 'first_name',
				name: 'First Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.firstName

			},
			{
				prop: 'last_name',
				name: 'Last Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.lastName

			},
			{
				prop: 'email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.emailId

			},
			{
				prop: 'mobile',
				name: 'Mobile #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobileNo

			}
		];
		return contactsGroupsDetail;
	} else if (key === 'home users') {
		const homeUsers = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 150,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'user_id',
				name: 'Passenger ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'user_name',
				name: 'Passenger Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'user_email',
				name: 'Passenger Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'user_mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
			{
				prop: 'date_registered',
				name: 'Registration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'login_device',
				name: 'Device',
				sortable: true,
				draggable: false,
				width: 180,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.device,
			},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Block',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.block,
			// },

		];
		return homeUsers
	} else if (key === 'home affiliate') {
		const homeAffiliates = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 150,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'vendor_id',
				name: 'Affiliate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'email',
				name: 'Affiliate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
			{
				prop: 'date_registered',
				name: 'Registration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state,
			},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Block',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },

		];
		return homeAffiliates;
	} else if (key === 'home client') {
		const homeClient = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 150,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'admin_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id
			},
			{
				prop: 'first_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'business_name',
				name: 'Business Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.business
			},
			{
				prop: 'email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile
			},
			{
				prop: 'date_created',
				name: 'Registration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state
			},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Block',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return homeClient;
	} else if (key === 'home driver') {
		const homeDriver = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 150,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'email',
				name: 'Driver Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
			{
				prop: 'date_registered',
				name: 'Registration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date,
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state,
			},
			// {
			// 	prop: 'device',
			// 	name: 'Device',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.device,
			// }
		];
		return homeDriver;
	} else if (key === 'home corporate') {
		const homeCorporate = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 150,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'corporate_id',
				name: 'Corporate ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'first_name',
				name: 'Corporate Detail',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.name
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.business_name
			},
			{
				prop: 'email',
				name: 'Corporate Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.email,
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
			{
				prop: 'date_registered',
				name: 'Registration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.state,
			},
			// {
			// 	prop: 'is_blocked',
			// 	name: 'Blocked',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// }
		];
		return homeCorporate;
	} else if (key === 'home retial rides') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let retailRides = [];
		if (currentUser?.regulation_type != 2) {
			retailRides = [
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.tripNo,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverId,
				},
				{
					prop: 'driver_image',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverName,
				},
				{
					prop: 'user_name',
					name: 'Rider Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderName,
				},
				{
					prop: 'ride_essential',
					name: 'Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.essential,
				},
				{
					prop: 'car_name',
					name: 'Car Detail',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail,
				},
				{
					prop: 'driver_rating',
					name: 'Driver Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRating,
				},
				{
					prop: 'user_rating',
					name: 'Rider Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderRating,
				},
			];
		} else {
			retailRides = [
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverName,
				},
				{
					prop: 'user_name',
					name: 'Rider Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderName,
				},
				{
					prop: 'car_name',
					name: 'Car Detail',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail,
				}
			];
		}
		return retailRides;
	} else if (key === 'home corporate rides') {
		const currentUser: any = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let corporateRides = [];
		if (currentUser?.regulation_type != 2) {
			corporateRides = [
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'trip_number',
					name: 'Trip No',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.tripNo,
				},
				{
					prop: 'driver_id',
					name: 'Driver ID',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverId,
				},
				{
					prop: 'driver_image',
					name: 'Profile Pic',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.profilePic,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverName,
				},
				{
					prop: 'user_name',
					name: 'Rider Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderName,
				},
				{
					prop: 'ride_essential',
					name: 'Essential',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.essential,
				},
				{
					prop: 'car_name',
					name: 'Car Detail',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail,
				},
				{
					prop: 'driver_rating',
					name: 'Driver Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverRating,
				},
				{
					prop: 'user_rating',
					name: 'Rider Rating',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderRating,
				},
			];
		} else {
			corporateRides = [
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 120,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.id,
				},
				{
					prop: 'driver_name',
					name: 'Driver Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.driverName,
				},
				{
					prop: 'user_name',
					name: 'Rider Name',
					sortable: true,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.riderName,
				},
				{
					prop: 'car_name',
					name: 'Car Detail',
					sortable: false,
					draggable: false,
					canAutoResize: true,
					// width: 150,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carDetail,
				}
			];
		}
		return corporateRides;
	} else if (key === 'home payout') {
		const payout = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 120,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'id',
				name: 'ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'transfer_id',
				name: 'Transfer ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tID,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userName,
			},
			{
				prop: 'state_name',
				name: 'State Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.stateName,
			},
			{
				prop: 'releasor',
				name: 'Releasor',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.releasor,
			},
			{
				prop: 'charge_type',
				name: 'Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.type,
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount,
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId,
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDate,
			},
			{
				prop: 'transfer_available_on',
				name: 'Available on',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.available,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description,
			}
		];
		return payout;
	} else if (key === 'home charge') {
		const charge = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 120,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'id',
				name: 'ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'transaction_id',
				name: 'Transfer ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tID,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userName,
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.corporateName,
			},
			{
				prop: 'state_name',
				name: 'State Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.stateName,
			},
			{
				prop: 'releasor',
				name: 'Releasor',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.releasor,
			},
			{
				prop: 'charge_type',
				name: 'Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.type,
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount,
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId,
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDate,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description,
			}
		];
		return charge;
	} else if (key === 'home dispute') {
		const dispute = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 120,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'id',
				name: 'ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'transfer_id',
				name: 'Transfer ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tID,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userName,
			},
			{
				prop: 'state_name',
				name: 'State Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.stateName,
			},
			{
				prop: 'amount',
				name: 'Amount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.amount,
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId,
			},
			{
				prop: 'trip_date',
				name: 'Trip Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripDate,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description,
			}
		];
		return dispute;
	} else if (key === 'home revenue') {
		const revenue = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				maxWidth: 120,
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sessionId,
			},
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.tripNo,
			},
			{
				prop: '',
				name: 'Customer Details',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.customerDetail
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverName
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carDetail
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: yesNo()
			},
			{
				prop: 'corporate_name',
				name: 'Corporate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.corporateName
			},
			{
				prop: 'driver_affiliate_name',
				name: 'Driver Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverAffiliateName
			},
			{
				prop: 'rider_affiliate_name',
				name: 'Rider Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.riderAffiliateName
			},
			{
				prop: '',
				name: 'Date And Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTime
			},
			// ---------------------------------------------------------------
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'manual_destination_address',
				name: 'Drop Off Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'total_payment',
				name: 'Total Payment',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'total_with_tax',
				name: 'Total Rider Paid',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'rider_paid',
				name: 'Rider Paid Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'discount',
				name: 'Qudos Promotions',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'qudos_incentive',
				name: 'Qudos Incentive',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'ride_fare',
				name: 'Ride Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'driver_tip',
				name: 'Tip',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'fare_calculated',
				name: 'Net Ride Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'master_merchant_pay',
				name: 'Master Merchant pay',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'sub_merchant_pay',
				name: 'Sub Merchant Pay',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'driver_share',
				name: 'Driver Share',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_base_share',
				name: 'Base Fare (Driver Share)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_miles_share',
				name: 'Distance Fare (Driver Share)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'distance_fare_total',
				name: 'Distance Fare Total (Driver Share)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'driver_min_share',
				name: 'Time Fare (Driver Share)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'time_fare_total',
				name: 'Time Fare Total (Driver Share)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'qudos_fee',
				name: 'Qudos Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'qudos_total_take',
				name: 'Qudos Total Take',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'corporate_cut',
				name: 'Corporate Cut',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: corporateCut()
			},
			{
				prop: 'credit_card_processing',
				name: 'CC Processing fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'credit_card_processing_tip_fee',
				name: 'CC Processing fee (Tip)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'payment_gateway_fee',
				name: 'Stripe Transaction Fees',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'payment_gateway_tip_fee',
				name: 'Stripe Transaction Fees (Tip)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'sales_tax',
				name: 'Tax',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'black_car_fund',
				name: 'Black Car Fund',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'base_fare',
				name: 'Base Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'distance_fare',
				name: 'Distance Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'distance_estimate',
				name: 'Distance Estimate (Mile)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'real_distance_fare',
				name: 'Real Distance Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'real_distance',
				name: 'Distance Estimate (Mile)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'time_fare',
				name: 'Time Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'time_estimate',
				name: 'Time estimate',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'real_time_fare',
				name: 'Real Time Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'real_time',
				name: 'Real Time (Minutes)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'toll',
				name: 'Toll',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'scheduled_ride_surcharge_fee',
				name: 'Scheduled Surcharge Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'affiliate_fee',
				name: 'Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'driver_affiliate_fee',
				name: 'Driver Affiliate fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.driverAffiliateFee
				pipe: nullable()
			},
			{
				prop: 'rider_affiliate_fee',
				name: 'Rider Affiliate fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'promo_code',
				name: 'Promo Code Used',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'discount',
				name: 'Promo Code Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		];
		return revenue;
	} else if (key === 'home promo') {
		const promohome = [
			{
				prop: 'id',
				name: 'Promo ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'promo_type_name',
				name: 'Promo Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'category_name',
				name: 'Category Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'ride_type_name',
				name: 'Ride Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'promo_image',
				name: 'Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.image,
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'start_date',
				name: 'Start Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: formatDate(),
			},
			{
				prop: 'end_date',
				name: 'End Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: formatDate(),
			},
			{
				prop: 'is_visible',
				name: 'Visibility',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'max_off',
				name: 'Maximum Discount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'percent_discount',
				name: 'Variable Discount',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable(),
			},
			{
				prop: 'days_validity',
				name: 'Validity (No. of days)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'per_user',
				name: 'User Quota',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		];
		return promohome;
	} else if (key === 'home offer') {
		const offerhome = [
			{
				prop: 'id',
				name: 'Offer ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'offer_type_id',
				name: 'Offer Type ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'offer_title',
				name: 'Offer Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'offer_description',
				name: 'Offer Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'promo_code',
				name: 'Promo Code',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'description',
				name: 'Promo Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'created_at',
				name: 'Created At',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: formatDate()
			},
			{
				prop: 'start_date',
				name: 'Start Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: formatDate()
			},
			{
				prop: 'end_date',
				name: 'End Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: formatDate()
			},
			{
				prop: 'number_issued_per_user',
				name: 'Number Issued Per User',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		];
		return offerhome;
	} else if (key === 'campaign') {
		const campaign = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'id',
				name: 'Campaign ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'title',
				name: 'Campaign Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.title,
			},
			{
				prop: 'message',
				name: 'Campaign Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.message,
			},
			{
				prop: 'image',
				name: 'Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.image,
			},
			{
				prop: 'date_created',
				name: 'Date/Time Created',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date,
			},
			{
				prop: 'type',
				name: 'Sent To',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.type,
			},
			{
				prop: 'user_count',
				name: 'Number of Users Sent to',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userCount,
			},
		];
		return campaign;
	} else if (key === 'home contacts') {
		const homeContacts = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 250,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'id',
				name: 'Contacts ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id,
			},
			{
				prop: 'title',
				name: 'Contacts Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.title,
			},
			{
				prop: 'description',
				name: 'Contacts Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.description,
			},
			{
				prop: 'mobile',
				name: '# of Contacts',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.mobile,
			},
		];
		return homeContacts;
	} else if (key === 'email') {
		const emailTemplates = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 150,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'email_template_id',
				name: 'Email Template ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.id
			},
			{
				prop: 'title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.title
			},
			{
				prop: 'message',
				name: 'Message',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.message
			},
			{
				prop: 'image',
				name: 'Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.image
			},
			{
				prop: 'date_created',
				name: 'Date/Time Created',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'type',
				name: 'Sent Via',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.type
			},
			{
				prop: 'user_count',
				name: 'Number of Users Sent to',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.userCount
			},
		];
		return emailTemplates;
	} else if (key === 'client-setting-driver-doc') {
		const data = [
			{
				prop: 'status',
				name: 'Enabled/Disabled',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'is_mandatory',
				name: 'Mandatory',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.mandatory
			},
			{
				prop: 'document_type_id',
				name: 'Document Type Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'document_name',
				name: 'Document Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: "doc_type_label",
				name: 'Document Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'date_added',
				name: 'Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
		];
		return data;
	} else if (key === 'client-setting-affiliate-doc') {
		const data = [
			{
				prop: 'status',
				name: 'Enabled/Disabled',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'is_mandatory',
				name: 'Mandatory',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.mandatory
			},
			{
				prop: 'document_type_id',
				name: 'Document Type Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'document_name',
				name: 'Document Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: "doc_type_label",
				name: 'Document Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'date_added',
				name: 'Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
		];
		return data;
	} else if (key === 'client-setting-corporate-doc') {
		const data = [
			{
				prop: 'status',
				name: 'Enabled/Disabled',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'is_mandatory',
				name: 'Mandatory',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.mandatory
			},
			{
				prop: 'document_type_id',
				name: 'Document Type Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'document_name',
				name: 'Document Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: "doc_type_label",
				name: 'Document Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop'
			},
			{
				prop: 'date_added',
				name: 'Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
		];
		return data;
	} else if (key === 'client-setting-client-doc') {
		const data = [
			{
				prop: 'status',
				name: 'Enabled/Disabled',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 200,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'document_type_id',
				name: 'Document Type Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'document_name',
				name: 'Document Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: "doc_type_label",
				name: 'Document Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer'
			},
			{
				prop: 'date_added',
				name: 'Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.date
			},
		];
		return data;
	} else if (key === 'client-setting-car-type-and-rates') {
		const carRates = [
			{
				prop: '',
				name: 'Enabled/Disabled',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'car_name',
				name: 'Car Type ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carType
			},
			{
				prop: 'car_name',
				name: 'Car Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.fixedFare
			},
			{
				prop: 'minimum_fare',
				name: 'Car Image',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.minFare
			},
			{
				prop: 'fare_per_km',
				name: 'Date And Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.farePerMile
			},
		];
		return carRates;
	}

	else if (key === 'car rates settings') {
		const carRates = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				maxWidth: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'status',
				name: 'View',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.view
			},
			{
				prop: 'car_name',
				name: 'Car Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carType
			},
			{
				prop: 'fare_fixed',
				name: 'Fixed Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.fixedFare
			},
			{
				prop: 'minimum_fare',
				name: 'Minimum Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.minFare
			},
			{
				prop: 'fare_per_km',
				name: 'Fare Per Mile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.farePerMile
			},
			{
				prop: 'fare_per_min',
				name: 'Fare Per Min',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.farePerMin
			},
			{
				prop: 'black_car_fund',
				name: 'Black Car Fund',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.blackCarFund
			},
			{
				prop: 'sales_tax',
				name: 'Sales Tax',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.salesTax
			},
			{
				prop: 'cancellation_time',
				name: 'Cancellation Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.cancelTime
			},
			{
				prop: 'cancellation_fee',
				name: 'Cancellation Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.cancelFare
			},
			{
				prop: 'car_type_image',
				name: 'Car Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.img
			},
		];
		return carRates;
	}
	else if (key === 'voDriversData') {
		const voDrivers = [
			// {
			// 	prop: '',
			// 	name: 'Actions',
			// 	sortable: false,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	maxWidth: 150,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.action
			// },
			{
				prop: 'video_status',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'title',
				name: 'Title',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 350,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'created_at',
				name: 'Date Completed',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.createdDate
			},
			{
				prop: 'cover_image',
				name: 'Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.coverImage
			},
			{
				prop: 'content_url',
				name: 'Video',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return voDrivers;
	} else if (key === 'business center account charges') {
		const accountCharges = [
			{
				prop: 'id',
				name: 'Charges Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'transaction_id',
				name: 'Transaction Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'card_number',
				name: 'Card Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'date	',
				name: 'Charge Date And Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'amount',
				name: 'Amount ($)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return accountCharges;
	} else if (key === 'business center account transfer') {
		const accountTransfer = [
			{
				prop: 'id',
				name: 'Transfer Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'transaction_id',
				name: 'Transaction Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'date	',
				name: 'Transfer Date And Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'amount',
				name: 'Amount ($)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return accountTransfer;
	} else if (key === 'business center payment method') {
		const paymentMethod = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'bank_name',
				name: 'Bank',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'last4',
				name: 'Account Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.accountDetail
			},
			{
				prop: 'created_at',
				name: 'Date Added',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'currency',
				name: 'Currency',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop text-uppercase',
			},
			{
				prop: 'status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop text-capitalize',
			},
			{
				prop: '',
				name: 'Verification',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.verification
			},
		];
		return paymentMethod;
	} else if (key === 'business center topup') {
		const paymentMethod = [
			{
				prop: 'transaction_status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: "d-flex align-items-center cell-class text-crop",
				cellTemplate: cellTemplate.transactionStatus
			},
			{
				prop: 'deposit_amount',
				name: 'Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'transaction_id',
				name: 'Top-Up ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'initiated_date',
				name: 'Initiated Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.initiatedDate
			},
			{
				prop: 'completed_date',
				name: 'Funds Available Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.availableDate
			}
		];
		return paymentMethod;
	} else if (key === 'business center global topup') {
		const paymentMethodGlobal = [
			{
				prop: 'transaction_status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: "d-flex align-items-center cell-class text-crop",
				cellTemplate: cellTemplate.transactionStatus
			},
			{
				prop: 'deposit_amount',
				name: 'Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'transaction_id',
				name: 'Top-Up ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Client/Corporate',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'user_type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.userType
			},
			{
				prop: 'initiated_date',
				name: 'Initiated Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.initiatedDate
			},
			{
				prop: 'completed_date',
				name: 'Funds Available Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.availableDate
			}
		];
		return paymentMethodGlobal;
	}
	else if (key === 'business center transaction history') {
		const transactionHistory = [
			{
				prop: 'status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'withdraw_amount',
				name: 'Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'transaction_id',
				name: 'Transaction ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'transaction_type',
				name: 'Transaction Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'created_at',
				name: 'Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'remaining_balance',
				name: 'Remaining Balance',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.remainingBalance
			},
		];
		return transactionHistory;
	}
	else if (key === 'business center global transaction history') {
		const transactionHistory = [
			{
				prop: 'status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'withdraw_amount',
				name: 'Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.amount
			},
			{
				prop: 'transaction_id',
				name: 'Transaction ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'business_name',
				name: 'Client/Corporate',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},

			{
				prop: 'user_type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.userType
			},


			{
				prop: 'transaction_type',
				name: 'Transaction Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'created_at',
				name: 'Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'remaining_balance',
				name: 'Remaining Balance',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.remainingBalance
			},
		];
		return transactionHistory;
	}
	else if (key === 'driver-car-base-affiliation-list') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let carBaseList: any[] = [];
		if (currentUser.admin_type == 1 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id == 1 && currentUser.parent_type == 1 ||
			currentUser.admin_type == 3 && currentUser.parent_id == 1 && currentUser.parent_type == 1) {
			carBaseList = [
				{
					prop: 'is_approved',
					name: 'Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 100,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.statusCarBase
				},
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 100,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'is_online',
					name: 'Online',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 100,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carBaseOnlineStatus
				},
				{
					prop: 'business_name',
					name: 'Car Base Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 280,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.businessNameCarBase
				},
				{
					prop: 'state_name',
					name: 'Car Base Address',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 400,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.addressCarBase
				},
				{
					prop: 'added_at',
					name: 'Car Base Added Date',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carBaseDateApproved
				},
				{
					prop: '',
					name: 'Account',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.accountCarBase
				},
			];
		} else {
			carBaseList = [
				{
					prop: 'is_approved',
					name: 'Status',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 100,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.statusCarBase
				},
				{
					prop: '',
					name: 'Actions',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 100,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'business_name',
					name: 'Car Base Name',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.businessNameCarBase
				},
				{
					prop: 'state_name',
					name: 'Car Base Address',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 400,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.addressCarBase
				},
				{
					prop: 'added_at',
					name: 'Car Base Added Date',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 400,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.carBaseDateApproved
				},
				{
					prop: '',
					name: 'Account',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class text-crop pointer',
					cellTemplate: cellTemplate.accountCarBase
				},
			];
		}
		return carBaseList;
	} else if (key === 'passenger-car-base-affiliation-list') {
		const carBaseList: any[] = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.businessNameCarBase
			},
			{
				prop: 'state_name',
				name: 'Car Base Address',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 400,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.addressCarBase
			},
			{
				prop: 'added_at',
				name: 'Car Base Added Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 400,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.carBaseDateApproved
			},
			{
				prop: '',
				name: 'Account',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.accountCarBase
			},
		];
		return carBaseList;
	} else if (key === 'area-country-list') {
		const areaCountryList: any[] = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'status',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'area_id',
				name: 'Area ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'country_name',
				name: 'Country Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'regulation_type',
				name: 'Regulation Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: regulationType()
			},
			{
				prop: 'image',
				name: 'Region Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.image
			},
			// {
			// 	prop: 'added_at',
			// 	name: 'Date Added',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 250,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.date
			// },
		];
		return areaCountryList;
	} else if (key === 'area-state-list') {
		const areaStateList: any[] = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'status',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'state_id',
				name: 'State ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'regulation',
				name: 'Regulation',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'state_image',
				name: 'Region Image',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.image
			},
		];
		return areaStateList;
	} else if (key === 'demo request') {
		const demoReq: any[] = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'client_id',
				name: 'Client ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'client_name',
				name: 'Client Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'business_name',
				name: 'Business Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'client_email',
				name: 'Client Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'mobile',
				name: 'Mobile',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'state',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'reg_date',
				name: 'Registration Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'demo_date_time',
				name: 'Demo Request Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
		];
		return demoReq;
	} else if (key === 'Trip History') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let tripHistoryColumns: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			tripHistoryColumns = [
				{
					prop: 'pay_to_driver',
					name: 'Payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					pipe: dollar()
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'under_client',
					name: 'Under Client',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: '',
					name: 'Miles & Minutes',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.milesMinutes
				},
				{
					prop: '',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'pickup_real_time',
					name: 'Pickup Date & Time',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.date
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
				{
					prop: 'drop_address',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
			];
		} else {
			tripHistoryColumns = [
				{
					prop: 'pay_to_driver',
					name: 'Payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					pipe: dollar()
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'under_client',
					name: 'Under Client',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: '',
					name: 'Miles & Minutes',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.milesMinutes
				},
				{
					prop: '',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'pickup_real_time',
					name: 'Pickup Date & Time',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.date
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
				{
					prop: 'drop_address',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
			];
		}
		return tripHistoryColumns;
	} else if (key === 'Earnings History') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let tripHistoryColumns: any[] = [];
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4 ||
			currentUser.admin_type == 3 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			tripHistoryColumns = [
				{
					prop: 'driver_payout',
					name: 'Payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					pipe: dollar()
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: '',
					name: 'Miles & Minutes',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.milesMinutes
				},
				{
					prop: '',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'pickup_real_time',
					name: 'Pickup Date & Time',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: formatDate()
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
			];
		} else {
			tripHistoryColumns = [
				{
					prop: 'driver_payout',
					name: 'Payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					pipe: dollar()
				},
				{
					prop: 'session_id',
					name: 'Session ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: '',
					name: 'Miles & Minutes',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.milesMinutes
				},
				{
					prop: '',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'pickup_real_time',
					name: 'Pickup Date & Time',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: formatDate()
				},
				{
					prop: 'pickup_location_address',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
				{
					prop: 'manual_destination_address',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
			];
		}
		return tripHistoryColumns;
	} else if (key === 'Corporate Trip History') {
		const tripHistoryColumns: any[] = [
			{
				prop: 'qudos_corporate_share',
				name: 'Payout',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				cellTemplate: cellTemplate.historyPayout,
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: '',
				name: 'Driver Detail',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.driverDetail
			},
			{
				prop: 'user_name',
				name: 'Rider Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'pickup_real_time',
				name: 'Pickup Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: nullable()
			},
			{
				prop: 'drop_address',
				name: 'Drop off Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: nullable()
			},
		];
		return tripHistoryColumns;
	} else if (key === 'client-driver-pool-setting') {
		const poolSettings: any[] = [
			{
				prop: 'is_enabled',
				name: 'Enable',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.carBase
			},
			{
				prop: 'state_name',
				name: 'State & Country',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.state
			},
			{
				prop: 'regulation_type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 150,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: regulationType()
			},
		];
		return poolSettings;
	} else if (key === "car-base-pool-setting") {
		const carBasePoolSettings: any[] = [
			{
				prop: 'is_enable',
				name: 'Enable',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'business_name',
				name: 'Car Base Name',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 400,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'country_name',
				name: 'Country & State',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.loc
			},
			{
				prop: 'regulation_type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: regulationType()
			},
		];
		return carBasePoolSettings;
	} else if (key === "driver-network-setting") {
		const driverNetworkSetting: any[] = [
			{
				prop: 'is_network_enable',
				name: 'Enable',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 200,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'driver_network_fees',
				name: 'Driver Network Fees %',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				// width: 200,
				cellClass: 'd-flex align-items-center  fw-bold cell-class py-2',
				cellTemplate: cellTemplate.updateFees
			},
		];
		return driverNetworkSetting;
	} else if (key === 'Assigned Trips') {
		const currentUser = JSON.parse(localStorage.getItem('qudos-admin-currentUser'));
		let assignedTrips: any[] = []
		if (currentUser.admin_type == 1 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id == 1 && currentUser.parent_type == 1 ||
			currentUser.admin_type == 3 && currentUser.parent_id == 1 && currentUser.parent_type == 1) {
			assignedTrips = [
				{
					prop: '',
					name: 'Action',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 80,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'ride_status',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					cellTemplate: cellTemplate.rideStatus
				},
				{
					prop: 'id',
					name: 'Assigned ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 110,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'miles',
					name: 'Mileage',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 110,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.mileage
				},
				{
					prop: 'leg_cost',
					name: 'MAS payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 130,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.assignerReimbursement
				},
				{
					prop: 'third_party_amount',
					name: 'Amount Paid',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 130,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.amount
				},
				{
					prop: 'user_toll',
					name: 'Toll',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 130,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.toll
				},
				{
					prop: 'user_name',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'business_name',
					name: 'Under Client',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
				{
					prop: 'pickup_date',
					name: 'Pickup Date & Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.date
				},
				{
					prop: 'pickup_location',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.pickupLocation
				},
				{
					prop: 'dropoff_location',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cpipe: nullable()
				},
			];
		} else {
			assignedTrips = [
				{
					prop: '',
					name: 'Action',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 80,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					cellTemplate: cellTemplate.action
				},
				{
					prop: 'ride_status',
					name: 'Status',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 150,
					cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
					cellTemplate: cellTemplate.rideStatus
				},
				{
					prop: 'id',
					name: 'Assigned ID',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 110,
					cellClass: 'd-flex align-items-center cell-class py-2',
				},
				{
					prop: 'miles',
					name: 'Mileage',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 110,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.mileage
				},
				{
					prop: 'leg_cost',
					name: 'MAS payout',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 130,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.assignerReimbursement
				},
				{
					prop: 'third_party_amount',
					name: 'Amount',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 110,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.amount
				},
				{
					prop: 'user_toll',
					name: 'Toll',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 130,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.toll
				},
				{
					prop: 'user_name',
					name: 'Rider Details',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.riderDetail
				},
				{
					prop: 'pickup_date',
					name: 'Pickup Date & Time',
					sortable: true,
					draggable: false,
					canAutoResize: false,
					width: 200,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.date
				},
				{
					prop: 'pickup_location',
					name: 'Pickup Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					cellTemplate: cellTemplate.pickupLocation
				},
				{
					prop: 'dropoff_location',
					name: 'Drop off Location',
					sortable: false,
					draggable: false,
					canAutoResize: false,
					width: 250,
					cellClass: 'd-flex align-items-center cell-class py-2',
					pipe: nullable()
				},
			];
		}
		return assignedTrips;
	}
	else if (key == 'Deposited Amount') {
		const depositedAmount = [
			{
				prop: 'released_amount',
				name: 'Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				pipe: dollar()
			},
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.sessionIDs
			},
			{
				prop: 'bank_details',
				name: 'External Account',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.bankDetails
			},
			{
				prop: 'payout_id',
				name: 'Transaction ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: nullable()
			},
			{
				prop: 'initiated_date',
				name: 'Initiated',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: formatDate()
			},
			{
				prop: 'arrival_date',
				name: 'Est. Arrival',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: formatDateWithoutTime()
			},
			{
				prop: 'payment_status',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.status
			},
		];
		return depositedAmount;
	}
	else if (key == 'billing_earnings') {
		const billingEarnigs = [
			{
				prop: 'id',
				name: 'Id #',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Id #'
			},
			{
				prop: 'processing_fee',
				name: 'Earnigs',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class fw-bold py-2',
				pipe: dollar(),
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.earnings,
				searchPlaceholder: 'Earnigs'
			},
			{
				prop: 'client_name',
				name: 'Client',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.client,
				searchPlaceholder: 'Client'
			},
			{
				prop: 'payment_type',
				name: 'Type',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.type,
				pipe: nullable()
			},
			{
				prop: 'payment_date',
				name: 'Charged Date',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.chargeDate,
			}
		];
		return billingEarnigs;
	}
	else if (key === 'All Trips' || key === 'Confirmed Trips' || key === 'Declined Trips' || key === 'Cancelled Trips' || key === 'Completed Trips') {
		const assignedTrips: any[] = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Session ID #'
			},
			{
				prop: 'trip_leg_id',
				name: 'Prepaid & Trip ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.ids
			},
			{
				prop: '',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellTemplate: cellTemplate.status,
				headerTemplate: cellTemplate.statusHeader,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'car_base_name',
				name: 'Search Corporate',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: nullable(),
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Corporate'
			},
			{
				prop: 'user_name',
				name: 'Rider Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.riderDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Rider'
			},
			{
				prop: 'driver_name',
				name: 'Driver Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.driverDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Driver'
			},
			{
				prop: 'pickup_date',
				name: 'Pickup Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'pickup_location',
				name: 'Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.pickupLoc,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Pickup Location'
			},
			{
				prop: 'dropoff_location',
				name: 'Drop off Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Drop off Location'
			},
		];
		return assignedTrips;
	} else if (key === 'Queued Trips') {
		const trips: any[] = [
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.action
			},
			{
				prop: '',
				name: 'Prepaid | Queue',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.queueStatus
			},
			{
				prop: 'trip_leg_id',
				name: 'Invoice & Trip ID',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 160,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.ids
			},
			{
				prop: 'driver_name',
				name: 'Assign Driver',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.driver,
			},
			{
				prop: 'user_name',
				name: 'Rider Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 190,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.riderDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Rider'
			},
			{
				prop: 'car_base_name',
				name: 'Under Client',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 190,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Car Base'
			},
			{
				prop: 'pickup_date',
				name: 'Pickup Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'pickup_location',
				name: 'Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 230,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.pickupLoc,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Pickup Location'
			},
			{
				prop: 'dropoff_location',
				name: 'Drop off Location',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 230,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Search Drop off Location'
			},
		];
		return trips;
	} else if (key === 'driving hours') {
		const drivingHours: any[] = [
			{
				prop: 'hours_online',
				name: 'Hours Online',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'hours_with_customer',
				name: 'Hours w/Passenger',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'hours_without_customer',
				name: 'Hours w/out Passenger',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'start_time',
				name: 'Start Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.startDate
			},
			{
				prop: 'end_time',
				name: 'End Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.endDate
			},
		];
		return drivingHours;
	} else if (key === 'points') {
		const points: any[] = [
			{
				prop: '',
				name: 'Total Points',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.totalPoints
			},
			{
				prop: 'total_referral',
				name: 'Total Referral',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'total_referral_points',
				name: 'Total Referral Points',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'total_trip_points',
				name: 'Total Trip Points',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'total_favorite',
				name: 'Total Favorite',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'total_favorite_points',
				name: 'Total Favorite Points',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
			},
			{
				prop: 'total_hours',
				name: 'Total Hours',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'total_hour_points',
				name: 'Total Hours Points',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
		];
		return points;
	} else if (key === 'driving mileage') {
		const drivingMileage: any[] = [
			{
				prop: '',
				name: 'Total Miles',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.totalMiles
			},
			{
				prop: 'mileage',
				name: 'Miles w/Passenger',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'mileage_without_customer',
				name: 'Miles w/out Passenger',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				pipe: zero()
			},
			{
				prop: 'start_time',
				name: 'Start Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.startDate
			},
			{
				prop: 'end_time',
				name: 'End Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.endDate
			},
		];
		return drivingMileage;
	} else if (key === 'ride_broker') {
		const rideBroker: any[] = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'broker_name',
				name: 'Ride Broker Details',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.brokerDetail
			},
			{
				prop: 'date_time',
				name: 'API Date & Time',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.apidateTime
			},
			{
				prop: 'api_key',
				name: 'API Key',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 400,
				cellClass: 'd-flex align-items-center cell-class text-crop py-2',
				cellTemplate: cellTemplate.apiKey
			}
		];
		return rideBroker;
	} else if (key === 'revenueTable') {
		const revenueTable: any[] = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 100,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'ID #'
			},
			{
				prop: '',
				name: 'Total Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellTemplate: cellTemplate.totalFee,
				cellClass: 'd-flex align-items-center cell-class text-crop fw-bold',
			},
			{
				prop: '',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellTemplate: cellTemplate.status,
				headerTemplate: cellTemplate.statusHeader,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: '',
				name: 'PU Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTimeCRT
			},
			{
				prop: '',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.client,
				searchPlaceholder: 'Client'
			},
			{
				prop: '',
				name: 'Corporate',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.corporate,
				searchPlaceholder: 'Corporate'
			},
			{
				prop: '',
				name: 'Rider',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.rider,
				searchPlaceholder: 'Rider'
			},
			{
				prop: '',
				name: 'Driver',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.driver,
				searchPlaceholder: 'Driver'
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellTemplate: cellTemplate.carDetails,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'drop_address',
				name: 'Drop Off Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		]
		return revenueTable;
	} else if (key === 'v2Trips') {
		const revenueTable: any[] = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'ID #'
			},
			{
				prop: '',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellTemplate: cellTemplate.status,
				headerTemplate: cellTemplate.statusHeader,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: '',
				name: 'PU Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTimeCRT
			},
			{
				prop: '',
				name: 'Under Client',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.client,
				searchPlaceholder: 'Client'
			},
			{
				prop: '',
				name: 'Corporate',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.corporate,
				searchPlaceholder: 'Corporate'
			},
			{
				prop: '',
				name: 'Rider',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.rider,
				searchPlaceholder: 'Rider'
			},
			{
				prop: '',
				name: 'Driver',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.driver,
				searchPlaceholder: 'Driver'
			},
			{
				prop: '',
				name: 'Car Details',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellTemplate: cellTemplate.carDetails,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'drop_address',
				name: 'Drop Off Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		]
		return revenueTable;
	} else if (key === 'Client_Earning_History') {
		const clientEarningTable: any[] = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.session_id,
				searchPlaceholder: 'ID #'
			},
			{
				prop: 'client_earnings',
				name: 'Earnings',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer fw-bold',
				searchPlaceholder: 'Client',
				cellTemplate: cellTemplate.earnings,
			},
			{
				prop: 'client_paid_amount',
				name: 'Deposited',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer fw-bold',
				searchPlaceholder: 'Client',
				cellTemplate: cellTemplate.deposited,
			},
			{
				prop: 'total_payment',
				name: 'Gross Fare',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer fw-bold',
				searchPlaceholder: 'Corporate',
				cellTemplate: cellTemplate.groceFare,
			},
			{
				prop: '',
				name: 'Car Base',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellTemplate: cellTemplate.carBase,
				headerTemplate: cellTemplate.hdrTpl,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				searchPlaceholder: 'Car Base'
			},
			{
				prop: '',
				name: 'Corporate',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellTemplate: cellTemplate.corporate,
				headerTemplate: cellTemplate.hdrTpl,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				searchPlaceholder: 'Corporate'
			},
			{
				prop: '',
				name: 'Driver',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.driver,
				searchPlaceholder: 'Driver'
			},
			{
				prop: '',
				name: 'Rider',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				cellTemplate: cellTemplate.rider,
				searchPlaceholder: 'Rider'
			},
			{
				prop: '',
				name: 'Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTimeCRT
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'drop_address',
				name: 'Drop Off Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
		]
		return clientEarningTable;
	}
	else if (key === 'payout_methods') {
		const payoutMethods: any[] = [
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'bank_name',
				name: 'Bank',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class bold text-crop pointer',
			},
			{
				prop: '',
				name: 'Account Details',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.accountDetail
			},

			{
				prop: '',
				name: 'Date Added',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTimeCRT,
			},
			{
				prop: '',
				name: 'Account',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.default,
			},
		]
		return payoutMethods;
	} else if (key === 'billing_amount') {
		const payoutMethods: any[] = [
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: 'bank_name',
				name: 'Bank Account',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class bold text-crop pointer',
			},
			{
				prop: '',
				name: 'Submerchant ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.accountDetail
			},

			{
				prop: '',
				name: 'Created Date',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateTimeCRT,
			},
			{
				prop: '',
				name: 'Account',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.default,
			},
		]
		return payoutMethods;
	}
	else if (key === 'add_ride_borker') {
		const addRideBrokers: any[] = [
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: '',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.status,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'id',
				name: 'Id #',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class bold text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Id #'
			},
			{
				prop: '',
				name: 'Brokers',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.brokers,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Brokers'
			},

			{
				prop: '',
				name: 'Contact Person',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.contactPerson,
			},
			{
				prop: '',
				name: 'Office Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.officeLocation,
			},
		]
		return addRideBrokers;
	} else if (key === 'ride_borkers_settings') {
		const riderBrokersSettings: any[] = [
			{
				prop: '',
				name: 'Action',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.action
			},
			{
				prop: '',
				name: 'Status',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.status,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'ride_broker_id',
				name: 'Account ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 120,
				maxWidth: 120,
				cellClass: 'd-flex align-items-center cell-class bold text-crop pointer',
			},
			{
				prop: '',
				name: 'Ride Broker Details',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.brokers,
			},

			{
				prop: '',
				name: 'Contact Person',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.contactPerson,
			},
			{
				prop: '',
				name: 'Office Location',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.officeLocation,
			},
			{
				prop: 'api_key',
				name: 'API Key',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 350,
				maxWidth: 350,
				cellClass: 'd-flex align-items-center cell-class pointer',
				cellTemplate: cellTemplate.apiKey,
			},
		]
		return riderBrokersSettings;
	} else if (key === 'broker_status') {
		const riderBrokersSettings: any[] = [
			{
				prop: '',
				name: 'Account',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.accountValue,
				width: 180,
				maxWidth: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Account'
			},
			// {
			// 	prop: '',
			// 	name: 'Care Base',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: true,
			// 	width: 250,
			// 	maxWidth: 250,
			// 	cellClass: 'd-flex align-items-center cell-class bold text-crop pointer',
			// 	headerTemplate: cellTemplate.hdrTpl,
			// 	cellTemplate: cellTemplate.careBase,
			// 	searchPlaceholder: 'Care Base'
			// },
			{
				prop: '',
				name: 'Sync Date & Time',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.dateAndTime,
			},

			{
				prop: '',
				name: 'Driver/Vehicle ID',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverVehicleID,
			},
			{
				prop: '',
				name: 'Account',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.account,
			},
			{
				prop: '',
				name: 'Sync',
				sortable: true,
				draggable: false,
				canAutoResize: true,
				width: 250,
				maxWidth: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.sync,
			},
		]
		return riderBrokersSettings;
	}
}