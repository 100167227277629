function nullable() {
	return { transform: (value) => value && value !== '' ? value : 'NA' };
}

function paymentStatus() {
  return { transform: (value) => value ? 'Payment Successful' : 'Unsuccessfull Payment' };
}

export function driverRideColumn(key: string, cellTemplate: any = {}) {
  if (key === 'Completed') {
    const completedRideColumn = [
      {
        prop: 'session_id',
        name: 'Session ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 120,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.id,
      },
      {
        prop: 'trip_number',
        name: 'Trip No',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: '',
        name: 'Fare Structure 1',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.fareStructure,
      },
      // {
      //   prop: 'driver_id',
      //   name: 'Driver ID',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 120,
      //   cellClass: 'd-flex align-items-center cell-class',
      // },
      // {
      //   prop: 'driver_image',
      //   name: 'Profile Pic',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 150,
      //   cellClass: 'd-flex align-items-center cell-class',
      //   cellTemplate: cellTemplate.pic,
      // },
      // {
      //   prop: 'driver_name',
      //   name: 'Driver Full Name',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   cellClass: 'd-flex align-items-center cell-class',
      // },
      {
        prop: 'user_name',
        name: 'Rider Full Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      // {
      //   prop: 'car_name',
      //   name: 'Car Details',
      //   sortable: false,
      //   draggable: false,
      //   canAutoResize: false,
      //   cellClass: 'd-flex align-items-center cell-class',
      //   cellTemplate: cellTemplate.rideessential,
      // },
      {
        prop: 'affiliate_name',
        name: 'Affiliate Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.isessential,
        pipe:nullable()
      },
      {
        prop: 'promo_code',
        name: 'Promo Code Used',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        pipe: nullable()
      },
      {
        prop: 'discount',
        name: 'Promo Code Cost',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        pipe: nullable()
      },
      {
        prop: 'drop_time',
        name: 'Pickup Date/Time',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.droptime,
      },
      {
        prop: 'pickup_location_address',
        name: 'Pickup Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'manual_destination_address',
        name: 'Requested Dropoff Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      // {
      //   prop: '',
      //   name: 'Fare Structure 2',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 220,
      //   cellClass: 'd-flex align-items-center cell-class',
      //   cellTemplate: cellTemplate.fareStructure,
      // },
      {
        prop: 'is_payment_successful',
        name: 'Payment Status',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
        pipe: paymentStatus()
      },
      // {
      //   prop: 'cancellation_fee',
      //   name: 'Cancellation Charge',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 150,
      //   cellClass: 'd-flex align-items-center cell-class',
      // },
      {
        prop: 'toll',
        name: 'Toll Free',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
        pipe: nullable()
      },
      {
        prop: 'scheduled_ride_surcharge_fee',
        name: 'Scheduled Surcharge Fee',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'affiliate_fee',
        name: 'Affiliate Fee',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        pipe:nullable(),

        cellClass: 'd-flex align-items-center cell-class',
      },
    ];
    return completedRideColumn;
  } else if (key === 'Cancelled') {
    const cancelledRideColumn = [
      {
        prop: 'session_id',
        name: 'Session ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 120,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.id,
      },
      {
        prop: 'trip_number',
        name: 'Trip No',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'driver_id',
        name: 'Driver ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.email,
      },
      {
        prop: 'ride_status',
        name: 'Ride Status',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.ridestatus,
      },
      {
        prop: 'driver_image',
        name: 'Profile Pic',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.pic,
      },
      {
        prop: 'driver_name',
        name: 'Driver Full Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'user_name',
        name: 'Rider Full Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'car_name',
        name: 'Car Details',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },

      {
        prop: 'affiliate_name',
        name: 'Affiliate Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        pipe:nullable(),

        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'sent_to',
        name: 'Request Sent to Driver ID(s)',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'drivers',
        name: 'Request Sent to Driver(s)',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'payment_date',
        name: 'Date/Time Ride Requested',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.droptime,
      },
      {
        prop: 'pickup_location_address',
        name: 'Pickup Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 250,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'manual_destination_address',
        name: 'Requested Dropoff Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_with_tax',
        name: 'Total Payment',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'pay_to_driver',
        name: 'Total Payout',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
    ];
    return cancelledRideColumn;
  } else if (key === 'Missed') {
    const missedRideColumn = [
      {
        prop: 'session_id',
        name: 'Session ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 120,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.id,
      },
      {
        prop: 'trip_number',
        name: 'Trip No',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      // {
      //   prop: 'driver_id',
      //   name: 'Driver ID',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 150,
      //   cellClass: 'd-flex align-items-center cell-class',
      //   cellTemplate: cellTemplate.email,
      // },
      {
        prop: 'payment_date',
        name: 'Date/Time Ride Requested',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.droptime,
      },
      {
        prop: 'ride_status',
        name: 'Ride Status',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.ridestatus,
      },
      {
        prop: 'driver_image',
        name: 'Profile Pic',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.pic,
      },
      {
        prop: 'driver_name',
        name: 'Driver Full Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'user_name',
        name: 'Rider Full Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'car_name',
        name: 'Car Details',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },

      {
        prop: 'affiliate_name',
        name: 'Affiliate Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'sent_to',
        name: 'Request Sent to Driver ID(s)',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'drivers',
        name: 'Request Sent to Driver(s)',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      // {
      //   prop: 'payment_date',
      //   name: 'Date/Time Ride Requested',
      //   sortable: true,
      //   draggable: false,
      //   canAutoResize: false,
      //   width: 220,
      //   cellClass: 'd-flex align-items-center cell-class',
      //   cellTemplate: cellTemplate.droptime,
      // },
      {
        prop: 'pickup_location_address',
        name: 'Pickup Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 250,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'manual_destination_address',
        name: 'Requested Dropoff Location',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_with_tax',
        name: 'Missed Total Payment',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'pay_to_driver',
        name: 'Missed Total Payout',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
    ];
    return missedRideColumn;
  }
}