<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
	<div class="logo clearfix mtp-5">
		<div class="dropdown" *ngIf="isBusinessDDVisible" (clickOutside)="onClickedOutside($event)">
			<span class="qudos-title dropdown-toggle d-flex justify-content-between align-items-center"
				id="dropdownMenuButton" data-toggle="" aria-haspopup="true" aria-expanded="false"
				(click)="isBusinessDDListVisible = true;focusInputField()">
				<div class="selected-item">
					<ng-container *ngIf="currentUser?.admin_type == 1">
						{{ selectedClient?.business_name ? selectedClient?.business_name : 'Qudos Technologies Inc'}}
					</ng-container>
					<ng-container *ngIf="currentUser?.admin_type == 3 || currentUser?.admin_type == 2">
						{{ selectedClient?.business_name ? selectedClient?.business_name : currentUser?.admin_name }}
					</ng-container>
					<ng-container *ngIf="currentUser?.admin_type === 4">
						{{ selectedBusiness?.business_name }}
					</ng-container>
				</div>
				<img src="assets/img/icons/caret-down-gray-outline.png" alt="caret-down">
			</span>
			<div class="dropdown-menu dropdown-content" [ngClass]="{'show': isBusinessDDListVisible}"
				aria-labelledby="dropdownMenuButton">
				<ng-container
					*ngIf="currentUser?.admin_type == 1 || currentUser?.admin_type == 3 || currentUser?.parent_type == 1">
					<a>
						<div class="form-group mb-0 businessname-search">
							<input #input type="text" class="form-control" placeholder="Search">
							<img src="assets/img/icons/navbar/search.png" width="15" height="15" alt="search">
						</div>
					</a>
					<a class="dropdown-item" (click)="clientSelect('')">Qudos Technologies Inc t</a>
					<ng-container *ngFor="let item of clients">
						<a *ngIf="item.business_name && item.admin_id" class="dropdown-item"
							(click)="clientSelect(item)">{{ item.business_name }}</a>
					</ng-container>
				</ng-container>
				<ng-container
					*ngIf="currentUser?.admin_type === 4 || currentUser?.admin_type === 2 || currentUser?.admin_type === 3">
					<a *ngFor="let item of clientBusiness" class="dropdown-item"
						(click)="(currentUser?.admin_type === 2 || currentUser?.admin_type === 3) ? clientSelect(item) : switchBusiness(item)">
						{{ item.business_name ? item.business_name : item.admin_name }}
						<ng-container *ngIf="currentUser?.admin_type === 4">
							<span class="show-less default-business ml-1"
								*ngIf="item.is_approved && item.is_default">Default</span>
							<span class="show-less ml-1" *ngIf="!item.is_approved && item.is_default">Pending</span>
							<span class="show-less ml-1" *ngIf="!item.is_approved && !item.is_default">Pending</span>
						</ng-container>
					</a>
					<ng-container *ngIf="currentUser?.admin_type != 2 && currentUser?.admin_type != 3">
						<a class="dropdown-item" *ngIf="defaultBusiness?.is_approved" (click)="createNewAccount()">
							Create new account
						</a>
					</ng-container>
					<a class="dropdown-item toggle-up" (click)="isBusinessDDListVisible = false">
						<img src="assets/img/icons/sidebar/up.png" alt="caret-up" />
					</a>
				</ng-container>
			</div>
		</div>

		<a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;"
			(click)="CloseSidebar()">
			<em class="ft-x"></em>
		</a>
	</div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
	<div class="sidebar-content-wrapper">
		<div class="nav-container">
			<ul class="navigation" appSidebarDropdown>
				<!-- First level menu -->
				<ng-container *ngFor="let menuItem of menuItems">
					<!-- {{menuItem.adminToClientSwitchVisibility}} -->
					<li appSidebarlink
						*ngIf="menuItem.parent_type.includes(currentUser?.parent_type) && menuItem.admin_type.includes(currentUser?.admin_type) && menuItem.adminToClientSwitchVisibility"
						[parent]="menuItem.title" [path]="menuItem.path" level="{{ level + 1 }}"
						[hasSub]="menuItem.class.includes('has-sub') ? true : false"
						[routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'"
						(click)="verticalMenuItemEvt()" [routerLinkActiveOptions]="{ exact: false }"
						class="{{menuItem.class}}" [ngClass]="{ 'sidebar-divider': menuItem.divider }">
						<!-- 'has-sub': menuItem.class.includes('has-sub') ? true: false, -->

						<ng-container *ngIf="!menuItem.path && menuItem.submenu.length > 0 && !menuItem.isExternalLink">
							<a appSidebarAnchorToggle>
								<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
								<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
									}}</span>
								<span *ngIf="menuItem.badge && menuItem.badge != ''"
									[ngClass]="[menuItem.badgeClass]">{{
									menuItem.badge }}</span>
							</a>
						</ng-container>
						<ng-container *ngIf="menuItem.path && menuItem.submenu.length > 0 && !menuItem.isExternalLink">
							<a appSidebarAnchorToggle routerLink="{{ menuItem.path }}">
								<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
								<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
									}}</span>
								<span *ngIf="menuItem.badge && menuItem.badge != ''"
									[ngClass]="[menuItem.badgeClass]">{{
									menuItem.badge }}</span>
							</a>
						</ng-container>

						<ng-container *ngIf="menuItem.path && menuItem.title != 'Logout'">
							<a appSidebarAnchorToggle routerLink="{{ menuItem.path }}"
								*ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
								<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
								<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
									}}</span>
								<span *ngIf="menuItem.badge && menuItem.badge != ''"
									[ngClass]="[menuItem.badgeClass]">{{
									menuItem.badge }}</span>
							</a>
						</ng-container>
						<ng-container *ngIf="!menuItem.path && menuItem.title != 'Logout'">
							<a appSidebarAnchorToggle *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
								<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
								<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
									}}</span>
								<span *ngIf="menuItem.badge && menuItem.badge != ''"
									[ngClass]="[menuItem.badgeClass]">{{
									menuItem.badge }}</span>
							</a>
						</ng-container>
						<ng-container *ngIf="!menuItem.path && menuItem.title === 'Logout'">
							<a appSidebarAnchorToggle (click)="auth.logout()"
								*ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
								<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
								<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
									}}</span>
								<span *ngIf="menuItem.badge && menuItem.badge != ''"
									[ngClass]="[menuItem.badgeClass]">{{
									menuItem.badge }}</span>
							</a>
						</ng-container>

						<a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
							<img *ngIf="menuItem?.icon" class="sidebar-icons" [src]="menuItem?.icon" alt="" />
							<span class="menu-title" [ngClass]="{'left-space': !menuItem?.icon}">{{ menuItem.title
								}}</span>
							<span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{
								menuItem.badge }}</span>
						</a>
						<!-- Second level menu -->
						<ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0">
							<ng-container *ngFor="let menuSubItem of menuItem.submenu">
								<li appSidebarlink
									*ngIf="menuSubItem.parent_type.includes(currentUser?.parent_type) && menuSubItem.admin_type.includes(currentUser?.admin_type) && menuSubItem.adminToClientSwitchVisibility"
									[parent]="menuItem.title"
									[hasSub]="menuSubItem.class.includes('has-sub') ? true : false"
									[path]="menuSubItem.path" (click)="verticalMenuItemEvt()" level="{{ level + 2 }}"
									[routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
									<!-- [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}" -->

									<ng-container
										*ngIf="!menuSubItem.path && menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
										<a appSidebarAnchorToggle>
											<img *ngIf="menuSubItem?.icon" class="sidebar-icons"
												[src]="menuSubItem?.icon" alt="" />
											<span class="menu-title" [ngClass]="{'left-space': !menuSubItem?.icon}">
												{{ menuSubItem.title }}
											</span>
											<span *ngIf="menuSubItem.badge && menuSubItem.badge != ''"
												[ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge }}</span>
										</a>
									</ng-container>
									<ng-container
										*ngIf="menuSubItem.path && menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
										<a appSidebarAnchorToggle routerLink="{{ menuSubItem.path }}">
											<img *ngIf="menuSubItem?.icon" class="sidebar-icons"
												[src]="menuSubItem?.icon" alt="" />
											<span class="menu-title" [ngClass]="{'left-space': !menuSubItem?.icon}">{{
												menuSubItem.title }}</span>
											<span *ngIf="menuSubItem.badge && menuSubItem.badge != ''"
												[ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge }}</span>
										</a>
									</ng-container>

									<a appSidebarAnchorToggle routerLink="{{ menuSubItem.path }}"
										*ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
										<img *ngIf="menuSubItem?.icon" class="sidebar-icons" [src]="menuSubItem?.icon"
											alt="" />
										<span class="menu-title" [ngClass]="{'left-space': !menuSubItem?.icon}">{{
											menuSubItem.title
											}}</span>
										<span *ngIf="menuSubItem.badge && menuSubItem.badge != ''"
											[ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge }}</span>
									</a>
									<a routerLink="{{ menuSubItem.path }}" target="_blank"
										*ngIf="menuSubItem.isExternalLink">
										<img *ngIf="menuSubItem?.icon" class="sidebar-icons" [src]="menuSubItem?.icon"
											alt="" />
										<span class="menu-title" [ngClass]="{'left-space': !menuSubItem?.icon}">{{
											menuSubItem.title
											}}</span>
										<span *ngIf="menuSubItem.badge && menuSubItem.badge != ''"
											[ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge }}</span>
									</a>
									<!-- Third level menu -->
									<!-- <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
										<li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu"
											[parent]="menuItem.title" [hasSub]="false" [path]="menuSubsubItem.path"
											level="{{level + 3}}" routerLinkActive="active"
											[routerLinkActiveOptions]="{exact: true}"
											[ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
											<a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
												*ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
												<img class="sidebar-icons" [src]="menuSubsubItem?.icon" alt="">
												<span class="menu-title">{{menuSubsubItem.title }}</span>
												<span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
													[ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
											</a>
											<ng-template #externalSubSubLinkBlock>
												<a routerLink="{{menuSubsubItem.path}}" target="_blank">
													<img class="sidebar-icons" [src]="menuSubsubItem?.icon" alt="">
													<span class="menu-title">{{menuSubsubItem.title }}</span>
													<span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
														[ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
												</a>
											</ng-template>
										</li>
									</ul> -->
								</li>
							</ng-container>
						</ul>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</div>
<div class="p-3" class="rights bottom-content">
	<div>@ 2021 Qudos Technologies Inc</div>
	<div>All right reserved</div>
</div>
<!-- Sidebar Content Ends -->