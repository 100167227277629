import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-logout-confirm',
	templateUrl: './logout-confirm.component.html',
	styleUrls: ['./logout-confirm.component.scss']
})
export class LogoutConfirmComponent implements OnInit {

	constructor(private dialogRef: MatDialogRef<LogoutConfirmComponent>) { }

	ngOnInit(): void {
	}

	logout(): void {
		this.dialogRef.close(true);
	}
}
