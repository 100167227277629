<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">Edit Client Info</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">First Name</label>
							<div class="col-sm-8">
								<input formControlName="first_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.first_name.invalid,
									'is-valid': isSubmitting && !formControls.first_name.invalid
								}">
								<div *ngIf="formControls.first_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.first_name.errors?.required">*First name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Last Name</label>
							<div class="col-sm-8">
								<input formControlName="last_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.last_name.invalid,
									'is-valid': isSubmitting && !formControls.last_name.invalid
								}">
								<div *ngIf="formControls.last_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_name.errors?.required">*Last name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Email</label>
							<div class="col-sm-8">
								<input formControlName="email" type="email" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.email.invalid,
									'is-valid': isSubmitting && !formControls.email.invalid
								}">
								<div *ngIf="formControls.email.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.email.errors?.required">*Email is required.</small>
									<small [hidden]="!formControls.email.errors?.pattern">*Invalid email.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Phone</label>
							<div class="col-sm-8">
								<!-- <input formControlName="mobile" type="tel" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.mobile.invalid,
									'is-valid': isSubmitting && !formControls.mobile.invalid
								}"> -->
								<ngx-intl-tel-input [cssClass]="'custom'" [enableAutoCountrySelect]="true"
                                [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                customPlaceholder="Enter mobile number" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                [maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                name="phone" formControlName="mobile">
                                </ngx-intl-tel-input>
								<div *ngIf="formControls.mobile.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.mobile.errors?.required">*Mobile is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Customer Support Number</label>
							<div class="col-sm-8">
								<!-- <input formControlName="support_number" type="tel" class="form-control" maxlength="15"> -->
								<ngx-intl-tel-input [cssClass]="'custom'" [enableAutoCountrySelect]="true"
                                [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                customPlaceholder="Enter mobile number" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                [maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                name="phone" formControlName="support_number">
                                </ngx-intl-tel-input>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Customer Support Email</label>
							<div class="col-sm-8">
								<input formControlName="support_email" type="email" class="form-control">
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Zipcode</label>
							<div class="col-sm-8">
								<input formControlName="zipcode" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.zipcode.invalid,
									'is-valid': isSubmitting && !formControls.zipcode.invalid
								}">
								<div *ngIf="formControls.zipcode.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.zipcode.errors?.required">*Zipcode is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Country</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="country_id" [ngClass]="{
									'is-invalid': isSubmitting && formControls.country_id.invalid,
									'is-valid': isSubmitting && !formControls.country_id.invalid
								}" (change)="onCountryChange($event)">
									<option value="">Select Country</option>
									<option [value]="item.country_id" *ngFor="let item of countries">{{ item.country_name }}</option>
								</select>
								<div *ngIf="formControls.country_id.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.country_id.errors?.required">*Country is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">State</label>
							<div class="col-sm-8">
								<select class="form-control" formControlName="state_id" [ngClass]="{
									'is-invalid': isSubmitting && formControls.state_id.invalid,
									'is-valid': isSubmitting && !formControls.state_id.invalid
								}">
									<option value="">Select State</option>
									<option [value]="item.state_id" *ngFor="let item of states">{{ item.state_name }}</option>
								</select>
								<div *ngIf="formControls.state_id.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.state_id.errors?.required">*State is required.</small>
								</div>
							</div>
						</div>

					</div>
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Registration Date</label>
							<div class="col-sm-8">
								<input formControlName="date_registered" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.date_registered.invalid,
									'is-valid': isSubmitting && !formControls.date_registered.invalid
								}">
								<div *ngIf="formControls.date_registered.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.date_registered.errors?.required">*Registration Date
										is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Business Name</label>
							<div class="col-sm-8">
								<input formControlName="business_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.business_name.invalid,
									'is-valid': isSubmitting && !formControls.business_name.invalid
								}">
								<div *ngIf="formControls.business_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.business_name.errors?.required">*Business Name is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Car Base #</label>
							<div class="col-sm-8">
								<input formControlName="car_base_number" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.car_base_number.invalid,
									'is-valid': isSubmitting && !formControls.car_base_number.invalid
								}">
								<div *ngIf="formControls.car_base_number.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.car_base_number.errors?.required">*Car base # is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Regulation Type</label>
							<div class="col-sm-8">
								<select formControlName="regulation_type" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.regulation_type.invalid,
									'is-valid': isSubmitting && !formControls.regulation_type.invalid
								}">
									<option value="">Select Regulation Type</option>
									<option value="0">TNC</option>
									<option value="1">TLC</option>
									<option value="2">Others</option>
								</select>
								<div *ngIf="formControls.regulation_type.invalid && isSubmitting" class="help-block form-err text-danger">
									<small [hidden]="!formControls.regulation_type.errors?.required">
										*Regulation type is required.
									</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Passport #</label>
							<div class="col-sm-8">
								<input formControlName="passport_number" type="text" class="form-control">
								<div *ngIf="formControls.passport_number.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.passport_number.errors?.required">*Passport number is
										required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">DMV License #</label>
							<div class="col-sm-8">
								<input formControlName="dmv_num" type="text" class="form-control">
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Profile Pic</label>
							<div class="col-sm-8">
								<input class="d-none" type="file" accept="image/x-png,image/jpeg,image/jpg" (change)="onFileChanged($event)" #fileInput accept="image/x-png,image/jpeg,image/jpg">
								<div class="user-avatar-sm user-avatar-edit">
									<!-- <img (click)="fileInput.click()" class="edit-icon pointer" src="assets/img/icons/edit-white.png" alt="edit"> -->
									<div (click)="fileInput.click()" class="overlay"></div>
									<img *ngIf="!formControls.image.value" class="profile-pic" src="assets/img/attach2.jpg" alt="user-avatal">
									<img *ngIf="formControls.image.value" class="profile-pic" [src]="formControls.image.value"
										alt="user-avatal">
								</div>
								<div *ngIf="formControls.image.invalid && isSubmitting" class="help-block form-err text-danger">
									<small [hidden]="!formControls.image.errors?.required">*Profile Pic is required.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions  class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-4 offset-md-8">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button (click)="submitHandler()" mat-button class="btn btn-primary button-dialog ml-2">Save</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>