import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ConfirmModalComponent>) { }

	ngOnInit(): void {
		if (this.data.modalParams.isOutsideClisckdisabled) {
			this.dialogRef.disableClose = true;
		}
	}

	downloadSampleCSV(): void {
		const data = [
			{
				first_name: "first name here",
				last_name: "last name here",
				email: "email here",
				mobile: "+1-9084446652",
			},
			{
				first_name: "",
				last_name: "",
				email: "",
				mobile: "+1-",
			},
		];
		new ngxCsv(data, 'contact_to_csv', {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: false,
			title: '',
			useBom: true,
			noDownload: false,
			headers: ["first_name", "last_name", "email", "mobile"]
		});
	}
}