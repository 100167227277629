import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { Address } from 'ngx-google-places-autocomplete/objects/address';

import { ApiRoutes } from '../../routes';
import { DataService } from '../../services';
import { pad, triangleBall } from '../../utility';
import { MapsAPILoader } from '@agm/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
    selector: 'app-edit-trip-time',
    templateUrl: './edit-trip-time.component.html',
    styleUrls: ['./edit-trip-time.component.scss']
})

export class EditTripTimeComponent implements OnInit {
    info: any;
    mins: number[] = [];
    options = {
        componentRestrictions: {
            country: []
        }
    };
    isApiLoaded: boolean = false;
    @ViewChild("placesRef") placesRef: GooglePlaceDirective;

    constructor(private mapsAPILoader: MapsAPILoader, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditTripTimeComponent>, private ds: DataService, private spinner: NgxSpinnerService) {

    }

    ngOnInit(): void {
        console.log(this.data)
        if (this.data.flag == 'Time') {
            for (let i = 0; i < 60; i++) {
                this.mins.push(pad(i));
            }
            this.data.hours = this.data.pickup_time.split(":")[0] + ' ' + this.data.pickup_time.split(" ")[1];
            this.data.mins = this.data.pickup_time.split(":")[1].split(" ")[0];
        } else if (this.data.flag == 'Location') {
            this.mapsAPILoader.load().then(() => {
                this.isApiLoaded = true;
            });
        }
    }

    save(): void {
        this.spinner.show(undefined, triangleBall);
        if (this.data.flag == 'Time') {
            const payload: any = {
                will_call: this.data.will_call,
                pickup_time: this.data.hours.split(" ")[0] + ':' + this.data.mins + ' ' + this.data.hours.split(" ")[1],
                mas_trip_id: this.data.id
            };
            this.updateTimeLocation(payload);
        } else if (this.data.flag == 'Location') {
            const payload: any = {
                pickup_location: this.data.pickup_location,
                pickup_latitude: +this.data.pickup_latitude,
                pickup_longitude: +this.data.pickup_longitude,
                dropoff_location: this.data.dropoff_location,
                dropoff_latitude: +this.data.dropoff_latitude,
                dropoff_longitude: +this.data.dropoff_longitude,
                mas_trip_id: this.data.id
            }
            this.updateTimeLocation(payload);
        }
    }

    private updateTimeLocation(payload: any): void {
        this.ds.post(ApiRoutes.unqueueTrip, payload).subscribe(res => {
            if (res.flag == 1329) {
                this.dialogRef.close(true);
            }
            this.spinner.hide();
        }, err => this.spinner.hide());
    }

    handleAddressChange(address: Address, flag: string): void {
        if (flag === 'pick') {
            this.data.pickup_latitude = address.geometry.location.lat();
            this.data.pickup_latitude = address.geometry.location.lng();
        } else {
            this.data.dropoff_latitude = address.geometry.location.lat();
            this.data.dropoff_longitude = address.geometry.location.lng();
        }
    }
}