export function pointSystemColumn(key: string, cellTemplate: any = {}) {
  if (key === 'Driver') {
    const driverColumn = [
      {
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 150,
				maxWidth: 150,
				cellClass: 'd-flex align-items-center cell-class',
			},
      {
        prop: 'driver_id',
        name: 'Driver ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 120,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'driver_name',
        name: 'Driver Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        
      },
      {
        prop: 'referral_code',
        name: 'Referral Code',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'state_name',
        name: 'State',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_points',
        name: 'Total Point',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.isessential,
      },
      {
        prop: 'total_referral',
        name: 'Total Referral',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_trip_points',
        name: 'Total Trip Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_hour_points',
        name: 'Total Hour Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_hours',
        name: 'Total Hours',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_favorite',
        name: 'Total Favorite',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_referral_points',
        name: 'Total Referral Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_referral_points',
        name: 'Total Referral Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.farestructure,
      },
      {
        prop: 'total_favorite_points',
        name: 'Total Favorite Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.farestructure,
      },
    ];
    return driverColumn;
  } else if (key === 'Rider') {
    const ridersColumn = [
      {
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				cellTemplate: cellTemplate.action,
				width: 150,
				maxWidth: 150,
				cellClass: 'd-flex align-items-center cell-class',
			},
      {
        prop: 'user_id',
        name: 'User ID',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 120,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'user_name',
        name: 'User Name',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'referral_code',
        name: 'Referral Code',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 180,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'state_name',
        name: 'State',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_points',
        name: 'Total Point',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        cellClass: 'd-flex align-items-center cell-class',
        cellTemplate: cellTemplate.isessential,
      },
      //{
        //prop: 'total_hours',
        //name: 'Total Hours',
        //sortable: true,
        //draggable: false,
        //canAutoResize: false,
        //cellClass: 'd-flex align-items-center cell-class',
      //},
     // {
       // prop: 'total_favorite',
       // name: 'Total Favorite',
       // sortable: true,
       // draggable: false,
       // canAutoResize: false,
       // cellClass: 'd-flex align-items-center cell-class',
      //},
      {
        prop: 'total_referral',
        name: 'Total Referral',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      {
        prop: 'total_trip_points',
        name: 'Total Trip Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      //{
        //prop: 'total_hour_points',
        //name: 'Total Hour Points',
        //sortable: true,
        //draggable: false,
        //canAutoResize: false,
        //width: 220,
        //cellClass: 'd-flex align-items-center cell-class',
      //},
      {
        prop: 'total_referral_points',
        name: 'Total Referral Points',
        sortable: true,
        draggable: false,
        canAutoResize: false,
        width: 220,
        cellClass: 'd-flex align-items-center cell-class',
      },
      //{
        //prop: 'total_favorite_points',
        //name: 'Total Favorite Points',
        //sortable: true,
        //draggable: false,
        //canAutoResize: false,
        //width: 220,
        //cellClass: 'd-flex align-items-center cell-class',
      //},
    ];
    return ridersColumn;
  }
}
