export class APP_ROUTES {
  public static root: string = '';
  public static slash: string = '/';
  public static previous: string = '../';
  public static new: string = 'new';

  //* Content layout */
  public static login: string = 'login';
  public static signup: string = 'signup';
  public static forgotPassword: string = 'forgot-password';

  //* Full Layoyt */
  public static dashboard: string = 'dashboard';
  public static note: string = 'note';
  public static heatMap: string = 'heat-map';
  public static campaign: string = 'campaign';
  public static createCampaign: string = 'create-campaign';
  public static contacts: string = 'contacts';
  public static users: string = 'users';
  public static passengers: string = 'passengers';
  public static clients: string = 'clients';
  public static rideBroker: string = 'ride-brokers';
  public static rideBrokersSetting: string = 'ride-brokers-settings';
  public static affiliates: string = 'affiliates';
  public static corporates: string = 'corporates';
  public static drivers: string = 'drivers';
  public static inactiveUsers: string = 'inactive-users';
  public static demoRequest: string = 'demo-request';

  public static driverProfile: string = 'driver-profile';

  public static rides: string = 'rides';
  public static v2Trip: string = 'v2Trip';
  public static retail: string = 'retail';
  public static corporate: string = 'corporate';

  public static payments: string = 'payments';
  public static customPayouts: string = 'custom-payouts';
  public static trips: string = 'trips';
  public static customCharge: string = 'custom-charge';
  public static disputes: string = 'disputes';
  public static revenue: string = 'revenue';
  public static promosAndOffers: string = 'promos-and-offers';
  public static promoCodes: string = 'promo-codes';
  public static offers: string = 'offers';
  public static analytics2: string = 'analytics2';
  public static analytics: string = 'analytics';
  public static reports: string = 'reports';
  public static userReports: string = 'reports/user-reports';
  public static stripeReports: string = 'reports/stripe-reports';
  public static tlcReport: string = 'reports/tlc-reports';
  public static insuranceReports: string = 'reports/insurance-reports';
  public static congestionReports: string = 'reports/congestion-nyc-reports';
  public static generalReport: string = 'reports/general-reports';
  public static pastRides: string = 'reports/tlc-rides-report';
  // public static assignedTripsReport: string = 'reports/assigned-trips-reports';
  public static paymentPayout: string = 'reports/payment-payout';
  public static wavTrips: string = 'reports/wav-trips';
  public static feedback: string = 'reports/feedback';

  public static tickets: string = 'tickets';
  public static pointSystem: string = 'point-system';
  // public static pointSystems: string = 'point-systems';

  public static auditLogs: string = 'audit-logs';
  public static settings: string = 'settings';
  public static carTypes: string = 'car-types';
  public static affiliateTypes: string = 'affiliate-types';
  public static documentTypes: string = 'document-types';
  public static dashboardUsers: string = 'dashboard-users';
  public static liveMapUsers: string = 'live-map-users';
  public static adminUsers: string = 'admin-users';
  public static pointSetting: string = 'point-setting';
  public static masSetting: string = 'mas-setting';
  public static paymentSetting: string = 'payment-setting';
  public static driverAppSetting: string = 'driver-app-setting';
  // public static viewArea: string = 'area-list';
  public static countryList: string = 'area-list';
  public static stateList: string = 'state-list';
  public static viewAreaClient: string = 'view-area';
  public static area: string = 'area';
  public static carDetail: string = 'car-detail';
  public static addArea: string = 'add-area';
  public static virtualOrientation: string = 'virtual-orientation';
  public static profile: string = 'profile';
  public static carRates: string = 'car-rates';
  public static optionSettings: string = 'option-settings';
  public static businessCenter: string = 'business-center';
  public static businessCenterGlobal: string = 'business-center-global';
  public static businessCenterQudos: string = 'business-center-qudos';
}
