<section class="custom-mat-dialog-wrapper">

    <h2 class="mb-0 dialog-header-text dialog-header">{{ data?.action}}</h2>

    <hr class="cutom-divider m-0">
    <mat-dialog-content class="dialog-body">
        <div>
            <!-- Content -->
            <ngx-extended-pdf-viewer [src]="data?.url" useBrowserLocale="true" height="60vh" [showSidebarButton]="true"
                [showHandToolButton]="false" [showRotateButton]="false" [showOpenFileButton]="false"
                [showDownloadButton]="false" [showPrintButton]="false" [showBookmarkButton]="false" [showToolbar]="true"
                [showSecondaryToolbarButton]="false" [zoom]="'page-actual'">
            </ngx-extended-pdf-viewer>
        </div>
    </mat-dialog-content>
    <hr class="cutom-divider m-0">
    <mat-dialog-actions align="end" class="dialog-footer">
        <button *ngIf="data?.cancelButtonText" mat-button mat-dialog-close
            class="btn button-dialog-cancel button-dialog mr-4">
            {{data?.cancelButtonText}}
        </button>
    </mat-dialog-actions>
</section>