import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

import { ProfileDd } from '../../../interfaces';
import { profileDd } from './navbar.config';
import { ConfigService, LayoutService, DataService, GlobalService } from '../../services';
import { AuthService } from '../../auth';
import { ApiRoutes, APP_ROUTES } from 'app/shared/routes';
import { LocalStorage } from '../../../storage';
import { LogoutConfirmComponent } from './logout-confirm/logout-confirm.component';
import { ConfirmModalComponent } from "../../../shared/components";
import { environment } from '../../../../environments/environment';

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	currentLang = "en";
	selectedLanguageText = "English";
	selectedLanguageFlag = "./assets/img/flags/us.png";
	toggleClass = "ft-maximize";
	corpUrl = environment.corpUrl;
	livemapUrl = environment.livemapUrl;
	placement = "bottom-right";
	logoUrl = 'assets/img/logo.png';
	menuPosition = 'Side';
	isSmallScreen = false;
	protected innerWidth: any;
	searchOpenClass = "";
	transparentBGClass = "";
	hideSidebar: boolean = true;
	public isCollapsed = true;
	layoutSub: Subscription;
	configSub: Subscription;
	profileDd: ProfileDd[] = profileDd;
	subscription: Subscription;
	route: string;
	show: boolean;
	selectesState: any;
	selectedCountry: any;
	countryList: any[] = [];
	stateList: any[] = [];
	isGlobalSearchSuggestionVisible: boolean = false;
	globalSearchPayload: any = {
		searchCountry: 1,
		searchFlag: 0,
		searchState: 0,
		searchString: ""
	}
	globalSearcghResult: any;
	globalSearcghResultObj: any;

	@ViewChild('search') searchElement: ElementRef;
	@ViewChildren('searchResults') searchResults: QueryList<any>;

	@Output()
	toggleHideSidebar = new EventEmitter<Object>();

	@Output()
	seachTextEmpty = new EventEmitter<boolean>();

	public config: any = {};

	countryStateObj: any = {
		countryId: null,
		stateId: null
	}
	isCountryStateSearch: boolean;
	currentUser: any;
	subscriptions: Subscription = new Subscription();
	userStatus: any;
	searchText: string = '';

	constructor(public auth: AuthService, private gs: GlobalService,
		private layoutService: LayoutService, private ls: LocalStorage,
		private router: Router, public ds: DataService, private dialog: MatDialog,
		private configService: ConfigService, private cdr: ChangeDetectorRef) {
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
		this.config = this.configService.templateConf;
		this.innerWidth = window.innerWidth;
		this.layoutSub = layoutService.toggleSidebar$.subscribe(isShow => {
			this.hideSidebar = !isShow;
		});

		if (this.ls.getItem('qudos-admin-userStatus')) {
			this.userStatus = this.ls.getItem('qudos-admin-userStatus');
			this.cdr.markForCheck();
		}

		const userStatus = this.ds.userStatus.subscribe((data) => {
			this.userStatus = data;
			this.cdr.markForCheck();
		});
		this.subscriptions.add(userStatus);

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.stateCountrySearchOption(event);
				this.clearSeachOnRouteChange();
				// this.selectedCountry = this.countryList.find(i => i.country_id === 1);
				// this.selectesState = {};
			}
		});

		const navbarSearchClearEvent = this.gs.navbarSearchClearEvent$.subscribe(res => {
			if (res) {
				this.clearSeachOnRouteChange();
			}
		});
		this.subscriptions.add(navbarSearchClearEvent);

		const clientDetailObs = this.gs.clientDetailEvent$.subscribe(data => {
			this.currentUser = this.ls.getItem('qudos-admin-currentUser');
			this.currentUser = { ...this.currentUser, ...data };
		});
		this.subscriptions.add(clientDetailObs);
	}

	ngOnInit() {
		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		}
		else {
			this.isSmallScreen = false;
		}
		this.show = this.ds.show;
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.show = this.ds.show;
				this.searchText = '';
				if (!event.url.includes(APP_ROUTES.clients) && this.gs.automateClientTab.tabIndex) {
					this.gs.automateTabReset('client');
				} else if (!event.url.includes(APP_ROUTES.passengers) && this.gs.automatepassengerTab.tabIndex) {
					this.gs.automateTabReset('passenger');
				} else if (!event.url.includes(APP_ROUTES.affiliates) && this.gs.automateAffiliateTab.tabIndex) {
					this.gs.automateTabReset('affiliate');
				} else if (!event.url.includes(APP_ROUTES.corporates) && this.gs.automateCorporateTab.tabIndex) {
					this.gs.automateTabReset('corporate');
				} else if (!event.url.includes(APP_ROUTES.drivers) && this.gs.automateDriverTab.tabIndex) {
					this.gs.automateTabReset('driver');
				} else if (!event.url.includes(APP_ROUTES.offers) && this.gs.automateOfferTab.tabIndex) {
					this.gs.automateTabReset('offer');
				} else if (!event.url.includes(APP_ROUTES.revenue) && (this.gs.automateRevenueTab.tabIndex || this.gs.automateRevenueTab.parentTabIndex)) {
					this.gs.automateTabReset('revenue');
				} else if (!event.url.includes(APP_ROUTES.virtualOrientation) && (this.gs.automateVOTab.tabIndex || this.gs.automateVOTab.parentTabIndex)) {
					this.gs.automateTabReset('VO');
				} else if (!event.url.includes(APP_ROUTES.retail) && (this.gs.automateRetailRidesTab.tabIndex || this.gs.automateRetailRidesTab.parentTabIndex)) {
					this.gs.automateTabReset('retailRide');
				} else if (!event.url.includes(APP_ROUTES.corporate) && (this.gs.automateCorporateRidesTab.tabIndex || this.gs.automateCorporateRidesTab.parentTabIndex)) {
					this.gs.automateTabReset('corporateRide');
				} else if (!event.url.includes(APP_ROUTES.feedback) && this.gs.automatefeedbackReport.tabIndex) {
					this.gs.automateTabReset('feedback');
				}
			}
		});
		this.getCountries();
	}

	private stateCountrySearchOption(e): void {
		this.isCountryStateSearch = true;
	}

	ngAfterViewInit() {
		this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
			if (templateConf) {
				this.config = templateConf;
			}
			this.loadLayout();
			this.cdr.markForCheck();
		});

		setTimeout(() => {
			const searchEvt = fromEvent(this.searchElement.nativeElement, 'keyup').pipe(debounceTime(500), distinctUntilChanged()).subscribe((data: any) => {
				this.globalSearch(this.searchElement.nativeElement.value);
			});
			this.subscriptions.add(searchEvt);
		});
	}

	ngOnDestroy() {
		if (this.layoutSub) {
			this.layoutSub.unsubscribe();
		}
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
		this.subscriptions.unsubscribe();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = event.target.innerWidth;
		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		}
		else {
			this.isSmallScreen = false;
		}
	}

	private clearSeachOnRouteChange(): void {
		if (this.searchElement) {
			this.searchElement.nativeElement.value = '';
		}
		this.globalSearcghResult = [];
		this.globalSearcghResultObj = null;
	}

	loadLayout() {

		if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
			this.menuPosition = this.config.layout.menuPosition;
		}

		if (this.config.layout.variant === "Light") {
			this.logoUrl = 'assets/img/logo-dark.png';
		}
		else {
			this.logoUrl = 'assets/img/logo.png';
		}

		if (this.config.layout.variant === "Transparent") {
			this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
		}
		else {
			this.transparentBGClass = "";
		}

	}

	ToggleClass() {
		if (this.toggleClass === "ft-maximize") {
			this.toggleClass = "ft-minimize";
		} else {
			this.toggleClass = "ft-maximize";
		}
	}

	onSearchFocus(e): void {
		this.isGlobalSearchSuggestionVisible = true;
	}

	onSearchBlur(e): void {
		this.isGlobalSearchSuggestionVisible = false;
	}

	globalSearch(value: string): void {
		this.globalSearchPayload = {
			searchCountry: 1,
			searchState: 0,
			searchFlag: value ? 1 : 0,
			searchString: value
		}
		if (!this.globalSearchPayload.searchFlag) {
			// this.onSearchSelect('');
			const obj = {
				action: 'nav-search',
				value: null,
			};
			this.ds.navbarSearch(obj);
			this.isGlobalSearchSuggestionVisible = false;
			this.globalSearcghResult = [];
		} else {
			const url: string = ApiRoutes.userHome
			this.ds.post(url, this.globalSearchPayload).subscribe(res => {
				this.currentUser?.admin_type !== 4
				if (this.currentUser.admin_type === 4) {
					res.clients = [];
					res.clients_count = 0;
				}
				const data = [...res.affiliates, ...res.clients, ...res.corporates, ...res.drivers];
				const set1 = [...new Set(data.map(item => item.first_name + ' ' + item.last_name))];
				const set2 = [...new Set(res.users.map(i => i.user_name))];
				const concat = [...set1, ...set2];
				this.globalSearcghResult = [...new Set(concat.map(j => j))];
				this.globalSearcghResultObj = res;
				this.cdr.markForCheck();
			});
		}
	}

	onSearchSelect(item: string): void {
		const obj = {
			action: 'nav-search',
			value: this.globalSearcghResultObj,
		};
		this.ds.navbarSearch(obj);
		this.isGlobalSearchSuggestionVisible = false;
		this.globalSearcghResult = [];
		this.globalSearcghResultObj = null;
	}

	private getCountries(): void {
		const url: string = ApiRoutes.getCountryList;
		this.ds.get(url).subscribe(res => {
			this.countryList = res.countries;
			this.selectedCountry = this.countryList.find(i => i.country_id === 1);
			this.getStates();
		});
	}

	private getStates(): void {
		this.selectesState = {};
		const url: string = ApiRoutes.getStateList;
		const obj = {
			country_id: this.selectedCountry.country_id,
			state_id: 0
		}
		this.ds.post(url, obj).subscribe(res => {
			res.states.unshift({ state_id: null, state_name: "All" })
			this.stateList = res.states;
			this.cdr.markForCheck();
		});
	}

	onCountrySelect(): void {
		this.countryStateObj = {
			state_id: this.selectesState.state_id,
			country_id: this.selectedCountry.country_id
		}
		this.gs.countryStateObj = this.countryStateObj;
		this.ds.countryStateSearch(this.countryStateObj);
	}

	onStateSelect(): void {
		this.countryStateObj = {
			state_id: this.selectesState.state_id,
			country_id: this.selectedCountry.country_id
		}
		this.gs.countryStateObj = this.countryStateObj;
		this.ds.countryStateSearch(this.countryStateObj);
	}

	removeActiveClass() {
		if (this.searchResults && this.searchResults.length > 0) {
			this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
		}
	}

	redirectTo(value) {
		this.router.navigate([value]);
		this.seachTextEmpty.emit(true);
	}

	toggleNotificationSidebar() {
		this.layoutService.toggleNotificationSidebar(true);
	}

	toggleSidebar() {
		this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
	}

	goto(path): void {
		this.router.navigate([path]);
	}

	externalNavigation(url: string, flag: number): void {
		const type: string = !flag ? 'concierge' : 'live tracking'
		let modalParams: any = {
			action: !flag ? 'Concierge Login Verification' : 'Live Tracking Login Verification',
			for: '',
			text: "To verify your account, you will be directed to the " + type + " login page to enter your login information. Do you want to proceed?",
			confirmButtonText: 'Proceed',
			cancelButtonText: 'Cancel'
		};
		const data = { modalParams: modalParams };
		const dialogRef = this.dialog.open(ConfirmModalComponent, { data, width: '450px' });
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				window.open(url, '_blank');
			}
		});
	}

	logout(): void {
		const dialogRef = this.dialog.open(LogoutConfirmComponent, { width: '430px' });
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.gs.countryStateObj = null;
				this.auth.logout();
			}
		});
	}

	/** Clear Search */
	clearSearch() {
		this.searchText = '';
	}
}