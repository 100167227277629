import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { fromEvent } from "rxjs";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { debounceTime } from "rxjs/internal/operators/debounceTime";

import { ROUTES, ROUTES2 } from "./vertical-menu-routes.config";
import { AuthService } from "../../auth";
import { ConfigService, LayoutService, GlobalService } from "../../services";
import { ApiRoutes, APP_ROUTES } from "../../routes";
import { DataService } from '../../services';
import { LocalStorage } from '../../../storage';
import { NewAccountModalComponent } from '../new-account-modal/new-account-modal.component';

@Component({
	selector: "app-sidebar",
	templateUrl: "./vertical-menu.component.html",
	styleUrls: ["./vertical-menu.component.scss"],
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("toggleIcon") toggleIcon: ElementRef;
	@ViewChild('input') input: ElementRef;
	public menuItems: any[];
	routes = APP_ROUTES;
	level: number = 0;
	logoUrl = "assets/img/logo.png";
	public config: any = {};
	protected innerWidth: any;
	layoutSub: Subscription;
	configSub: Subscription;
	perfectScrollbarEnable = true;
	collapseSidebar = false;
	resizeTimeout;
	isVisibleFull = true;
	userStatus: any;
	currentUser: any;
	clientDetail: any;
	clientBusiness: any[] = [];
	selectedBusiness: any;
	selectedClient: any;
	isBusinessDDVisible: boolean = false;
	isBusinessDDListVisible: boolean = false;
	clients: any[] = [];
	defaultBusiness: any;

	constructor(public auth: AuthService, private router: Router, private layoutService: LayoutService, private configService: ConfigService, private cdr: ChangeDetectorRef, private deviceService: DeviceDetectorService, private ds: DataService, private ls: LocalStorage, private gs: GlobalService, private dialog: MatDialog) {
		this.config = this.configService.templateConf;
		this.innerWidth = window.innerWidth;
		this.isTouchDevice();
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
		if (this.currentUser.admin_type !== 4) {
			this.fetchClients();
		}
		this.getNewBusinessClient();
		this.getClientDetails();
	}

	ngOnInit(): void { }

	ngAfterViewInit() {
		this.configSub = this.configService.templateConf$.subscribe(
			(templateConf) => {
				if (templateConf) {
					this.config = templateConf;
				}
				// this.loadLayout();
				this.cdr.markForCheck();
			}
		);

		this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
			(collapse) => {
				if (this.config.layout.menuPosition === "Side") {
					this.collapseSidebar = collapse;
				}
			}
		);
	}

	@HostListener("window:resize", ["$event"])
	onWindowResize(event) {
		if (this.resizeTimeout) {
			clearTimeout(this.resizeTimeout);
		}
		this.resizeTimeout = setTimeout(
			(() => {
				this.innerWidth = event.target.innerWidth;
				this.loadLayout();
			}).bind(this),
			500
		);
	}

	loadSidebar(data: any): void {
		if (!data?.docs || !data?.email_verified || !data?.is_approved || !data?.docs_verified) {
			this.menuItems = ROUTES.slice(0, 1);
		} else {
			if (this.currentUser.admin_type != 0) {
				if (this.currentUser.regulation_type == 2) {
					this.menuItems = ROUTES2;
				} else {
					this.menuItems = ROUTES;
				}
			} else {
				this.menuItems = ROUTES;
			}
		}
		this.cdr.markForCheck();
	}

	loadLayout() {
		if (this.currentUser.admin_type === 4) {
			const userStatus = this.ls.getItem('qudos-admin-userStatus');
			if (userStatus) {
				this.loadSidebar(userStatus);
			} else {
				this.ds.userStatus.subscribe((data) => {
					this.userStatus = data;
					this.loadSidebar(data);
				});
			}
		} else if ((this.currentUser.admin_type === 2 && this.currentUser.parent_type === 4) || (this.currentUser.admin_type == 3 && this.currentUser.parent_type == 4)) {
			if (this.currentUser.regulation_type == 2) {
				this.menuItems = ROUTES2;
			} else {
				this.menuItems = ROUTES;
			}
		} else {
			this.menuItems = ROUTES;
		}
		if (this.config.layout.sidebar.backgroundColor === "white") {
			this.logoUrl = "assets/img/logo-dark.png";
		} else {
			this.logoUrl = "assets/img/logo.png";
		}

		if (this.config.layout.sidebar.collapsed) {
			this.collapseSidebar = true;
		} else {
			this.collapseSidebar = false;
		}
	}

	verticalMenuItemEvt() {
		this.gs.navbarSearchClear(true);
	}

	toggleSidebar() {
		this.isVisibleFull = !this.isVisibleFull;
		let conf = this.config;
		conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
		this.configService.applyTemplateConfigChange({ layout: conf.layout });

		setTimeout(() => {
			this.fireRefreshEventOnWindow();
		}, 300);
	}

	fireRefreshEventOnWindow = function () {
		const evt = document.createEvent("HTMLEvents");
		evt.initEvent("resize", true, false);
		window.dispatchEvent(evt);
	};

	CloseSidebar() {
		this.layoutService.toggleSidebarSmallScreen(false);
	}

	isTouchDevice() {
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();

		if (isMobile || isTablet) {
			this.perfectScrollbarEnable = false;
		} else {
			this.perfectScrollbarEnable = true;
		}
	}

	ngOnDestroy() {
		if (this.layoutSub) {
			this.layoutSub.unsubscribe();
		}
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}

	createNewAccount(): void {
		this.isBusinessDDListVisible = false;
		const data: any = {
			currentUser: { ...this.currentUser, ...this.clientDetail },
		}
		const dialogRef = this.dialog.open(NewAccountModalComponent, { data, width: '1040px' });
		dialogRef.afterClosed().subscribe(result => {
			if (result.status) {
				this.getNewBusinessClient(result.data.modalParams.data);
			}
		});
	}

	private getClientDetails(): void {
		const url: string = ApiRoutes.getClientDetail;
		const pagePayload: any = {
			limit: 0,
			offset: 0,
			requestType: 0,
			searchFlag: 0,
			searchString: "",
			sort_by: "session_id",
			sort_order: "DESC",
			admin_id: this.gs.adminId == 0 ? 1 : this.gs.adminId
		};
		if (this.currentUser.admin_type == 2 && this.currentUser.parent_type == 1) {
			pagePayload.useParentIdAsAdminId = "false";
		}
		this.ds.paginatedPost(url, pagePayload).subscribe(res => {
			if (res.flag === 8012) {
				this.clientDetail = res.client_detail[0];
				this.currentUser.regulation_type = this.clientDetail.regulation_type;
				this.ls.setItem('qudos-admin-currentUser', JSON.stringify(this.currentUser));
				this.gs.clientDetail(this.clientDetail);
				this.loadLayout();
				this.cdr.markForCheck();
			}
		});
	}

	getNewBusinessClient(data: any = null): void {
		const url: string = ApiRoutes.getNewBusinessClient;
		this.ds.post(url, {}).subscribe(res => {
			this.isBusinessDDVisible = true;
			if (res.businessInfo.length) {
				if (this.currentUser?.admin_type === 2 || this.currentUser?.admin_type === 3) {
					res.businessInfo.unshift(this.currentUser);
				}
				this.clientBusiness = res.businessInfo;

				this.defaultBusiness = this.clientBusiness.find(i => i.is_default)
			}
			if (data) {
				const newlyAddedBusiness = this.clientBusiness.find(i => i.business_name === data.business_name);
				this.switchBusiness(newlyAddedBusiness);
			} else {
				const currentSelectedBusiness = this.ls.getItem('qudos-admin-selectedBusiness');
				if (!currentSelectedBusiness) {
					this.selectedBusiness = this.clientBusiness.find(i => i.is_default);
				} else {
					this.selectedBusiness = currentSelectedBusiness;
				}
			}

			if (this.currentUser.admin_type == 1 || this.currentUser.admin_type == 2) {
				setTimeout(() => {
					fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(500), distinctUntilChanged()).subscribe((data: any) => {
						if (!this.input.nativeElement.value) {
							this.fetchClients(0, '');
						} else {
							this.fetchClients(1, this.input.nativeElement.value);
						}
					});
				});
			}
			this.cdr.markForCheck();
		});
	}

	private fetchClients(searchFlag = 0, searchString = ''): void {
		const url: string = ApiRoutes.fetchClients;
		const payload: any = {
			searchFlag: searchFlag,
			searchString: searchString
		};
		this.ds.post(url, payload).subscribe(res => {
			this.clients = res.clients.filter(i => i.is_approved);
			const selectedClient = this.ls.getItem('qudos-admin-selectedClient');
			if (selectedClient) {
				this.selectedClient = selectedClient;
			} else {
				this.selectedClient = '';
			}
			this.cdr.markForCheck();
		});
	}

	switchBusiness(item: any): void {
		this.isBusinessDDListVisible = false;
		const currentBusiness = this.ls.getItem('qudos-admin-selectedBusiness');
		if (currentBusiness) {
			if (currentBusiness.admin_id === item.admin_id) {
				return;
			}
		}
		if (item.is_default) {
			this.ls.removeItem('qudos-admin-selectedBusiness')
		} else {
			this.ls.setItem('qudos-admin-selectedBusiness', JSON.stringify(item));
		}
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
		this.currentUser.admin_id = item.admin_id;
		this.ls.setItem('qudos-admin-currentUser', JSON.stringify(this.currentUser));
		this.ls.removeItem('qudos-admin-userStatus');
		this.router.navigate([APP_ROUTES.dashboard]).then(() => {
			window.location.reload();
		});
		this.selectedBusiness = item;
	}

	clientSelect(item): void {
		// debugger
		this.selectedClient = item;
		if (this.selectedClient) {
			this.ls.setItem('qudos-admin-selectedClient', JSON.stringify(item));
		} else {
			this.ls.removeItem('qudos-admin-selectedClient');
		}
		this.router.navigate([APP_ROUTES.dashboard]).then(() => {
			window.location.reload();
		});
		this.isBusinessDDListVisible = false;
	}

	onClickedOutside(e: Event) {
		if (this.isBusinessDDListVisible) {
			this.isBusinessDDListVisible = false;
		}
	}

	focusInputField() {
		setTimeout(() => {
			// Check if the input field is available before focusing on it
			if (this.input && this.input.nativeElement) {
				this.input.nativeElement.focus();
			}
		}, 100);
	}

}