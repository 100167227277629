function nullable() {
	return { transform: (value) => value ? value : 'NA' };
}

export function ticketsColumn(key: string, cellTemplate: any = {}) {
	if (key === 'ticket') {
		const ticketColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'ticket_id',
				name: 'Ticket Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.ticket,
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class pointer',
				pipe: nullable(),
				cellTemplate: cellTemplate.sessionId
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class pointer',
				pipe: nullable(),
				cellTemplate: cellTemplate.driverName
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class pointer',
				pipe: nullable(),
				cellTemplate: cellTemplate.customerName
			},
			{
				prop: 'created_at',
				name: 'Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'type',
				name: 'Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class',
				cellTemplate: cellTemplate.offer,
			},
			{
				prop: 'status',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class pointer text-crop',
				cellTemplate: cellTemplate.status
			},
			{
				prop: 'description',
				name: 'Description',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class pointer text-crop',
				cellTemplate: cellTemplate.desc
			}
		];
		return ticketColumn;
	}
}
