<section class="custom-mat-dialog-wrapper" id="doc-view-modal">
	<div class="row align-items-center">
		<div class="col-md-10">
			<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">
				View Document
			</h2>
		</div>
		<div class="col-md-2">
			<a href="javascript:;" (click)="download(data.doc)">
				<img class="download-icon" src="assets/img/icons/save_altdownload.png" alt="download" width="20">
			</a>
			<!-- <div >download</div> -->
		</div>
		<!-- <div class="pz-zoom-control pz-zoom-control-position-right">
			<div class="pz-zoom-in" (click)="zoomIn()"></div>
			<div class="pz-zoom-out pz-disabled" (click)="zoomOut()"></div>
		</div> -->
	</div>
	<hr class="cutom-divider m-0" />
	<mat-dialog-content class="modal-body">
		<div class="row">
			<div class="col-sm-12 d-flex justify-content-center">
				<div class="rot-{{angle}}">
					<!-- <pinch-zoom #myPinch="pinchZoom" limit-zoom="1.25" limitPan="true" minScale="0.5" overflow="visible" [wheel]="false" [autoHeight]="true"
					[wheelZoomFactor]="0.2" [draggableImage]="false">
						<img src="{{data.doc.document_url}}" />
					</pinch-zoom> -->
					
					<app-zoom
						[thumbImage]="data.doc.document_url"
						[fullImage]="data.doc.document_url"
						[magnification]="1"
						[enableScrollZoom]="true"
						[enableLens]="true"
						[lensWidth]="450"
						[lensHeight]="450"
						[maxZoomRatio]="1.5"
						[circularLens]="false"
						[zoomMode]="'click'">
					</app-zoom>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0" />

	<mat-dialog-actions align="center" class="dialog-footer">
		<button mat-button (click)="rotateImage('right')" class="btn button-dialog-cancel button-dialog width-btn rotate-icon">
			<span class="ml-3"> Rotate Image</span>
		</button>
		<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog width-btn">
			Cancel
		</button>
		<button mat-button [disabled]="subBtn" class="btn btn-primary button-dialog ml-2 width-btn" (click)="submitHandler()">
			Save
		</button>
	</mat-dialog-actions>
</section>