<section class="custom-mat-dialog-wrapper">

	<h2 class="mb-0 dialog-header-text dialog-header">{{ data?.modalParams.action | titlecase}}</h2>

	<hr class="cutom-divider m-0">
	<mat-dialog-content class="dialog-body">
		<div>
			<p *ngIf="!data?.modalParams.text">
				Are you sure you want to {{ data?.modalParams.action }} <b>{{ data?.modalParams.for }}</b><span *ngIf="data?.modalParams?.mainTitle">
					{{ data?.modalParams?.mainTitle}}
				</span>?</p>
			<p *ngIf="data?.modalParams.text">
				{{ data?.modalParams.text }}
				<a *ngIf="data?.modalParams.downloadLink"  href="javascript:;" (click)="downloadSampleCSV()" class="sample-format">
					{{ data?.modalParams.downloadLink }}
				</a>
			</p>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="end" class="dialog-footer">
		<button *ngIf="data?.modalParams.cancelButtonText" mat-button mat-dialog-close
			class="btn button-dialog-cancel button-dialog">{{ data?.modalParams.cancelButtonText }}</button>
		<button mat-button class="btn btn-primary button-dialog ml-2" [mat-dialog-close]="true">
			{{ data?.modalParams.confirmButtonText }}
		</button>
	</mat-dialog-actions>
</section>