import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { ApiRoutes } from 'app/shared/routes';
import { LocalStorage } from '../../../../../storage';
import { DataService,ToastService } from '../../../../../shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { triangleBall, countryCodeHiphen } from 'app/shared/utility';
import { Subscription } from 'rxjs/Subscription';

@Component({
	selector: 'app-corporate-info-edit-modal',
	templateUrl: './corporate-info-edit-modal.component.html',
	styleUrls: ['./corporate-info-edit-modal.component.scss'],
})
export class CorporateInfoEditModalComponent implements OnInit {
	form: FormGroup;
	isSubmitting: boolean = false;
	subscription: Subscription = new Subscription();
	selectedDoc: any;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

	constructor(private toastr: ToastService,private ls: LocalStorage, private ds: DataService, private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<CorporateInfoEditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get formControls() {
		return this.form.controls;
	}

	get dateString(): string {
		let date: any = new Date(this.data.data.date_registered);
		date = date.toDateString().replace(/^\S+\s/, '');
		return date;
	}

	/**
	 * Description: Form initialisation
	 */
	initForm(): void {
		this.form = new FormGroup({
			first_name: new FormControl(this.data.data.first_name, [Validators.required]),
			last_name: new FormControl(this.data.data.last_name, [Validators.required]),
			email: new FormControl(this.data.data.email, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
			mobile: new FormControl(this.data.data.mobile, [Validators.required]),
			zipcode: new FormControl(this.data.data.zipcode, [Validators.required]),
			date_registered: new FormControl({ value: this.dateString, disabled: true }),
			ein_number: new FormControl(this.data.data.ein_number),
			business_permit: new FormControl(this.data.data.business_permit),
			doc_file: new FormControl(this.data.data.image, [Validators.required]),
			corporate_name: new FormControl(this.data.actionFrom === 'list' ? this.data.data.corporate_name : this.data.data.business_name, [Validators.required]),
		}, { updateOn: "change" });
	}

	/**
	 * Description: file change event
	 */
	onFileChanged(event): void {
		if (event.target.files && event.target.files[0]) {
			// this.selectedDocName = event.target.files[0].name;
			this.selectedDoc = event.target.files[0];
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]); // read file as data url
			reader.onload = (e) => {
				this.form.get('doc_file').setValue(e.target.result);
			};
		}
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.spinner.show(undefined, triangleBall);
		const url: string = ApiRoutes.editCorporate;

		const formdata = new FormData();
		formdata.append('access_token', this.ls.getItem("qudos-admin-token"))
		formdata.append('corporate_id', this.data.data.corporate_id);
		formdata.append('first_name', this.form.get('first_name').value);
		formdata.append('last_name', this.form.get('last_name').value);
		formdata.append('email', this.form.get('email').value);
		formdata.append('mobile', countryCodeHiphen(this.form.get('mobile').value));
		formdata.append('zipcode', this.form.get('zipcode').value);
		if (this.form.get('ein_number').value) {
			formdata.append('ein_number', this.form.get('ein_number').value);
		}
		if (this.form.get('business_permit').value) {
			formdata.append('business_permit', this.form.get('business_permit').value);
		}
		formdata.append('image', this.selectedDoc);
		formdata.append('image_flag', this.selectedDoc ? '1' : '0');
		formdata.append('corporate_name', this.form.get('corporate_name').value);
		this.ds.formDataRequest(url, formdata).subscribe((res) => {
			if (res.message === 'Corporate data updated successfully.') {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, (err) => this.spinner.hide());
	}
}
