import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { map } from 'rxjs/operators/map';
import { Observable } from 'rxjs/Observable';
import { saveAs } from '@progress/kendo-file-saver';
import * as XLSX from 'xlsx';
import { GlobalService } from '../global.service';

@Injectable({
	providedIn: 'root',
})

export class DataService {
	spinnerTxt: string = '';
	event: any = new EventEmitter<any>();
	tabChangeEvent: any = new EventEmitter<any>();
	public show: boolean;

	userStatus: any = new EventEmitter<any>();

	countryStateSearchEvent = new Subject<any>();
	countryStateSearchEvent$ = this.countryStateSearchEvent.asObservable();

	navbarSearchEvent = new Subject<any>();
	navbarSearchEvent$ = this.navbarSearchEvent.asObservable();

	constructor(private http: HttpClient, private gs: GlobalService) { }

	countryStateSearch(data: any) {
		this.countryStateSearchEvent.next(data);
	}

	navbarSearch(data: any) {
		this.navbarSearchEvent.next(data);
	}

	public forkJoin(urls: string[]): Observable<any[]> {
		let response: any = [];
		for (let i = 0; i < urls.length; i++) {
			response.push(this.http.get(urls[i]));
		}
		return forkJoin(response);
	}

	public get(url: string): Observable<any> {
		return this.http.get<any>(url).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public post(url: string, reqBody: any): Observable<any> {
		return this.http.post<any>(url, reqBody).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public formDataRequest(url: string, reqBody: any): Observable<any> {
		return this.http.post<any>(url, reqBody, { headers: { Anonymous: 'undefined' } }).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public paginatedPost(url: string, reqBody: any): Observable<any> {
		return this.http.post<any>(url, reqBody).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public delete(url: string) {
		return this.http.delete<any>(url).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public put(url: string, reqBody: any): Observable<any> {
		return this.http.put<any>(url, reqBody).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public patch(url: string, reqBody: any): Observable<any> {
		return this.http.patch<any>(url, reqBody).pipe(
			map((data) => {
				return data;
			})
		);
	}

	public upload(url: string, payload: any, attachListener: boolean = false) {
		return this.http.post<any>(url, payload, { headers: { Anonymous: 'undefined' }, reportProgress: true, observe: 'events' }).pipe(
			map((event) => {
				switch (event.type) {
					case HttpEventType.UploadProgress:
						const progress = Math.round(100 * event.loaded / event.total);
						return { status: 'progress', progress: progress };

					case HttpEventType.Response:
						if (attachListener) {
							this.gs.isVOPopUpClosedBeforeUploadComplete = false;
							this.gs.videoUploadComplete(attachListener);
						}
						return event.body;
					default:
						return `Unhandled event: ${event.type}`;
				}
			})
		);
	}

	// for making query params for api urls
	public formUrlParam(url: string, data: any): string {
		let queryString: string = '';
		for (const key in data) {
			if (data.hasOwnProperty(key) && data[key]) {
				if (!queryString) {
					queryString = `?${key}=${data[key]}`;
				} else {
					queryString += `&${key}=${data[key]}`;
				}
			}
		}
		return url + queryString;
	}

	public downloadDocumentBlob(downLoadUrl: string, fileName: string, extension: string, requestType: string, payload: any = {}) {
		var HTTPOptions = {
			headers: new HttpHeaders({ Accept: 'application/octet-stream' }),
			observe: 'response' as 'body', // to display the full response & as 'body' for type cast
			responseType: 'blob' as 'json',
		};
		if (requestType === 'get') {
			return this.http
				.get(downLoadUrl, HTTPOptions)
				.map((res: HttpResponse<any>) => {
					var blob = new Blob([res.body], { type: 'application/octet-stream' });
					saveAs(blob, fileName + extension);
					return res;
				});
		} else {
			return this.http
				.post(downLoadUrl, payload, HTTPOptions)
				.map((res: HttpResponse<any>) => {
					var blob = new Blob([res.body], { type: 'application/octet-stream' });
					saveAs(blob, fileName + extension);
					return res;
				});
		}
	}

	public downloadImageBlob(downLoadUrl: string, fileName: string, extension: string, payload: any = {}) {
		// image/x-png,image/jpeg,image/jpg
		var HTTPOptions = {
			headers: new HttpHeaders({ Accept: 'application/json' }),
			observe: 'response' as 'body', // to display the full response & as 'body' for type cast
			responseType: 'blob' as 'json',
		};
		return this.http
			.post(downLoadUrl, payload, HTTPOptions)
			.map((res: HttpResponse<any>) => {
				var blob = new Blob([res.body], { type: 'application/json' });
				saveAs(blob, fileName + extension);
				return res;
			});
	}

	// json to xlsx
	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		// const EXCEL_EXTENSION = '.xlsx';
		const EXCEL_TYPE = 'text/csv; charset=UTF-8';
		const EXCEL_EXTENSION = '.csv';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		// + '_export_' + new Date().getTime()
		saveAs(data, fileName + EXCEL_EXTENSION);
	}
	// json to xlsx ends
}