import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
	selector: 'app-demo-request',
	templateUrl: './demo-request.component.html',
	styleUrls: ['./demo-request.component.scss']
})
export class DemoRequestComponent implements OnInit {
	isSubmitting: boolean = false;
	form: FormGroup;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DemoRequestComponent>, private dialog: MatDialog) { }

	ngOnInit(): void {
		this.form = new FormGroup({ }, { updateOn: "change" });

		if (this.data.isDate) {
			this.form.addControl('date', new FormControl('', [Validators.required]));
		}
		if (this.data.isTime) {
			this.form.addControl('time', new FormControl('', [Validators.required]));
		}
	}

	get formControls() {
		return this.form.controls;
	}

	submitHandler(): void {
		if (this.form.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
	}
}
