import { Component, OnInit, Optional, Inject, Renderer2, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from 'moment';

import { DataService, ToastService } from "../../../shared/services";
import { LocalStorage } from "../../../storage";
import { triangleBall } from "../../utility";
import { ApiRoutes } from "app/shared/routes";

@Component({
	selector: 'app-doc-view-modal',
	templateUrl: './doc-view-modal.component.html',
	styleUrls: ['./doc-view-modal.component.scss']
})
export class DocViewModalComponent implements OnInit {
	isSubmitting: boolean = false;
	angle = 0;
	subBtn: boolean = true;
	@ViewChild('myPinch') myPinch;

	constructor(private ls: LocalStorage, private ds: DataService, private toastr: ToastService, private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<DocViewModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private renderer: Renderer2) {
		// this.today.setDate(this.today.getDate() + 7);
	}

	ngOnInit(): void {
		this.angle = this.data.doc.angle ? this.data.doc.angle : 0;
	}

	ngOnDestroy(): void { }

	// To Rotate
	rotateImage(direction) {
		this.angle += direction == "left" ? -90 : 90;
		if (this.angle >= 360) {
			this.angle = 0;
		}
		// this.renderer.setStyle(document.querySelector("pinch-zoom"), "transform", `rotate(${this.angle}deg)`);
		this.subBtn = false;
	}

	// Image zoom in/out
	zoomIn() {
		this.myPinch.zoomIn();
	}

	zoomOut() {
		this.myPinch.zoomOut();
	}

	get requestType(): number {
		if (this.data.type === 'client-doc-view') {
			return 1;
		} else if (this.data.type === 'driver-doc-view') {
			return 2;
		} else if (this.data.type === 'corporate-doc-view') {
			return 3;
		} else if (this.data.type === 'affiliate-doc-view') {
			return 4;
		}
	}

	download(doc: any): void {
		const payload: any = {
			doc_id: doc.doc_id,
			requestType: this.requestType,
			user_id: this.data.detail.driver_id || this.data.detail.corporate_id || this.data.detail.vendor_id || this.data.detail.admin_id
		};
		// const image = await fetch(doc.document_url)
		// const imageBlog = await image.blob()
		// const imageURL = URL.createObjectURL(imageBlog)

		// const link = document.createElement('a')
		// link.href = imageURL
		// const fileName = doc.document_url.substring(doc.document_url.lastIndexOf('/') + 1).split(".")[0];
		// link.download = fileName;
		// document.body.appendChild(link)
		// link.click()
		// document.body.removeChild(link)
		const fileName = doc.document_url.substring(doc.document_url.lastIndexOf('/') + 1).split(".")[0];
		const extension = '.' + doc.document_url.substring(doc.document_url.lastIndexOf('/') + 1).split(".")[1];
		this.ds.downloadImageBlob(ApiRoutes.downloadDocs, fileName, extension, payload).subscribe(res => {
		}, err => {
			console.log(err);
		});
	}

	submitHandler(): void {
		this.spinner.show(undefined, triangleBall);
		const url: string = this.data.endPoint;
		let payload: any;
		let method: string;
		if (this.data.type === 'affiliate-doc-view' || this.data.type === 'corporate-doc-view') {
			method = 'post'
			payload = {
				access_token: this.ls.getItem("qudos-admin-token"),
				admin_id: this.data.detail.client_id,
				doc_id: this.data.doc.doc_id,
				document_type_id: this.data.doc.document_type_id,
				expiry_date: this.data.doc.expiry_date,
				reminder_before: this.data.doc.reminder_before,
				angle: this.angle
			}
		} else {
			method = 'formDataRequest';
			payload = new FormData();
			payload.append("access_token", this.ls.getItem("qudos-admin-token"));
			payload.append("doc_id", this.data.doc.doc_id);
			payload.append("expiry_date", this.expiryDate);
			payload.append("reminder_before", this.data.doc.reminder_before);
			payload.append("doc_file", undefined);
			payload.append("angle", this.angle);
			payload.append("admin_id", this.data.detail.admin_id || this.data.detail.client_id);
			if (this.data.type == 'driver-doc-view') {
				payload.append("car_type", this.data.detail.car_type);
				payload.append("driver_car_id", this.data.doc.driver_car_id);
			}
		}
		this.ds[method](url, payload).subscribe((res) => {
			if (res.flag === 1308) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			}
			this.spinner.hide();
		}, (err) => this.spinner.hide());
	}

	get expiryDate(): string {
		if (this.data.doc.expiry_date == '0000-00-00 00:00:00') {
			return this.data.doc.expiry_date;
		}
		return moment(this.data.doc.expiry_date).format('YYYY-MM-DD')
	}
}