import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { ApiRoutes } from '../../../shared/routes';
import { DataService, ToastService } from '../../../shared/services';
import { triangleBall } from '../../../shared/utility';

@Component({
	selector: 'app-map-view',
	templateUrl: './map-view.component.html',
	styleUrls: ['./map-view.component.scss']
})

export class MapViewComponent implements OnInit {
	lat: Number;
	lng: Number;
	origin: any
	destination: any;
	@Input() data: any;
	@Output() emitter: EventEmitter<boolean> = new EventEmitter();

	constructor(private readonly dialog: MatDialog, public activeModal: NgbActiveModal, private readonly ds: DataService, private readonly spinner: NgxSpinnerService, private readonly toastr: ToastService) { }

	ngOnInit(): void {
		// console.log(this.data);
		this.lat = +this.data.rows[this.data.currentIndex].pickup_latitude;
		this.lng = +this.data.rows[this.data.currentIndex].pickup_longitude;
		this.getDirection();
	}

	getDirection() {
		// this.origin = {
		// 	lat: this.data.pickup_latitude,
		// 	lng: this.data.pickup_longitude
		// };
		// this.destination = {
		// 	lat: this.data.dropoff_latitude, 
		// 	lng: this.data.dropoff_longitude
		// };
		this.origin = this.data.rows[this.data.currentIndex].pickup_location;
		this.destination = this.data.rows[this.data.currentIndex].dropoff_location;
	}

	next(): void {
		++this.data.currentIndex;
		++this.data.nextIndex;
		++this.data.prevIndex;
		console.log(this.data);
	}

	prev(): void {
		--this.data.currentIndex;
		--this.data.nextIndex;
		--this.data.prevIndex;
	}

	onMapReady(map: any): void {
		map.setOptions({
			mapTypeControl: 'true',
			mapTypeControlOptions: {
				mapTypeIds: [],
				// position: google.maps.ControlPosition.TOP_LEFT,
				// style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
			},
			fullscreenControl: true,
			streetViewControl: false
		});
	}

	// Queue trip
	@ViewChild("dialogTemplate") dialogTemplate: TemplateRef<any>;
	contextMenuSearchString: string;
	selectedDriver: any;

	dismissContextMenu(): void {
		this.dialog.closeAll();
	}

	private getContextPopUpPosition(e: any): DialogPosition {
		const popUpheight: number = 300;
		let position: DialogPosition;
		if (e.clientY + popUpheight < window.innerHeight) {
			position = { left: e.clientX + 'px', top: e.clientY + 'px' };
		} else {
			position = { left: e.clientX + 'px', bottom: (window.innerHeight - e.clientY) + 'px' };
		}
		return position;
	}

	openAssignDriverContextMenu(e: any, row: any): void {
		this.contextMenuSearchString = "";
		this.selectedDriver = null;
		this.dialog.closeAll();
		this.dialog.open(this.dialogTemplate, {
			hasBackdrop: true,
			position: this.getContextPopUpPosition(e)
		});
		if (!this.data.rows[this.data.currentIndex]?.drivers?.length) {
			const payloadDriver: any = {
				corporate_id: row.corporate_id,
				client_id: row.client_id,
				trip_leg_id: row.trip_leg_id,
				invoice_number: row.invoice_number,
				limit: 10000,
				offset: 0,
				searchFlag: 0,
				searchString: ""
			};
			this.ds.post(ApiRoutes.getDriversForQueueTrips, payloadDriver).subscribe(res => {
				if (res.flag == 703) {
					if (res.drivers.length) {
						this.data.rows[this.data.currentIndex].drivers = res.drivers;
					} else {
						this.data.rows[this.data.currentIndex].drivers = [];
					}
					this.data.rows[this.data.currentIndex].isDriverLoaded = true;
				}
			});
		}
	}

	assignDriverInitiate(driver: any, row: any): void {
		// Get session identifier
		this.spinner.show(undefined, triangleBall);
		this.ds.post(ApiRoutes.startMasSession, { admin_id: row.client_id, useParentIdAsAdminId: "false" }).subscribe(res => {
			if (res.session_identifier) {
				const payloadMasTripRoaster: any = {
					corporate_id: row.corporate_id,
					session_identifier: res.session_identifier,
					invoice_number: row.invoice_number,
					trip_date: row.pickup_date,
					client_id: row.client_id,
				}
				// Get mas trip roaster
				this.ds.post(ApiRoutes.masTripRoaster, payloadMasTripRoaster).subscribe(masTripRoasterRes => {
					if (!masTripRoasterRes.trips.length) {
						this.toastr.error("No trips found!", "Error");
						this.contextMenuSearchString = "";
						this.dialog.closeAll();
						return;
					}
					const trip: any = masTripRoasterRes.trips.find(i => i.tripLegId == row.trip_leg_id);
					const payloadCheckMobile: any = {
						corporate_id: row.corporate_id,
						user_mobile: row.user_mobile,
						user_name: row.user_name,
						client_id: row.client_id
					}
					this.ds.post(ApiRoutes.checkMobile, payloadCheckMobile).subscribe(checkMobRes => {
						if (checkMobRes.flag == 138) {
							this.assignDriverToTrip(trip, row, driver);
						} else {
							// flag will be 1316
							const regUserPayload: any = {
								user_name: row.user_name,
								user_mobile: row.user_mobile,
								corporate_id: row.corporate_id,
								client_id: row.client_id
							};
							this.ds.post(ApiRoutes.registerUser, regUserPayload).subscribe(regUserRes => {
								console.log('regUserRes', regUserRes);
								if (regUserRes.user_id) {
									this.assignDriverToTrip(trip, row, driver);
								}
							}, err => {
								console.log(err);
								this.spinner.hide();
							})
						}
					}, err => {
						console.log(err);
						this.spinner.hide();
					});
				}, err => {
					console.log(err);
					this.spinner.hide();
				});
			}
		}, err => {
			console.log(err);
			this.spinner.hide();
		});
	}

	assignDriverToTrip(trip: any, row: any, driver: any): void {
		let confirmedDriverId;
		if (row?.confirmed_drivers) {
			confirmedDriverId = row?.confirmed_drivers.find(o => o.driver_id == driver.driver_id)?.driver_id;
		}
		const temp: any = trip?.secondary_services?.find(i => i.service_name === "Congestion Fee Single");
		const congestionTax: number = !!temp ? +temp.service_rate : 0;
		const payload: any = {
			first_name: trip.firstName,
			last_name: trip.lastName,
			user_mobile: row.user_mobile,
			pickup_date: row.pickup_date,
			pickup_latitude: row.pickup_latitude,
			pickup_longitude: row.pickup_longitude,
			pickup_location: row.pickup_location,
			dropoff_location: row.dropoff_location,
			dropoff_latitude: row.dropoff_latitude,
			dropoff_longitude: row.dropoff_longitude,
			invoice_number: row.invoice_number,
			trip_leg_id: row.trip_leg_id,
			pickup_time: row.pickup_time,
			corporate_id: row.corporate_id,
			client_id: row.client_id,
			trip_leg_sequence: trip.tripLegSequence,
			ride_estimate_time: row.ride_estimate_time,
			driver_id: driver.driver_id,
			zip_code: trip.dropoffAddress.zip,
			miles: trip.legMileage,
			congestion_tax: congestionTax,
			car_type: row.car_type,
			confirmed_driver_id: confirmedDriverId ? confirmedDriverId : 0,
			offset: new Date().getTimezoneOffset() * -1,
		};
		this.ds.post(ApiRoutes.assignDriverMasTrip, payload).subscribe(res => {
			console.log(res);
			if (res.flag == 1322) {
				this.toastr.success("Trip Assigned Successfully!", "Success");
				this.dialog.closeAll();
				this.activeModal.dismiss();
				this.emitter.next(true);
			}
			this.spinner.hide();
		}, err => {
			console.log(err);
			this.dialog.closeAll();
			this.spinner.hide();
		});
	}

	sendAcceptanceAlertToDriver(driver: any, row: any): void {
		const payload: any = {
			corporate_id: row.corporate_id,
			client_id: row.client_id,
			mas_trip_id: row.id,
			driver_id: driver.driver_id
		};
		this.dialog.closeAll();
		this.ds.post(ApiRoutes.sendAcceptanceAlertToDriver, payload).subscribe(res => {
			if (res.flag == 1333) {
				this.toastr.success("Acceptance request sent successfully", "Success");
				this.dialog.closeAll();
			}
		}, err => console.log(err));
	}
}