<section class="custom-mat-dialog-wrapper">

	<h2 class="mb-0 dialog-header-text dialog-header">{{ data?.title | titlecase}}</h2>

	<hr class="cutom-divider m-0">
	<mat-dialog-content class="dialog-body">
		<div>
			<p *ngIf="data?.description">
				{{ data?.description }}
			</p>
			<div class="mt-4" *ngIf="data?.termsText1 || data?.termsText2 || data?.anchor">
				<label class="d-flex">
					<input type="checkbox" class="check-input" (change)="isChecked = !isChecked">
					<span class="checkmark"></span>
					<p class="tick-text mb-0 ml-2 pointer">
						<ng-container *ngIf="data?.termsText1">{{ data?.termsText1 }}</ng-container>
						<a href="javascript:;" *ngIf="data?.anchor" (click)="openPdfViewer()">
							{{ data?.anchor }}
						</a>
						<ng-container *ngIf="data?.termsText2">{{ data?.termsText2 }}</ng-container>
					</p>
				</label>
				<div *ngIf="!isChecked && proceedWithBonus" class="help-block form-err text-danger ml-3">
					<small>*Please agree with the terms.</small>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="end" class="dialog-footer">
		<button *ngIf="data?.cancelButtonText" mat-button class="btn button-dialog-cancel button-dialog"
			(click)="proceed(0)">
			{{ data?.cancelButtonText }}
		</button>
		<button mat-button class="btn btn-primary button-dialog ml-2" (click)="proceed(1)">
			{{ data?.confirmButtonText }}
		</button>
	</mat-dialog-actions>
</section>