import { DatePipe } from "@angular/common";

function nullable(sign: string = '') {
	return { transform: (value) => value ? sign + ' ' + value : 'NA' };
}

// function miles(sign: string = '') {
// 	return { transform: (value) => value ? (value * 0.000621) : 'NA' };
// }

function zero(sign: string = '') {
	return { transform: (value) => value ? sign + ' ' + value : 0 };
}

function yesNo() {
	return { transform: (value) => value ? 'Yes' : 'No' };
}

function yesNo2() {
	return { transform: (value) => value ? 'Y' : 'N' };
}

function dollar() {
	return { transform: (value) => (value || value == 0) ? '$ ' + value : 'NA' };
}

function formatDate() {
	return { transform: (value: any) => (value && value != '0000-00-00 00:00:00') ? new DatePipe('en-US').transform(value, 'MM-dd-yyyy') : 'NA' };
}

function formatTime() {
	return { transform: (value: any) => (value && value != '0000-00-00 00:00:00') ? new DatePipe('en-US').transform(value, 'hh:mm:ss') : 'NA' };
}

export function reportsColumn(key: string, cellTemplate: any = {}) {
	if (key === 'report user reports') {
		const userReportsColumn = [
			{
				prop: 'user_id',
				name: 'Passenger Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'user_mobile',
				name: 'Phone',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'region_name',
				name: 'Zone',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'login_device',
				name: 'Device Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'date_registered',
				name: 'Creation Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},

			{
				prop: 'completePer',
				name: 'Complete %',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'cancelPer',
				name: 'Cancel %',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return userReportsColumn;
	} else if (key === 'report driver reports') {
		const driverReportsColumn = [
			{
				prop: 'driver_id',
				name: 'Driver Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_email',
				name: 'Email',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_mobile',
				name: 'Phone',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'state_name',
				name: 'Zone',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'login_device',
				name: 'Device Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'date_registered',
				name: 'Creation Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'completePer',
				name: 'Complete %',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'cancelPer',
				name: 'Cancel %',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return driverReportsColumn;
	} else if (key === 'report stripe reports') {
		const stripeReportsColumn = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			// {
			//   prop: 'sub_merchant_number',
			//   name: 'Sub Merchant Number',
			//   sortable: true,
			//   draggable: false,
			//   canAutoResize: false,
			//   width: 170,
			//   cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// },
			{
				prop: 'charge_id',
				name: 'Charge ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable()
			},
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'sub_merchant_name',
				name: 'Driver Details',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.driverDetail
			},
			{
				prop: 'client',
				name: 'Rider Detail',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'payment_date',
				name: 'Order Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				width: 180,
				cellTemplate: cellTemplate.paymentDate
			},
			{
				prop: 'total_fare',
				name: 'Total Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: dollar()
			},
			// {
			// 	prop: 'ride_essential',
			// 	name: 'Is Essential',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 170,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	cellTemplate: cellTemplate.essential
			// },
			{
				prop: 'corporate_payout',
				name: 'Submerchant Corporate Payout',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				
			},
			{
				prop: 'affiliate_payout',
				name: 'Submerchant Affiliate Payout',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			},
			{
				prop: 'sub_merchant_pay',
				name: 'Submerchant Driver Payout',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.submerchantDriverPayout,
			},
			{
				prop: 'sub_merchant_client_payout',
				name: 'Submerchant Client Payout',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				// cellTemplate: cellTemplate.submerchantDriverPayout,
				pipe: nullable('$')
			},
			// {
			// 	prop: 'sub_merchant_client_payout_pre_stripe_fee',
			// 	name: 'Submerchant Client Payout Pre Stripe Fees',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 220,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop pointer',
			// 	pipe: nullable('$')
			// },
			{
				prop: 'qudos_service_fee',
				name: 'Master Merchant Payout (Qudos)',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: zero('$')
			},
			{
				prop: 'stripe_fees',
				name: 'Stripe Fees',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: dollar()
			},
			{
				prop: 'total_fare',
				name: 'Total Ride Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				pipe: nullable('$'),
			},
			{
				prop: 'ride_status',
				name: 'Order Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.order_status,
			},
			{
				prop: 'payment_status',
				name: 'Payment Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer',
				cellTemplate: cellTemplate.payment_status
			}
		];
		return stripeReportsColumn;
	} else if (key === 'report past reports') {
		const pastReportsColumn = [
			// {
			// 	prop: 'session_id',
			// 	name: 'Session Id',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 120,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			// {
			// 	prop: 'trip_number',
			// 	name: 'Trip No',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			{
				prop: 'dispatching_base_number',
				name: 'Dispatching Base Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickupDate
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickupTime
			},
			// {
			// 	prop: 'ride_essential',
			// 	name: 'Is Essential',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 120,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	cellTemplate: cellTemplate.essential
			// },
			{
				prop: 'tlc_num',
				name: 'TLC',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			// {
			// 	prop: 'driver_tip',
			// 	name: 'Tip',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 170,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			{
				prop: 'car_no',
				name: 'License',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				// prop: 'affiliated_base_number',
				prop: 'dispatching_base_number',
				name: 'Affiliated Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_location_type',
				name: 'Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_longitude',
				name: 'Pickup Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_latitude',
				name: 'Pickup Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropDate
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropTime
			},
			{
				prop: 'dropoff_location_type',
				name: 'Dropoff Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_longitude',
				name: 'Dropoff Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_latitude',
				name: 'Dropoff Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			// {
			// 	prop: 'state_name',
			// 	name: 'State',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 170,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// },
			{
				prop: 'congestion_tax',
				name: 'Congestion Zone Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: yesNo2()
			},
			{
				prop: 'da',
				name: 'Shared Request Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'Shared Match Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'Access Ride Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'WAV Request Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'Request Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'Request Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'Originating Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'On Scene Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'da',
				name: 'On Scene Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
		];
		return pastReportsColumn;
	} else if (key === 'report wav reports') {
		const pastReportsColumn = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'trip_number',
				name: 'Trip No.',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'dispatching_base_number',
				name: 'Dispatching Base Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'start_time',
				name: 'Pickup Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropDateTime
			},
			{
				prop: 'tlc_num',
				name: 'TLC',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'dmv_num',
				name: 'License',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			}, {
				prop: 'car_no',
				name: 'License Plate',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.isEssential
			},
			{
				prop: 'affiliated_base_number',
				name: 'Affiliated Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_location_type',
				name: 'Pickup Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_longitude',
				name: 'Pickup Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_latitude',
				name: 'Pickup Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Date/Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropDateTime
			},
			{
				prop: 'dropoff_location_type',
				name: 'Dropoff Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},

			{
				prop: 'manual_destination_longitude',
				name: 'Dropoff Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_latitude',
				name: 'Dropoff Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},

		];
		return pastReportsColumn;
	} else if (
		key === 'report regularrides reports' ||
		key === 'report schedulerides reports'
	) {
		const regularRidesReportsColumn = [
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_name',
				name: 'Customer Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'user_mobile',
				name: 'Customer Mobile #',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_name',
				name: 'Driver Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_affiliate_name',
				name: 'Driver Affiliate Name',
				sortable: true,
				draggable: false,
				width: 170,
				canAutoResize: false,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'rider_affiliate_name',
				name: 'Rider Affiliate Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.essential
			},
			{
				prop: 'start_time',
				name: 'Date And Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			},
			{
				prop: 'pickup_location_address',
				name: 'Pick Up Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'manual_destination_address',
				name: 'Drop Off location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'state_name',
				name: 'State',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'total_payment',
				name: 'Total Payment',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'ride_fare ',
				name: 'Ride Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'driver_tip',
				name: 'Tip',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'affiliate_fee',
				name: 'Affiliate Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'base_fare',
				name: 'Base Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'distance_fare',
				name: 'Distance Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'real_distance_fare',
				name: 'Real Distance Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'time_fare',
				name: 'Time Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'real_time_fare',
				name: 'Real Time Fare',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'toll',
				name: 'Toll Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'credit_card_processing',
				name: 'Credit Card Processing',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'sales_tax',
				name: 'Tax',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'black_car_fund',
				name: 'Black Car Fund',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'net_ride_cost',
				name: 'Net Ride Cost',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'master_merchant_pay',
				name: 'Master Merchant pay',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'sub_merchant_pay',
				name: 'Sub Merchant Pay',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'qudos_fee',
				name: 'Qudos Fee',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
		];
		return regularRidesReportsColumn;
	} else if (key === 'report feedback reports') {
		const feedbackReportsColumn = [
			{
				prop: '',
				name: 'Actions',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 170,
				maxWidth: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.action,
			},
			{
				prop: 'session_id',
				name: 'Session Id',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.sessionId,
			},
			{
				prop: 'trip_number',
				name: 'Trip No',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.tripNo,
			},

			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverId,
			},
			{
				prop: 'driver_name',
				name: 'Driver Name and Mobile Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverName,
			},
			{
				prop: 'user_id',
				name: 'Passenger ID',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.passengerId,
			},

			{
				prop: 'user_name',
				name: 'Passenger Name and Mobile Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 280,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.passengerName,
			},

			{
				prop: 'pickup_location_address',
				name: 'Pick Up Location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickLocation,
			},
			{
				prop: 'start_time',
				name: 'Pickup Date and Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickDateTime,
			},
			{
				prop: 'manual_destination_address',
				name: 'Requested Drop Off location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropLocation,
			},
			{
				prop: 'drop_address',
				name: 'Real Drop Off location',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.realDropLocation,
			},
			{
				prop: 'ride_essential',
				name: 'Is Essential',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: yesNo(),
				cellTemplate: cellTemplate.isEssential,
			},
			{
				prop: 'driver_rating',
				name: 'Rating To Driver',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverRating,
			},
			{
				prop: 'driver_feedback',
				name: 'Feedback To Driver',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.driverFeedback,
			},
			{
				prop: 'user_rating',
				name: 'Rating To Rider',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.riderRating,
			},
			{
				prop: 'user_feedback',
				name: 'Feedback To Rider',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.riderFeedback,
			},
		];
		return feedbackReportsColumn;
	} else if (key === 'transactions') {
		const transactions = [
			{
				prop: 'type_id',
				name: 'Type ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'type',
				name: 'Transaction Type',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'current',
				name: 'Current',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'previous',
				name: 'Previous',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'current_month_to_date',
				name: 'Current Month-To-Date',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'current_quarter_to_date',
				name: 'Current Quarter-To-Date',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
			{
				prop: 'year_to_date',
				name: 'Year-To-Date',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop pointer text-crop',
			},
		];
		return transactions;
	} else if (key === 'ride insurance reports') {
		const rideInsurance = [
			{
				prop: 'session_id',
				name: 'Session ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'trip_number',
				name: 'Trip ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class',
				pipe: nullable()
			},
			{
				prop: 'driver_id',
				name: 'Driver ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'user_id',
				name: 'Rider ID',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 150,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'start_time',
				name: 'Pickup Date',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickUpDateTime
			},
			{
				prop: 'accept_location_address',
				name: 'Starting Pickup Route Location',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable()
			},
			{
				prop: 'phase2_distance',
				name: 'Distance from Starting Pickup Route Location to Pickup Location',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.phase2Distance
			},
			{
				prop: 'phase3_distance',
				name: 'Distance from Pickup Location to Dropoff Location',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.phase3Distance
			},
			{
				prop: 'phase1_distance',
				name: 'Period #1',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.period1
			},
			{
				prop: 'phase2_distance',
				name: 'Period #2',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.period2
			},
			{
				prop: 'phase3_distance',
				name: 'Period #3',
				sortable: false,
				draggable: false,
				canAutoResize: true,
				width: 250,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.period3
			},
		];
		return rideInsurance;
	} else if (key === "assigned trip reports") {
		const assignedTripReports: any[] = [
			{
				prop: 'dispatching_base_number',
				name: 'Dispatching Base Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'pickup_date',
				name: 'Pickup Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.pickDate,
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'tlc_num',
				name: 'TLC',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'car_no',
				name: 'License',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'dispatching_base_number',
				name: 'Affiliated Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'pickup_location_type',
				name: 'Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_location',
				name: 'Pickup Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_longitude',
				name: 'Pickup Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.long,
			},
			{
				prop: 'pickup_latitude',
				name: 'Pickup Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.long,
			},
			{
				prop: 'drop_date',
				name: 'Dropoff Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.dropDate,
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'dropoff_location_type',
				name: 'Dropoff Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_longitude',
				name: 'Dropoff Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_latitude',
				name: 'Dropoff Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'congestion_tax',
				name: 'Congestion Zone Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: yesNo2(),
			},
			{
				prop: 'da',
				name: 'Shared Request Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'Shared Match Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'Access Ride Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'WAV Request Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'Request Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'Request Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'Originating Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'On Scene Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'da',
				name: 'On Scene Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
		];
		return assignedTripReports;
	} else if (key === "congestion nyc report") {
		const congestionNycColumn: any[] = [
			{
				prop: 'dispatching_base_number',
				name: 'Dispatching Base Number',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 220,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'congestion_tax',
				name: 'Congestion Zone Flag',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: yesNo2(),
			},
			{
				prop: 'congestion_amount',
				name: 'Congestion Amount',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				// cellTemplate: cellTemplate.congestionAmount
				pipe: dollar()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: formatDate()
			},
			{
				prop: 'pickup_time',
				name: 'Pickup Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: formatTime()
			},
			{
				prop: 'tlc_num',
				name: 'TLC',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'car_no',
				name: 'License',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'dispatching_base_number',
				name: 'Affiliated Base',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: nullable(),
			},
			{
				prop: 'pickup_location_type',
				name: 'Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_location_address',
				name: 'Pickup Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_longitude',
				name: 'Pickup Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'pickup_latitude',
				name: 'Pickup Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Date',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: formatDate()
			},
			{
				prop: 'drop_time',
				name: 'Dropoff Time',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 170,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				pipe: formatTime()
			},
			{
				prop: 'dropoff_location_type',
				name: 'Dropoff Location Type',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_address',
				name: 'Dropoff Location Name',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 300,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_longitude',
				name: 'Dropoff Longitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'manual_destination_latitude',
				name: 'Dropoff Latitude',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			// {
			// 	prop: 'da',
			// 	name: 'Request Date',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	pipe: nullable(),
			// },
			// {
			// 	prop: 'da',
			// 	name: 'Request Time',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	pipe: nullable(),
			// },
			// {
			// 	prop: 'da',
			// 	name: 'Originating Base',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 170,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	pipe: nullable(),
			// },
			// {
			// 	prop: 'da',
			// 	name: 'On Scene Date',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	pipe: nullable(),
			// },
			// {
			// 	prop: 'da',
			// 	name: 'On Scene Time',
			// 	sortable: true,
			// 	draggable: false,
			// 	canAutoResize: false,
			// 	width: 180,
			// 	cellClass: 'd-flex align-items-center cell-class text-crop',
			// 	pipe: nullable(),
			// },
		];
		return congestionNycColumn;
	} else if (key === 'report tlc-driver reports') {
		const userReportsColumn = [
			{
				prop: 'license_number',
				name: 'License Number',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				// cellTemplate: cellTemplate.riderDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'License'
			},
			{
				prop: '',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.activeStatus,
			},
			{
				prop: 'name',
				name: 'Driver Name',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Driver Name'
			},
			{
				prop: 'expiration_date',
				name: 'Expiration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			}
		];
		return userReportsColumn;
	} else if (key === 'report tlc-vehicle reports') {
		const userReportsColumn = [
			{
				prop: 'dmv_license_plate_number',
				name: 'Plate',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				// cellTemplate: cellTemplate.riderDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Plate #'
			},
			{
				prop: '',
				name: 'Status',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 120,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.activeStatus,
			},
			{
				prop: 'vehicle_vin_number',
				name: 'VIN',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				// cellTemplate: cellTemplate.riderDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'VIN'
			},
			{
				prop: '',
				name: 'Base Detail',
				sortable: false,
				draggable: false,
				canAutoResize: false,
				width: 200,
				cellClass: 'd-flex align-items-center cell-class py-2',
				cellTemplate: cellTemplate.baseDetail,
				headerTemplate: cellTemplate.hdrTpl,
				searchPlaceholder: 'Base Details'
			},
			{
				prop: 'vehicle_year',
				name: 'Car Year',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
			},
			{
				prop: 'expiration_date',
				name: 'Expiration',
				sortable: true,
				draggable: false,
				canAutoResize: false,
				width: 180,
				cellClass: 'd-flex align-items-center cell-class text-crop',
				cellTemplate: cellTemplate.date
			}
		];
		return userReportsColumn;
	}
}