<section class="custom-mat-dialog-wrapper">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header">Edit Corporate Info</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="dialog-body">
		<div>
			<form novalidate [formGroup]="form">
				<div class="form-group row">
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Photo</label>
							<div class="col-sm-8">
								<input class="d-none" type="file" accept="image/x-png,image/jpeg,image/jpg" (change)="onFileChanged($event)" #fileInput accept="image/x-png,image/jpeg,image/jpg">
								<label class="col-form-label justify-content-start p-0">
									<div class="user-avatar-sm user-avatar-edit d-flex flex-row align-items-center">
										<!-- <img (click)="fileInput.click()" class="edit-icon pointer" src="assets/img/icons/edit-white.png" alt="edit"> -->
										<div (click)="fileInput.click()" class="overlay"></div>
										<img *ngIf="!formControls.doc_file.value" class="profile-pic" src="assets/img/attach2.jpg" alt="user-avatal">
										<img *ngIf="formControls.doc_file.value" class="profile-pic" [src]="formControls.doc_file.value" alt="user-avatal">
										<p (click)="fileInput.click()" class="mb-0 ml-2 pointer">Click to attach</p>
									</div>
								</label>
								<div *ngIf="formControls.doc_file.invalid && isSubmitting" class="help-block form-err text-danger">
									<small [hidden]="!formControls.doc_file.errors?.required">*Document is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">First Name</label>
							<div class="col-sm-8">
								<input placeholder="Enter last name" formControlName="first_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.first_name.invalid,
									'is-valid': isSubmitting && !formControls.first_name.invalid
								}">
								<div *ngIf="formControls.first_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.first_name.errors?.required">*First name is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Last Name</label>
							<div class="col-sm-8">
								<input placeholder="Enter first name" formControlName="last_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.last_name.invalid,
									'is-valid': isSubmitting && !formControls.last_name.invalid
								}">
								<div *ngIf="formControls.last_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.last_name.errors?.required">*Last name is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Corporate Name</label>
							<div class="col-sm-8">
								<input placeholder="Enter corporate name" formControlName="corporate_name" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.corporate_name.invalid,
									'is-valid': isSubmitting && !formControls.corporate_name.invalid
								}">
								<div *ngIf="formControls.corporate_name.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.corporate_name.errors?.required">*Corporate name is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Email</label>
							<div class="col-sm-8">
								<input placeholder="Enter email" formControlName="email" type="email" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.email.invalid,
									'is-valid': isSubmitting && !formControls.email.invalid
								}">
								<div *ngIf="formControls.email.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.email.errors?.required">*Email is required.</small>
									<small [hidden]="!formControls.email.errors?.pattern">*Invalid email.</small>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<label class="col-sm-4 col-form-label">Registration Date</label>
							<div class="col-sm-8">
								<input formControlName="date_registered" type="text" class="form-control">
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Phone</label>
							<div class="col-sm-8">
								<ngx-intl-tel-input
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15"
                                    [tooltipField]="TooltipLabel.Name"
                                    [phoneValidation]="true"
                                    [separateDialCode]="false"
                                    name="personalInfo_phone"
                                    [inputId]="'personalInfo_phone'"
                                    formControlName="mobile"
                                    class="form-control intl position-rel"
                                    [customPlaceholder]="'Enter phone number'">
                                </ngx-intl-tel-input>
								<div *ngIf="formControls.mobile.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.mobile.errors?.required">*Contact is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Zipcode</label>
							<div class="col-sm-8">
								<input placeholder="Enter zipcode" formControlName="zipcode" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.zipcode.invalid,
									'is-valid': isSubmitting && !formControls.zipcode.invalid
								}">
								<div *ngIf="formControls.zipcode.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.zipcode.errors?.required">*Zipcode is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">EIN Number</label>
							<div class="col-sm-8">
								<input placeholder="Enter EIN number" formControlName="ein_number" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.ein_number.invalid,
									'is-valid': isSubmitting && !formControls.ein_number.invalid
								}">
								<div *ngIf="formControls.ein_number.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.ein_number.errors?.required">*EIN number is required.</small>
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<label class="col-sm-4 col-form-label">Bussiness Permit</label>
							<div class="col-sm-8">
								<input placeholder="Enter business permit" formControlName="business_permit" type="text" class="form-control" [ngClass]="{
									'is-invalid': isSubmitting && formControls.business_permit.invalid,
									'is-valid': isSubmitting && !formControls.business_permit.invalid
								}">
								<div *ngIf="formControls.business_permit.invalid && isSubmitting"
									class="help-block form-err text-danger">
									<small [hidden]="!formControls.business_permit.errors?.required">*Business permit is required.</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions  class="dialog-footer">
		<div class="row" style="width: 100%;">
			<div class="col-md-4 offset-md-8">
				<button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
				<button mat-button class="btn btn-primary button-dialog ml-2" (click)="submitHandler()">Save</button>
			</div>
		</div>
	</mat-dialog-actions>
</section>