<section class="custom-mat-dialog-wrapper">

	<h2 class="mb-0 dialog-header-text dialog-header">{{ data?.modalParams.action | titlecase}}</h2>

	<hr class="cutom-divider m-0">
	<mat-dialog-content class="dialog-body">
		<div>
			<div class="form-group">
				<div class="row mt-3">
					<label class="col-sm-4 col-form-label align-items-baseline py-0">
						{{ data?.modalParams.for }}
					</label>

					<div class="col-sm-8 pl-4">
						<input type="checkbox" id="confirm-transfer" class="form-check-input pointer" [(ngModel)]="isConfirmed"
						style="margin-top: 2px;">
						<label for="confirm-transfer" class="mb-0 confirm-label">
							<span class="pointer mb-0">
								Pay <b>$ {{ data?.modalParams.payload.amount }} USD for {{ data?.modalParams.payload.ride_details.length }} completed assigned trips</b> 
								from your <b>$ {{ data?.modalParams.walletDetails.availableBalance | number : '1.2-2' }} USD</b> 
								available balance.
							</span>
						</label>
						<div *ngIf="isSubmitted" class="help-block text-danger ml-n3">
							<small [hidden]="isConfirmed">*Please confirm before you proceed.</small>
							<small [hidden]="!(data?.modalParams.payload.amount > data?.modalParams.walletDetails.availableBalance)">
								*Insufficient Balance.
							</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<hr class="cutom-divider m-0">
	<mat-dialog-actions align="end" class="dialog-footer">
		<button *ngIf="data?.modalParams.cancelButtonText" mat-button mat-button mat-dialog-close 
		class="btn button-dialog-cancel button-dialog">
			{{ data?.modalParams.cancelButtonText }}
		</button>
		<button mat-button class="btn btn-primary button-dialog ml-2 w-auto" (click)="submit()">
			{{ data?.modalParams.confirmButtonText }}
		</button>
	</mat-dialog-actions>
</section>