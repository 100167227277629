<section class="custom-mat-dialog-wrapper" id="otp-modal">
	<h2 mat-dialog-title class="mb-0 dialog-header-text dialog-header text-capitalize">{{ data?.modalParams.action }}</h2>
	<hr class="cutom-divider m-0">

	<mat-dialog-content class="mat-typography dialog-body">
		<div fxLayout="row" fxLayoutGap="20px">
			<div class="title">{{ data?.modalParams.description }}</div>
			<form novalidate [formGroup]="form" class="mt-3">
				<div class="form-group">
					<div class="">
						<input type="tel" numbersOnly class="form-control" formControlName="otp" placeholder="0 0 0 0">
						<div *ngIf="formControls.otp.invalid && isSubmitting" class="help-block form-err text-danger">
							<small [hidden]="!formControls.otp.errors?.required">*OTP is required.</small>
						</div>
					</div>
					<div class="d-flex justify-content-between mt-2" *ngIf="data.modalParams?.isResendMobileChange">
						<div>
							<span *ngIf="counter !== 0" class="title">Resend OTP in {{ counter }} seconds</span>
							<a *ngIf="counter == 0" href="javascript:;" class="title" (click)="resendOtp()">Resend OTP</a>
						</div>
						<!-- <div>
							<a href="javascript:;" mat-dialog-close class="title">Change Mobile Number</a>
						</div> -->
					</div>

					<div class="d-flex justify-content-between mt-2" *ngIf="data.modalParams?.resendEmail">
						<div>
							<span *ngIf="counter !== 0" class="title">Resend OTP in {{ counter }} seconds</span>
							<a *ngIf="counter == 0" href="javascript:;" class="title" (click)="resendOtpEmail()">Resend OTP</a>
						</div>
					</div>
				</div>
			</form>
		</div>
	</mat-dialog-content>

	<hr class="cutom-divider m-0">

	<mat-dialog-actions align="end" class="dialog-footer px-3">
		<button *ngIf="data?.modalParams.cancelButtonText" mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">{{ data?.modalParams.cancelButtonText }}</button>
		<button mat-button class="btn btn-primary button-dialog ml-2" (click)="submitOtp()">{{ data?.modalParams.confirmButtonText }}</button>
	</mat-dialog-actions>
</section>