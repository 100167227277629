import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

import { LocalStorage } from '../../storage'
import { BehaviorSubject } from "rxjs";

interface IAutomateTabSwitch {
	parentTabIndex?: number,
	tabIndex: number,
	isTabSwitch?: boolean,
	requestType: number,
	subRequestType?: number,
	type?: string
}

@Injectable({
	providedIn: "root",
})

export class GlobalService {
	clientData: any;
	languages: any[] = [];
	allDocTypes: any[] = [];
	ridesData: any;
	ridesAction: string;   //ex 'create-custom-charge',create-custom-payout

	countryStateObj: any;

	clientDetailEvent = new Subject<any>();
	clientDetailEvent$ = this.clientDetailEvent.asObservable();

	reloadDashboardEvent = new Subject<any>();
	reloadDashboardEvent$ = this.reloadDashboardEvent.asObservable();

	navbarSearchClearEvent = new Subject<any>();
	navbarSearchClearEvent$ = this.navbarSearchClearEvent.asObservable();

	rideDetailsEvent = new Subject<any>();
	rideDetailsEvent$ = this.clientDetailEvent.asObservable();

	isVOPopUpClosedBeforeUploadComplete: boolean = false;
	videoUploadCompleteEvent = new Subject<any>();
	videoUploadCompleteEvent$ = this.videoUploadCompleteEvent.asObservable();


	openModelData: {
		modelData: any,
		isDialog: boolean
	} = null;

	clientDetailViewActiveTab = {
		mainTab: 0,
		subTab: 0
	};

	// user tabs
	automateClientTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 1, type: '' };
	automatepassengerTab: IAutomateTabSwitch = { tabIndex: 0, isTabSwitch: false, requestType: 1, type: '' };
	automateAffiliateTab: IAutomateTabSwitch = { tabIndex: 0, isTabSwitch: false, requestType: 1, type: '' };
	automateCorporateTab: IAutomateTabSwitch = { tabIndex: 0, isTabSwitch: false, requestType: 1, type: '' };
	automateDriverTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 1, type: '' };
	automateRevenueTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 0, subRequestType: 0, type: '' };
	automateVOTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 0, subRequestType: 0, type: '' };
	automateRetailRidesTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 0, subRequestType: 0, type: '' };
	automateCorporateRidesTab: IAutomateTabSwitch = { parentTabIndex: 0, tabIndex: 0, isTabSwitch: false, requestType: 0, subRequestType: 0, type: '' };
	automatefeedbackReport: IAutomateTabSwitch = { tabIndex: 0, isTabSwitch: false, requestType: 0, type: '' };

	// promo offer tasbs
	automateOfferTab: IAutomateTabSwitch = { tabIndex: 0, isTabSwitch: false, requestType: 0, type: '' };

	constructor(private ls: LocalStorage) { }

	get adminId() {
		const currentUser = this.ls.getItem('qudos-admin-currentUser');
		const selectedBusiness = this.ls.getItem('qudos-admin-selectedBusiness');
		const selectedClient = this.ls.getItem('qudos-admin-selectedClient');
		let adminID: number;
		if (currentUser.admin_type == 1) {
			return adminID = selectedClient ? selectedClient.admin_id : 0;
		} else if ((currentUser.admin_type == 2 || currentUser.admin_type == 3) && currentUser.parent_id) {
			if (selectedClient) {
				return adminID = selectedClient.admin_id
			} else {
				return adminID = currentUser.parent_id;
			}
		} else {
			return adminID = selectedBusiness ? selectedBusiness.admin_id : currentUser.admin_id;
		}
	}

	automateTabReset(tabType: string): void {
		switch (tabType) {
			case 'client':
				this.automateClientTab = { tabIndex: 0, requestType: 1, type: '' };
				break;
			case 'passenger':
				this.automatepassengerTab = { tabIndex: 0, requestType: 1, type: '' };
				break;
			case 'affiliate':
				this.automateAffiliateTab = { tabIndex: 0, requestType: 1, type: '' };
				break;
			case 'corporate':
				this.automateCorporateTab = { tabIndex: 0, requestType: 1, type: '' };
				break;
			case 'driver':
				this.automateDriverTab = { tabIndex: 0, requestType: 1, type: '' };
				break;
			case 'offer':
				this.automateOfferTab = { tabIndex: 0, requestType: 0, type: '' };
				break;
			case 'revenue':
				this.automateRevenueTab = { parentTabIndex: 0, tabIndex: 0, requestType: 0, subRequestType: 0, type: '' };
				break;
			case 'VO':
				this.automateVOTab = { parentTabIndex: 0, tabIndex: 0, requestType: 0, subRequestType: 0, type: '' };
				break;
			case 'retailRide':
				this.automateRetailRidesTab = { parentTabIndex: 0, tabIndex: 0, requestType: 0, subRequestType: 0, type: '' };
				break;
			case 'corporateRide':
				this.automateCorporateRidesTab = { parentTabIndex: 0, tabIndex: 0, requestType: 0, subRequestType: 0, type: '' };
				break;
			case 'feedback':
				this.automatefeedbackReport = { parentTabIndex: 0, tabIndex: 0, requestType: 0, subRequestType: 0, type: '' };
				break;
		}
	}

	public get isCLientAndStaffAccisibleAction(): boolean {
		const currentUser = this.ls.getItem('qudos-admin-currentUser');
		if (currentUser.admin_type == 4 && currentUser.parent_id == 0 && currentUser.parent_type == 0 ||
			currentUser.admin_type == 2 && currentUser.parent_id != 0 && currentUser.parent_type == 4) {
			return false;
		} else {
			return true;
		}
	}

	reloadDashboard(data: boolean) {
		this.reloadDashboardEvent.next(data);
	}

	clientDetail(data: any) {
		this.clientDetailEvent.next(data);
	}

	videoUploadComplete(data: boolean): void {
		this.videoUploadCompleteEvent.next(data);
	}

	navbarSearchClear(data: boolean) {
		this.navbarSearchClearEvent.next(data);
	}

	rideDetails(data: any) {
		this.rideDetailsEvent.next(data);
	}

	/** Get time */
	generateTimeOptions(startHour: number, endHour: number): { value: string }[] {
		const timeOptions: { value: string }[] = [];
		for (let hour = startHour; hour <= endHour; hour++) {
			const time = hour % 12;
			const period = hour < 12 ? 'AM' : 'PM';
			const formattedHour = hour === 0 ? 12 : time;
			const value = `${hour.toString().padStart(2, '0')} (${formattedHour} ${period})`;
			timeOptions.push({ value });
		}
		return timeOptions;
	}

	/** Get minutes */
	generateMinuteOptions(): { value: string }[] {
		const minuteOptions: { value: string }[] = [];
		for (let minute = 0; minute < 60; minute++) {
			const value = minute.toString().padStart(2, '0') + ' mins';
			minuteOptions.push({ value });
		}
		return minuteOptions;
	}
}