import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DataService } from "../../../shared/services";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs/Subscription';

import { IAffiliates, IDrivers, ICorporates, IPassengers } from '../../../interfaces';
import { columns } from "../../../shared/utility";
import { APP_ROUTES } from "../../../shared/routes";
// import { triangleBall } from "../../../shared/utility";
import { MatDialog } from '@angular/material/dialog';
import { ClientsEditModalComponent } from '../../../pages/full-pages/users/clients/clients-edit-modal/clients-edit-modal.component';
import { PassengersEditModalComponent } from '../../../pages/full-pages/users/passengers/passengers-edit-modal/passengers-edit-modal.component';
import { AffiliatesEditModalComponent } from '../../../pages/full-pages/users/affiliates/affiliates-edit-modal/affiliates-edit-modal.component';
import { DriverInfoEditModalComponent } from '../../../pages/full-pages/users/drivers/driver-info-edit-modal/driver-info-edit-modal.component';
import { CorporateInfoEditModalComponent } from '../../../pages/full-pages/users/corporates/corporate-info-edit-modal/corporate-info-edit-modal.component';
import { LocalStorage } from '../../../storage';

@Component({
	selector: 'app-search-result',
	templateUrl: './search-result.component.html',
	styleUrls: ['./search-result.component.scss']
})

export class SearchResultComponent implements OnInit {
	route = APP_ROUTES;
	readonly headerHeight = 48;
	readonly rowHeight = 64;
	readonly footerHeight = 50;
	pagePayload: any = {
		searchState: 0,
		searchCountry: 1,
		searchFlag: 0,
		searchString: ''
	}
	@Input() data: any;
	@Output() toogleView: EventEmitter<boolean> = new EventEmitter();

	clientRows: any[] = [];
	@ViewChild(DatatableComponent, { static: false }) clientTable: DatatableComponent;
	@ViewChild("actionClientT", { static: false }) public actionClientT: TemplateRef<any>;
	@ViewChild("dateClientT", { static: false }) public dateClientT: TemplateRef<any>;
	@ViewChild("nameClientT", { static: false }) public nameClientT: TemplateRef<any>;
	@ViewChild("idClientT", { static: false }) public idClientT: TemplateRef<any>;
	@ViewChild("emailClientT", { static: false }) public emailClientT: TemplateRef<any>;
	@ViewChild("mobileClientT", { static: false }) public mobileClientT: TemplateRef<any>;
	@ViewChild("stateClientT", { static: false }) public stateClientT: TemplateRef<any>;
	@ViewChild("blockClientT", { static: false }) public blockClientT: TemplateRef<any>;
	clientColumns: TableColumn[] = [];

	usersRows: IPassengers[] = [];
	@ViewChild(DatatableComponent, { static: false }) userTable: DatatableComponent;
	@ViewChild("actionUserT", { static: false }) public actionUserT: TemplateRef<any>;
	@ViewChild("dateUserT", { static: false }) public dateUserT: TemplateRef<any>;
	@ViewChild("nameUserT", { static: false }) public nameUserT: TemplateRef<any>;
	@ViewChild("idUserT", { static: false }) public idUserT: TemplateRef<any>;
	@ViewChild("emailUserT", { static: false }) public emailUserT: TemplateRef<any>;
	@ViewChild("mobileUserT", { static: false }) public mobileUserT: TemplateRef<any>;
	@ViewChild("deviceUserT", { static: false }) public deviceUserT: TemplateRef<any>;
	@ViewChild("blockUserT", { static: false }) public blockUserT: TemplateRef<any>;
	userColumns: TableColumn[] = [];

	affiliatesRows: IAffiliates[] = [];
	@ViewChild(DatatableComponent, { static: false }) affiliateTable: DatatableComponent;
	@ViewChild("actionaffiliateT", { static: false }) public actionaffiliateT: TemplateRef<any>;
	@ViewChild("dateaffiliateT", { static: false }) public dateaffiliateT: TemplateRef<any>;
	@ViewChild("nameAffiliateT", { static: false }) public nameAffiliateT: TemplateRef<any>;
	@ViewChild("idAffiliateT", { static: false }) public idAffiliateT: TemplateRef<any>;
	@ViewChild("emailAffiliateT", { static: false }) public emailAffiliateT: TemplateRef<any>;
	@ViewChild("mobileAffiliateT", { static: false }) public mobileAffiliateT: TemplateRef<any>;
	@ViewChild("stateAffiliateT", { static: false }) public stateAffiliateT: TemplateRef<any>;
	@ViewChild("blockAffiliateT", { static: false }) public blockAffiliateT: TemplateRef<any>;
	affiliateColumns: TableColumn[] = [];

	driversRows: IDrivers[] = [];
	@ViewChild(DatatableComponent, { static: false }) driverTable: DatatableComponent;
	@ViewChild("actionDriverT", { static: false }) public actionDriverT: TemplateRef<any>;
	@ViewChild("dateDriverT", { static: false }) public dateDriverT: TemplateRef<any>;
	@ViewChild("nameDriverT", { static: false }) public nameDriverT: TemplateRef<any>;
	@ViewChild("idDriverT", { static: false }) public idDriverT: TemplateRef<any>;
	@ViewChild("emailDriverT", { static: false }) public emailDriverT: TemplateRef<any>;
	@ViewChild("mobileDriverT", { static: false }) public mobileDriverT: TemplateRef<any>;
	@ViewChild("stateDriverT", { static: false }) public stateDriverT: TemplateRef<any>;
	@ViewChild("deviceDriverT", { static: false }) public deviceDriverT: TemplateRef<any>;
	driverColumns: TableColumn[] = [];

	corporatesRows: ICorporates[] = [];
	@ViewChild(DatatableComponent, { static: false }) corporatesTable: DatatableComponent;
	@ViewChild("actionCorporateT", { static: false }) public actionCorporateT: TemplateRef<any>;
	@ViewChild("dateCorporateT", { static: false }) public dateCorporateT: TemplateRef<any>;
	@ViewChild("nameCorporateT", { static: false }) public nameCorporateT: TemplateRef<any>;
	@ViewChild("userNameCorporateT", { static: false }) public userNameCorporateT: TemplateRef<any>;
	@ViewChild("idCorporateT", { static: false }) public idCorporateT: TemplateRef<any>;
	@ViewChild("emailCorporateT", { static: false }) public emailCorporateT: TemplateRef<any>;
	@ViewChild("mobileCorporateT", { static: false }) public mobileCorporateT: TemplateRef<any>;
	@ViewChild("stateCorporateT", { static: false }) public stateCorporateT: TemplateRef<any>;
	@ViewChild("blockCorporateT", { static: false }) public blockCorporateT: TemplateRef<any>;
	corporateColumns: TableColumn[] = [];

	subscription = new Subscription();
	// res: any;
	currentUser: any;

	constructor(private ls: LocalStorage, private dialog: MatDialog, private ds: DataService, private spinner: NgxSpinnerService, private cd: ChangeDetectorRef, private router: Router) {
		this.currentUser = this.ls.getItem('qudos-admin-currentUser');
		const countryStateSearchEvent = this.ds.countryStateSearchEvent.subscribe(data => {
			this.pagePayload.searchState = null;
			this.pagePayload.searchCountry = null;
			if (data.state_id) {
				this.pagePayload.searchState = data.state_id;
			}
			if (data.country_id) {
				this.pagePayload.searchCountry = data.country_id;
			}
			this.pagePayload.offset = 0;
		});
		this.subscription.add(countryStateSearchEvent);
	}

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges) {
		for (let propName in changes) {
			let change = changes[propName];
			let curVal = change.currentValue;
			this.data = curVal;
		}
		this.mapData();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private createColumn(): void {
		setTimeout(() => {
			const clientCellTemplate = {
				action: this.actionClientT,
				date: this.dateClientT,
				name: this.nameClientT,
				id: this.idClientT,
				email: this.emailClientT,
				mobile: this.mobileClientT,
				state: this.stateClientT,
				block: this.blockClientT,
			};
			this.clientColumns = columns('home client', clientCellTemplate);

			const userCellTemplate = {
				action: this.actionUserT,
				date: this.dateUserT,
				name: this.nameUserT,
				id: this.idUserT,
				email: this.emailUserT,
				mobile: this.mobileUserT,
				device: this.deviceUserT,
				block: this.blockUserT,
			};
			this.userColumns = columns('home users', userCellTemplate);

			const affiliateCellTemplate = {
				action: this.actionaffiliateT,
				date: this.dateaffiliateT,
				name: this.nameAffiliateT,
				id: this.idAffiliateT,
				email: this.emailAffiliateT,
				mobile: this.mobileAffiliateT,
				state: this.stateAffiliateT,
				block: this.blockAffiliateT,
			};
			this.affiliateColumns = columns('home affiliate', affiliateCellTemplate);

			const driverCellTemplate = {
				action: this.actionDriverT,
				date: this.dateDriverT,
				name: this.nameDriverT,
				id: this.idDriverT,
				email: this.emailDriverT,
				mobile: this.mobileDriverT,
				state: this.stateDriverT,
				device: this.deviceDriverT,
			};
			this.driverColumns = columns('home driver', driverCellTemplate);

			const corporateCellTemplate = {
				action: this.actionCorporateT,
				date: this.dateCorporateT,
				name: this.userNameCorporateT,
				business_name: this.nameCorporateT,
				id: this.idCorporateT,
				email: this.emailCorporateT,
				mobile: this.mobileCorporateT,
				state: this.stateCorporateT,
				block: this.blockCorporateT,
			};
			this.corporateColumns = columns('home corporate', corporateCellTemplate);
			this.cd.markForCheck();
		});
	}

	/**
	 * Description: get row identity
	 * @param row object
	 */
	getId(row) {
		return row.id;
	}

	getRowClass = (row) => {
		return {
			"dt-row-border": true,
		};
	};

	private mapData(): void {
		this.usersRows = [...this.data.value.users];
		this.affiliatesRows = [...this.data.value.affiliates];
		this.clientRows = [...this.data.value.clients];
		this.driversRows = [...this.data.value.drivers];
		this.corporatesRows = [...this.data.value.corporates];
		this.createColumn();
		this.cd.markForCheck();
	}

	goTo(url: string): void {
		this.router.navigate([url]);
	}

	clientAction(item: any, action: string): void {
		if (action === 'view') {
			this.toogleView.emit(true);
			let type: number;
			if (!item.is_blocked && !item.is_verified && !item.is_deleted && !item.is_approved) {
				type = 1;
			} else if (item.is_deleted) {
				type = 5;
			} else if (item.is_blocked) {
				type = 4;
			} else if (item.is_approved && item.is_verified) {
				type = 3;
			} else if (!item.is_approved && item.is_verified) {
				type = 2
			}
			this.router.navigate([APP_ROUTES.clients + APP_ROUTES.slash + item.admin_id], { queryParams: { type: type } });
		} else if (action === 'edit') {
			const data: any = {
				vendorDetail: item
			}
			const dialogRef = this.dialog.open(ClientsEditModalComponent, { data, width: '1024px' });
			dialogRef.afterClosed().subscribe(result => {
				if (result) { }
			});
		}
	}

	passengerAction(item: IPassengers, action: string): void {
		if (action === 'view') {
			this.router.navigate([APP_ROUTES.passengers + APP_ROUTES.slash + item.user_id], { queryParams: { type: 1 } });
		} else {
			const data: any = {
				pessengerDetail: item
			}
			const dialogRef = this.dialog.open(PassengersEditModalComponent, { data, width: '501px' });
			dialogRef.afterClosed().subscribe(result => {
				if (result) { }
			});
		}
	}

	affiliateAction(item: IAffiliates, action: string): void {
		if (action === 'view') {
			// this.router.navigate([APP_ROUTES.affiliates + APP_ROUTES.slash + item.vendor_id], { queryParams: { type: 1 } });
		} else {
			const data: any = {
				vendorDetail: item
			}
			const dialogRef = this.dialog.open(AffiliatesEditModalComponent, { data, width: '1024px' });
			dialogRef.afterClosed().subscribe(result => {
				if (result) { }
			});
		}
	}

	driverAction(item: IDrivers, action: string): void {
		if (action === 'view') {
			let type: number;
			if (!item.is_deleted && !item.is_approved && !item.is_blocked && !item.is_verified) {
				type = 1;
			} else if (item.is_deleted) {
				type = 8;
			} else if (item.is_blocked) {
				type = 7;
			} else if (item.is_approved && item.is_verified) {
				type = 3;
			} else if (!item.is_approved && item.is_verified) {
				type = 2;
			} else if (item.is_approved && item.car_type === 5) {
				type = 4;
			} else if (item.is_approved && item.car_type === 6) {
				type = 5;
			} else if (!item.is_approved && !item.is_verified && item.submerchant_id) {
				type = 6;
			}
			this.router.navigate([APP_ROUTES.drivers + APP_ROUTES.slash + item.driver_id + APP_ROUTES.slash + type]);
		} else {
			const data: any = {
				data: item,
				type: 'driverInfo',
			}
			const dialogRef = this.dialog.open(DriverInfoEditModalComponent, { data });
			dialogRef.afterClosed().subscribe((result) => {
				if (result) { }
			});
		}
	}

	corporateAction(item: ICorporates, action: string): void {
		if (action === 'view') {
			this.ls.setItem('corporateData', JSON.stringify(item));
			this.router.navigate([APP_ROUTES.corporates + APP_ROUTES.slash + item.corporate_id]);
		} else {
			const data: any = {
				data: item,
				type: 'corporateInfo',
			}
			const dialogRef = this.dialog.open(CorporateInfoEditModalComponent, { data });

			dialogRef.afterClosed().subscribe((result) => {
				if (result) { }
			});
		}
	}
}