import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoomComponent } from './zoom.component';

@NgModule({
    declarations: [
        ZoomComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ZoomComponent
    ],
    providers: [

    ],
    bootstrap: [
        
    ],
    entryComponents: [
        ZoomComponent
    ]
})
export class ZoomModule { }
