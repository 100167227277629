import { RouteInfo } from './vertical-menu.metadata';
import { APP_ROUTES } from '../../routes';

function adminToClientSwitchVisibility(path: string): boolean {
	const selectedClient = localStorage.getItem('qudos-admin-selectedClient');
	let currentUser: any = localStorage.getItem('qudos-admin-currentUser');
	if (currentUser) {
		currentUser = JSON.parse(currentUser)
	}
	if ((selectedClient || (currentUser?.admin_type == 2 && currentUser?.parent_type == 4) || currentUser?.admin_type == 3) && (path === APP_ROUTES.clients || path === APP_ROUTES.demoRequest || path === APP_ROUTES.rideBroker)) {
		return false
	}
	return true;
}

// Sidebar menu Routes and data for normal flow
export const ROUTES: RouteInfo[] = [
	{
		path: APP_ROUTES.slash + APP_ROUTES.dashboard,
		title: 'Home',
		iconActive: 'assets/img/icons/sidebar/home-active.png',
		icon: 'assets/img/icons/sidebar/home.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.dashboard)
	},
	// {
	//   path: APP_ROUTES.slash + APP_ROUTES.heatMap,
	//   title: 'Heat Map',
	//   iconActive: 'assets/img/icons/sidebar/heatmap-active.png',
	//   icon: 'assets/img/icons/sidebar/heatmap.png',
	//   class: '',
	//   badge: '',
	//   badgeClass: '',
	//   isExternalLink: false,
	//   divider: true,
	//   submenu: [],
	// },
	{
		path: APP_ROUTES.slash + APP_ROUTES.campaign,
		title: 'Campaign',
		iconActive: 'assets/img/icons/sidebar/heatmap-active.png',
		icon: 'assets/img/icons/sidebar/heatmap.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.campaign),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.createCampaign,
				title: 'Create Campaign',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.createCampaign),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.contacts,
				title: 'Contacts',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.contacts),
			},
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.demoRequest,
		title: 'Demo Request',
		iconActive: 'assets/img/icons/sidebar/heatmap-active.png',
		icon: 'assets/img/icons/sidebar/heatmap.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: true,
		submenu: [],
		admin_type: [1, 2],
		parent_type: [0, 1],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.demoRequest)
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.users,
		// path: APP_ROUTES.slash + APP_ROUTES.drivers,
		title: 'Users',
		iconActive: 'assets/img/icons/sidebar/users-active.png',
		icon: 'assets/img/icons/sidebar/users.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.users),
		// adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.drivers),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.clients,
				title: 'Clients',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2],
				parent_type: [0, 1],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.clients),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.rideBroker,
				title: 'Ride Brokers',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2],
				parent_type: [0, 1],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.rideBroker),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.drivers,
				title: 'Drivers',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.drivers),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.passengers,
				title: 'Passengers',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.passengers),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.corporates,
				title: 'Corporates',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.corporates),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.affiliates,
				title: 'Affiliates',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.affiliates),
			},
			// {
			// 	path: APP_ROUTES.slash + APP_ROUTES.inactiveUsers,
			// 	title: 'Inactive Users',
			// 	icon: '',
			// 	class: '',
			// 	badge: '',
			// 	badgeClass: '',
			// 	isExternalLink: false,
			// 	submenu: [],
			// 	admin_type: [1,2,3,4],
			// 	adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.inactiveUsers),
			// },
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.rides,
		title: 'Rides',
		iconActive: 'assets/img/icons/sidebar/rides-active.png',
		icon: 'assets/img/icons/sidebar/rides.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.rides),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.retail,
				title: 'Retail',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.retail),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.corporate,
				title: 'Corporate',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.corporate),
			},
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.v2Trip,
		title: 'Trips',
		iconActive: 'assets/img/icons/sidebar/assigned_trips-active.png',
		icon: 'assets/img/icons/sidebar/assigned_trips.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.v2Trip),
		submenu: [],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.trips,
		title: 'Assigned & Queued',
		iconActive: 'assets/img/icons/sidebar/assigned_trips-active.png',
		icon: 'assets/img/icons/sidebar/assigned_trips.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.revenue),
		submenu: [],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.revenue,
		title: 'Revenue',
		iconActive: 'assets/img/icons/sidebar/revenue-active.png',
		icon: 'assets/img/icons/sidebar/revenue.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.revenue),
		submenu: [],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.payments,
		title: 'Payments',
		iconActive: 'assets/img/icons/sidebar/paymets-active.png',
		icon: 'assets/img/icons/sidebar/paymets.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.payments),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.customPayouts,
				title: 'Custom Payout',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.customPayouts),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.customCharge,
				title: 'Custom Charge',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.customCharge),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.disputes,
				title: 'Disputes',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.disputes),
			},
			// {
			// 	path: APP_ROUTES.slash + APP_ROUTES.revenue,
			// 	title: 'Revenue',
			// 	icon: '',
			// 	class: '',
			// 	badge: '',
			// 	badgeClass: '',
			// 	isExternalLink: false,
			// 	submenu: [],
			// 	admin_type: [1,2,4],
			// 	adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.revenue),
			// },
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.promosAndOffers,
		title: 'Promos and Offers',
		iconActive: 'assets/img/icons/sidebar/promos-active.png',
		icon: 'assets/img/icons/sidebar/promos.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.promosAndOffers),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.promoCodes,
				title: 'Promo Codes',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.promoCodes),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.offers,
				title: 'Offer',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.offers),
			},
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.virtualOrientation,
		title: 'Virtual Orientation',
		iconActive: 'assets/img/icons/sidebar/vo.png',
		icon: 'assets/img/icons/sidebar/vo.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.virtualOrientation),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.analytics2,
		title: 'Analytics2',
		iconActive: 'assets/img/icons/sidebar/analytics-active.png',
		icon: 'assets/img/icons/sidebar/analytics.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.analytics2),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.analytics,
		title: 'Analytics',
		iconActive: 'assets/img/icons/sidebar/analytics-active.png',
		icon: 'assets/img/icons/sidebar/analytics.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.analytics),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.reports,
		title: 'Reports',
		iconActive: 'assets/img/icons/sidebar/report-active.png',
		icon: 'assets/img/icons/sidebar/report.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.reports),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.tickets,
		title: 'Tickets',
		iconActive: 'assets/img/icons/sidebar/tickets-active.png',
		icon: 'assets/img/icons/sidebar/tickets.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.tickets),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.pointSystem,
		title: 'Point System',
		iconActive: 'assets/img/icons/sidebar/points-active.png',
		icon: 'assets/img/icons/sidebar/points.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: true,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.pointSystem),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.auditLogs,
		title: 'Audit Logs',
		iconActive: 'assets/img/icons/sidebar/audit-1.png',
		icon: 'assets/img/icons/sidebar/audit-1.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.auditLogs),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.settings,
		title: 'Settings',
		iconActive: 'assets/img/icons/sidebar/settings-active.png',
		icon: 'assets/img/icons/sidebar/settings.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.settings),
	},

	{
		path: APP_ROUTES.slash + APP_ROUTES.businessCenter,
		title: 'Business Center',
		iconActive: 'assets/img/icons/sidebar/paymets-active.png',
		icon: 'assets/img/icons/sidebar/paymets.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.businessCenter)
	},

	{
		path: APP_ROUTES.slash + APP_ROUTES.businessCenterGlobal,
		title: 'Business Center - Global',
		iconActive: 'assets/img/icons/sidebar/paymets-active.png',
		icon: 'assets/img/icons/sidebar/paymets.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1],
		parent_type: [0],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.settings),
	},
];

// Sidebar for regulation type other(2)
export const ROUTES2: RouteInfo[] = [
	{
		path: APP_ROUTES.slash + APP_ROUTES.dashboard,
		title: 'Home',
		iconActive: 'assets/img/icons/sidebar/home-active.png',
		icon: 'assets/img/icons/sidebar/home.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.dashboard)
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.campaign,
		title: 'Campaign',
		iconActive: 'assets/img/icons/sidebar/heatmap-active.png',
		icon: 'assets/img/icons/sidebar/heatmap.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.campaign),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.createCampaign,
				title: 'Create Campaign',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.createCampaign),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.contacts,
				title: 'Contacts',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.contacts),
			},
		],
	},
	{
		// path: APP_ROUTES.slash + APP_ROUTES.users,
		path: APP_ROUTES.slash + APP_ROUTES.drivers,
		title: 'Users',
		iconActive: 'assets/img/icons/sidebar/users-active.png',
		icon: 'assets/img/icons/sidebar/users.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		// adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.users),
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.drivers),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.drivers,
				title: 'Drivers',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.drivers),
			},
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.rides,
		title: 'Rides',
		iconActive: 'assets/img/icons/sidebar/rides-active.png',
		icon: 'assets/img/icons/sidebar/rides.png',
		class: 'has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.rides),
		submenu: [
			{
				path: APP_ROUTES.slash + APP_ROUTES.retail,
				title: 'Retail',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.retail),
			},
			{
				path: APP_ROUTES.slash + APP_ROUTES.corporate,
				title: 'Corporate',
				icon: '',
				class: '',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				admin_type: [1, 2, 3, 4],
				parent_type: [0, 1, 4],
				adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.corporate),
			},
		],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.revenue,
		title: 'Revenue',
		iconActive: 'assets/img/icons/sidebar/revenue-active.png',
		icon: 'assets/img/icons/sidebar/revenue.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.revenue),
		submenu: [],
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.virtualOrientation,
		title: 'Virtual Orientation',
		iconActive: 'assets/img/icons/sidebar/vo.png',
		icon: 'assets/img/icons/sidebar/vo.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.virtualOrientation),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.reports,
		title: 'Reports',
		iconActive: 'assets/img/icons/sidebar/report-active.png',
		icon: 'assets/img/icons/sidebar/report.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.reports),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.tickets,
		title: 'Tickets',
		iconActive: 'assets/img/icons/sidebar/tickets-active.png',
		icon: 'assets/img/icons/sidebar/tickets.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 3, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.tickets),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.auditLogs,
		title: 'Audit Logs',
		iconActive: 'assets/img/icons/sidebar/audit-1.png',
		icon: 'assets/img/icons/sidebar/audit-1.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.auditLogs),
	},
	{
		path: APP_ROUTES.slash + APP_ROUTES.settings,
		title: 'Settings',
		iconActive: 'assets/img/icons/sidebar/settings-active.png',
		icon: 'assets/img/icons/sidebar/settings.png',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		divider: false,
		submenu: [],
		admin_type: [1, 2, 4],
		parent_type: [0, 1, 4],
		adminToClientSwitchVisibility: adminToClientSwitchVisibility(APP_ROUTES.settings),
	},
];
