import { DatePipe } from "@angular/common";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs/internal/observable/interval';
import { map } from 'rxjs/operators/map';
import * as moment from 'moment';

const months = [
    { month: 'Jan', number: 1 },
    { month: 'Feb', number: 2 },
    { month: 'Mar', number: 3 },
    { month: 'Apr', number: 4 },
    { month: 'May', number: 5 },
    { month: 'Jun', number: 6 },
    { month: 'Jul', number: 7 },
    { month: 'Aug', number: 8 },
    { month: 'Sep', number: 9 },
    { month: 'Oct', number: 10 },
    { month: 'Nov', number: 11 },
    { month: 'Dec', number: 12 }
];

export function timeFormat(value: any) {
    value = value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];
    if (value.length > 1) { // If time format correct
        value = value.slice(1);  // Remove full string match value
        value[5] = +value[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        value[0] = +value[0] % 12 || 12; // Adjust hours
    }
    return value.join(''); // return adjusted time or original string
}

export function month(year) {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    let monthArr: any[] = [];
    if (currentYear === +year) {
        let startMonth = 1;
        while (startMonth <= currentMonth) {
            monthArr.push({
                month: months.find(i => i.number === startMonth).month,
                number: startMonth++,
            });
        }
    } else {
        monthArr = months
    }
    return monthArr
}

export function years(): number[] {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    let startYear = 2010;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years;
}

export class DateOnlyPipe extends DatePipe {
    public transform(value): any {
        return super.transform(value, 'MM/dd/yyyy');
    }
}

export function pad(d: any) {
    return (d < 10) ? '0' + d : d;
}

export function objToDateObj(data, type: number = 0) {
    if (data) {
        data = pad(data.day) + '-' + pad(data.month) + '-' + data.year;
        if (type) {
            return data;
        } else {
            return new Date(data);
        }
    }
}
// mm-dd-yyyy
export function objToDateObj2(data, type: number = 0) {
    if (data) {
        data = pad(data.month) + '-' + pad(data.day) + '-' + data.year;
        if (type) {
            return data;
        } else {
            return new Date(data);
        }
    }
}

// date methods
export function dateToObj(data: any) {
    let date: NgbDateStruct;
    let d = new Date(data);
    date = {
        year: d.getFullYear(),
        month: parseInt(pad(d.getMonth() + 1)),
        day: parseInt(pad(d.getDate()))
    };
    return date;
}

export function timeToObj(data: Date) {
    let time: any;
    let t = new Date(data);
    time = {
        hour: t.getHours(),
        minute: t.getMinutes(),
        second: t.getSeconds()
    }
    return time;
}

export function dateTimeFormatter(date: Date) {
    date = new Date(date);
    const dformat = [date.getFullYear(), pad(date.getMonth() + 1), pad(date.getDate())].join('-') + ' ' + [pad(date.getHours()), pad(date.getMinutes()), pad(0)].join(':');
    return dformat;
}

export function dateFormatter(date: Date) {
    date = new Date(date);
    const dformat = [date.getFullYear(), pad(date.getMonth() + 1), pad(date.getDate())].join('-');
    return dformat;
}

export function datetoLocalString(d) {
    let temp = new Date(d);
    // d = temp.toLocaleDateString();
    d = [pad(temp.getDate()), pad(temp.getMonth() + 1), temp.getFullYear()].join('/');
    return d;
}

export function dhms(t) {
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    return [
        //   days + 'd',
        pad(hours) + ':',
        pad(minutes) + ':',
        pad(seconds)
    ].join('');
}

export function countDownTimer(id) {
    const time: any = localStorage.getItem('startTime_' + id);
    return interval(1000).pipe(
        map((x) => {
            return dhms(Math.floor((new Date().getTime() - time) / 1000));
        })
    );
}

export function toUtc(data): string {
    const resDate = moment.utc().format(data);
    const stillUtc = moment.utc(resDate).toDate();
    const localDate = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    return localDate;
}

export function currentMonthRange() {
    const date = new Date();
    let firstDay: any = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay = dateFormatter(firstDay);
    let lastDay: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay = dateFormatter(lastDay);
    return {
        firstDay: firstDay,
        lastDay: lastDay
    }
}

export function yearsDD(startYear: number = 2018) {
    const date = new Date();
    const currentYear: number = date.getFullYear();
    let arr: string[] = [];
    for (let i = startYear; i <= currentYear; i++) {
        const year: any = { name: i.toString() };
        arr.push(year);
    }
    return arr;
}

// moment functions
export function allDay(flag: string, filterDate?: any) {
    if (flag == 'start') {
        if (filterDate) {
            return moment(filterDate).startOf('day').toISOString(true);
        }
        return moment().startOf('day').toISOString(true);
    } else {
        if (filterDate) {
            return moment(filterDate).endOf('day').toISOString(true);
        }
        return moment().endOf('day').toISOString(true);
    }
}

export function active(flag: string, filterDate?: any) {
    if (flag == 'start') {
        if (filterDate) {
            return moment(filterDate).set({ hour: moment().hour(), minute: 0, second: 0, millisecond: 0 }).toISOString(true);
        }
        return moment().set({ hour: moment().hour(), minute: 0, second: 0, millisecond: 0 }).toISOString(true);
    } else {
        if (filterDate) {
            return moment(filterDate).endOf('day').toISOString(true);
        }
        return moment().endOf('day').toISOString(true);
    }
}

export function past(flag: string, filterDate?: any) {
    if (flag == 'start') {
        if (filterDate) {
            return moment(filterDate).startOf('day').toISOString(true);
        }
        return moment().startOf('day').toISOString(true);
    } else {
        if (filterDate) {
            return moment(filterDate).set({ hour: moment().hour(), minute: 0, second: 0, millisecond: 0 }).toISOString(true);
        }
        return moment().set({ hour: moment().hour(), minute: 0, second: 0, millisecond: 0 }).toISOString(true);
    }
}

export function difference(input: any): number {
    const date: any = moment().toISOString();     // current date;
    const currentDate: any = moment().utc(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
    const comparisonDate = moment(input).utc(input).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
    const diff: any = moment(comparisonDate).diff(moment(currentDate));
    return diff;
}