<section class="custom-mat-dialog-wrapper">
    <h2 mat-dialog-title class="px-3 py-2 mb-0 dialog-header-text">Edit Trip {{data?.flag}}</h2>
    <hr class="cutom-divider m-0">
    <mat-dialog-content class="mat-typography px-3 py-2">
        <ng-container *ngIf="data?.flag == 'Time'">
            <form class="body py-4">
                <div class="call d-flex align-items-center">
                    <span class="custom-control custom-switch d-inline-block" (click)="data.will_call = !data.will_call">
                        <input type="checkbox" class="custom-control-input" [checked]="data.will_call">
                        <label class="custom-control-label pointer"></label>
                    </span>
                    Will Call
                </div>
                <div class="form-control">
                    <img src="assets/img/icons/clock.svg" width="20" height="20" alt="time">
                    <select [(ngModel)]="data.hours" [ngModelOptions]="{standalone: true}">
                        <option value="12 AM">00 (12 AM)</option>
                        <option value="01 AM">01 (1 AM)</option>
                        <option value="02 AM">02 (2 AM)</option>
                        <option value="03 AM">03 (3 AM)</option>
                        <option value="04 AM">04 (4 AM)</option>
                        <option value="05 AM">05 (5 AM)</option>
                        <option value="06 AM">06 (6 AM)</option>
                        <option value="07 AM">07 (7 AM)</option>
                        <option value="08 AM">08 (8 AM)</option>
                        <option value="09 AM">09 (9 AM)</option>
                        <option value="10 AM">10 (10 AM)</option>
                        <option value="11 AM">11 (11 AM)</option>
                        <option value="12 PM">12 (12 PM)</option>
                        <option value="01 PM">13 (1 PM)</option>
                        <option value="02 PM">14 (2 PM)</option>
                        <option value="03 PM">15 (3 PM)</option>
                        <option value="04 PM">16 (4 PM)</option>
                        <option value="05 PM">17 (5 PM)</option>
                        <option value="06 PM">18 (6 PM)</option>
                        <option value="07 PM">19 (7 PM)</option>
                        <option value="08 PM">20 (8 PM)</option>
                        <option value="09 PM">21 (9 PM)</option>
                        <option value="10 PM">22 (10 PM)</option>
                        <option value="11 PM">23 (11 PM)</option>
                    </select>
                </div>
                <div class="form-control">
                    <img src="assets/img/icons/clock.svg" width="20" height="20" alt="time">
                    <select [(ngModel)]="data.mins" [ngModelOptions]="{standalone: true}">
                        <option [value]="item" *ngFor="let item of mins">{{ item }} mins</option>
                    </select>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="data?.flag == 'Location' && isApiLoaded">
            <form class="py-4 location-form">
                <div class="row">
                    <div class="col-md-4">
                        <label class="col-form-label">Pickup Location</label>
                    </div>
                    <div class="col-md-8">
                        <input class="form-control"
                            ngx-google-places-autocomplete
                            #placesRef="ngx-places"
                            [options]="options"
                            (onAddressChange)="handleAddressChange($event, 'pick')"
                            [(ngModel)]="data.pickup_location"
                            [ngModelOptions]="{standalone: true}"
                        />
                    </div>
                    <br>
                    <div class="col-md-4">
                        <label class="col-form-label">Dropoff Location</label>
                    </div>
                    <div class="col-md-8">
                        <input class="form-control"
                            ngx-google-places-autocomplete
                            #placesRef="ngx-places"
                            [options]="options"
                            (onAddressChange)="handleAddressChange($event, 'drop')"
                            [(ngModel)]="data.dropoff_location"
                            [ngModelOptions]="{standalone: true}"
                        />
                    </div>
                </div>
            </form>
        </ng-container>
    </mat-dialog-content>
    <hr class="cutom-divider m-0">
    <mat-dialog-actions align="end" class="px-3 py-2">
        <button mat-button mat-dialog-close class="btn button-dialog-cancel button-dialog">Cancel</button>
        <button mat-button class="btn btn-primary button-dialog ml-2" (click)="save()">Save</button>
    </mat-dialog-actions>
</section>