import { environment } from "../../../environments/environment";

export class ApiRoutes {
  // api base url
  // static admin: string = "admin/";
  public static apiBaseUrl: string = environment.apiBaseUrl;

  public static get ipConfig(): string {
    return 'https://ipapi.co/json/';
  }

  // auth
  public static get login(): string {
    return this.apiBaseUrl + "admin_login";
  }
  public static get registration(): string {
    return this.apiBaseUrl + "client_signup";
  }
  public static get sendOtp(): string {
    return this.apiBaseUrl + "otp_send";
  }
  public static get resendOtp(): string {
    return this.apiBaseUrl + "otp_resend";
  }
  public static get resendOtpEmail(): string {
    return this.apiBaseUrl + "otp_email_resend";
  }
  public static get verifyOtp(): string {
    return this.apiBaseUrl + "otp_verify";
  }

  // driver
  public static get drivers_by_type(): string {
    return this.apiBaseUrl + "drivers_by_type";
  }
  public static get drivers_trip_history(): string {
    return this.apiBaseUrl + "get_trip_history";
  }
  public static get driverHoursSummery(): string {
    return this.apiBaseUrl + "driver_hours_summery";
  }
  public static get driverPointSummery(): string {
    return this.apiBaseUrl + "driver_point_summery";
  }
  public static get driverMileageSummery(): string {
    return this.apiBaseUrl + "driver_mileage_summery";
  }
  public static get earningHistory(): string {
    return this.apiBaseUrl + "earning_history";
  }
  public static get get_driver_earnings(): string {
    return this.apiBaseUrl + "get_driver_earnings";
  }
  public static get driverPerformanceAnalyticsByType(): string {
    return this.apiBaseUrl + "driver_performance_analytics_by_type";
  }
  public static get driverPerformanceAnalytics(): string {
    return this.apiBaseUrl + "driver_performance_analytics";
  }
  public static get get_assigned_trip_history(): string {
    return this.apiBaseUrl + "get_assigned_trip_history";
  }
  public static get get_deposited_amount(): string {
    return this.apiBaseUrl + "daily_payout";
  }
  public static get get_driver_analytics(): string {
    return this.apiBaseUrl + "get_driver_analytics";
  }
  public static get blockUnblockDriver(): string {
    return this.apiBaseUrl + "block_unblock_driver";
  }
  public static get carBaseOnlineStatus(): string {
    return this.apiBaseUrl + "update_driver_car_base_status";
  }
  public static get deleteDriver(): string {
    return this.apiBaseUrl + "delete_driver";
  }
  public static get editNewDriverInfo(): string {
    return this.apiBaseUrl + "edit_new_driver";
  }
  public static get getDriverDocs(): string {
    return this.apiBaseUrl + "get_all_docs";
  }
  public static get getVehicleDetails(): string {
    return this.apiBaseUrl + "get_vehicle_details";
  }

  public static get verifyDriverDocs(): string {
    return this.apiBaseUrl + "verify_driver_doc";
  }
  public static get verifyDriver(): string {
    return this.apiBaseUrl + "verify_driver";
  }

  public static get editDriverDoc(): string {
    return this.apiBaseUrl + "edit_document";
  }

  public static get notesDetail(): string {
    return this.apiBaseUrl + "notes_details";
  }

  public static get approveDriver(): string {
    return this.apiBaseUrl + "approve_driver";
  }

  public static get uploadDriverDoc(): string {
    return this.apiBaseUrl + "upload_driver_doc";
  }
  public static get editDriverCarInfo(): string {
    return this.apiBaseUrl + "edit_driver_car_detail";
  }
  public static get addVehicle(): string {
    return this.apiBaseUrl + "add_driver_car_detail";
  }

  public static get driverDetails(): string {
    return this.apiBaseUrl + "driver_details";
  }

  public static get getDriverOngoingTrips(): string {
    return this.apiBaseUrl + "get_driver_ongoing_trips";
  }

  public static get inactiveAllTrips(): string {
    return this.apiBaseUrl + "inactive_all_trips";
  }

  public static get editAssignedTrip(): string {
    return this.apiBaseUrl + "edit_assigned_trip";
  }


  public static get updateTripRadius(): string {
    return this.apiBaseUrl + "trip_radius_setting";
  }

  public static get getTripRadius(): string {
    return this.apiBaseUrl + "fetch_trip_radius_setting";
  }

  //point system
  public static get userPoints(): string {
    return this.apiBaseUrl + "user_points";
  }
  public static get driverPoints(): string {
    return this.apiBaseUrl + "driver_points";
  }

  //promo and offer
  public static get editPromoCode(): string {
    return this.apiBaseUrl + "edit_promocode";
  }
  public static get fetchOffers(): string {
    return this.apiBaseUrl + "fetch_offers";
  }
  public static get fetchOfferTypes(): string {
    return this.apiBaseUrl + "fetch_offer_types";
  }
  public static get deletePromoCode(): string {
    return this.apiBaseUrl + "delete_promocode";
  }

  public static get addPromoCode(): string {
    return this.apiBaseUrl + "add_promocode";
  }
  public static get getCountryListData(): string {
    return this.apiBaseUrl + "get_country_list";
  }

  //offer
  public static get activeInactiveOffer(): string {
    return this.apiBaseUrl + "active_inactive_offer";
  }
  public static get setDefaultOffer(): string {
    return this.apiBaseUrl + "set_default_offer";
  }
  public static get deleteOffer(): string {
    return this.apiBaseUrl + "delete_offer";
  }

  public static get activeInactiveOfferType(): string {
    return this.apiBaseUrl + "active_inactive_offer_type";
  }

  public static get deleteOfferType(): string {
    return this.apiBaseUrl + "delete_offer_type";
  }
  public static get makeOfferType(): string {
    return this.apiBaseUrl + "make_offer_type";
  }
  public static get offerTypeList(): string {
    return this.apiBaseUrl + "offer_type_list";
  }
  public static get promoList(): string {
    return this.apiBaseUrl + "promo_list";
  }
  public static get makeOffer(): string {
    return this.apiBaseUrl + "make_offer";
  }

  //reports
  public static get getRidesByType(): string {
    return this.apiBaseUrl + "get_rides_by_type";
  }
  public static get getTlcRides(): string {
    return this.apiBaseUrl + "get_tlc_rides";
  }
  public static get getAssignedTlcRides(): string {
    return this.apiBaseUrl + "get_assigned_tlc_rides";
  }
  public static get getAssignedNycRides(): string {
    return this.apiBaseUrl + "get_assigned_nyc_rides";
  }
  public static get getReports(): string {
    return this.apiBaseUrl + "get_reports";
  }
  public static get paymentPayout(): string {
    return this.apiBaseUrl + "payment_payout";
  }

  public static get getTlcDrivers(): string {
    return this.apiBaseUrl + "tlc_drivers";
  }

  public static get getTlcVehicles(): string {
    return this.apiBaseUrl + "tlc_vehicles";
  }
  //common
  public static get getPromocode(): string {
    return this.apiBaseUrl + "get_promocode";
  }
  public static get getAllDocs(): string {
    return this.apiBaseUrl + "get_document_types";
  }
  public static get getAllCarTypes(): string {
    return this.apiBaseUrl + "get_all_car_types";
  }
  public static get getBankDetails(): string {
    return this.apiBaseUrl + "view_sub_merchant_account_details";
  }
  public static get fetchNotes(): string {
    return this.apiBaseUrl + "fetch_notes";
  }
  public static get deleteNotes(): string {
    return this.apiBaseUrl + "delete_notes";
  }
  public static get createNotes(): string {
    return this.apiBaseUrl + "create_notes";
  }
  public static get changeNetworkSetting(): string {
    return this.apiBaseUrl + "change_network_setting";
  }
  public static get syncDriverNetwork(): string {
    return this.apiBaseUrl + "sync_driver_network";
  }
  public static get editNotes(): string {
    return this.apiBaseUrl + "update_notes";
  }
  public static get validInvalidDoc(): string {
    return this.apiBaseUrl + "valid_invalid_doc";
  }
  public static get getDocumentTypes(): string {
    return this.apiBaseUrl + "get_document_types";
  }
  public static get driverPoolSettings(): string {
    return this.apiBaseUrl + "get_driver_pool_client";
  }
  public static get carBasePoolList(): string {
    return this.apiBaseUrl + "get_client_pool";
  }
  public static get addDocumentType(): string {
    return this.apiBaseUrl + "add_document_type";
  }
  public static get activeInactiveDocumentType(): string {
    return this.apiBaseUrl + "active_inactive_document_type";
  }
  public static get editDocumentType(): string {
    return this.apiBaseUrl + "edit_document_type";
  }
  public static get getDashboardUserList(): string {
    return this.apiBaseUrl + "dashboard_user_list";
  }

  public static get updateDriverNetworkFee(): string {
    return this.apiBaseUrl + "update_driver_network_fee"
  }

  // corporates
  public static get corporates_list(): string {
    return this.apiBaseUrl + "corporate_list";
  }
  public static get deleteCorporate(): string {
    return this.apiBaseUrl + "delete_corporate";
  }
  public static get editCorporate(): string {
    return this.apiBaseUrl + "edit_corporate";
  }
  public static get corporate_trip_history(): string {
    return this.apiBaseUrl + "get_corporate_trip_history";
  }
  public static get get_corporate_earnings(): string {
    return this.apiBaseUrl + "get_corporate_earnings";
  }
  public static get get_corporate_analytics(): string {
    return this.apiBaseUrl + "get_corporate_analytics";
  }
  public static get getCorporateDocs(): string {
    return this.apiBaseUrl + "get_all_corporate_docs";
  }

  public static get uploadCorporateDoc(): string {
    return this.apiBaseUrl + "upload_corporate_doc";
  }

  public static get editCorporateDoc(): string {
    return this.apiBaseUrl + "edit_corporate_document";
  }

  public static get verifyCorporateDoc(): string {
    return this.apiBaseUrl + "verify_corporate_doc";
  }

  public static get verifyCorporate(): string {
    return this.apiBaseUrl + "verify_corporate";
  }

  public static get validInvalidCorporateDoc(): string {
    return this.apiBaseUrl + "valid_invalid_corporate_doc";
  }
  public static get getCorporateBankDetails(): string {
    return (
      this.apiBaseUrl +
      "view_corporate_sub_merchant_account_details"
    );
  }

  public static get editCorporateSetting(): string {
    return this.apiBaseUrl + "edit_corporate_setting";
  }

  public static get updateCorporateBankDetails(): string {
    return (
      this.apiBaseUrl + "update_corporate_sub_merchant_account"
    );
  }
  public static get completedRides(): string {
    return this.apiBaseUrl + "completed_rides";
  }

  //rides
  public static get enableDisableFareV1(): string {
    return this.apiBaseUrl + "enable_disable_fare_v1";
  }
  public static get enableDisableAreaFare(): string {
    return this.apiBaseUrl + "enable_disable_area_fare";
  }
  //tickets
  public static get fetchTickets(): string {
    return this.apiBaseUrl + "fetch_tickets";
  }
  public static get deleteTicket(): string {
    return this.apiBaseUrl + "delete_ticket";
  }
  public static get updateTicket(): string {
    return this.apiBaseUrl + "update_ticket";
  }
  public static get customChargesInfoSearch(): string {
    return this.apiBaseUrl + "custom_charges_info_search";
  }
  public static get addTicket(): string {
    return this.apiBaseUrl + "add_ticket";
  }
  public static get fetchTicketById(): string {
    return this.apiBaseUrl + "fetch_ticket_by_id";
  }

  // language
  public static get languages(): string {
    return this.apiBaseUrl + "get_languages";
  }

  // users
  public static get passengers(): string {
    return this.apiBaseUrl + "user_list";
  }
  public static get passengerDetail(): string {
    return this.apiBaseUrl + "user_details";
  }
  public static get editUser(): string {
    return this.apiBaseUrl + "edit_user";
  }
  public static get userPrefrences(): string {
    return this.apiBaseUrl + "fetch_preference";
  }
  public static get setUserPrefrences(): string {
    return this.apiBaseUrl + "set_preference";
  }
  public static get blockUnBlockUser(): string {
    return this.apiBaseUrl + "block_unblock_user";
  }
  public static get deleteUser(): string {
    return this.apiBaseUrl + "delete/user";
  }
  public static get vendorList(): string {
    return this.apiBaseUrl + "vendor_list";
  }
  public static get vendoblockUnblock(): string {
    return this.apiBaseUrl + "block_unblock_vendor";
  }
  public static get vendorDelete(): string {
    return this.apiBaseUrl + "delete_vendor";
  }
  public static get vendorDetail(): string {
    return this.apiBaseUrl + "vendor_details";
  }
  public static get listDocumentType(): string {
    return this.apiBaseUrl + "get_document_types";
  }
  public static get uploadVendorDoc(): string {
    return this.apiBaseUrl + "upload_vendor_doc";
  }
  public static get editVendorDoc(): string {
    return this.apiBaseUrl + "edit_vendor_document";
  }
  public static get getVendorDocs(): string {
    return this.apiBaseUrl + "get_all_vendor_docs";
  }
  public static get verifyVendorDoc(): string {
    return this.apiBaseUrl + "verify_vendor_doc";
  }
  public static get verifyClientDoc(): string {
    return this.apiBaseUrl + "verify_client_doc";
  }
  public static get validInvalidVendorDoc(): string {
    return this.apiBaseUrl + "valid_invalid_vendor_doc";
  }
  public static get validInvalidClientDoc(): string {
    return this.apiBaseUrl + "valid_invalid_client_doc";
  }
  public static get editVendor(): string {
    return this.apiBaseUrl + "edit_vendor";
  }
  public static get approveVendor(): string {
    return this.apiBaseUrl + "approve_vendor";
  }
  public static get vendorBankInfo(): string {
    return (
      this.apiBaseUrl + "view_vendor_submerchant_account_details"
    );
  }
  public static get clientBankInfo(): string {
    return this.apiBaseUrl + "client_view_submerchant_account_details";
  }
  public static get vendorUpdateExternalAccount(): string {
    return this.apiBaseUrl + "update_external_account";
  }
  public static get updateSubmerchantAccount(): string {
    return this.apiBaseUrl + "update_sub_merchant_account";
  }
  public static get addSubmerchantAccount(): string {
    return this.apiBaseUrl + "add_sub_merchant_account";
  }
  public static get addVendorSubmerchantAccount(): string {
    return this.apiBaseUrl + "add_vendor_submerchant_account";
  }
  public static get addCorporateSubmerchantAccount(): string {
    return this.apiBaseUrl + "add_corporate_submerchant_account";
  }
  public static get verifyVendor(): string {
    return this.apiBaseUrl + "verify_vendor";
  }
  public static get customPayouts(): string {
    return this.apiBaseUrl + "custom_payout_info";
  }
  public static get customCharges(): string {
    return this.apiBaseUrl + "custom_charges_info";
  }
  public static get disputeList(): string {
    return this.apiBaseUrl + "dispute_info";
  }
  public static get revenueList(): string {
    return this.apiBaseUrl + "revenue_details";
  }
  public static get driverTripInfoCustomPayout(): string {
    return this.apiBaseUrl + "driver_trip_info_custom_payout";
  }
  public static get createCustomPayout(): string {
    return this.apiBaseUrl + "custom_payment_v2";
  }
  public static get customChargeInfoSearch(): string {
    return this.apiBaseUrl + "custom_charges_info_search";
  }
  public static get customChargePayment(): string {
    return this.apiBaseUrl + "custom_charge_payment";
  }
  public static get driverTripInfoDispute(): string {
    return this.apiBaseUrl + "driver_trip_info_dispute";
  }
  public static get raiseDispute(): string {
    return this.apiBaseUrl + "dispute_payment";
  }
  public static get fetchOrientationVideos(): string {
    return this.apiBaseUrl + "fetch_orientation_videos";
  }
  public static get fetchVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "fetch_virtual_orientation_videos";
  }
  public static get getVoById(): string {
    return this.apiBaseUrl + "fetch_virtual_orientation_video_by_id";
  }
  public static get editVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "edit_virtual_orientation_video";
  }
  public static get addVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "add_virtual_orientation_video";
  }
  public static get addStatusVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "update_user_orientation_video_status";
  }
  public static get deleteVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "delete_virtual_orientation_video";
  }
  public static get editCarType(): string {
    return this.apiBaseUrl + "edit_car_type";
  }
  public static get addCarType(): string {
    return this.apiBaseUrl + "add_car_type";
  }
  public static get fetchSendorSubtype(): string {
    return this.apiBaseUrl + "fetch_vendor_subtype";
  }
  public static get createVendorSubType(): string {
    return this.apiBaseUrl + "create_vendor_subtype";
  }
  public static get editVendorSubType(): string {
    return this.apiBaseUrl + "edit_vendor_subtype";
  }
  public static get deleteDashboardUser(): string {
    return this.apiBaseUrl + "delete_dashboard_user";
  }
  public static get editDashboardUser(): string {
    return this.apiBaseUrl + "edit_dashboard_user";
  }
  public static get addDashboardUser(): string {
    return this.apiBaseUrl + "create_dashboard_user";
  }
  public static get getLivemapuser(): string {
    return this.apiBaseUrl + "get_livemapuser";
  }
  public static get editLivemapuser(): string {
    return this.apiBaseUrl + "update_liveMapUser";
  }
  public static get addLivemapuser(): string {
    return this.apiBaseUrl + "add_livemapUser";
  }
  public static get deleteLivemapuser(): string {
    return this.apiBaseUrl + "delete_livemapUser";
  }
  public static get getAdminUsers(): string {
    return this.apiBaseUrl + "get_admin_user";
  }
  public static get deleteAdminUser(): string {
    return this.apiBaseUrl + "delete_admin_user";
  }
  public static get addAdminUser(): string {
    return this.apiBaseUrl + "add_admin_user";
  }
  public static get editAdminUser(): string {
    return this.apiBaseUrl + "update_admin_user";
  }
  public static get getAllRegions(): string {
    return this.apiBaseUrl + "get_all_regions";
  }
  public static get getArea(): string {
    return this.apiBaseUrl + "get_area";
  }
  public static get addArea(): string {
    return this.apiBaseUrl + "add_area";
  }
  public static get addRegion(): string {
    return this.apiBaseUrl + "add_region";
  }
  public static get masterMerchanInfo(): string {
    return this.apiBaseUrl + "master_merchant_Info";
  }
  public static get addTopUp(): string {
    return this.apiBaseUrl + "master_merchant_topup";
  }
  public static get regionDetail(): string {
    return this.apiBaseUrl + "get_regional_details";
  }
  public static get getAreaFareDetail(): string {
    return this.apiBaseUrl + "get_area_fare_detail";
  }
  public static get getAreaFare(): string {
    return this.apiBaseUrl + "get_area_fare";
  }
  public static get getRegionalFareV1(): string {
    return this.apiBaseUrl + "get_regional_fare_v1";
  }
  public static get updateRegionalFare(): string {
    return this.apiBaseUrl + "update_regional_fare";
  }
  public static get updateAreaFare(): string {
    return this.apiBaseUrl + "update_area_fare";
  }
  public static get updateCarTypeFare(): string {
    return this.apiBaseUrl + "update_car_type_fare";
  }
  public static get addCarTypeFare(): string {
    return this.apiBaseUrl + "add_car_type_fare";
  }
  public static get addRegionalFare(): string {
    return this.apiBaseUrl + "add_regional_fare";
  }
  public static get getCarTypeFare(): string {
    return this.apiBaseUrl + "get_car_type_fare";
  }
  public static get getCarTypeFareDetail(): string {
    return this.apiBaseUrl + "get_fare_by_car_type";
  }
  public static get auditLogs(): string {
    return this.apiBaseUrl + "audit_logs";
  }
  public static get getClientList(): string {
    return this.apiBaseUrl + "client_list";
  }
  public static get getAssignedTrip(): string {
    return this.apiBaseUrl + "get_assigned_trip";
  }
  public static get getRideAnalytics(): string {
    return this.apiBaseUrl + "ride_analytics";
  }
  public static get getQueuedTrip(): string {
    return this.apiBaseUrl + "get_queued_trip";
  }
  public static get approveVerifyClient(): string {
    return this.apiBaseUrl + "client_approve_verify";
  }
  public static get getClientAccDetail(): string {
    return (
      this.apiBaseUrl + "client_view_submerchant_account_details"
    );
  }
  public static get addClientMerchant(): string {
    return this.apiBaseUrl + "client_add_submerchant_account";
  }
  public static get updateClientMerchant(): string {
    return this.apiBaseUrl + "client_update_submerchant";
  }
  public static get rides(): string {
    return this.apiBaseUrl + "rides";
  }
  // New revenue detail v2
  public static get revenueDetailsV2(): string {
    return this.apiBaseUrl + "revenue_details_v2";
  }
  public static get ridesSingle(): string {
    return this.apiBaseUrl + "fetch_ride_by_id";
  }
  public static get userHome(): string {
    return this.apiBaseUrl + "home_users";
  }
  public static get ridesHome(): string {
    return this.apiBaseUrl + "home_rides";
  }
  public static get paymentHome(): string {
    return this.apiBaseUrl + "home_payments";
  }
  public static get promoHome(): string {
    return this.apiBaseUrl + "home_promos";
  }
  public static get homeAnalytics(): string {
    return this.apiBaseUrl + "home_analytics";
  }
  public static get homeAnalyticsByType(): string {
    return this.apiBaseUrl + "home_analytics_by_type";
  }

  public static get getCountryList(): string {
    return this.apiBaseUrl + "get_country_list";
  }

  public static get getStateList(): string {
    return this.apiBaseUrl + "get_states";
  }
  public static get getStateList1(): string {
    return this.apiBaseUrl + "get_states";
  }
  public static get getCampList(): string {
    return this.apiBaseUrl + "fetch_campaign";
  }
  public static get getCampDetail(): string {
    return this.apiBaseUrl + "fetch_campaign_id";
  }
  public static get getRegionalFareDetails(): string {
    return this.apiBaseUrl + "get_regional_fare_details";
  }
  public static get getClientDetail(): string {
    return this.apiBaseUrl + "client_details";
  }
  public static get clientDelete(): string {
    return this.apiBaseUrl + "delete_client";
  }
  public static get blockUnblockClient(): string {
    return this.apiBaseUrl + "block_unblock_client";
  }
  public static get editClient(): string {
    return this.apiBaseUrl + "edit_client";
  }

  public static get addCampaign(): string {
    return this.apiBaseUrl + "add_campaign";
  }

  public static get sendCampaign(): string {
    return this.apiBaseUrl + "send_campaign_message";
  }
  public static get editCampaign(): string {
    return this.apiBaseUrl + "edit_campaign";
  }
  public static get deleteCampaign(): string {
    return this.apiBaseUrl + "delete_campaign";
  }
  public static get duplicateCampaign(): string {
    return this.apiBaseUrl + "add_duplicate_campaign";
  }


  public static get testCampaign(): string {
    return this.apiBaseUrl + "send_test_campaign_message";
  }
  public static get deleteVehicle(): string {
    return this.apiBaseUrl + "delete_driver_car_detail";
  }
  public static get paymentDetail(): string {
    return this.apiBaseUrl + "payment_details";
  }
  public static get blockUnblockCorporate(): string {
    return this.apiBaseUrl + "block_unblock_corporate";
  }
  public static get approveCorporate(): string {
    return this.apiBaseUrl + "approve_corporate";
  }
  public static get addCorporatePayout(): string {
    return this.apiBaseUrl + "add_corporate_submerchant_account"
  }
  public static get updateCorporatePayout(): string {
    return this.apiBaseUrl + "update_corporate_sub_merchant_account"
  }
  public static get getCorporateDetail(): string {
    return this.apiBaseUrl + "corporate_details";
  }
  public static get mobileForgotPassword(): string {
    return this.apiBaseUrl + "mobile_forgot_password";
  }
  public static get resetPassword(): string {
    return this.apiBaseUrl + "reset_password";
  }
  public static get getEmailVerifyOtp(): string {
    return this.apiBaseUrl + "otp_email_send";
  }
  public static get verifyEmail(): string {
    return this.apiBaseUrl + "otp_email_verify";
  }
  public static get addNewBusiness(): string {
    return this.apiBaseUrl + "add_new_business_client";
  }
  public static get getUserStatus(): string {
    return this.apiBaseUrl + "get_user_status";
  }
  public static get getClientDocs(): string {
    return this.apiBaseUrl + "docs_fetch";
  }
  public static get clientDocUpload(): string {
    return this.apiBaseUrl + "docs_upload";
  }
  public static get clientDocedit(): string {
    return this.apiBaseUrl + "docs_edit";
  }
  public static get getNewBusinessClient(): string {
    return this.apiBaseUrl + "get_new_business_client";
  }
  public static get fetchClients(): string {
    return this.apiBaseUrl + "fetch_clients";
  }

  public static get fetchCorporateAndClient(): string {
    return this.apiBaseUrl + "list_corp_nd_client";
  }

  public static get fetchPromocodeById(): string {
    return this.apiBaseUrl + "fetch_promocode_by_id";
  }
  public static get fetchCorporateSubtype(): string {
    return this.apiBaseUrl + "fetch_corporate_subtype";
  }
  public static get fetchOfferTypeById(): string {
    return this.apiBaseUrl + "fetch_offer_type_by_id";
  }
  public static get fetchOfferById(): string {
    return this.apiBaseUrl + "fetch_offer_by_id";
  }
  public static get inviteLink(): string {
    return this.apiBaseUrl + "send_invites";
  }
  public static get updateSubmerchant(): string {
    return this.apiBaseUrl + "update_submerchant";
  }
  public static get checkBusinessEinAvailability(): string {
    return this.apiBaseUrl + "check_client_detail";
  }
  public static get editDefaultClient(): string {
    return this.apiBaseUrl + "edit_default_client";
  }
  public static get changeVirtualOrientationStatus(): string {
    return this.apiBaseUrl + "virtual_orientation_video_status";
  }
  public static get changeVirtualOrientationBlockAndUnBlocked(): string {
    return this.apiBaseUrl + "virtual_orientation_video_blocked_status";
  }
  public static get fetchClientVirtualOrientationVideos(): string {
    return this.apiBaseUrl + "fetch_client_virtual_orientation_videos";
  }
  public static get virtualOrientationVideoMandatoryStatus(): string {
    return this.apiBaseUrl + "virtual_orientation_video_mandatory_status";
  }
  public static get editUserDocumentType(): string {
    return this.apiBaseUrl + "edit_user_document_type";
  }
  public static get updateDriverPoolAccess(): string {
    return this.apiBaseUrl + "update_driver_pool_client";
  }
  public static get updateCarBasePoolAccess(): string {
    return this.apiBaseUrl + "update_client_pool";
  }
  public static get updateCarBaseAffiliation(): string {
    return this.apiBaseUrl + "update_default_car_base_affiliation";
  }
  public static get addUserCarBase(): string {
    return this.apiBaseUrl + "add_user_car_base";
  }
  public static get getUserCarBase(): string {
    return this.apiBaseUrl + "get_user_car_base";
  }
  public static get updateDefaultCarBaseAffiliation(): string {
    return this.apiBaseUrl + "update_default_car_base_affiliation";
  }
  public static get removeCarBaseAffiliation(): string {
    return this.apiBaseUrl + "remove_user_car_base";
  }
  public static get deleteCarTypeFare(): string {
    return this.apiBaseUrl + "delete_car_type_fare";
  }
  public static get changeCarTypeStatus(): string {
    return this.apiBaseUrl + "change_car_type_status";
  }
  public static get carbaseAffiliationBanner(): string {
    return this.apiBaseUrl + "carbase_affiliation_banner";
  }
  public static get getCarbaseAffiliationBanner(): string {
    return this.apiBaseUrl + "get_carbase_affiliation_banner";
  }

  //Campaign
  public static get addContact(): string {
    return `${this.apiBaseUrl}add_contacts`;
  }
  public static get editContact(): string {
    return `${this.apiBaseUrl}edit_contact_id`;
  }
  public static get getContact(): string {
    return `${this.apiBaseUrl}fetch_contacts`;
  }
  public static get getContactByID(): string {
    return this.apiBaseUrl + "fetch_contact_by_id";
  }
  public static get editContactGroup(): string {
    return this.apiBaseUrl + "edit_contact_group";
  }
  public static get getContactGroups(): string {
    return `${this.apiBaseUrl}fetch_contact_groups`;
  }
  public static get getContactByGroupID(): string {
    return `${this.apiBaseUrl}fetch_contact_group_id`;
  }
  public static get campHome(): string {
    return this.apiBaseUrl + "home_campaign";
  }
  public static get deleteContactGroup(): string {
    return this.apiBaseUrl + "delete_contact";
  }
  public static get deleteContactBYID(): string {
    return this.apiBaseUrl + "delete_contact_id";
  }
  public static get groupContacts(): string {
    return this.apiBaseUrl + "fetch_contact_groups";
  }
  public static get deletePerticularContactUsingGroupId(): string {
    return this.apiBaseUrl + "delete_contact";
  }

  public static get mapKeyAdd(): string {
    return this.apiBaseUrl + "third_party_integration";
  }
  public static get fetchKeyAdd(): string {
    return this.apiBaseUrl + "fetch_third_party_integration";
  }
  public static get addMassDriver(): string {
    return this.apiBaseUrl + "add_mas_driver";
  }

  public static get startMasSession(): string {
    return this.apiBaseUrl + "mas_start_session";
  }

  public static get activeDeactiveMasDriver(): string {
    return this.apiBaseUrl + "active_deactive_mas_driver";
  }

  public static get downloadDocs(): string {
    return this.apiBaseUrl + "download_doc";
  }

  public static get getDriversForQueueTrips(): string {
    return this.apiBaseUrl + "get_driver";
  }

  public static get masTripRoaster(): string {
    return this.apiBaseUrl + "mas_trip_roster_request";
  }

  public static get checkMobile(): string {
    return this.apiBaseUrl + "check_mobile";
  }

  public static get registerUser(): string {
    return this.apiBaseUrl + "new_user_register";
  }

  public static get assignDriverMasTrip(): string {
    return this.apiBaseUrl + "assign_driver_mas_trip";
  }

  public static get getQueueDriverInfo(): string {
    return this.apiBaseUrl + "get_queue_driver_info";
  }

  public static get unqueueTrip(): string {
    return this.apiBaseUrl + "edit_queue_mas_trip";
  }

  public static get cancelMasTrip(): string {
    return this.apiBaseUrl + "cancel_mas_trip";
  }

  public static get editAssignMasTrip(): string {
    return this.apiBaseUrl + "edit_assign_mas_trip";
  }

  public static get sendAcceptanceAlertToDriver(): string {
    return this.apiBaseUrl + "send_acceptance_alert_to_driver";
  }

  public static get listBank(): string {
    return this.apiBaseUrl + "list_bank";
  }

  public static get addBank(): string {
    return this.apiBaseUrl + "add_bank";
  }

  public static get deleteBank(): string {
    return this.apiBaseUrl + "delete_bank";
  }

  public static get updateBank(): string {
    return this.apiBaseUrl + "update_bank";
  }

  public static get getWalletBalance(): string {
    return this.apiBaseUrl + "get_wallet_balance";
  }

  public static get getBalance(): string {
    return this.apiBaseUrl + "get_balance";
  }

  public static get addWalletbalance(): string {
    return this.apiBaseUrl + "add_wallet_balance";
  }

  public static get verifyBank(): string {
    return this.apiBaseUrl + "verify_bank";
  }

  public static get loadWalletPaymentHistory(): string {
    return this.apiBaseUrl + "load_wallet_payment_history";
  }

  public static get listPaymentHistory(): string {
    return this.apiBaseUrl + "list_payment_history";
  }

  public static get loadTransactionHistory(): string {
    return this.apiBaseUrl + "load_wallet_trans_history";
  }

  public static get listTransHistory(): string {
    return this.apiBaseUrl + "list_trans_history";
  }

  public static get makeWalletPayment(): string {
    return this.apiBaseUrl + "make_wallet_payment";
  }

  public static get editAssignDriverMasTrip(): string {
    return this.apiBaseUrl + "edit_assign_driver_mas_trip";
  }

  public static get getSuggestedFare(): string {
    return this.apiBaseUrl + "get_suggested_fare";
  }

  public static get businessCenterAnalyticsByType(): string {
    return this.apiBaseUrl + "business_center_analytics_by_type";
  }

  public static get businessCenterAnalytics(): string {
    return this.apiBaseUrl + "business_center_analytics";
  }

  public static get globalBusinessCenterAnalyticsByType(): string {
    return this.apiBaseUrl + "global_business_analytics_by_type";
  }

  public static get globalBusinessCenterAnalytics(): string {
    return this.apiBaseUrl + "global_business_analytics";
  }

  public static get plaidLinkToken(): string {
    return this.apiBaseUrl + "plaid_link_token";
  }

  public static get getUserSecondaryContact(): string {
    return this.apiBaseUrl + "get_user_secondary_contact";
  }

  public static get addUserSecondaryContact(): string {
    return this.apiBaseUrl + "add_user_secondary_contact";
  }

  public static get updateUserSecondaryContact(): string {
    return this.apiBaseUrl + "update_user_secondary_contact";
  }

  public static get syncMasDriverDetail(): string {
    return this.apiBaseUrl + "sync_mas_driver_detail";
  }

  /** Version 2*/
  public static get tripsV2(): string {
    return this.apiBaseUrl + "trips_v2";
  }

  public static get corporateDailyPayout(): string {
    return this.apiBaseUrl + "corporate_daily_payout";
  }

  public static get clientEarningHistory(): string {
    return this.apiBaseUrl + "get_corporate_earning_history";
  }

  public static get clientPayloadList(): string {
    return this.apiBaseUrl + "list_client_submerchant_account";
  }

  public static get clientAddSubmerchantAccount(): string {
    return this.apiBaseUrl + "client_add_submerchant_account";
  }

  public static get clientSubmerchantRemove(): string {
    return this.apiBaseUrl + "delete_sub_merchant_account";
  }

  public static get clientSubmerchantDefault(): string {
    return this.apiBaseUrl + "default_sub_merchant_account";
  }

  public static get commonDepositedAmount(): string {
    return this.apiBaseUrl + "list_diposted_amount";
  }

  public static get corporateAccountList(): string {
    return this.apiBaseUrl + "corporate_account_list";
  }

  public static get get_client_earnings(): string {
    return this.apiBaseUrl + "get_client_earning";
  }
  public static get get_client_analytics(): string {
    return this.apiBaseUrl + "get_client_analytics";
  }

  public static get get_admin_business_center_analytics(): string {
    return this.apiBaseUrl + "admin_business_center_analytics";
  }
  public static get get_admin_business_center_earnings(): string {
    return this.apiBaseUrl + "admin_business_center_earnings";
  }


  /** Ride Broker */
  public static get get_ride_broker(): string {
    return this.apiBaseUrl + "list_ride_brokers";
  }
  public static get create_driver(): string {
    return this.apiBaseUrl + "create_driver";
  }

  public static get add_ride_broker(): string {
    return this.apiBaseUrl + "add_ride_broker";
  }

  public static get update_ride_broker(): string {
    return this.apiBaseUrl + "update_ride_broker";
  }

  public static get change_ride_broker_status(): string {
    return this.apiBaseUrl + "change_ride_broker_status";
  }

  public static get add_broker_api_key(): string {
    return this.apiBaseUrl + "add_broker_api_key";
  }

  public static get fetch_broker_status(): string {
    return this.apiBaseUrl + "fetch_broker_status";
  }

  public static get remove_ride_broker(): string {
    return this.apiBaseUrl + "remove_ride_broker";
  }

  public static get create_subscription(): string {
    return this.apiBaseUrl + "create_subscription";
  }

  public static get edit_subscription(): string {
    return this.apiBaseUrl + "edit_subscription";
  }

  public static get get_subscription(): string {
    return this.apiBaseUrl + "get_subscription";
  }

  public static get change_subs_status(): string {
    return this.apiBaseUrl + "change_subs_status";
  }

  public static get list_subscription(): string {
    return this.apiBaseUrl + "list_subscription";
  }

  public static get list_subscription_history(): string {
    return this.apiBaseUrl + "list_subscription_history";
  }

  public static get pay_subscription(): string {
    return this.apiBaseUrl + "pay_subscription";
  }

  public static get client_billing_account(): string {
    return this.apiBaseUrl + "client_billing_account";
  }

  public static get list_client_billing_account(): string {
    return this.apiBaseUrl + "list_client_billing_account";
  }

  public static get remove_client_billing_account(): string {
    return this.apiBaseUrl + "remove_client_billing_account";
  }

  public static get default_client_billing_account(): string {
    return this.apiBaseUrl + "default_client_billing_account";
  }

  public static get list_billing_earnings(): string {
    return this.apiBaseUrl + "list_billing_earnings";
  }

  public static get list_diposted_billing_amount(): string {
    return this.apiBaseUrl + "list_diposted_billing_amount";
  }

  /** Driver Shift  */
  public static get get_driver_work_shifts(): string {
    return this.apiBaseUrl + "get_driver_work_shifts";
  }

  public static get update_driver_work_shifts(): string {
    return this.apiBaseUrl + "update_driver_work_shifts";
  }

}