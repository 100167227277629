import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { APP_ROUTES } from '../routes';
import { LocalStorage } from '../../storage';
import { ROUTES } from '../components/vertical-menu/vertical-menu-routes.config';

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(private router: Router, private ls: LocalStorage) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userStatus: any = this.ls.getItem('qudos-admin-userStatus');
        const currentUser: any = this.ls.getItem('qudos-admin-currentUser');
        const selectedClient: any = this.ls.getItem('qudos-admin-selectedClient');
        if (userStatus && (!userStatus.email_verified || !userStatus.docs) && currentUser.admin_type === 4) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }

        if ((state.url.includes(APP_ROUTES.demoRequest) || state.url.includes('/clients') || state.url.includes('/add-area') || state.url.includes('/area-list') || state.url.includes('/area')) && currentUser.admin_type === 4) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }

        if (selectedClient && state.url.includes('/view-area')) {
            return true
        }

        if ((state.url.includes('/view-area')) && currentUser.admin_type != 4) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        if ((state.url.includes('/clients') || state.url.includes(APP_ROUTES.demoRequest)) && selectedClient) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        if (state.url.includes('/payment-setting') && selectedClient && currentUser.admin_type !== 4) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        /** Revanue tab is in maintanance */
        // if (state.url.includes('/revenue')) {
        //     this.router.navigate([APP_ROUTES.dashboard]);
        //     return false;
        // }
        // operator
        if ((state.url.includes(APP_ROUTES.revenue) || state.url.includes(APP_ROUTES.payments) || state.url.includes(APP_ROUTES.customPayouts) || state.url.includes(APP_ROUTES.customCharge) ||
            state.url.includes(APP_ROUTES.disputes) || state.url.includes(APP_ROUTES.promosAndOffers) || state.url.includes(APP_ROUTES.promoCodes) || state.url.includes(APP_ROUTES.offers) ||
            state.url.includes(APP_ROUTES.virtualOrientation) || state.url.includes(APP_ROUTES.analytics) || state.url.includes(APP_ROUTES.reports) ||
            state.url.includes(APP_ROUTES.clients) || state.url.includes(APP_ROUTES.auditLogs) || state.url.includes(APP_ROUTES.settings) || state.url.includes(APP_ROUTES.affiliateTypes) ||
            state.url.includes(APP_ROUTES.documentTypes) || state.url.includes(APP_ROUTES.dashboardUsers) || state.url.includes(APP_ROUTES.liveMapUsers) || state.url.includes(APP_ROUTES.adminUsers) ||
            state.url.includes(APP_ROUTES.countryList) || state.url.includes(APP_ROUTES.viewAreaClient) || state.url.includes(APP_ROUTES.area) || state.url.includes(APP_ROUTES.carDetail) ||
            state.url.includes(APP_ROUTES.carRates) || state.url.includes(APP_ROUTES.pointSetting) || state.url.includes(APP_ROUTES.demoRequest)) && currentUser.admin_type == 3) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        // --------------------------------------------------------------------------------------

        // child admin
        if ((state.url.includes(APP_ROUTES.clients)) && (currentUser.admin_type == 2 && currentUser?.parent_type == 4)) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        // ---------------------------------------------------------------------------------------

        // for regulation type others(2)
        if (currentUser.admin_type != 0 && currentUser?.regulation_type == 2 && (state.url.includes(APP_ROUTES.passengers) || state.url.includes(APP_ROUTES.corporates) || state.url.includes(APP_ROUTES.affiliates) ||
            state.url.includes(APP_ROUTES.trips) || state.url.includes(APP_ROUTES.payments) || state.url.includes(APP_ROUTES.customCharge) || state.url.includes(APP_ROUTES.customPayouts) ||
            state.url.includes(APP_ROUTES.promosAndOffers) || state.url.includes(APP_ROUTES.promoCodes) || state.url.includes(APP_ROUTES.offers) || state.url.includes(APP_ROUTES.pointSystem) ||
            state.url.includes(APP_ROUTES.businessCenter) || state.url.includes(APP_ROUTES.analytics) || state.url.includes(APP_ROUTES.viewAreaClient) || state.url.includes(APP_ROUTES.carRates))) {
            this.router.navigate([APP_ROUTES.dashboard]);
            return false;
        }
        // ----------------------------------------------------------------------------------------
        return true
    }
}