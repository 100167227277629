import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorModule } from "ngx-device-detector";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { CookieService, CookieModule } from "@gorniv/ngx-universal";
import { ToastrModule } from "ngx-toastr";
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService, AuthGuard, UnAuthGuard, AccessGuard } from "./shared/auth";
import { InternetInterceptor, RequestInterceptor, ResponseInterceptor } from "./interceptors";
import { CookieStorage, LocalStorage } from "./storage";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { SearchResultComponent } from './layouts/full/search-result/search-result.component';

// mat modules
import { MatMenuModule } from '@angular/material/menu';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};

@NgModule({
	declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, SearchResultComponent],
	imports: [
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		NgbModule,
		NgxSpinnerModule,
		DeviceDetectorModule.forRoot(),
		CookieModule.forRoot(),
		PerfectScrollbarModule,
		ToastrModule.forRoot(),
		NgxDatatableModule,
		MatMenuModule
	],
	providers: [
		AuthService,
		AuthGuard,
		UnAuthGuard,
		AccessGuard,
		CookieService,
		CookieStorage,
		LocalStorage,
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{ provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		WINDOW_PROVIDERS,
	],
	bootstrap: [AppComponent],
})
export class AppModule { }