import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { Location } from '@angular/common';
// import { Router, NavigationEnd } from "@angular/router";
// import { filter } from "rxjs/operators";

import { DataService } from "./shared/services";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
	subscription: Subscription;
	currentUrl: string = '';
	// private router: Router
	constructor(public ds: DataService, public location: Location, public router: Router) {
		//hide overlay class on router change
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: any) => {
			this.currentUrl = routeChange.url;
		});
	}

	ngOnInit() {
		// this.subscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
		// 	window.scrollTo(0, 0);
		// });
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}